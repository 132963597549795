import { Component, Input } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { TYPE_TOAST } from 'src/app/system/core/enums/ToastsEnums';
import { MipFormsService } from 'src/app/system/services/MipForms.service';
import { LoadingService } from 'src/app/system/services/loading.service';
import { MessagesService } from 'src/app/system/services/message.service';
import { AddUpdatePlaguesComponent } from '../add-update-plagues/add-update-plagues.component';

@Component({
  selector: 'app-table-plagues',
  templateUrl: './table-plagues.component.html',
  styleUrls: ['./table-plagues.component.scss']
})
export class TablePlaguesComponent {
  @Input() Inspection:any;
  loading: boolean = false;
  index : number = 0;
  Evidences:any[] = [];
  constructor( private MipFormsService:MipFormsService, private LoadingService:LoadingService, private DialogService:DialogService, private MessagesService:MessagesService){

  }

  handleChange(event: any) {
    this.index = event.index;
  }
    ngOnInit(): void {

  setTimeout(() => {
    this.getInspectionData();
  }, 0);
    }

    getInspectionData(){
      this.LoadingService.Show();
      this.MipFormsService.getInspectionData(this.Inspection.id).subscribe((res) => {

      this.Evidences = res;
        this.LoadingService.Hide();

      });
    }


    AddUpdate(Plague: any,) {
      const dialog = this.DialogService.open(AddUpdatePlaguesComponent, {
       header: "Plaga",
       modal: true,
       width: '40rem',
       //height:"100%",
       closable: true,
       draggable: false,
       maximizable: false,
       resizable: true,
       closeOnEscape: true,
       dismissableMask: true,
       data: {
         Inspection:this.Inspection,
         Plague
       },
     });
     dialog.onClose.subscribe((res) => {
       if (res?.success) {
         this.getInspectionData();
       }
     });

   }

   deleteItem(item: any) {
    this.MessagesService.confirm(
      '¿Esta seguro de eliminar el registro ' + item.plague.name + ' ?',
      () => {
        this.MipFormsService.deleteDataForm(
          item.id,
          'inspection-forms-data'
        ).subscribe((res) => {
          this.MessagesService.ShowMessage(TYPE_TOAST.SUCCESS,"Correcto","Se ha eliminado correctamente")
          this.getInspectionData();
        });
      }
    );
  }
}
