import { Component, ElementRef, inject, OnInit, ViewChild } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { LayoutService } from 'src/app/layout/service/app.layout.service';
import { Employee, JobCenter } from 'src/app/system/core/models/employee.interface';
import { AddUpdateEmployeeComponent } from 'src/app/system/Panel/administrative/humanresources/employees/components/add-update-employee/add-update-employee.component';
import { AuthService } from 'src/app/system/services/auth.service';

import { JobCenterService } from 'src/app/system/services/job_center.service';
import { MessagesService } from 'src/app/system/services/message.service';
import { PermissionsServices } from 'src/app/system/services/permissions.service';
import { UserService } from '../../../services/user.service';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss']
})
export class TopbarComponent implements OnInit {
  public permissionsService = inject(PermissionsServices);

  @ViewChild('searchinput') searchInput!: ElementRef;
  @ViewChild('menubutton') menuButton!: ElementRef;

  public menu: MenuItem[] = [];
  public jobCenters: JobCenter[] = [];
  public searchActive: boolean = false;
  public employee!: Employee;
  public isLoading: boolean = false;

  constructor(
    public layoutService: LayoutService,
    private DialogService: DialogService,
    public AuthService: AuthService,
    public UserService: UserService,
    private JobCenterService: JobCenterService,
    private MessagesService: MessagesService
  ) { }

  ngOnInit(): void {
    this.employee = this.UserService.Employee;
  }

  onMenuButtonClick() {
    this.layoutService.onMenuToggle();
  }

  removeTab(event: MouseEvent, item: MenuItem, index: number) {
    this.layoutService.onTabClose(item, index);
    event.preventDefault();
  }

  get layoutTheme(): string {
    return this.layoutService.config.layoutTheme;
  }

  get colorScheme(): string {
    return this.layoutService.config.colorScheme;
  }

  get tabs(): MenuItem[] {
    return this.layoutService.tabs;
  }

  getPicture(): string {
    return this.UserService.profilePhoto;
  }

  //   https://pestwareapp.com/landing/images/pestware-cuadre.png
  profile() {
    this.employee.name = "as"
  }

  viewProfile() {
    let title = 'Mi perfil';

    const dialog = this.DialogService.open(AddUpdateEmployeeComponent, {
      header: title,
      modal: true,
      width: '50%',
      //height:"100%",
      closable: true,
      draggable: false,
      maximizable: false,
      resizable: true,
      closeOnEscape: true,
      dismissableMask: true,
      data: {
        item: this.UserService.Employee,
        isMain: true,
        main_customer: '',
      },
    });

    dialog.onClose.subscribe((res) => { });
  }

  getJobCenters() {
    if (this.jobCenters.length > 0) return;

    this.isLoading = true;

    let form = {
      company: this.UserService.Company.id,
    };

    this.JobCenterService.getJobCenters(form).subscribe(
      (response: JobCenter[]) => {
        console.log(response);
        this.jobCenters = response;
        this.isLoading = false;
      }
    );
  }

  changeJobCenter(job_center: JobCenter) {
    this.MessagesService.confirm(
      '¿ Está seguro de cambiar de centro de trabajo ?',
      () => {
        this.UserService.Employee.job_center = job_center;
        localStorage.setItem('employee', JSON.stringify(this.UserService.Employee))
        location.reload();
      }
    );
  }
}
