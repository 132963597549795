
<div *appHasPermission="permissionsService.PERMISSIONS_ADD.MIP">
    <button *ngIf="Conditions.length ==0" (click)="addCondition(null)" pButton class="mb-4 p-button-raised w-full sm:w-auto flex-order-0 sm:flex-order-1  p-button-info" icon="pi pi-plus" severity="info" label="Nueva Condición"></button>

</div>
<h3 *ngIf="Conditions.length ==0">No tienes Condiciones</h3>
<h5 *ngIf="Conditions.length >0">Condiciones</h5>




<p-table
class="custom-table "
styleClass="p-datatable-sm"
scrollDirection="horizontal"
#dtCalendar
[value]="Conditions"
dataKey="id"
sortMode="multiple"
[rows]="10"
[showCurrentPageReport]="true"
[rowsPerPageOptions]="[10, 25, 50]"
[paginator]="false"
[globalFilterFields]="['name']">
  <ng-template pTemplate="header">
      <tr>
          <th      class="white-space-nowrap bg-primary " style="width: 5rem"></th>
          <th     class="white-space-nowrap bg-primary " >Nombre Condición </th>
          <th     class="white-space-nowrap bg-primary ">Acciones</th>

      </tr>
  </ng-template>
  <ng-template pTemplate="body" let-condition let-expanded="expanded">
      <tr class="border-bottom">
          <td>
              <button type="button" pButton pRipple [pRowToggler]="condition" class="p-button-text p-button-rounded p-button-plain fw-bold" [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
          </td>
          <td><span class="fw-bold h5">{{ condition.name }}</span></td>
          <td >
            <p-button
            *appHasPermission="permissionsService.PERMISSIONS_ADD.MIP"
            (click)="deleteItem(condition)"
          type="button"
          icon="fa-solid fa-trash"
          styleClass="p-button-rounded p-button-outlined p-button-danger p-button-sm ml-2 btn-sm"
          >
          </p-button>

          </td>
      </tr>
  </ng-template>

  <ng-template pTemplate="rowexpansion" let-condition>
    <button *appHasPermission="permissionsService.PERMISSIONS_ADD.MIP" (click)="AddUpdateCleaning(null,condition)" pButton class="mb-4 p-button-outlined btn-sm mt-3 ms-5" icon="pi pi-plus" severity="info" label="Agregar tipo de condición"></button>
    <button (click)="viewImages(condition)" pButton class="mb-4 p-button-outlined btn-sm mt-3 ms-2" icon="pi pi-image" severity="info" label="Ver imagenes"></button>

      <tr>
          <td colspan="7">
              <div class="p-3 mx-3">
              <app-table-cleanings #tableRef [Condition] ="condition"></app-table-cleanings>
              </div>
          </td>
      </tr>
  </ng-template>
</p-table>

