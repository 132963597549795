import { Component, Inject, OnInit, ViewChild, inject } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { MIP_FORM } from 'src/app/system/core/enums/MipForms.model';
import { TYPE_TOAST } from 'src/app/system/core/enums/ToastsEnums';
import { MipFormsService } from 'src/app/system/services/MipForms.service';
import { LoadingService } from 'src/app/system/services/loading.service';
import { MessagesService } from 'src/app/system/services/message.service';

import { TableCleaningsComponent } from './components/table-cleanings/table-cleanings.component';
import { AddUpdateCleaningComponent } from './components/add-update-cleaning/add-update-cleaning.component';
import { PhotosCondicionesComponent } from './components/photos-condiciones/photos-condiciones.component';
import { PermissionsServices } from 'src/app/system/services/permissions.service';

@Component({
  selector: 'app-condition',
  templateUrl: './condition.component.html',
  styleUrls: ['./condition.component.scss']
})
export class ConditionComponent implements OnInit {
  @ViewChild(TableCleaningsComponent, { static: false }) tableRef!: TableCleaningsComponent;
  public permissionsService = inject(PermissionsServices);
  index : number = 0;
  activeTabIndex: { [key: string]: number } = {};

Conditions:any[] = [];
constructor(@Inject('dynamicData') public Event: any, private MipFormsService:MipFormsService, private LoadingService:LoadingService, private DialogService:DialogService, private MessagesService:MessagesService){

}
handleChange(event: any) {
  this.index = event.index;
}
activeAccordionId: string | null = null;


handleAccordionClick(accordionId: string) {
  if (this.activeAccordionId === accordionId) {
    this.activeAccordionId = null;
  } else {
    this.activeAccordionId = accordionId;
  }
}
  ngOnInit(): void {


setTimeout(() => {
  this.getConditions();
}, 0);
  }

  getConditions(){
    this.LoadingService.Show();
    this.MipFormsService.getDataForm(this.Event.id,MIP_FORM.CONDITION_FORM).subscribe((res) => {
      this.Conditions = res;
      this.LoadingService.Hide();

    });
  }



  deleteItem(item: any) {
    this.MessagesService.confirm(
      '¿Esta seguro de eliminar la condición ' + item?.name + ' ?',
      () => {
        this.MipFormsService.deleteDataForm(
          item.id,
          'condition-forms'
        ).subscribe((res) => {
          this.MessagesService.ShowMessage(TYPE_TOAST.SUCCESS,"Correcto","Se ha eliminado correctamente")
          this.getConditions();
        });
      }
    );
  }




  AddUpdateCleaning(Cleaning: any,Condition:any) {
    const dialog = this.DialogService.open(AddUpdateCleaningComponent, {
     header: "Limpieza",
     modal: true,
     width: '30rem',
     closable: true,
     draggable: false,
     maximizable: false,
     resizable: true,
     closeOnEscape: true,
     dismissableMask: true,
     data: {
      Condition,
      Event:this.Event,
       Cleaning
     },
   });
   dialog.onClose.subscribe((res) => {
     if (res?.success) {
      this.tableRef.getCleanings();
     }
   });

 }


 viewImages( Condition:any) {
  const dialog = this.DialogService.open(PhotosCondicionesComponent, {
   header: "Fotos Condiciones " + Condition.name,
   modal: true,
   width: '50rem',
   //height:"100%",
   closable: true,
   draggable: false,
   maximizable: false,
   resizable: true,
   closeOnEscape: true,
   dismissableMask: true,
   data: {
    Condition,
   },
 });
 dialog.onClose.subscribe((res) => {
   if (res?.success) {

   }
 });

}

addCondition(){
this.LoadingService.Show()

    let data = {
      name:"Condición-1",
      event:this.Event.id,
    }

      this.MipFormsService.saveDataForm(data, MIP_FORM.CONDITION_FORM).subscribe((res)=>{
          this.LoadingService.Hide();
          this.MessagesService.ShowMessage(
              TYPE_TOAST.SUCCESS,
              'Correcto',
              'Guardado correctamente'
          );
       this.getConditions();
        });


}


}
