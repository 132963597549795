<div class="service-order px-5 mx-5">
  <div class="section bg-primary">
    <h2 class="p-2">
      {{ Event?.title }}
    </h2>
  </div>

  <div class="">
    <div class="modal-container card">
      <h2 class="event-title">
        {{ Event?.title }}
      </h2>

      <div class="">
        <div class="modal-image d-flex justify-content-between">
          <p-image [src]="Event.customer?.logo" alt="Logotipo" width="100" height="100" [preview]="true" />

          <div class="ms-3">
            <h2 class="service-title mb-2">👤 Cliente</h2>

            <div class="service-details mt-0">
              <p>
                <strong>🖋️ Nombre: </strong>
                {{ Event?.customer.name }}
              </p>

              <p>
                <strong>📧 Correo: </strong>
                {{ Event?.customer.email }}
              </p>

              <p>
                <strong>📞 Contacto: </strong>
                {{ Event?.customer.contact_phone }} || {{ Event?.customer.phone }}
              </p>
            </div>
          </div>

          <div class="modal-info ms-5">
            <h2 class="service-title mb-2">🛠 Orden de Servicio</h2>

            <div class="service-details mt-0">
              <p>
                <strong>👷 Técnico: </strong>
                {{ Event?.employee[0].name }}
              </p>

              <p>
                <strong>💰 Importe: </strong>
                {{ Symbol_Country }} {{ Event.total | number:'1.2-2' }}
              </p>

              <p>
                <strong>📅 Fecha: </strong>
                {{ Event?.initial_date }}
              </p>

              <p>
                <strong>🕒 Hora Inicial: </strong>
                {{ Event?.initial_hour }}
              </p>

              <p>
                <strong>🕓 Hora Final: </strong>
                {{ Event?.final_hour }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <h3 class="fw-bold mb-4 pb-0 mt-4">
    FORMULARIOS MIP
  </h3>

  <div class="section">
    <h3 class="bg-primary">
      INSPECCIONES
    </h3>

    <div class="mt-3">
      <p-accordion [multiple]="true" [(activeIndex)]="activeIndexesInspections">
        <p-accordionTab
          *ngFor="let inspection of inspections"
          [header]="inspection.plague_category"
        >
          <div class="mb-3">
            <p-table
              [value]="inspection.plagues"
              styleClass="p-datatable-sm p-datatable-gridlines"
              [responsive]="true"
            >
              <ng-template pTemplate="header">
                <tr>
                  <th class="bg-primary text-white">Plaga</th>
                  <th class="bg-primary text-white">Grado de Infestación</th>
                  <th class="bg-primary text-white">Ciclo de Vida</th>
                  <th class="bg-primary text-white">Evidencias</th>
                  <th class="bg-primary text-white">Áreas</th>
                  <th class="bg-primary text-white">Comentarios</th>
                </tr>
              </ng-template>

              <ng-template pTemplate="body" let-plague>
                <tr>
                  <td>{{ plague.plague__name }}</td>
                  <td>{{ plague.infestation_degree__name?.join(', ') || '' }}</td>
                  <td>{{ plague.life_cycle_plague__name?.join(', ') || '' }}</td>
                  <td>{{ plague.evidence_type__name?.join(', ') || '' }}</td>
                  <td>{{ plague.nesting_area__name?.join(', ') || '' }}</td>
                  <td>{{ plague.comments || '' }}</td>
                </tr>
              </ng-template>

              <ng-template pTemplate="emptymessage">
                <tr>
                  <td colspan="6" class="text-center">
                    No se encontraron plagas en esta categoría
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>

          <div *ngIf="inspection.photos?.length > 0">
            <h5 class="mb-2">Fotografías</h5>

            <div class="documents-scroller">
              <div *ngFor="let photo of inspection.photos" class="photo-item">
                <p-image
                  [src]="s3Url + photo.photo"
                  alt="Evidencia"
                  width="100"
                  height="180"
                  [preview]="true"
                  class="photo-image"
                >
                </p-image>

                <div class="document-comment p-2">
                  {{ photo.nesting_area__name ? 'Área: ' + photo.nesting_area__name : 'Sin área especificada' }}
                </div>
              </div>
            </div>
          </div>
        </p-accordionTab>
      </p-accordion>

      <div *ngIf="inspections.length === 0" class="text-center p-3">
        No se encontraron inspecciones registradas
      </div>
    </div>
  </div>

  <div class="section">
    <h3 class="bg-primary">
      CONDICIONES
    </h3>

    <div class="mt-3">
      <p-accordion [multiple]="true" [(activeIndex)]="activeIndexesConditions">
        <p-accordionTab *ngFor="let condition of conditions" [header]="condition.condition">
          <div class="mb-3">
            <p-table
              [value]="condition.cleaning"
              styleClass="p-datatable-sm p-datatable-gridlines"
              [responsive]="true"
            >
              <ng-template pTemplate="header">
                <tr>
                  <th class="bg-primary text-white">Tipo de Limpieza</th>
                  <th class="bg-primary text-white">Áreas</th>
                  <th class="bg-primary text-white">Acciones Preventivas</th>
                  <th class="bg-primary text-white">Acciones Correctivas</th>
                  <th class="bg-primary text-white">Comentarios</th>
                </tr>
              </ng-template>

              <ng-template pTemplate="body" let-cleaning>
                <tr>
                  <td>{{ cleaning.cleaning || '' }}</td>

                  <td>
                    <span *ngIf="cleaning.nesting_area__name?.length > 0">
                      {{ cleaning.nesting_area__name.join(', ') }}
                    </span>

                    <span *ngIf="!cleaning.nesting_area__name?.length">N/A</span>
                  </td>

                  <td>{{ cleaning.preventive_action || '' }}</td>
                  <td>{{ cleaning.action_corrective || '' }}</td>
                  <td>{{ cleaning.comments || '' }}</td>
                </tr>
              </ng-template>

              <ng-template pTemplate="emptymessage">
                <tr>
                  <td colspan="5" class="text-center">
                    No se encontraron registros de limpieza para esta condición
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>

          <div *ngIf="condition.photos?.length > 0">
            <h5 class="mb-2">
              Fotografías
            </h5>

            <div class="documents-scroller">
              <div *ngFor="let photo of condition.photos" class="photo-item">
                <p-image
                  [src]="s3Url + photo.photo"
                  alt="Evidencia"
                  width="100"
                  height="180"
                  [preview]="true"
                  class="photo-image"
                >
                </p-image>

                <div class="document-comment p-2">
                  {{ photo.comments || '' }}
                </div>
              </div>
            </div>
          </div>
        </p-accordionTab>
      </p-accordion>

      <div *ngIf="conditions.length === 0" class="text-center p-3">
        No se encontraron condiciones registradas
      </div>
    </div>
  </div>

  <div class="section">
    <h3 class="bg-primary">
      CONTROL DE PLAGAS
    </h3>

    <div class="mt-3">
      <p-accordion [multiple]="true" [(activeIndex)]="activeIndexesPestControls">
        <p-accordionTab *ngFor="let control of pestControls" [header]="control.controlForm">
          <div class="mb-3">
            <p-table
              [value]="control.controlFormProducts"
              styleClass="p-datatable-sm p-datatable-gridlines"
              [responsive]="true"
            >
              <ng-template pTemplate="header">
                <tr>
                  <th class="bg-primary text-white">Áreas</th>
                  <th class="bg-primary text-white">Producto</th>
                  <th class="bg-primary text-white">Cantidad</th>
                  <th class="bg-primary text-white">Dosis</th>
                  <th class="bg-primary text-white">Comentarios</th>
                </tr>
              </ng-template>

              <ng-template pTemplate="body" let-product>
                <tr>
                  <td>
                    <span *ngIf="product.nesting_area__name?.length > 0">
                      {{ product.nesting_area__name.join(', ') }}
                    </span>

                    <span *ngIf="!product.nesting_area__name?.length">N/A</span>
                  </td>

                  <td>
                    {{ product.product || '' }} <br>
                    <span class="fw-bold">Lote: </span> {{ product.batch || '' }} <br>
                    <span class="fw-bold">Registro: </span> {{ product.register || '' }} <br>
                  </td>

                  <td>{{ product.quantity || '0' }} {{ product.unit || '' }}</td>

                  <td>
                    {{ product.dose.join(' ') || '' }}
                  </td>

                  <td>{{ product.comments || '' }}</td>
                </tr>
              </ng-template>

              <ng-template pTemplate="emptymessage">
                <tr>
                  <td colspan="5" class="text-center">
                    No se encontraron productos de control registrados
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>

          <div *ngIf="control.photos?.length > 0">
            <h5 class="mb-2">Fotografías</h5>
            <div class="documents-scroller">
              <div *ngFor="let photo of control.photos" class="photo-item">
                <p-image
                  [src]="s3Url + photo.photo"
                  alt="Evidencia"
                  width="100"
                  height="180"
                  [preview]="true"
                  class="photo-image"
                >
                </p-image>

                <div class="document-comment p-2">
                  {{ photo.comments || '' }}
                </div>
              </div>
            </div>
          </div>
        </p-accordionTab>
      </p-accordion>

      <div *ngIf="pestControls.length === 0" class="text-center p-3">
        No se encontraron controles de plagas registrados
      </div>
    </div>
  </div>

  <div class="section">
    <h3 class="bg-primary">
      MONITOREO DE ESTACIONES
    </h3>

    <div class="mt-3">
      <div class="mb-3">
        <button
          pButton
          type="button"
          icon="pi pi-plus"
          label="Agregar"
          class="p-button-sm"
          (click)="AddUpdateStationMonitoring(null)">
        </button>
      </div>

      <p-table
        [value]="stationsMonitoring"
        styleClass="p-datatable-striped p-datatable-sm"
        [responsive]="true"
      >
        <ng-template pTemplate="header">
          <tr>
            <th class="bg-primary text-white">Estación</th>
            <th class="bg-primary text-white">Condiciones en estación</th>
            <th class="bg-primary text-white">Tipo de actividades en estación</th>
            <th class="bg-primary text-white">Actividades en estación</th>
            <th class="bg-primary text-white">Plagas</th>
            <th class="bg-primary text-white">Comentarios</th>
            <th class="bg-primary text-white">Acciones</th>
          </tr>
        </ng-template>

        <ng-template pTemplate="body" let-station>
          <tr>
            <td class="font-bold">
              {{ station.sation.station.join(' - ') }}
            </td>

            <td>{{ station.sation.condition.join(', ') }}</td>
            <td>{{ station.sation.typeActivity.join(', ') }}</td>
            <td>{{ station.sation.activity.join(', ') }}</td>

            <td>
              <span *ngFor="let plague of station.sation.plague">
                {{ plague[0] + ' (' + plague[1] + ')' }} <br />
              </span>
            </td>

            <td>{{ station.sation.comments || '' }}</td>

            <td class="table-actions">
              <button
                pButton
                type="button"
                icon="pi pi-pencil"
                class="p-button-rounded p-button-text p-button-sm edit-button"
                (click)="AddUpdateStationMonitoring(station)"
              ></button>

              <button
                pButton
                type="button"
                icon="pi pi-trash"
                class="p-button-rounded p-button-text p-button-sm delete-button"
                (click)="DeleteStationMonitoring(station)"
              ></button>
            </td>
          </tr>

          <tr>
            <td colspan="7">
              <div>
                <h6 class="mb-2 mt-2">
                  Fotografías
                </h6>

                <div class="documents-scroller">
                  <div class="photo-item add-photo">
                    <button
                      type="button"
                      class="add-photo-button"
                      (click)="AddUpdateStationMonitoringPhotos(station, null)"
                    >
                      <i class="pi pi-plus icon"></i>
                    </button>

                    <div class="document-comment p-2">
                      Agregar foto
                    </div>
                  </div>

                  <div *ngFor="let photo of station.photos" class="photo-item">
                    <div class="photo-actions">
                      <button
                        pButton
                        type="button"
                        icon="fas fa-pencil-alt"
                        class="p-button-rounded p-button-text p-button-sm edit-button"
                        severity="warning"
                        (click)="AddUpdateStationMonitoringPhotos(station, photo)"
                        size="small"
                      ></button>

                      <button
                        pButton
                        type="button"
                        icon="fas fa-trash-alt"
                        class="p-button-rounded p-button-text p-button-sm delete-button"
                        severity="danger"
                        (click)="DeleteStationMonitoringPhoto(photo)"
                        size="small"
                      ></button>
                    </div>

                    <p-image
                      [src]="getDocumentUrl(photo.photo)"
                      alt="Evidencia"
                      width="100"
                      height="180"
                      [preview]="true"
                      class="photo-image"
                    >
                    </p-image>

                    <div class="document-comment p-2">
                      {{ photo.comments || '' }}
                    </div>
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </ng-template>

        <ng-template pTemplate="emptymessage" let-columns>
          <tr>
            <td [colSpan]="7" class="text-center">
              No se encontraron registros de monitoreo de estaciones
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>

  <div class="section">
    <h3 class="bg-primary">
      PLANES DE ACCIÓN
    </h3>

    <div class="mt-3">
      <div class="mb-3">
        <button
          pButton
          type="button"
          icon="pi pi-plus"
          label="Agregar"
          class="p-button-sm"
          (click)="AddUpdateActionPlan(null)">
        </button>
      </div>

      <p-table
        [value]="actionPlans"
        styleClass="p-datatable-striped p-datatable-sm"
        [responsive]="true"
      >
        <ng-template pTemplate="header">
          <tr>
            <th class="bg-primary text-white">Folio</th>
            <th class="bg-primary text-white">Descripción</th>
            <th class="bg-primary text-white">Causa Raíz</th>
            <th class="bg-primary text-white">Calificación</th>
            <th class="bg-primary text-white"># de tareas</th>
            <th class="bg-primary text-white">Acciones</th>
          </tr>
        </ng-template>

        <ng-template pTemplate="body" let-plan>
          <tr>
            <td class="font-bold">{{ plan.folio }}</td>

            <td>{{ plan.description || '' }}</td>
            <td>{{ plan.root_cause__name || '' }}</td>
            <td>{{ plan.qualification__name || '' }}</td>
            <td>{{ plan.task_count || 0 }}</td>

            <td class="table-actions">
              <button
                pButton
                type="button"
                icon="pi pi-pencil"
                class="p-button-rounded p-button-text p-button-sm edit-button"
                (click)="AddUpdateActionPlan(plan)"
              ></button>

              <button
                pButton
                type="button"
                icon="pi pi-trash"
                class="p-button-rounded p-button-text p-button-sm delete-button"
                (click)="DeleteActionPlan(plan)"
              ></button>
            </td>
          </tr>
        </ng-template>

        <ng-template pTemplate="emptymessage" let-columns>
          <tr>
            <td [colSpan]="6" class="text-center">
              No se encontraron planes de acción registrados
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>

  <div class="section">
    <h3 class="bg-primary">
      TAREAS
    </h3>

    <div class="mt-3">
      <div class="mb-3">
        <button
          pButton
          type="button"
          icon="pi pi-plus"
          label="Agregar"
          class="p-button-sm"
          (click)="AddUpdateTask(null)">
        </button>
      </div>

      <p-table
        [value]="tasks"
        styleClass="p-datatable-striped p-datatable-sm"
        [responsive]="true"
      >
        <ng-template pTemplate="header">
          <tr class="bg-primary text-white">
            <th class="bg-primary text-white">Folio</th>
            <th class="bg-primary text-white">Título</th>
            <th class="bg-primary text-white">Descripción</th>
            <th class="bg-primary text-white">Estatus</th>
            <th class="bg-primary text-white">Acciones</th>
          </tr>
        </ng-template>

        <ng-template pTemplate="body" let-task>
          <tr>
            <td class="fw-bold">{{ task.folio }}</td>
            <td>{{ task.title || '' }}</td>
            <td>{{ task.description || '' }}</td>

            <td>
              <p-tag
                [value]="task.status__name"
                [severity]="getStatusSeverity(task.status__name)"
              ></p-tag>
            </td>

            <td class="table-actions">
              <button
                pButton
                type="button"
                icon="pi pi-pencil"
                class="p-button-rounded p-button-text p-button-sm edit-button"
                (click)="AddUpdateTask(task)"
              ></button>

              <button
                pButton
                type="button"
                icon="pi pi-trash"
                class="p-button-rounded p-button-text p-button-sm delete-button"
                (click)="DeleteTask(task)"
              ></button>
            </td>
          </tr>
        </ng-template>

        <ng-template pTemplate="emptymessage" let-columns>
          <tr>
            <td [colSpan]="5" class="text-center">
              No se encontraron tareas registradas
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>

  <div class="section">
    <h3 class="bg-primary">
      PAGOS
    </h3>

    <div class="mt-3">
      <div class="mb-3">
        <button
          pButton
          type="button"
          icon="pi pi-plus"
          label="Agregar"
          class="p-button-sm"
          (click)="AddUpdatePayment(null)">
        </button>
      </div>

      <p-table
        [value]="payments"
        styleClass="p-datatable-striped p-datatable-sm"
        [responsive]="true"
      >
        <ng-template pTemplate="header">
          <tr>
            <th class="bg-primary text-white">Método de Pago</th>
            <th class="bg-primary text-white">Forma de Pago</th>
            <th class="bg-primary text-white">Cantidad</th>
            <th class="bg-primary text-white">Días de crédito</th>
            <th class="bg-primary text-white">Acciones</th>
          </tr>
        </ng-template>

        <ng-template pTemplate="body" let-payment>
          <tr>
            <td>{{ payment.payment_method__name || '' }}</td>
            <td>{{ payment.payment_way__name || '' }}</td>
            <td>{{ Symbol_Country }} {{ payment.amount | number:'1.2-2' }}</td>
            <td>{{ payment.payment_way__credit_days || 0 }}</td>

            <td class="table-actions">
              <button
                pButton
                type="button"
                icon="pi pi-pencil"
                class="p-button-rounded p-button-text p-button-sm edit-button"
                (click)="AddUpdatePayment(payment)"
              ></button>

              <button
                pButton
                type="button"
                icon="pi pi-trash"
                class="p-button-rounded p-button-text p-button-sm delete-button"
                (click)="DeletePayment(payment)"
              ></button>
            </td>
          </tr>
        </ng-template>

        <ng-template pTemplate="emptymessage" let-columns>
          <tr>
            <td [colSpan]="5" class="text-center">
              No se encontraron registros de pago
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>

  <div class="section">
    <h3 class="bg-primary">
      FIRMAS
    </h3>

    <div class="mt-3">
      <div class="mb-3">
        <button
          pButton
          type="button"
          icon="pi pi-plus"
          label="Agregar"
          class="p-button-sm"
          (click)="AddUpdateSignature(null)">
        </button>
      </div>

      <p-table
        [value]="signatures"
        styleClass="p-datatable-striped p-datatable-sm"
        [responsive]="true"
      >
        <ng-template pTemplate="header">
          <tr>
            <th class="bg-primary text-white">Nombre</th>
            <th class="bg-primary text-white">Puesto</th>
            <th class="bg-primary text-white">Comentarios</th>
            <th class="bg-primary text-white">Firma</th>
            <th class="bg-primary text-white">Acciones</th>
          </tr>
        </ng-template>

        <ng-template pTemplate="body" let-signature>
          <tr>
            <td>{{ signature.name }}</td>
            <td>{{ signature.job_position }}</td>
            <td>{{ signature.comments || '' }}</td>

            <td class="table-actions">
              <p-image
                [src]="getDocumentUrl(signature.signature)"
                alt="Firma"
                width="120"
                height="60"
                class="signature-image"
              >
              </p-image>
            </td>

            <td>
              <button
                pButton
                type="button"
                icon="pi pi-pencil"
                class="p-button-rounded p-button-text p-button-sm edit-button"
                (click)="AddUpdateSignature(signature)"
              ></button>

              <button
                pButton
                type="button"
                icon="pi pi-trash"
                class="p-button-rounded p-button-text p-button-sm delete-button"
                (click)="DeleteSignature(signature)"
              ></button>
            </td>
          </tr>
        </ng-template>

        <ng-template pTemplate="emptymessage" let-columns>
          <tr>
            <td colSpan="5" class="text-center">
              No se encontraron firmas registradas
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>

  <div class="section">
    <h3 class="bg-primary">
      MONITOREO DE ÁREAS
    </h3>

    <div class="mt-3">
      <div class="mb-3">
        <button
          pButton
          type="button"
          icon="pi pi-plus"
          label="Agregar"
          class="p-button-sm"
          (click)="AddUpdateAreaMonitoring(null)">
        </button>
      </div>

      <p-table
        [value]="areasActivity"
        styleClass="p-datatable-striped p-datatable-sm"
        [responsive]="true"
      >
        <ng-template pTemplate="header">
          <tr>
            <th class="bg-primary text-white">Área</th>
            <th class="bg-primary text-white">Actividades en el área</th>
            <th class="bg-primary text-white">Condiciones en el área</th>
            <th class="bg-primary text-white">Plagas</th>
            <th class="bg-primary text-white">Comentarios</th>
            <th class="bg-primary text-white">Acciones</th>
          </tr>
        </ng-template>

        <ng-template pTemplate="body" let-station>
          <tr>
            <td class="font-bold">
              {{ station.area.nestingArea }}
            </td>

            <td>{{ station.area.activity_area.join(', ') }}</td>
            <td>{{ station.area.condition_area.join(', ') }}</td>
            <td>{{ station.area.plague.join(', ') }}</td>

            <td>{{ station.area.comments || '' }}</td>

            <td class="table-actions">
              <button
                pButton
                type="button"
                icon="pi pi-pencil"
                class="p-button-rounded p-button-text p-button-sm edit-button"
                (click)="AddUpdateAreaMonitoring(station)"
              ></button>

              <button
                pButton
                type="button"
                icon="pi pi-trash"
                class="p-button-rounded p-button-text p-button-sm delete-button"
                (click)="DeleteAreaMonitoring(station)"
              ></button>
            </td>
          </tr>

          <tr>
            <td colspan="6">
              <div>
                <h6 class="mb-2 mt-2">
                  Fotografías
                </h6>

                <div class="documents-scroller">
                  <div class="photo-item add-photo">
                    <button
                      type="button"
                      class="add-photo-button"
                      (click)="AddUpdateAreaMonitoringPhotos(station, null)"
                    >
                      <i class="pi pi-plus icon"></i>
                    </button>

                    <div class="document-comment p-2">
                      Agregar foto
                    </div>
                  </div>

                  <div *ngFor="let photo of station.photos" class="photo-item">
                    <div class="photo-actions">
                      <button
                        pButton
                        type="button"
                        icon="fas fa-pencil-alt"
                        class="p-button-rounded p-button-text p-button-sm edit-button"
                        severity="warning"
                        (click)="AddUpdateAreaMonitoringPhotos(station, photo)"
                        size="small"
                      ></button>

                      <button
                        pButton
                        type="button"
                        icon="fas fa-trash-alt"
                        class="p-button-rounded p-button-text p-button-sm delete-button"
                        severity="danger"
                        (click)="DeleteAreaMonitoringPhoto(photo)"
                        size="small"
                      ></button>
                    </div>

                    <p-image
                      [src]="getDocumentUrl(photo.photo)"
                      alt="Evidencia"
                      width="100"
                      height="180"
                      [preview]="true"
                      class="photo-image"
                    >
                    </p-image>

                    <div class="document-comment p-2">
                      {{ photo.comments || '' }}
                    </div>
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </ng-template>

        <ng-template pTemplate="emptymessage" let-columns>
          <tr>
            <td [colSpan]="6" class="text-center">
              No se encontraron registros de monitoreo de áreas
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>

  <div class="section">
    <h3 class="bg-primary">
      DOCUMENTOS
    </h3>

    <div class="documents-scroller mt-3">
      <div class="document-item add-document">
        <button
          type="button"
          class="add-button"
          (click)="AddUpdateDocument(null)"
        >
          <i class="fas fa-plus icon"></i>
        </button>

        <div class="document-comment p-2">
          Agregar documento
        </div>
      </div>

      <ng-container *ngIf="documents.length > 0; else noDocuments">
        <div class="document-item" *ngFor="let document of documents">
          <div class="document-actions">
            <button
              *ngIf="isPdfDocument(document.document)"
              pButton
              type="button"
              icon="fas fa-external-link-alt"
              class="p-button-rounded p-button-text pdf-button"
              severity="info"
              (click)="openPdfInNewWindow(document.document)"
            ></button>

            <button
              pButton
              type="button"
              icon="fas fa-pencil-alt"
              class="p-button-rounded p-button-text edit-button"
              severity="warning"
              (click)="AddUpdateDocument(document)"
            ></button>

            <button
              pButton
              type="button"
              icon="fas fa-trash-alt"
              class="p-button-rounded p-button-text delete-button"
              severity="danger"
              (click)="DeleteDocument(document)"
            ></button>
          </div>

          <div *ngIf="isPdfDocument(document.document); else imagePreview">
            <div class="pdf-preview">
              <i class="fas fa-file-pdf pdf-icon"></i>
              <p>
                {{ document.name }}
              </p>
            </div>
          </div>

          <ng-template #imagePreview>
            <p-image
              [src]="getDocumentUrl(document.document)"
              [alt]="document.name"
              width="200"
              height="355"
              [preview]="true"
              class="document-image"
            ></p-image>
          </ng-template>

          <div class="document-comment p-2">
            {{ document.comments || '' }}
          </div>
        </div>
      </ng-container>

      <ng-template #noDocuments>
        <div>
          <p class="text-center">
            No se encontraron documentos registrados
          </p>
        </div>
      </ng-template>
    </div>
  </div>

  <div class="d-flex justify-content-center mt-4 mb-5" *ngIf="Event?.status.key_string != 'event-completed'">
    <button
      class="finish-button"
      (click)="FinishEvent()"
    >
      <i class="pi pi-check"></i>
      Finalizar Servicio
    </button>
  </div>
</div>
