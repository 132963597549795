import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SignatureService {
  private baseUrl: string = environment.baseUrl

  constructor(
    private http: HttpClient
  ) { }

  sendRequest(data: any) {
    return this.http.post<any>(`${this.baseUrl}/events/${data.eventId}/send_signature/`, data);
  }
}
