








import { Component, ElementRef, QueryList, ViewChildren } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { catchError, throwError } from 'rxjs';
import { MIP_FORM } from 'src/app/system/core/enums/MipForms.model';
import { TYPE_TOAST } from 'src/app/system/core/enums/ToastsEnums';
import { CatalogsEnum } from 'src/app/system/core/enums/catalogs.model';
import { MipFormsService } from 'src/app/system/services/MipForms.service';
import { LoadingService } from 'src/app/system/services/loading.service';
import { MessagesService } from 'src/app/system/services/message.service';
import { UtilsService } from 'src/app/system/services/utils.service';

@Component({
  selector: 'app-add-update-control-form',
  templateUrl: './add-update-control-form.component.html',
  styleUrls: ['./add-update-control-form.component.scss']
})
export class AddUpdateControlFormComponent {
  @ViewChildren('buttonEl') buttonEl!: QueryList<ElementRef>;


  objectURL: string = '';
  textPhoto: string = 'Selecciona o arrastra una imagen';
  Catalog = CatalogsEnum;
  Form: FormGroup = this.FormBuilder.group({
    name: ['', [Validators.required]],
    id: [''],
    event: [''],
  });

  constructor(
    private UtilsService:UtilsService,
    private FormBuilder: FormBuilder,
    private LoadingService: LoadingService,
    public config: DynamicDialogConfig,
    public ref: DynamicDialogRef,
    private DialogService: DialogService,
    private MipFormsService: MipFormsService,
    private MessagesService: MessagesService,

  ) {}

  ngOnInit(): void {

    if (this.config.data.Control) {
      this.patchForm();
    }
  }

  patchForm() {
    let item = this.config.data.Control;

console.log(item);


    this.Form.patchValue({
      name: item.name, //cambiar por name
    id: item.id,

    });
  }

  accept() {
    this.LoadingService.Show();
    if (!this.config.data.Control) {
      this.save();
      return;
    }
    this.update();
  }
  cancel() {
    this.ref?.close();
  }

  save() {
    let data = {
      name: this.Form.value.name,
      event:this.config.data.Event.id
    };


    this.MipFormsService.saveDataForm(  data, MIP_FORM.CONTROL_FORMS).subscribe((res)=>{
      this.LoadingService.Hide();
      this.MessagesService.ShowMessage(
          TYPE_TOAST.SUCCESS,
          'Correcto',
          'Guardado correctamente'
      );
      this.ref?.close({ success: true });
    });



  }

  update() {


    let data = {
    name: this.Form.value.name,
   event:this.config.data.Event.id,
    id:this.Form.value.id,
    };






    this.MipFormsService.patchDataForm(
    data,
    MIP_FORM.CONTROL_FORMS,
      data.id
    ).pipe(
      catchError((error) => {
        console.error('Error during patch request:', error);
        return throwError(error);
      })
    ).subscribe((res) => {
      this.LoadingService.Hide();
      this.MessagesService.ShowMessage(
        TYPE_TOAST.SUCCESS,
        'Correcto',
        'Guardado correctamente'
      );
      this.ref?.close({ success: true });
    });
  }




}
