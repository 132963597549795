<h5 *ngIf="Signatures.length >0">Firma de cliente</h5>
<button   *appHasPermission="permissionsService.PERMISSIONS_ADD.MIP" (click)="AddUpdate(null)" pButton class="mb-4 p-button-raised w-full sm:w-auto flex-order-0 sm:flex-order-1  p-button-info" icon="pi pi-plus" severity="info" label="Nueva Firma"></button>

<h3 *ngIf="Signatures.length ==0">No tienes firmas</h3>

<div class="grid formgrid">
  <div  class="col-12 mb-2  lg:col-2 lg:mb-0" *ngFor="let item of Signatures">
    <div  class="card " >
    <img            [src]="item.signature + '?' + generateRandomString()" alt="signature" class="">
     <div class="text-center mt-3">
      <p class="fw-bold my-0">{{item.name}}</p>
      <p class=" my-0">{{item.job_position}}</p>
      <p-button
      *appHasPermission="permissionsService.PERMISSIONS_ADD.MIP"
      (click)="AddUpdate(item)"
        type="button"
        icon="fa-solid fa-edit"
        styleClass="p-button-rounded p-button-outlined p-button-info ms-2 btn-sm "
      >
      </p-button>
      <p-button
      *appHasPermission="permissionsService.PERMISSIONS_ADD.MIP"
      (click)="deleteItem(item)"
    type="button"
    icon="fa-solid fa-trash"
    styleClass="p-button-rounded p-button-outlined p-button-danger p-button-sm ml-2 btn-sm mt-3"
    >
    </p-button>

     </div>
    </div>
  </div>
</div>





<app-table-anexo [Event]="Event"></app-table-anexo>
