import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

import { CatalogsEnum } from 'src/app/system/core/enums/catalogs.model';
import { MIP_FORM } from 'src/app/system/core/enums/MipForms.model';
import { TYPE_TOAST } from 'src/app/system/core/enums/ToastsEnums';
import { LoadingService } from 'src/app/system/services/loading.service';
import { MessagesService } from 'src/app/system/services/message.service';
import { MipFormsService } from 'src/app/system/services/MipForms.service';

@Component({
  selector: 'app-add-update-station-monitoring',
  templateUrl: './add-update-station-monitoring.component.html',
  styleUrls: ['./add-update-station-monitoring.component.scss']
})
export class AddUpdateStationMonitoringComponent {
  Catalog = CatalogsEnum;
  Form: FormGroup = this.FormBuilder.group({
    station: ['', [Validators.required]],
    station_condition: ['', [Validators.required]],
    stationTypeActivity: ['', [Validators.required]],
    station_activity: ['', [Validators.required]],
    id: [''],
    event: [''],
    plague: ['', [Validators.required]],
    EmployeeActivity: ['', [Validators.required]],
    activityinStation: ['', [Validators.required]],
    comments: [''],
  });
  Event: any;
  plague_counts: any = [];

  constructor(
    private FormBuilder: FormBuilder,
    private LoadingService: LoadingService,
    public config: DynamicDialogConfig,
    public ref: DynamicDialogRef,
    private MipFormsService: MipFormsService,
    private MessagesService: MessagesService,
  ) { }

  ngOnInit(): void {
    this.Event = this.config.data.Event;

    if (this.config.data.Station) {
      this.patchForm();
    }

    this.listenFormPlague();
  }

  listenFormPlague() {
    this.Form.get('plague')?.valueChanges.subscribe((selectedValues) => {
      selectedValues.forEach((element: any) => {
        let rowExists = this.plague_counts.find((e: any) => e.plague === element.id);

        if (!rowExists) {
          this.plague_counts.push({
            plague: element.id,
            name: element.name,
            count_plague: 0
          });
        }
      });

      this.plague_counts = this.plague_counts.filter((item: any) =>
        selectedValues.some((selected: any) => selected.id === item.plague)
      );
    });
  }

  patchForm() {
    let item = this.config.data.Station;

    this.Form.patchValue({
      id: item.sation.id,
      event: this.Event.id,
      EmployeeActivity: item.sation.EmployeeActivity,
      activityinStation: item.sation.activityinStation,
      comments: item.sation.comments,
    });
  }

  accept() {
    this.LoadingService.Show();

    if (!this.config.data.Station) {
      this.save();
      return
    }

    this.update();
  }

  cancel() {
    this.ref?.close();
  }

  save() {
    let stationTypeActivity = this.Form.value?.stationTypeActivity.map((item: any) => item.id);
    let station_activity = this.Form.value?.station_activity.map((item: any) => item.id);
    let station_condition = this.Form.value?.station_condition.map((item: any) => item.id);

    let data = {
      event: this.Event.id,
      station: this.Form.value.station.id,
      plague_counts: this.plague_counts,
      station_condition,
      stationTypeActivity,
      station_activity,
      EmployeeActivity: this.Form.value.EmployeeActivity,
      activityinStation: this.Form.value.activityinStation,
      comments: this.Form.value.comments,
    }

    this.MipFormsService.saveDataForm(data, MIP_FORM.STATION_COUNT_DATA).subscribe((res) => {
      this.LoadingService.Hide();

      this.MessagesService.ShowMessage(
        TYPE_TOAST.SUCCESS,
        'Correcto',
        'Guardado correctamente'
      );

      this.ref?.close({
        success: true,
        station: res,
        isUpdate: false
      });
    });
  }

  update() {
    let stationTypeActivity = this.Form.value?.stationTypeActivity.map((item: any) => item.id);
    let station_activity = this.Form.value?.station_activity.map((item: any) => item.id);

    let data = {
      id: this.Form.value.id,
      event: this.Form.value.event,
      station: this.Form.value.station.id,
      plague_counts: this.plague_counts,
      station_condition: this.Form.value.station_condition.id,
      stationTypeActivity,
      station_activity,
      EmployeeActivity: this.Form.value.EmployeeActivity,
      activityinStation: this.Form.value.activityinStation,
      comments: this.Form.value.comments,
    }

    this.MipFormsService.putDataForm(data, MIP_FORM.STATION_COUNT_DATA).subscribe((res) => {
      this.LoadingService.Hide();

      this.MessagesService.ShowMessage(
        TYPE_TOAST.SUCCESS,
        'Correcto',
        'Actualizado correctamente'
      );
      this.ref?.close({
        success: true,
        station: res,
        isUpdate: true
      });
    });
  }
}
