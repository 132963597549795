import { Component, Input } from '@angular/core';
import { FormArray, FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DialogService } from 'primeng/dynamicdialog';
import { ProductService } from 'src/app/demo/service/product.service';
import { TYPE_TOAST } from 'src/app/system/core/enums/ToastsEnums';
import { CatalogsEnum } from 'src/app/system/core/enums/catalogs.model';
import { MipFormsService } from 'src/app/system/services/MipForms.service';
import { LoadingService } from 'src/app/system/services/loading.service';
import { MessagesService } from 'src/app/system/services/message.service';
import { UtilsService } from 'src/app/system/services/utils.service';

export interface MipPhotosForm {
  KeyForm: "inspection_form" | "condition_form" | string;
  ValueId:string;
  RouteForm:string; ///mip/inspection-forms-photo
}
@Component({
  selector: 'app-carousel-images-form',
  templateUrl: './carousel-images-form.component.html',
  styleUrls: ['./carousel-images-form.component.scss']
})
export class CarouselImagesFormComponent {

  @Input() ShowNestingArea:boolean = true;
  @Input() ShowComments:boolean = true;
  @Input() FormValue!:MipPhotosForm;

  loading: boolean = false;
  index : number = 0;
  Photos:any[] = [];

  Catalog = CatalogsEnum;
  randomValue = Math.random();
    responsiveOptions: any[] | undefined;
   ImgFile:any = "N/A";

  constructor(private UtilsService:UtilsService, private FormBuilder:FormBuilder, private productService: ProductService,public MipFormsService:MipFormsService, private LoadingService:LoadingService, private DialogService:DialogService, private MessagesService:MessagesService){

  }

  uploadFile(e:any){
    this.ImgFile = e;
  }


  handleChange(event: any) {
    this.index = event.index;
  }
    ngOnInit(): void {

    this.responsiveOptions = [
      {
        breakpoint: '1199px',
        numVisible: 1,
        numScroll: 1
    },
    {
        breakpoint: '991px',
        numVisible: 2,
        numScroll: 1
    },
    {
        breakpoint: '767px',
        numVisible: 1,
        numScroll: 1
    }
    ];

  setTimeout(() => {
   this.getPhotos();
  }, 0);
    }

    getPhotos(){
   
      this.Photos = [];
      this.LoadingService.Show();
  
      this.MipFormsService.getPhotosMipCarousel(this.FormValue.KeyForm,this.FormValue.ValueId,this.FormValue.RouteForm).subscribe((res) => {

      this.Photos = res;

        this.LoadingService.Hide();

      });
    }




   deletePhoto(item: any) {
    this.MessagesService.confirm(
      '¿Esta seguro de eliminar la foto ' + item.id + ' ?',
      () => {
        this.MipFormsService.deletePhotoInspection(
          item.id
        ).subscribe((res) => {
          this.MessagesService.ShowMessage(TYPE_TOAST.SUCCESS,"Correcto","Se ha eliminado correctamente")
          this.getPhotos();
        });
      }
    );
  }


  UpdateImage(photo:any){

      let data = {
        [this.FormValue.KeyForm]:this.FormValue.ValueId,
        nesting_area:photo?.nesting_area?.id ?? photo?.nesting_area ?? '' ,
        comments:photo.comments,
        photo:this.ImgFile
      }

    if(!this.ShowNestingArea){
      delete data.nesting_area;
    }

    if(this.UtilsService.isString(this.ImgFile)){
      delete data.photo;
    }




  let formData = this.UtilsService.objectToFormData(data);

  this.MipFormsService.patchPhotoCarousel(
    formData,
    photo.id,
    this.FormValue.RouteForm,

  ).subscribe((res) => {
    this.LoadingService.Hide();
    this.MessagesService.ShowMessage(
      TYPE_TOAST.SUCCESS,
      'Correcto',
      'Guardado correctamente'
    );

  });

  }

  GetArea(Area:any, photo:any){
    photo.nesting_area = Area;
  }


  deleteImage(photo:any){
    this.MessagesService.confirm(
      '¿Esta seguro de eliminar la foto ?',
      () => {
        this.MipFormsService.deleteImageMipForm(
          photo.id,
          this.FormValue.RouteForm,

        ).subscribe((res) => {
          this.MessagesService.ShowMessage(TYPE_TOAST.SUCCESS,"Correcto","Se ha eliminado correctamente")
          this.getPhotos();
        });
      }
    );
  }
}
