import { Component, Inject, ViewChild, inject } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { MIP_FORM } from 'src/app/system/core/enums/MipForms.model';
import { MipFormsService } from 'src/app/system/services/MipForms.service';
import { LoadingService } from 'src/app/system/services/loading.service';
import { MessagesService } from 'src/app/system/services/message.service';
import { AddUpdateControlFormComponent } from './components/add-update-control-form/add-update-control-form.component';
import { TYPE_TOAST } from 'src/app/system/core/enums/ToastsEnums';
import { AddUpdateProductsComponent } from './components/add-update-products/add-update-products.component';
import { TableControlComponent } from './components/table-control/table-control.component';
import { PhotosControlComponent } from './components/photos-control/photos-control.component';
import { PermissionsServices } from 'src/app/system/services/permissions.service';

@Component({
  selector: 'app-control',
  templateUrl: './control.component.html',
  styleUrls: ['./control.component.scss']
})
export class ControlComponent {
  @ViewChild(TableControlComponent, { static: false }) tableRef!: TableControlComponent;
  public permissionsService = inject(PermissionsServices);
Control:any[] = [];
constructor(@Inject('dynamicData') public Event: any, private MipFormsService:MipFormsService, private LoadingService:LoadingService, private DialogService:DialogService, private MessagesService:MessagesService){

}


  ngOnInit(): void {


setTimeout(() => {
  this.getControlForm();
}, 0);
  }

  getControlForm(){
    this.LoadingService.Show();
    this.MipFormsService.getDataForm(this.Event.id,MIP_FORM.CONTROL_FORMS).subscribe((res) => {
      console.log(res);

      this.Control = res;
      this.LoadingService.Hide();

    });
  }


  AddUpdate(Control: any,) {
    const dialog = this.DialogService.open(AddUpdateControlFormComponent, {
     header: "Control plagas",
     modal: true,
     width: '30rem',
     closable: true,
     draggable: false,
     maximizable: false,
     resizable: true,
     closeOnEscape: true,
     dismissableMask: true,
     data: {
      Event:this.Event,
      Control
     },
   });
   dialog.onClose.subscribe((res) => {
     if (res?.success) {
       this.getControlForm();
     }
   });

 }
  deleteItem(item: any) {
    this.MessagesService.confirm(
      '¿Esta seguro de eliminar el registro ' + item.name + ' ?',
      () => {
        this.MipFormsService.deleteDataForm(
          item.id,
          MIP_FORM.CONTROL_FORMS
        ).subscribe((res) => {
          this.MessagesService.ShowMessage(TYPE_TOAST.SUCCESS,"Correcto","Se ha eliminado correctamente")
          this.getControlForm();
        });
      }
    );
  }



  AddUpdateProducts(Product: any,Control:any) {
    const dialog = this.DialogService.open(AddUpdateProductsComponent, {
     header: "Producto",
     modal: true,
     width: '30rem',
     closable: true,
     draggable: false,
     maximizable: false,
     resizable: true,
     closeOnEscape: true,
     dismissableMask: true,
     data: {
      Control,
      Event:this.Event,
      Product
     },
   });
   dialog.onClose.subscribe((res) => {
     if (res?.success) {
      this.tableRef.getProducts();
     }
   });

 }


 viewImages( Control:any) {
  const dialog = this.DialogService.open(PhotosControlComponent, {
   header: "Fotos Control " + Control.name,
   modal: true,
   width: '50rem',
   //height:"100%",
   closable: true,
   draggable: false,
   maximizable: false,
   resizable: true,
   closeOnEscape: true,
   dismissableMask: true,
   data: {
     Control,
   },
 });
 dialog.onClose.subscribe((res) => {
   if (res?.success) {

   }
 });

}
}
