export const environment = {
  production: false,
  // baseUrl: 'http://127.0.0.1:8000/administrative',
  //baseUrl: 'https://api.pestwareapp.com/administrative',
  baseUrl: 'https://api-dev.pestforceapp.com/administrative',
//  s3Url: 'https://pestware-storage-prod.s3.amazonaws.com/',
 s3Url: 'https://pestware-storage-devs.s3.amazonaws.com/',

  
};
