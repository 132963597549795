import { MipFormsService } from './../../../../../../../services/MipForms.service';
import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { MIP_FORM } from 'src/app/system/core/enums/MipForms.model';
import { TYPE_TOAST } from 'src/app/system/core/enums/ToastsEnums';
import { CatalogsEnum } from 'src/app/system/core/enums/catalogs.model';
import { LoadingService } from 'src/app/system/services/loading.service';
import { MessagesService } from 'src/app/system/services/message.service';
import { UtilsService } from 'src/app/system/services/utils.service';

@Component({
  selector: 'app-add-update-inspection',
  templateUrl: './add-update-inspection.component.html',
  styleUrls: ['./add-update-inspection.component.scss']
})
export class AddUpdateInspectionComponent {

  Catalog = CatalogsEnum;
  Form: FormGroup = this.FormBuilder.group({
    plague_category: ['', [Validators.required]],
    id: [''],
    event:['']
});
Event:any;
files: File[] = [];

constructor(
  private FormBuilder: FormBuilder,
  private LoadingService: LoadingService,
  public config: DynamicDialogConfig,
  public ref: DynamicDialogRef,
  private DialogService: DialogService,
  public MipFormsService:MipFormsService,
  private MessagesService: MessagesService,
  private UtilsService:UtilsService
) {

}



onSelect(event:any) {

  this.files = event;
  //this.files.push(...event.addedFiles);
}



ngOnInit(): void {
this.Event = this.config.data.Event;
this.Form.patchValue({event:this.Event.id})
  if (this.config.data.Inspection) {
   this.patchForm();
  }
}

patchForm(){
  let item = this.config.data.Inspection;
  this.Form.patchValue({
    plague_category:item.plague_category,
    id:item.id,
  });
}

accept() {

  this.LoadingService.Show();
 if(!this.config.data.Inspection){

  this.save();
  return
 }
this.update();
}
cancel() {
  this.ref?.close();
}



save(){

let data = {
  event:this.Form.value.event,
  plague_category:this.Form.value.plague_category.id,
}

  this.MipFormsService.saveDataForm(data, MIP_FORM.INSPECTION_FORM).subscribe(async (res)=>{

if(this.files.length == 0){
  this.LoadingService.Hide();
  this.MessagesService.ShowMessage(TYPE_TOAST.SUCCESS,'Correcto','Guardado correctamente');
  this.ref?.close({ success: true });
  return
}


await this.saveImages(res.id);

  });

  }



async saveImages(idPadre:string){
  for (const file of this.files) {
    const data = {
      photo: file,
      inspection_form: idPadre,
    };

    const formData = this.UtilsService.objectToFormData(data);
    await this.MipFormsService.saveDataForm(formData, 'inspection-forms-photo').toPromise();
  }

  // Mostrar alerta después de que todas las fotos se hayan guardado
  this.LoadingService.Hide();
  this.MessagesService.ShowMessage(TYPE_TOAST.SUCCESS,'Correcto','Guardado correctamente');
  this.ref?.close({ success: true });


}



  update(){
    let data = {
      event:this.Form.value.event,
      plague_category:this.Form.value.plague_category.id,
      id:this.Form.value.id,
    }




      this.MipFormsService.putDataForm(data, MIP_FORM.INSPECTION_FORM).subscribe((res)=>{
          this.LoadingService.Hide();
          this.MessagesService.ShowMessage(
              TYPE_TOAST.SUCCESS,
              'Correcto',
              'Actualizado correctamente'
          );
          this.ref?.close({ success: true });
        });
  }
}
