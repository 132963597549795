import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from 'primeng/dynamicdialog';

import { RRule } from 'rrule';
import {
  CatalogsEnum,
  URL_ENDPOINTS,
} from 'src/app/system/core/enums/catalogs.model';
import { TYPE_TOAST } from 'src/app/system/core/enums/ToastsEnums';
import { CalendarService } from 'src/app/system/services/calendar.service';
import { CatalogService } from 'src/app/system/services/catalogs.service';
import { DateService } from 'src/app/system/services/date.service';
import { LoadingService } from 'src/app/system/services/loading.service';
import { MessagesService } from 'src/app/system/services/message.service';
import { QuotesService } from 'src/app/system/services/quotes.service';
import { UserService } from 'src/app/system/services/user.service';
import { AddUpdateCustomerComponent } from '../../../customers/components/add-update-customer/add-update-customer.component';
@Component({
  selector: 'app-create-update-event-form',
  templateUrl: './create-update-event-form.component.html',
  styleUrls: ['./create-update-event-form.component.scss'],
})
export class CreateUpdateEventFormComponent implements OnInit {
  Form: FormGroup = this.FormBuilder.group({
    id: '',
    folio: '',
    titlecopy: '',
    title: ['', Validators.required],
    event_type: ['', Validators.required],
    initial_date: ['', Validators.required],
    final_date: ['', Validators.required],
    customer: ['', Validators.required],
    initial_hour: [''],
    final_hour: [''],
    repeat_event: false,
    rrule: '',
    job_center: this.UserService.JobCenter,
  });

  groupSelected = '';
  actualClient = '';
  customer: any;
  isDisabled: boolean = false;

  Catalog = CatalogsEnum;

  constructor(
    private DialogService: DialogService,
    private FormBuilder: FormBuilder,
    private LoadingService: LoadingService,
    public config: DynamicDialogConfig,
    public ref: DynamicDialogRef,
    private QuotesService: QuotesService,
    private MessagesService: MessagesService,
    private UserService: UserService,
    private DateService: DateService,
    private CalendarService: CalendarService,
    private CatalogService: CatalogService
  ) { }

  URL_CATALOGS = URL_ENDPOINTS;

  ngOnInit(): void {
    this.setDropdown();

    if (this.config.data.Event) {
      setTimeout(() => {
        this.getEventById();
      }, 0);
      if (this.config.data.Event.is_many) {

        this.parseRRule(this.config.data.Event.rrule);
      }
      return;
    }
    if (this.config.data.Quote) {



      this.getNewForm(this.config.data.Concept.event_type);

      if (this.config.data.Concept.rrule) {

        this.parseRRule(this.config.data.Concept.rrule);
      }
      this.Form.patchValue({
        event_type: this.config.data.Concept.event_type,
        customer: this.config.data.Quote.customer,
        plague: this.config.data.Quote?.plague ?? null,
        employee: [this.config.data.Quote?.employee] ?? null,
        service_type: this.config.data.Quote?.service_type ?? null,
        repeat_event: this.config.data.Concept.rrule ? true : false
      });
      // this.customer = this.config.data.Customer;
      // this.actualClient = this.customer.name;
      /**
       *   this.Form.patchValue({
          customer: this.customer.name,
        });
       */
    }


    if (this.config.data.Customer) {
      this.customer = this.config.data.Customer;
      this.actualClient = this.customer.name;
      this.Form.patchValue({
        customer: this.customer.name,
      });
    }

    if (this.config.data.Date) {
      this.loadDataFormDate();
    }

    if (this.config.data.Ticket) {
      this.TicketPatch();
    }

    //ES
    this.listenCustomerContro();
  }

  listenCustomerContro() {
    this.Form.get('customer')?.valueChanges.subscribe((value: any) => {
      setTimeout(() => {
        if (!value) return;

        this.Form.patchValue({
          title: this.Form.value.titlecopy + ' ' + (value?.name ?? value),
        });
      }, 1500);
    });
  }

  TicketPatch() {
    let typeTicket = {
      id: '0004ffd3-cd49-4422-bc96-962c50055ca5',
      name: 'Seguimiento ticket',
      quote_field: false,
      customer_field: true,
      employee_field: true,
      service_type_field: true,
      plagues_field: true,
      cost_field: false,
      comments_field: true,
      ticket_field: true,
      group_field: true,
      mip_inspection_form: true,
      mip_condition_form: true,
      mip_control_form: true,
      mip_payment_form: true,
      mip_signature_form: true,
      mip_plan_action_form: true,
      mip_station_count_form: true,
      mip_task_form: true,
      notification_action: false,
      reminder_action: false,
      folio_key_setting: 'ST',
      folio_init_setting: 1,
      hide_cancel_setting: false,
      is_service_order: true,
      job_center: 'cc0b0cac-fcc6-4876-8bae-530e30ebe03e',
    };
    this.getNewForm(typeTicket);
    this.Form.patchValue({
      event_type: typeTicket,

      customer: this.config.data.Ticket.customer,
      ticket: this.config.data.Ticket ?? null,
    });
  }

  RepeatEvent() {
    // console.log(this.Form.value.repeat_event);
  }

  event: string = '';

  getEventById() {
    this.event = this.config.data.Warranty
      ? 'GARANTIA'
      : this.config.data.Follow
        ? 'SEGUIMIENTO'
        : this.config.data.Bootster
          ? 'REFUERZO'
          : 'NORMAL';

    let eventType = this.config.data.Warranty
      ? this.config.data.Warranty
      : this.config.data.Follow
        ? this.config.data.Follow
        : this.config.data.Bootster
          ? this.config.data.Bootster
          : this.config.data.Event.event_type;

    this.getNewForm(eventType);

    this.Form.patchValue({
      id: this.config.data.Event.id,
      rrule:
        this.event == 'GARANTIA' || this.event == 'SEGUIMIENTO' || this.event == 'REFUERZO'
          ? ''
          : this.config.data.Event.rrule,
      title: this.config.data.Warranty
        ? 'Garantía'
        : this.config.data.Follow
          ? 'Seguimiento'
          : this.config.data.Bootster
            ? 'Refuerzo'
            : this.config.data.Event.event_type.name,
      event_type: this.config.data.Warranty
        ? this.config.data.Warranty
        : this.config.data.Follow
          ? this.config.data.Follow
          : this.config.data.Bootster
            ? this.config.data.Bootster
            : this.config.data.Event.event_type,
      initial_date: new Date(`${this.config.data.Event.initial_date}T12:00:00`),
      initial_hour: this.DateService.parseTimeToDate(
        this.config.data.Event.initial_hour
      ),
      final_date: new Date(`${this.config.data.Event.final_date}T12:00:00`),
      final_hour: this.DateService.parseTimeToDate(
        this.config.data.Event.final_hour
      ),
      customer: this.config.data.Event.customer,
      job_center: this.UserService.JobCenter,
      folio: this.config.data.Event.folio,
      quote: this.config.data.Event?.quote ?? null,
      employee: this.config.data.Event?.employee ?? null,
      service_type: this.config.data.Event?.service_type ?? null,
      group: this.config.data.Event?.group[0] ?? null,
      total: this.config.data.Event?.total ?? null,
      ticket: this.config.data.Event?.ticket ?? null,
      plague: this.config.data.Event?.plague ?? null,
      comments: this.config.data.Event?.comments ?? null,
      repeat_event:
        this.event == 'GARANTIA' || this.event == 'GARANTÍA' || this.event == 'SEGUIMIENTO' || this.event == 'REFUERZO'
          ? false
          : this.config.data.Event?.is_many,
    });

    if (this.config.data.Date) {
      this.loadDataFormDate();
    }
  }

  getNewForm(e: any) {
    this.Form.patchValue({
      title: e.name,
      titlecopy: e.name,
    });

    if (e.quote_field) {
      this.Form.addControl(
        'quote',
        this.FormBuilder.control('', Validators.required)
      );
    }

    if (!e.quote_field) {
      this.Form.removeControl('quote');
      // this.Form.get('customer')!.enable();
    }

    if (e.employee_field) {
      this.Form.addControl(
        'employee',
        this.FormBuilder.control('', Validators.required)
      );
    }

    if (!e.employee_field) {
      this.Form.removeControl('employee');
      // this.Form.get('customer')!.enable();
    }

    if (e.service_type_field) {
      this.Form.addControl(
        'service_type',
        this.FormBuilder.control('', Validators.required)
      );
    }

    if (!e.service_type_field) {
      this.Form.removeControl('service_type');
      // this.Form.get('customer')!.enable();
    }

    if (e.group_field) {
      this.Form.addControl(
        'group',
        this.FormBuilder.control('', Validators.required)
      );
    }

    if (!e.group_field) {
      this.Form.removeControl('group');
    }

    if (e.cost_field) {
      this.Form.addControl(
        'total',
        this.FormBuilder.control('', Validators.required)
      );
    }

    if (!e.cost_field) {
      this.Form.removeControl('total');
    }

    if (e.ticket_field) {
      this.Form.addControl(
        'ticket',
        this.FormBuilder.control('', Validators.required)
      );
    }

    if (!e.ticket_field) {
      this.Form.removeControl('ticket');
    }

    if (e.plagues_field) {
      this.Form.addControl(
        'plague',
        this.FormBuilder.control('', Validators.required)
      );
    }

    if (!e.plagues_field) {
      this.Form.removeControl('plague');
    }

    if (e.comments_field) {
      this.Form.addControl('comments', this.FormBuilder.control(''));
    }

    if (!e.comments_field) {
      this.Form.removeControl('comments');
    }
  }

  fillFormByQuote(e: any) {
    if (this.Form.get('customer')) {
      this.Form.get('customer')!.setValue(e.customer);
    }

    if (this.Form.get('service_type')) {
      this.Form.get('service_type')!.setValue(e.service_type);
      this.Form.get('comments')!.setValue(
        e.service_type?.customerDescription?.description
      );
    }

    if (this.Form.get('total')) {
      this.Form.get('total')!.setValue(e.total);
    }

    if (this.Form.get('plague')) {
      this.Form.get('plague')!.setValue(e.plague);
    }

    //this.Form.get('customer')!.disable();

    this.isDisabled = true;
  }

  accept() {
    if (this.event == 'GARANTIA' || this.event == 'GARANTÍA' || this.event == 'SEGUIMIENTO' || this.event == 'REFUERZO') {
      this.LoadingService.Show();
      this.createEvent();
      return;
    }

    if (
      this.Form.value.repeat_event &&
      !this.FormRepeatEvent.value.repeat_type
    ) {
      this.MessagesService.ShowMessage(
        TYPE_TOAST.WARNING,
        'Advertencia',
        'Selecciona de favor una opción a repetir'
      );
      return;
    }

    if (
      this.Form.value.repeat_event &&
      !this.FormRepeatEvent.value.end &&
      this.FormRepeatEvent.value?.repeat_type.value != 'PERSONAL'
    ) {
      this.MessagesService.ShowMessage(
        TYPE_TOAST.WARNING,
        'Advertencia',
        'Selecciona de favor una opción de finalizar'
      );
      return;
    }

    if (!this.config.data.Event) {
      this.LoadingService.Show();
      this.createEvent();
      return;
    }

    this.updateEvent();
  }

  updateEvent() {

    //final_hou "19:00:00"
    let employee = this.Form.value?.employee?.map((item: any) => item.id) ?? [];
    let group = [this.Form.value?.group?.id];
    let plague = this.Form.value?.plague?.map((item: any) => item.id) ?? [];
    let custom_dates = this.datesPersonal.length < 1 ? null : this.datesPersonal.map((e) => this.formatDate(e))

    let data = {
      folio: this.Form.value.folio,
      id: this.Form.value.id,
      event_type: this.Form.value.event_type.id,
      title: this.Form.value.title,
      customer: this.Form.value.customer.id,
      initial_hour: this.DateService.getFormatDataTime(
        this.Form.value.initial_hour
      ),
      final_hour: this.DateService.getFormatDataTime(
        this.Form.value.final_hour
      ),
      initial_date: this.DateService.getFormatDataDate(
        this.Form.value.initial_date
      ),
      final_date: this.DateService.getFormatDataDate(
        this.Form.value.final_date
      ),
      job_center: this.UserService.JobCenter,
      quote: this.Form.value?.quote?.id ?? null,
      total: this.Form.value?.total ?? null,
      comments: this.Form.value?.comments ?? null,
      service_type: this.Form.value?.service_type?.id ?? null,
      ticket: this.Form.value?.ticket?.id ?? null,
      employee,
      group,
      plague,
      is_many: this.config.data?.Event?.is_many,
      rrule: this.repeat_type == "PERSONAL" ? '' : this.rrule() ?? null,
      custom_dates: this.repeat_type == "PERSONAL" ? custom_dates : null, //this.datesPersonal.length < 1 ? null : this.datesPersonal
    };


    if (this.config.data.Event.is_many) {
      this.MessagesService.labeBtnYes = 'Actualizar todos';
      this.MessagesService.labelBtnNo = 'No, solo el actual';

      this.MessagesService.confirm(
        '¿Desea actualizar todos los eventos recurrentes que pertenecen a este evento?',
        () => {
          this.LoadingService.Show();

          this.CalendarService.updateEventMassive(data).subscribe((res) => {
            this.LoadingService.Hide();
            this.MessagesService.ShowMessage(
              TYPE_TOAST.SUCCESS,
              'Correcto',
              'Actualizados correctamente'
            );
            this.MessagesService.labeBtnYes = 'Confirmar';
            this.MessagesService.labelBtnNo = 'Cancelar';
            this.ref?.close({ success: true });
          });
        },

        () => {
          this.LoadingService.Show();
          this.MessagesService.labeBtnYes = 'Confirmar';
          this.MessagesService.labelBtnNo = 'Cancelar';
          this.CalendarService.updateEvent(data).subscribe((res) => {
            this.LoadingService.Hide();
            this.MessagesService.ShowMessage(
              TYPE_TOAST.SUCCESS,
              'Correcto',
              'Actualizado correctamente'
            );
            this.ref?.close({ success: true });
          });
        }
      );
    } else {
      this.LoadingService.Show();

      this.CalendarService.updateEvent(data).subscribe((res) => {
        this.MessagesService.labeBtnYes = 'Confirmar';
        this.MessagesService.labelBtnNo = 'Cancelar';
        this.LoadingService.Hide();
        this.MessagesService.ShowMessage(
          TYPE_TOAST.SUCCESS,
          'Correcto',
          'Actualizado correctamente'
        );
        this.ref?.close({ success: true });
      });
    }
  }
  eventsMassives() {
    //obtener ids de este evento.
  }
  formatDate = (date: Date) => {
    console.log(date);

    return date.toISOString().split("T")[0].split("-").reverse().join("-");
  };
  createEvent() {
    // "initial_hour": "17:00:00",

    let employee = this.Form.value?.employee?.map((item: any) => item.id) ?? [];
    let group = [this.Form.value?.group?.id];
    let plague = this.Form.value?.plague?.map((item: any) => item.id) ?? [];
    let custom_dates = this.datesPersonal.length < 1 ? null : this.datesPersonal.map((e) => this.formatDate(e))
    let data = {
      event_type: this.Form.value.event_type.id,
      title: this.Form.value.title,
      customer: this.Form.value.customer.id,
      initial_hour: this.DateService.getFormatDataTime(
        this.Form.value.initial_hour
      ),
      final_hour: this.DateService.getFormatDataTime(
        this.Form.value.final_hour
      ),
      initial_date: this.DateService.getFormatDataDate(
        this.Form.value.initial_date
      ),
      final_date: this.DateService.getFormatDataDate(
        this.Form.value.final_date
      ),
      job_center: this.UserService.JobCenter,
      quote: this.Form.value?.quote?.id ?? null,
      total: this.Form.value?.total ?? '0',
      comments: this.Form.value?.comments ?? null,
      service_type: this.Form.value?.service_type?.id ?? null,
      ticket: this.Form.value?.ticket?.id ?? null,
      employee,
      group,

      plague,
      is_infinty: this.repeat_type === 'INDETERMINADO',
      rrule: this.repeat_type == "PERSONAL" ? '' : this.rrule() ?? null,
      custom_dates: this.repeat_type == "PERSONAL" ? custom_dates : null, //this.datesPersonal.length < 1 ? null : this.datesPersonal
    };


    if (this.customer) {
      data.customer = this.customer.id;
    }
    console.log(this.repeat_type);


    this.CalendarService.createEvent(data).subscribe((res) => {
      this.LoadingService.Hide();
      this.MessagesService.ShowMessage(
        TYPE_TOAST.SUCCESS,
        'Correcto',
        'Agregado correctamente'
      );
      this.ref?.close({ success: true });
    });
  }

  generateRRuleIndeterminate(): string {
    const now = new Date();
    const year = now.getFullYear(); // Obtiene el año actual
    const untilDate = `${year}1231T235959Z`; // Formato UNTIL en RRULE

    return `FREQ=DAILY;UNTIL=${untilDate}`;
  }

  loadDataFormDate(): void {
    console.log(this.config.data.Date.final_date.split('T')[1]);
    //  2025-02-14T10:30:00
    let initialDate = this.DateService.getFormatDataDate(
      this.config.data.Date.initial_date
    );

    let finalDate = this.DateService.getFormatDataDate(
      this.config.data.Date.final_date
    );

    console.log(finalDate);

    this.Form.patchValue({
      initial_date: new Date(`${initialDate} `),
      final_date: new Date(`${finalDate} `),
      initial_hour: this.DateService.parseTimeToDate(
        this.config.data.Date.initial_date.split('T')[1]
      ),
      final_hour: this.DateService.parseTimeToDate(
        this.config.data.Date.final_date.split('T')[1]
      ),
    });
  }
  addCustomer() {
    const dialog = this.DialogService.open(AddUpdateCustomerComponent, {
      header: 'Nuevo cliente',
      modal: true,
      width: '60rem',
      //height:"100%",
      closable: true,
      draggable: false,
      maximizable: false,
      resizable: true,
      closeOnEscape: true,
      dismissableMask: true,
      data: {
        item: null,
        isMain: true,
        main_customer: '',
      },
    });
  }

  /**REPEAT EVENT */
  FormRepeatEvent: FormGroup = this.FormBuilder.group({
    repeat_type: [],
    month: [{ label: 'Enero', value: 1 }],
    day: [{ day: 1 }],
    DayWithLetter: [{ label: 'Primero', value: 1 }],
    MonthWithLetter: [{ label: 'Lunes', value: 'MO' }],
    end: [],
    endAfterValue: [1],
    endDateValue: [''],
    every: [1],
    dayMontly: [{ day: 1 }],
    DayWithLetterMontly: [{ label: 'Primero', value: 1 }],
    MonthWithLetterMontly: [{ label: 'Lunes', value: 'MO' }],
  });
  repeatTypes!: any[];
  filteredRepeatTypes!: any[];
  /** VARIABLES PARA YEARLY */
  YearlyRadioButton: string = 'onDay';
  MonthlyRadioButton: string = 'onDay';
  YearlyMonthSelected: any;
  repeat_type: string = '';
  EndOptionSelected = '';
  Months: any = [];
  numberDaysMonth: any[] = [];
  DaysWitLetter: any = [];
  MontlhyDaysWithLetter: any = [];
  repeatTypesEnd: any;
  DaysOfWeek = [
    {
      Position: 6,
      id: 7,
      name: 'Domingo',
      key: 'SU',
      value: false,
    },
    {
      Position: 0,
      id: 1,
      name: 'Lunes',
      key: 'MO',
      value: false,
    },
    {
      Position: 1,
      id: 2,
      name: 'Martes',
      key: 'TU',
      value: false,
    },
    {
      Position: 2,
      id: 3,
      name: 'Miercoles',
      key: 'WE',
      value: false,
    },
    {
      Position: 3,
      id: 4,
      name: 'Jueves',
      key: 'TH',
      value: false,
    },
    {
      Position: 4,
      id: 5,
      name: 'Viernes',
      key: 'FR',
      value: false,
    },
    {
      Position: 5,
      id: 6,
      name: 'Sábado',
      key: 'SA',
      value: false,
    },
  ];
  filterRepeatType(event: any) {
    let filtered: any[] = [];
    let query = event.query;

    this.repeatTypes = [
      { label: 'Anual', value: 'yearly' },
      { label: 'Mensual', value: 'monthly' },
      { label: 'Semanal', value: 'weekly' },
      { label: 'Diario', value: 'daily' },
      { label: 'Personalizado', value: 'PERSONAL', },
    ];
    for (let i = 0; i < this.repeatTypes.length; i++) {
      let repeatType = this.repeatTypes[i];
      if (repeatType.label.toLowerCase().indexOf(query.toLowerCase()) == 0)
        filtered.push(repeatType);
    }
    this.filteredRepeatTypes = filtered;
  }

  //CUANDO SELECCIONE UN TIPO DE FRECUENCIA DEBEMOS RESETEAR VALORES
  SelectRepeatType(event: any) {
    this.repeat_type = event.value.toUpperCase();

    /**
   *   this.everyTitleInput = event.description;
    this.repeat_type = event.value.toUpperCase();

    if (this.repeat_type != 'WEEKLY') {
      //CAMBIAMOS NUEVAMENTE A FALSE LOS VALORES
      this.DaysOfWeek.forEach((element) => {
        element.value = false;
      });
    }
   */
  }
  datesPersonal: any[] = [];
  setDropdown() {
    this.Months = [
      { label: 'Enero', value: 1 },
      { label: 'Febrero', value: 2 },
      { label: 'Marzo', value: 3 },
      { label: 'Abril', value: 4 },
      { label: 'Mayo', value: 5 },
      { label: 'Junio', value: 6 },
      { label: 'Julio', value: 7 },
      { label: 'Agosto', value: 8 },
      { label: 'Septiembre', value: 9 },
      { label: 'Octubre', value: 10 },
      { label: 'Noviembre', value: 11 },
      { label: 'Diciembre', value: 12 },
    ];

    this.numberDaysMonth = [
      { day: 1 },
      { day: 2 },
      { day: 3 },
      { day: 4 },
      { day: 5 },
      { day: 6 },
      { day: 7 },
      { day: 8 },
      { day: 9 },
      { day: 10 },
      { day: 11 },
      { day: 12 },
      { day: 13 },
      { day: 14 },
      { day: 15 },
      { day: 16 },
      { day: 17 },
      { day: 18 },
      { day: 19 },
      { day: 20 },
      { day: 21 },
      { day: 22 },
      { day: 23 },
      { day: 24 },
      { day: 25 },
      { day: 26 },
      { day: 27 },
      { day: 28 },
      { day: 29 },
      { day: 30 },
      { day: 31 },
    ];

    this.DaysWitLetter = [
      { label: 'Primero', value: 1 },
      { label: 'Segundo', value: 2 },
      { label: 'Tercero', value: 3 },
      { label: 'Cuarto', value: 4 },
      { label: 'Último', value: -1 },
    ];

    this.MontlhyDaysWithLetter = [
      { label: 'Domingo', value: 'SU' },
      { label: 'Lunes', value: 'MO' },
      { label: 'Martes', value: 'TU' },
      { label: 'Miercoles', value: 'WE' },
      { label: 'Jueves', value: 'TH' },
      { label: 'Viernes', value: 'FR' },
      { label: 'Sábado', value: 'SA' },
    ];
  }

  SelectRepeatTypeEnd(event: any) {
    this.EndOptionSelected = event.value.toUpperCase();
  }
  filterEnd(event: any) {
    let filtered: any[] = [];
    let query = event.query;

    this.repeatTypesEnd = [
      { label: 'Despúes', value: 'COUNT' },
      { label: `En una fecha`, value: 'UNTIL' },
      { label: 'Indeterminado', value: 'INDETERMINADO' },
    ];
    for (let i = 0; i < this.repeatTypesEnd.length; i++) {
      let repeatType = this.repeatTypesEnd[i];
      if (repeatType.label.toLowerCase().indexOf(query.toLowerCase()) == 0)
        filtered.push(repeatType);
    }
    this.repeatTypesEnd = filtered;
  }

  rrule(): string {
    let rrule = '';

    if (this.FormRepeatEvent.value.every < 1) {
      this.FormRepeatEvent.patchValue({ every: 1 });
    }

    if (this.repeat_type == 'YEARLY') {
      if (this.YearlyRadioButton == 'onDay') {
        rrule = `FREQ=YEARLY;BYMONTH=${this.FormRepeatEvent.value.month.value};BYMONTHDAY=${this.FormRepeatEvent.value.day.day}`;
      }
      if (this.YearlyRadioButton == 'onThe') {
        rrule = `FREQ=YEARLY;BYSETPOS=${this.FormRepeatEvent.value.DayWithLetter.value};BYDAY=${this.FormRepeatEvent.value.MonthWithLetter.value};BYMONTH=${this.FormRepeatEvent.value.month.value}`;
      }
    }

    if (this.repeat_type == 'MONTHLY') {
      if (this.MonthlyRadioButton == 'onDay') {
        rrule = `FREQ=MONTHLY;BYMONTHDAY=${this.FormRepeatEvent.value.dayMontly.day};INTERVAL=${this.FormRepeatEvent.value.every}`;
      }
      if (this.MonthlyRadioButton == 'onThe') {
        rrule = `FREQ=MONTHLY;BYSETPOS=${this.FormRepeatEvent.value.DayWithLetterMontly.value};BYDAY=${this.FormRepeatEvent.value.MonthWithLetterMontly.value};INTERVAL=${this.FormRepeatEvent.value.every}`;
      }
    }

    if (this.repeat_type == 'WEEKLY') {
      const selectedDays = this.DaysOfWeek.filter((day) => day.value)
        .map((day) => day.key)
        .join(',');
      const byDay = selectedDays.length > 0 ? `;BYDAY=${selectedDays}` : '';

      rrule = `FREQ=${this.repeat_type}${byDay};INTERVAL=${this.FormRepeatEvent.value.every}`;
    }

    if (this.repeat_type == 'DAILY' || this.repeat_type == 'HOURLY') {
      rrule = `FREQ=${this.repeat_type};INTERVAL=${this.FormRepeatEvent.value.every}`;
    }

    if (this.FormRepeatEvent.value?.end?.value == 'COUNT') {
      rrule = rrule + ';COUNT=' + this.FormRepeatEvent.value.endAfterValue;
    }

    if (this.FormRepeatEvent.value?.end?.value == 'UNTIL') {
      let dateStartFormated = this.DateService.convertirFecha(
        this.Form.value.initial_date
      );
      let dateFormated = this.DateService.convertirFecha(
        this.FormRepeatEvent.value.endDateValue
      );
      rrule =
        rrule + ';DTSTART=' + dateStartFormated + ';UNTIL=' + dateFormated;
      console.log(rrule);
    }

    if (this.FormRepeatEvent.value?.end?.value == 'INDETERMINADO') {
      const now = new Date();
      const year = now.getFullYear(); // Obtiene el año actual
      const untilDate = `${year}1231T235959Z`; // Formato UNTIL en RRULE

      // let dateFormated = this.DateService.convertirFecha(this.FormRepeatEvent.value.endDateValue);
      rrule = rrule + ';UNTIL=' + untilDate;
    }

    return rrule;
  }

  changeSelected(e: any) {
    this.groupSelected = this.Form.value.group;
  }

  serviceSelected(e: any) {
    this.Form.patchValue({
      comments: this.Form.value.service_type?.customerDescription?.description,
    });
  }

  parseRRule(rruleString: string) {
    try {
      const rrule = RRule.fromString(rruleString);
      console.log(rrule);
      console.log(rruleString);
      console.log("*************************");

      // Obtener valores clave
      const freq: any = rrule.options.freq;
      const interval = rrule.options.interval || 1;
      const byMonth = rrule.options.bymonth ? rrule.options.bymonth[0] : null;
      const byMonthDay = rrule.options.bymonthday
        ? rrule.options.bymonthday[0]
        : null;
      const byWeekday = rrule.options.byweekday
        ? rrule.options.byweekday
        : null;
      const count = rrule.options.count || null;
      const until = rrule.options.until ? new Date(rrule.options.until) : null;
      // Determinar el tipo de repetición
      let repeatType: any;
      // Determinar el tipo de finalización
      let endOption = null;
      let endValue = null;

      switch (freq) {
        case RRule.DAILY:
          repeatType = { label: 'Diario', value: 'daily' };
          break;
        case RRule.WEEKLY:
          repeatType = { label: 'Semanal', value: 'weekly' };
          break;
        case RRule.MONTHLY:
          repeatType = { label: 'Mensual', value: 'monthly' };
          break;
        case RRule.YEARLY:
          repeatType = { label: 'Anual', value: 'yearly' };
          break;
      }

      console.log(freq);
      console.log(count);

      console.log(until);



      if (freq == 2) {
        endOption = { label: 'Indeterminado', value: 'INDETERMINADO' };
      }

      if (count) {
        endOption = { label: 'Despúes', value: 'COUNT' };
        endValue = count;
      }
      if (until) {
        endOption = { label: `En una fecha`, value: 'UNTIL' },
          endValue = until;
      }


      //  const filteredDays = this.MontlhyDaysWithLetter.filter( (day: any, index: any) => byWeekday?.includes(index) ?? []);



      if (rrule.options.byweekday && rrule.options.byweekday.length > 0) {

        this.DaysOfWeek = this.DaysOfWeek.map(day => ({
          ...day,
          value: rrule.options.byweekday.includes(day.Position) ? true : day.value
        }));
      }
      //  { label: 'Personalizado', value: 'PERSONAL', },

      // Actualizar el formulario con los valores
      this.FormRepeatEvent.patchValue({
        repeat_event: true,
        repeat_type: repeatType,
        every: interval,
        month: byMonth,
        day: byMonthDay,
        MonthWithLetter: [{ label: 'Domingo', value: 'SU' }],
        MonthWithLetterMontly: [{ label: 'Domingo', value: 'SU' }],
        end: endOption,
        endAfterValue: endOption?.value === 'COUNT' ? endValue : null,
        endDateValue: endOption?.value === 'UNTIL' ? endValue : null,
      });



      if (repeatType.value == 'daily') {
        this.repeat_type = 'DAILY'; // Para que se actualicen los `*ngIf`
      }
      if (repeatType.value == 'weekly') {
        this.repeat_type = 'WEEKLY'; // Para que se actualicen los `*ngIf`
      }
      if (repeatType.value == 'monthly') {
        this.repeat_type = 'MONTHLY'; // Para que se actualicen los `*ngIf`
      }
      if (repeatType.value == 'yearly') {
        this.repeat_type = 'YEARLY'; // Para que se actualicen los `*ngIf`
      }

      // Actualizar la opción seleccionada de finalización
      this.EndOptionSelected = endOption?.value || '';
    } catch (error) {
      console.error('Error al parsear RRULE:', error);
    }
  }
}
