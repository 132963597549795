import { Component, inject } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { TYPE_TOAST } from 'src/app/system/core/enums/ToastsEnums';
import { CalendarService } from 'src/app/system/services/calendar.service';
import { DateService } from 'src/app/system/services/date.service';
import { LoadingService } from 'src/app/system/services/loading.service';
import { MessagesService } from 'src/app/system/services/message.service';
import { QuotesService } from 'src/app/system/services/quotes.service';
import { UserService } from 'src/app/system/services/user.service';
import { CancelEventComponent } from '../cancel-event/cancel-event.component';
import { CreateUpdateEventFormComponent } from '../create-update-event-form/create-update-event-form.component';
import { MipFormsComponent } from '../MIP-FORMS/mip-forms.component';
import { DocumentsComponent } from '../documents/documents.component';
import { ReactivateEventComponent } from '../reactivate-event/reactivate-event.component';
import { PermissionsServices } from 'src/app/system/services/permissions.service';

@Component({
  selector: 'app-detail-event',
  templateUrl: './detail-event.component.html',
  styleUrls: ['./detail-event.component.scss']
})
export class DetailEventComponent {
Event:any;
public permissionsService = inject(PermissionsServices);
    constructor(    private dialogService: DialogService,
      private FormBuilder: FormBuilder,
      private LoadingService: LoadingService,
      public config: DynamicDialogConfig,
      public ref: DynamicDialogRef,
      private QuotesService: QuotesService,
      private MessagesService: MessagesService,
      private UserService: UserService,
      private DateService: DateService,
      private CalendarService:CalendarService) { }

    ngOnInit() {
    this.Event = this.config.data.Event;

    }


    deleteEvent() {
      this.MessagesService.confirm("¿Esta seguro de eliminar el event? " + this.Event.folio, () => {
        this.LoadingService.Show();
        this.CalendarService.deleteEvent(this.Event.id).subscribe((res)=>{
          this.LoadingService.Hide();
          this.ref?.close();
          this.MessagesService.ShowMessage(TYPE_TOAST.SUCCESS,"Eliminado correctamente","");
        })
      });
  }


  cancelEvent() {

    const dialog = this.dialogService.open(CancelEventComponent, {
      header: 'Cancelar evento '  + this.Event.folio,
      modal: true,
      width: '30rem',
      closable: true,
      draggable: false,
      resizable: true,
      closeOnEscape: true,
      dismissableMask: true,
      data: {
        Event: this.Event,
      },
    });
    dialog.onClose.subscribe((res) => {
      if (res?.success) {
        this.ref?.close({ success: true });
      }
    });
  }

  reactivateEvent(){
    const dialog = this.dialogService.open(ReactivateEventComponent, {
      header: 'Reactivar evento '  + this.Event.folio,
      modal: true,
      width: '30rem',
      closable: true,
      draggable: false,
      resizable: true,
      closeOnEscape: true,
      dismissableMask: true,
      data: {
        Event: this.Event,
      },
    });
    dialog.onClose.subscribe((res) => {
      if (res?.success) {
        this.ref?.close({ success: true });
      }
    });
  }

  editEvent() {

    let Date = null;


    const dialog = this.dialogService.open(CreateUpdateEventFormComponent, {
      header: 'Evento ' + this.Event.folio,
      modal: true,
      width: '50%',
      //height:"100%",
      closable: true,
      draggable: false,
      // maximizable: true,
      resizable: true,
      closeOnEscape: true,
      dismissableMask: true,
      data: {
        Event: this.Event,
        Date,
      },
    });
    dialog.onClose.subscribe((res) => {

        this.ref.close({success:true})

    });
  }

  CrudMipForms(){
    const dialog = this.dialogService.open(MipFormsComponent, {
      header: 'Folio ' + this.Event.folio,
      modal: true,
      width: window.innerWidth + 'px',  // Ancho inicial igual al ancho de la ventana
      height: window.innerHeight + 'px',
      closable: true,
      draggable: false,
       maximizable: true,
      resizable: true,
      closeOnEscape: true,
      dismissableMask: true,
      data: {
        Event: this.Event,
      },
    });
    dialog.onClose.subscribe((res) => {

      //  this.ref.close({success:true})

    });

  }


  openDocuments(){
    const dialog = this.dialogService.open(DocumentsComponent, {
      header: 'Folio ' + this.Event.folio,
      modal: true,
      width: '32rem',  // Ancho inicial igual al ancho de la ventana

      closable: true,
      draggable: false,
       maximizable: true,
      resizable: true,
      closeOnEscape: true,
      dismissableMask: true,
      data: {
        Event: this.Event,
      },
    });
    dialog.onClose.subscribe((res) => {

      //  this.ref.close({success:true})

    });

  }
}
