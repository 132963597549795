

<p-tabView [activeIndex]="index" (onChange)="handleChange($event)">

  <p-tabPanel>
    <ng-template pTemplate="header">
      <span>Imagenes</span>
    </ng-template>


    <div *ngIf="index == 0" >
      <app-carousel-images-form [ShowNestingArea]="true" [FormValue]="FormValue"></app-carousel-images-form>

    </div>

  </p-tabPanel>
  <p-tabPanel>
    <ng-template pTemplate="header">
      <span>Subir imagenes</span>
    </ng-template>


    <div *ngIf="index == 1">

      <app-multiple-files (uploadFiles)="onSelect($event)"></app-multiple-files>


    <!--
        <ngx-dropzone [accept]="'image/*'" (change)="onSelect($event)">
        <ngx-dropzone-label>Arrastra o carga una Imagen</ngx-dropzone-label>
        <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" *ngFor="let f of files" [file]="f" [removable]="true" (removed)="onRemove(f)">
        </ngx-dropzone-image-preview>
      </ngx-dropzone>
    -->

      <div class="d-flex justify-content-center mb-2">

        <p-button
        *appHasPermission="permissionsService.PERMISSIONS_ADD.MIP"
        (click)="save()"
          type="button"
          icon="fa-solid fa-save"
          styleClass="p-button-rounded p-button-outlined p-button-info ms-2 btn-sm "
        >
        </p-button>

    </div>

    </div>

  </p-tabPanel>
</p-tabView>


