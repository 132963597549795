import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';

import { Permission } from '../../core/models/permissions.interface';
import { PermissionsServices } from '../../services/permissions.service';

@Directive({
  selector: '[appHasPermission]',
})
export class HasPermissionDirective {
  @Input('appHasPermission') set config({
    moduleLabel,
    action,
  }: {
    moduleLabel: string;
    action: keyof Permission;
  }) {
        console.log(moduleLabel);
        console.log(action);
    if (this.permissionService.hasPermission(moduleLabel, action)) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } 
    else {
      this.viewContainer.clear();
    }
  }

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private permissionService: PermissionsServices
  ) {}
}
