
<div class="row">
  <div class="col-12 ">

      <form (ngSubmit)="accept()" [formGroup]="Form">
        <div class="grid formgrid  mt-3 ,b-4">
          <div class="col-12 mb-2  lg:col-12 lg:mb-0">
            <span class="p-float-label p-fluid">
              <input formControlName="name" id="float-input" type="text" pInputText>
              <label for="float-input">Nombre</label>
            </span>
          </div>
          <div class="col-12 mt-4 lg:col-12 lg:mb-0 mb-4">
            <span class="w-100 p-fluid p-float-label">
              <p-autoComplete
                formControlName="zone"
                [showEmptyMessage]="true"
                [suggestions]="filteredPerimeters"
                appendTo="body"
                [dropdown]="true"
                (completeMethod)="filterZone($event)"
                field="name"
                [minLength]="1"

              >
              </p-autoComplete>
              <label>Zona</label>
            </span>
          </div>

        </div>





          <div class="d-flex justify-content-center mt-4">
              <button pButton pRipple type="submit" [disabled]="Form.invalid" label="Aceptar" class="p-button-raised me-2 btn-sm"></button>
              <button (click)="cancel()" pButton pRipple type="button" label="Cancelar" class="p-button-raised p-button-secondary btn-sm"></button>
          </div>
      </form>

</div>


</div>

