<p-table class="custom-table "
styleClass="p-datatable-sm"
scrollDirection="horizontal"
#dtCalendar
[value]="Cleanings"
dataKey="id"
sortMode="multiple"
[rows]="10"
[showCurrentPageReport]="true"
[rowsPerPageOptions]="[10, 25, 50]"
[loading]="loading"
[paginator]="false"
[globalFilterFields]="['name']">
  <ng-template pTemplate="header">
      <tr>
          <th  class="white-space-nowrap bg-table-info text-center">Tipo condición</th>
          <th   class="white-space-nowrap bg-table-info text-center">Area</th>
          <th   class="white-space-nowrap bg-table-info text-center">Acción correctiva </th>
          <th   class="white-space-nowrap bg-table-info text-center">Acción preventiva </th>
          <th   class="white-space-nowrap bg-table-info text-center"></th>
      </tr>
  </ng-template>
  <ng-template pTemplate="body" let-item>
    <tr class="text-sm">
      <td class="text-center">
            {{ item.cleaning.name }}
      </td>
      <td class="text-center">
        <span *ngFor="let area of item?.nesting_area">
          {{ area.name }}
        </span>

  </td>
      <td class="text-center">
        {{ item?.cleaning.description }}
  </td>
  <td class="text-center">
    {{ item?.cleaning.suggestion }}
</td>

      <td class="text-center">

      <p-button
      *appHasPermission="permissionsService.PERMISSIONS_ADD.MIP"
        (click)="deleteItem(item)"
      type="button"
      icon="fa-solid fa-trash"
      styleClass="p-button-rounded p-button-outlined p-button-danger p-button-sm ml-2 btn-sm"
      >
      </p-button>
        <p-button
        *appHasPermission="permissionsService.PERMISSIONS_ADD.MIP"
        (click)="AddUpdateCleaning(item)"
          type="button"
          icon="fa-solid fa-edit"
          styleClass="p-button-rounded p-button-outlined p-button-info ms-2 btn-sm "
        >
        </p-button>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
      <tr>
          <td colspan="6">No tienes registros.</td>
      </tr>
  </ng-template>
</p-table>
