

<form (ngSubmit)="accept()" [formGroup]="Form">

  <div class="grid formgrid  mt-3 ,b-4">
      <div class="col-12 mb-2 mt-4  lg:col-6 lg:mb-0">
          <span class="w-100 p-fluid p-float-label p p-input-icon-left">
              <i class="fa-solid fa-building"></i>
              <input formControlName="name"  type="text" pInputText placeholder="Cliente / Compañía"/>
              <label for="autocomplete">Cliente / Compañía</label>
          </span>
      </div>

      <div class="col-12 mb-2 mt-4 lg:col-6 lg:mb-0">
        <span class="w-100 p-fluid p-float-label p p-input-icon-left">
            <i class="fa-solid fa-user-tie"></i>
            <input formControlName="contact_name"  type="text" pInputText placeholder="Contacto principal"/>
            <label for="autocomplete">Contacto principal</label>
        </span>
    </div>


    <div class="col-12 mb-2 mt-4 lg:col-6 lg:mb-0">
      <span class="w-100 p-fluid p-float-label p p-input-icon-left">
          <i class="fa-solid fa-phone"></i>
          <input formControlName="phone"  type="text" pInputText placeholder="Teléfono"/>
          <label for="autocomplete">Teléfono</label>
      </span>
  </div>

  <div class="col-12 mb-2 mt-4 lg:col-6 lg:mb-0">
    <span class="w-100 p-fluid p-float-label p p-input-icon-left">
        <i class="fa-solid fa-envelope"></i>
        <input formControlName="email"  type="text" pInputText placeholder="Correo"/>
        <label for="autocomplete">Correo</label>
    </span>
  </div>

  <div class="col-12 mb-2 mt-4 lg:col-6 lg:mb-0">
    <span class="w-100 p-fluid p-float-label p p-input-icon-left">
        <i class="fa-solid fa-phone"></i>
        <input formControlName="contact_phone"  type="text" pInputText placeholder="Teléfono contacto"/>
        <label for="autocomplete">Teléfono contacto</label>
    </span>
</div>

  <div class="col-12 mb-2 mt-4 lg:col-6 lg:mb-0">
    <span class="w-100 p-fluid p-float-label p p-input-icon-left">
        <i class="fa-solid fa-envelope"></i>
        <input formControlName="contact_email"  type="text" pInputText placeholder="Correo contacto"/>
        <label for="autocomplete">Correo contacto</label>
    </span>
  </div>

  <div class="col-12 mb-2 mt-4 lg:col-12 lg:mb-0">
    <span class="w-100 p-fluid p-float-label p p-input-icon-left">
        <i class="fa-solid fa-location-dot"></i>
        <input formControlName="address"  type="text" pInputText placeholder="Dirección"/>
        <label for="autocomplete">Dirección</label>
    </span>
  </div>

  <div class="col-12 mb-2 mt-4 lg:col-6 lg:mb-0">
    <span class="w-100 p-fluid p-float-label p p-input-icon-left">
        <i class="fa-solid fa-phone"></i>
        <input formControlName="internal_id"  type="text" pInputText placeholder="Número interno"/>
        <label for="autocomplete">Número interno</label>
    </span>
  </div>

  <div class="col-12 mb-2 mt-4 lg:col-6 lg:mb-0">
    <app-select

    [Catalog]="Catalog.BUSINESS_ACTIVITIES"
    field="name"
    [isDropdown]="true"
    type="p-autocomplete"
    [isMultiple]="false"
    label="Giro negocio"
    formControlName="business_activity"
  ></app-select>
  </div>



    <div class="col-12 mb-2 mt-4 text-center lg:col-12 lg:mb-0">
      <p class="fw-bold h5 me-3" >Logotipo</p>
      <div class=" text-center">
        <label  for="upload"
        class="hov-pointer   label-img" >
        <div class="contenedor-imagen">
  
         <i  *ngIf="!this.Form.value.logo" class="fa-solid fa-user fa-4x mt-3"></i>
          <img
            *ngIf="this.Form.value.logo"
            [src]="ImgDataUrl"
           class="bg-transparente"
          />
          <div class="leyenda" >Click para cambiar logo</div>
        </div>
        <input  (change)="myUploader($event)" type="file" accept="image/jpeg, image/jpg" name="file" id="upload" style="display:none;" />
      </label>
      </div>
    </div>




  </div>






  <div class="d-flex justify-content-center mt-4">
      <button pButton pRipple type="submit" [disabled]="Form.invalid" label="Aceptar" class="p-button-raised me-2 btn-sm"></button>
      <button (click)="cancel()" pButton pRipple type="button" label="Cancelar" class="p-button-raised p-button-secondary btn-sm"></button>
  </div>
</form>
