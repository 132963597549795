export enum StorageEnum {
  USER = 'user',
  EMPLOYE = 'employee',
  TOKEN = 'access_token',
  JOB_CENTER = 'job_center',
  COMPANY = 'company',
  COUNTRY_CODE = 'country_code',
  CURRENCY_CODE = 'currency_code',
  PERMISSIONS = 'permissions',
}
