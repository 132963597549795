import { Component, inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { TYPE_TOAST } from 'src/app/system/core/enums/ToastsEnums';
import { DateService } from 'src/app/system/services/date.service';
import { LoadingService } from 'src/app/system/services/loading.service';
import { MessagesService } from 'src/app/system/services/message.service';
import { MipFormsService } from 'src/app/system/services/MipForms.service';
import { PermissionsServices } from 'src/app/system/services/permissions.service';
import { UserService } from 'src/app/system/services/user.service';

@Component({
  selector: 'app-finish-service',
  templateUrl: './finish-service.component.html',
  styleUrls: ['./finish-service.component.scss']
})
export class FinishServiceComponent {
  public permissionsService = inject(PermissionsServices);

  Event: any;
  Form: FormGroup = this.FormBuilder.group({
    id: '',
    initial_date: ['', Validators.required],
    final_date: ['', Validators.required],
    initial_hour: [''],
    final_hour: [''],
    job_center: this.UserService.JobCenter,
  });

  constructor(
    private DateService: DateService,
    private FormBuilder: FormBuilder,
    private UserService: UserService,
    private MipFormsService: MipFormsService,
    private LoadingService: LoadingService,
    private MessagesService: MessagesService,
    public config: DynamicDialogConfig,
    public ref: DynamicDialogRef,
  ) { }

  ngOnInit(): void {
    this.Event = this.config.data.Event;

    if (this.Event.real_initial_date) {
      const real_initial_date = this.Event.real_initial_date;
      const real_initial_time = this.Event.real_initial_hour;

      const real_final_time = this.Event.real_final_hour;
      const real_final_date = this.Event.real_final_date;
      let combinedDateTimeInitial = new Date(real_initial_date + 'T' + real_initial_time);
      let combinedDateTimeFinal = new Date(real_final_date + 'T' + real_final_time);

      this.Form.patchValue({
        initial_date: combinedDateTimeInitial,
        final_date: combinedDateTimeFinal,
      });
    }
  }

  accept() {
    let data = {
      "real_initial_date": this.DateService.getFormatDataDate(this.Form.value.initial_date),
      "real_final_date": this.DateService.getFormatDataDate(this.Form.value.final_date),
      "real_initial_hour": this.DateService.getFormatDataTime(this.Form.value.initial_date),
      "real_final_hour": this.DateService.getFormatDataTime(this.Form.value.final_date),
    }

    this.MipFormsService.finishServiceMip(
      data,
      this.Event.id
    ).subscribe((res) => {
      this.LoadingService.Hide();
      this.MessagesService.ShowMessage(
        TYPE_TOAST.SUCCESS,
        'Correcto',
        'Finalizado correctamente'
      );

      this.ref?.close({
        success: true,
        event: res,
      });
    });
  }

  cancel() {
    this.ref?.close();
  }
}
