import { Component } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

import { LoadingService } from 'src/app/system/services/loading.service';
import { AreasControlService } from '../../../services/areasControlService';

@Component({
  selector: 'app-areas-control',
  templateUrl: './areas-control.component.html',
  styleUrls: ['./areas-control.component.scss']
})
export class AreasControlComponent {
  index: number = 0;
  Screen_Origin: string = "";
  idRegister: string = "";

  constructor(
    private LoadingService: LoadingService,
    public config: DynamicDialogConfig,
    public ref: DynamicDialogRef,
    private AreasControlService: AreasControlService
  ) { }

  ngOnInit() {
    this.Screen_Origin = this.AreasControlService.OriginScreen;
    this.idRegister = this.config.data.item.id;
  }

  handleChange(event: any) {
    this.index = event.index;
  }
}
