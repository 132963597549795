import { Component, inject } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { RRule } from 'rrule';

import { TYPE_TOAST } from 'src/app/system/core/enums/ToastsEnums';
import { CalendarService } from 'src/app/system/services/calendar.service';
import { DateService } from 'src/app/system/services/date.service';
import { LoadingService } from 'src/app/system/services/loading.service';
import { MessagesService } from 'src/app/system/services/message.service';
import { PermissionsServices } from 'src/app/system/services/permissions.service';
import { QuotesService } from 'src/app/system/services/quotes.service';
import { UserService } from 'src/app/system/services/user.service';
import { SendQuoteComponent } from '../../../quotes/components/send-quote/send-quote.component';
import { CancelEventComponent } from '../cancel-event/cancel-event.component';
import { CreateUpdateEventFormComponent } from '../create-update-event-form/create-update-event-form.component';
import { DocumentsComponent } from '../documents/documents.component';
import { EditTimeEmployeeComponent } from '../edit-time-employee/edit-time-employee.component';
import { MipFormsComponent } from '../MIP-FORMS/mip-forms.component';
import { AddUpdatePaymentMipComponent } from '../MIP-FORMS/payments/add-update-payment-mip/add-update-payment-mip.component';
import { ReactivateEventComponent } from '../reactivate-event/reactivate-event.component';
import { SendIndicationsComponent } from '../send-indications/send-indications.component';
import { SendReminderComponent } from '../send-reminder/send-reminder.component';
import { SendSignatureRequestComponent } from '../send-signature-request/send-signature-request.component';
import { ViewEventComponent } from '../view-event/ViewEvent.component';

@Component({
  selector: 'app-detail-event',
  templateUrl: './detail-event.component.html',
  styleUrls: ['./detail-event.component.scss']
})
export class DetailEventComponent {
  Event: any;
  labelRecurrent: string = "";

  public permissionsService = inject(PermissionsServices);

  constructor(private dialogService: DialogService,
    private FormBuilder: FormBuilder,
    private LoadingService: LoadingService,
    public config: DynamicDialogConfig,
    public ref: DynamicDialogRef,
    private QuotesService: QuotesService,
    private MessagesService: MessagesService,
    private UserService: UserService,
    private DateService: DateService,
    private CalendarService: CalendarService,
    private loading: LoadingService,
  ) { }

  ngOnInit() {
    this.Event = this.config.data.Event;

    if (this.Event.rrule) {
      this.labelRecurrent = this.describeRRule(this.Event.rrule);
    }
  }

  get Symbol_Country() {
    return this.UserService.Symbol_Country ?? ""
  }

  getStatusForMenu(status: string): string {
    const EVENT_STATUS_DICT: any = {
      'event-completed': 'completed',
      'event-created': 'created',
      'event-cancelled': 'cancelled',
      'event-in-progress': 'created',
      'event-confirm': 'confirm',
    };

    if (status && status in EVENT_STATUS_DICT) {
      return EVENT_STATUS_DICT[status];
    }

    return 'created';
  }

  eventHasLocation() {
    return this.Event.start_latitude != null && this.Event.start_longitude != null;
  }

  describeRRule(rruleString: string) {
    try {
      const rule = RRule.fromString(rruleString);
      const options = rule.options;

      const formatDate = (date: Date) => {
        return date.toISOString().split("T")[0].split("-").reverse().join("-");
      };

      const startDate = options.dtstart ? formatDate(options.dtstart) : "desconocida";
      const untilDate = options.until ? formatDate(options.until) : "sin fecha de finalización";

      const freqMap: any = {
        [RRule.DAILY]: "día",
        [RRule.WEEKLY]: "semana",
        [RRule.MONTHLY]: "mes",
        [RRule.YEARLY]: "año"
      };

      let frecuencia = freqMap[options.freq] || "desconocida";
      let intervalo = options.interval || 1;

      // Construir la parte de repetición
      let repeatText = `se repite ${intervalo > 1 ? `cada ${intervalo} ${frecuencia}s` : `cada ${frecuencia}`}`;

      // Si hay días específicos en una frecuencia semanal
      if (options.freq === RRule.WEEKLY && options.byweekday?.length) {
        const daysMap: Record<number, string> = {
          [RRule.MO.weekday]: "lunes",
          [RRule.TU.weekday]: "martes",
          [RRule.WE.weekday]: "miércoles",
          [RRule.TH.weekday]: "jueves",
          [RRule.FR.weekday]: "viernes",
          [RRule.SA.weekday]: "sábado",
          [RRule.SU.weekday]: "domingo"
        };

        let dias = options.byweekday.map((day: any) => daysMap[day]).join(", ");
        repeatText += ` los días ${dias}`;
      }

      return `Este evento comienza el ${startDate}, ${repeatText} y finaliza el ${untilDate}.`;

    } catch (error) {
      return "Formato de RRULE inválido.";
    }
  }

  deleteEvent() {
    if (this.Event.is_many) {
      this.MessagesService.labeBtnYes = "Eliminar todos";
      this.MessagesService.labelBtnNo = "No, solo el actual";

      this.MessagesService.confirm('¿Desea eliminar todos los eventos recurrentes que pertenecen a este evento?',
        () => {
          this.LoadingService.Show();

          this.CalendarService.deleteEventMassive(this.Event.id).subscribe((res) => {
            this.LoadingService.Hide();
            this.ref?.close();
            this.MessagesService.ShowMessage(TYPE_TOAST.SUCCESS, "Eliminados correctamente", "");

          })
        },
        () => {
          this.LoadingService.Show();
          this.MessagesService.labeBtnYes = "Confirmar";
          this.MessagesService.labelBtnNo = "Cancelar";

          this.LoadingService.Show();

          this.CalendarService.deleteEvent(this.Event.id).subscribe((res) => {
            this.LoadingService.Hide();
            this.ref?.close();
            this.MessagesService.ShowMessage(TYPE_TOAST.SUCCESS, "Eliminado correctamente", "");
          })
        }
      );

      return
    }

    this.MessagesService.confirm("¿Esta seguro de eliminar el event? " + this.Event.folio, () => {
      this.LoadingService.Show();

      this.CalendarService.deleteEvent(this.Event.id).subscribe((res) => {
        this.LoadingService.Hide();

        this.ref?.close();
        this.MessagesService.ShowMessage(TYPE_TOAST.SUCCESS, "Eliminado correctamente", "");
      })
    });
  }

  cancelEvent() {
    const dialog = this.dialogService.open(CancelEventComponent, {
      header: 'Cancelar evento ' + this.Event.folio,
      modal: true,
      width: '30rem',
      closable: true,
      draggable: false,
      resizable: true,
      closeOnEscape: true,
      dismissableMask: true,
      data: {
        Event: this.Event,
      },
    });

    dialog.onClose.subscribe((res) => {
      if (res?.success) {
        this.ref?.close({ success: true });
      }
    });
  }

  reactivateEvent() {
    const dialog = this.dialogService.open(ReactivateEventComponent, {
      header: 'Reactivar evento ' + this.Event.folio,
      modal: true,
      width: '30rem',
      closable: true,
      draggable: false,
      resizable: true,
      closeOnEscape: true,
      dismissableMask: true,
      data: {
        Event: this.Event,
      },
    });

    dialog.onClose.subscribe((res) => {
      if (res?.success) {
        this.ref?.close({ success: true });
      }
    });
  }

  editEvent() {
    let Date = null;

    const dialog = this.dialogService.open(CreateUpdateEventFormComponent, {
      header: 'Evento ' + this.Event.folio,
      modal: true,
      width: '50%',
      //height:"100%",
      closable: true,
      draggable: false,
      // maximizable: true,
      resizable: true,
      closeOnEscape: true,
      dismissableMask: true,
      data: {
        Event: this.Event,
        Date,
      },
    });

    dialog.onClose.subscribe((res) => {
      this.ref.close({ success: true })
    });
  }

  CrudMipForms() {
    const dialog = this.dialogService.open(MipFormsComponent, {
      header: 'Evento ' + this.Event.folio,
      modal: true,
      width: '75%',
      closable: true,
      draggable: false,
      maximizable: true,
      resizable: true,
      closeOnEscape: true,
      dismissableMask: true,
      data: {
        Event: this.Event,
      },
    });

    dialog.onClose.subscribe((res) => {
      this.ref.close({ success: true })
    });
  }

  viewEvent() {
    const dialog = this.dialogService.open(ViewEventComponent, {
      header: 'Evento ' + this.Event.folio,
      modal: true,
      width: '75%',
      closable: true,
      draggable: false,
      resizable: true,
      closeOnEscape: true,
      dismissableMask: true,
      data: {
        Event: this.Event,
      },
    });

    dialog.onClose.subscribe((res) => {
      //  this.ref.close({success:true})
    });
  }

  openLocation() {
    this.loading.Show();

    if (this.eventHasLocation()) {
      const { start_latitude, start_longitude } = this.Event;
      const url = `https://www.google.com/maps?q=${start_latitude},${start_longitude}`;

      window.open(url, '_blank');
    } else {
      this.MessagesService.ShowMessage(TYPE_TOAST.ERROR, "El evento no tiene una ubicación válida", "");
    }

    this.loading.Hide();
  }

  openDocuments() {
    const dialog = this.dialogService.open(DocumentsComponent, {
      header: 'Folio ' + this.Event.folio,
      modal: true,
      width: '32rem',
      closable: true,
      draggable: false,
      maximizable: true,
      resizable: true,
      closeOnEscape: true,
      dismissableMask: true,
      data: {
        Event: this.Event,
      },
    });

    dialog.onClose.subscribe((res) => {
      //  this.ref.close({success:true})
    });
  }

  sendCertificateEmail() {
    const dialog = this.dialogService.open(SendQuoteComponent, {
      header: "Enviar Certificado ",
      modal: true,
      width: "38rem",
      closable: true,
      draggable: false,
      maximizable: false,
      resizable: true,
      closeOnEscape: true,
      dismissableMask: true,
      data: {
        Quote: null,
        Event: this.Event,
      },
    });

    dialog.onClose.subscribe((res) => {
      //  this.ref.close({ success:true })
    });
  }

  sendCertificateWhatsApp() {
    this.loading.Show();

    this.CalendarService.sendCertificateWhatsApp(this.Event.id).subscribe((res) => {
      if (res?.data) {
        window.open(res.data, '_blank');
      }

      this.loading.Hide();
    });
  }

  sendSignatureRequest() {
    const dialog = this.dialogService.open(SendSignatureRequestComponent, {
      header: "Enviar solicitud de firma ",
      modal: true,
      width: "38rem",
      closable: true,
      draggable: false,
      maximizable: false,
      resizable: true,
      closeOnEscape: true,
      dismissableMask: true,
      data: {
        Event: this.Event,
      },
    });

    dialog.onClose.subscribe((res) => {
      //  this.ref.close({ success:true })
    });
  }

  sendEvaluate() {
    const dialog = this.dialogService.open(SendQuoteComponent, {
      header: "Enviar evaluación ",
      modal: true,
      width: "38rem",
      closable: true,
      draggable: false,
      maximizable: false,
      resizable: true,
      closeOnEscape: true,
      dismissableMask: true,
      data: {
        Quote: null,
        Event: this.Event,
        evaluation: true
      },
    });

    dialog.onClose.subscribe((res) => {
      //  this.ref.close({ success:true })
    });
  }

  openWarranty() {
    let Warranty: any = null;

    if (!this.Event.warranty) {
      this.MessagesService.ShowMessage(TYPE_TOAST.ERROR, "No cuenta con servicio de garantía", "");
      return
    }

    this.loading.Show();

    this.CalendarService.getEventsType().subscribe((res) => {
      Warranty = res.find((e: any) => e.name.toUpperCase() == "GARANTIA" || e.name.toUpperCase() == "GARANTÍA")

      this.CalendarService.getEventById(this.Event.id).subscribe((res) => {
        this.loading.Hide();

        if (!Warranty) {
          this.MessagesService.ShowMessage(TYPE_TOAST.ERROR, "Tienes que dar de alta el servicio de garantía en tipo de eventos para continuar", "");
          return
        }

        let Date = null;

        const dialog = this.dialogService.open(CreateUpdateEventFormComponent, {
          header: 'Evento ' + this.Event.folio,
          modal: true,
          width: '50%',
          //height:"100%",
          closable: true,
          draggable: false,
          // maximizable: true,
          resizable: true,
          closeOnEscape: true,
          dismissableMask: true,
          data: {
            Event: res.data,
            Date,
            Warranty
          },
        });

        dialog.onClose.subscribe((res) => {
          // this.ref.close({ success:true })
        });
      });
    });
  }

  openBooster() {
    let Bootster: any = null;

    if (!this.Event.bootster) {
      this.MessagesService.ShowMessage(TYPE_TOAST.ERROR, "No cuenta con servicio de refuerzo", "");
      return
    }

    this.loading.Show();

    this.CalendarService.getEventsType().subscribe((res) => {
      Bootster = res.find((e: any) => e.name.toUpperCase() == "REFUERZO")

      this.CalendarService.getEventById(this.Event.id).subscribe((res) => {
        this.loading.Hide();

        if (!Bootster) {
          this.MessagesService.ShowMessage(TYPE_TOAST.ERROR, "Tienes que dar de alta el servicio de refuerzo en tipo de eventos para continuar", "");
          return
        }

        let Date = null;

        const dialog = this.dialogService.open(CreateUpdateEventFormComponent, {
          header: 'Evento ' + this.Event.folio,
          modal: true,
          width: '50%',
          closable: true,
          draggable: false,
          resizable: true,
          closeOnEscape: true,
          dismissableMask: true,
          data: {
            Event: res.data,
            Date,
            Bootster
          },
        });

        dialog.onClose.subscribe((res) => {
          // this.ref.close({ success:true })
        });
      });
    });
  }

  openFollow() {
    let Follow: any = null;

    if (!this.Event.follow) {
      this.MessagesService.ShowMessage(TYPE_TOAST.ERROR, "No cuenta con servicio de Seguimiento", "");
      return
    }

    this.loading.Show();

    this.CalendarService.getEventsType().subscribe((res) => {
      Follow = res.find((e: any) => e.name.toUpperCase() == "SEGUIMIENTO");

      this.CalendarService.getEventById(this.Event.id).subscribe((res) => {
        this.loading.Hide();

        if (!Follow) {
          this.MessagesService.ShowMessage(TYPE_TOAST.ERROR, "Tienes que dar de alta el servicio de seguimiento en tipo de eventos para continuar", "");
          return
        }

        let Date = null;

        const dialog = this.dialogService.open(CreateUpdateEventFormComponent, {
          header: 'Evento ' + this.Event.folio,
          modal: true,
          width: '50%',
          //height:"100%",
          closable: true,
          draggable: false,
          // maximizable: true,
          resizable: true,
          closeOnEscape: true,
          dismissableMask: true,
          data: {
            Event: res.data,
            Date,
            Follow
          },
        });

        dialog.onClose.subscribe((res) => {
          // this.ref.close({ success:true })
        });
      });
    });
  }

  payEvent() {
    const dialog = this.dialogService.open(AddUpdatePaymentMipComponent, {
      header: 'Pago del servicio',
      modal: true,
      width: '42rem',
      closable: true,
      draggable: false,
      maximizable: false,
      resizable: true,
      closeOnEscape: true,
      dismissableMask: true,
      data: {
        Event: this.Event,
        Payment: null,
      },
    });

    dialog.onClose.subscribe((res) => {
      //  this.ref.close({ success:true })
    });
  }

  sendIndications() {
    this.dialogService.open(SendIndicationsComponent, {
      header: 'Enviar indicaciones del evento ' + this.Event.folio,
      modal: true,
      width: '30rem',
      closable: true,
      draggable: false,
      resizable: true,
      closeOnEscape: true,
      dismissableMask: true,
      data: {
        Event: this.Event
      },
    });
  }

  sendReminder() {
    this.dialogService.open(SendReminderComponent, {
      header: 'Enviar recordatorio del evento ' + this.Event.folio,
      modal: true,
      width: '30rem',
      closable: true,
      draggable: false,
      resizable: true,
      closeOnEscape: true,
      dismissableMask: true,
      data: {
        Event: this.Event
      },
    });
  }

  confirm() {
    this.loading.Show();

    this.CalendarService.ConfirmEvent(this.Event.id,).subscribe((res) => {
      this.loading.Hide();
      this.ref.close({ success: true });
    });
  }

  updateTimeAndEmployees() {
    const dialog = this.dialogService.open(EditTimeEmployeeComponent, {
      header: 'Editar fecha y técnico del evento ' + this.Event.folio,
      modal: true,
      width: '32rem',
      closable: true,
      draggable: false,
      resizable: true,
      closeOnEscape: true,
      dismissableMask: true,
      data: {
        Event: this.Event,
      },
    });

    dialog.onClose.subscribe((res) => {

      //this.ref.close({ success: true })
    });
  }
}
