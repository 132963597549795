
import { Component, Inject, inject } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { MIP_FORM } from 'src/app/system/core/enums/MipForms.model';
import { TYPE_TOAST } from 'src/app/system/core/enums/ToastsEnums';
import { MipFormsService } from 'src/app/system/services/MipForms.service';
import { LoadingService } from 'src/app/system/services/loading.service';
import { MessagesService } from 'src/app/system/services/message.service';
import { TablePaymentComponent } from './components/table-payment/table-payment.component';
import { AddUpdatePaymentMipComponent } from './components/add-update-payment-mip/add-update-payment-mip.component';
import { PermissionsServices } from 'src/app/system/services/permissions.service';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent {
  Payments:any[] = [];
  public permissionsService = inject(PermissionsServices);
  constructor(@Inject('dynamicData') public Event: any, private MipFormsService:MipFormsService, private LoadingService:LoadingService, private DialogService:DialogService, private MessagesService:MessagesService){

  }


    ngOnInit(): void {


  setTimeout(() => {
    this.getPayments();
  }, 0);
    }

    getPayments(){
      this.Payments = [];
      this.LoadingService.Show();
      this.MipFormsService.getDataForm(this.Event.id,'payment-forms').subscribe((res) => {

        this.Payments = res;
        this.LoadingService.Hide();

      });
    }


    AddUpdate(Payment: any,) {
      const dialog = this.DialogService.open(AddUpdatePaymentMipComponent, {
       header: "Pago del servicio",
       modal: true,
       width: '42rem',
       closable: true,
       draggable: false,
       maximizable: false,
       resizable: true,
       closeOnEscape: true,
       dismissableMask: true,
       data: {
        Event:this.Event,
        Payment
       },
     });
     dialog.onClose.subscribe((res) => {
       if (res?.success) {

         this.getPayments();
       }
     });

   }
    deleteItem(item: any) {
      this.MessagesService.confirm(
        '¿Esta seguro de eliminar el área ' + item.nesting_area?.name + ' ?',
        () => {
          this.MipFormsService.deleteDataForm(
            item.id,
            'condition-forms'
          ).subscribe((res) => {
            this.MessagesService.ShowMessage(TYPE_TOAST.SUCCESS,"Correcto","Se ha eliminado correctamente")
            this.getPayments();
          });
        }
      );
    }
}
