<form (ngSubmit)="accept()" [formGroup]="Form">
  <div class="grid formgrid mt-4">
    <div class="col-12 mb-4 lg:col-12">
      <ngx-dropzone [accept]="'image/*'" (change)="onSelect($event)" [multiple]="false">
        <ngx-dropzone-label>Arrastra o carga una Imagen</ngx-dropzone-label>
        <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" *ngFor="let f of files" [file]="f"
          [removable]="true" (removed)="onRemove(f)">
        </ngx-dropzone-image-preview>
      </ngx-dropzone>
    </div>

    <div class="col-12 mb-4 lg:col-12">
      <app-select [customer]="MipFormsService.CustomerIdMip" [Catalog]="Catalog.NESTING_AREAS" field="name"
        [isDropdown]="true" type="pest-control" [isMultiple]="false" label="Area"
        formControlName="nesting_area"></app-select>
    </div>

    <div class="col-12 mb-4 lg:col-12">
      <span class="p-fluid mt-5">
        <textarea formControlName="comments" placeholder="Comentarios" rows="3" cols="30" pInputTextarea></textarea>
      </span>
    </div>
  </div>

  <div class="d-flex justify-content-center mt-4">
    <button pButton pRipple type="submit" [disabled]="Form.invalid" label="Guardar"
      class="p-button-raised me-2 btn-sm"></button>

    <button (click)="cancel()" pButton pRipple type="button" label="Cancelar"
      class="p-button-raised p-button-secondary btn-sm"></button>
  </div>
</form>