import { HttpClient } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { environment } from 'src/environments/environment';
import { ModulePermission, Permission } from '../core/models/permissions.interface';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class PermissionsServices {
  private permissionsSubject = new BehaviorSubject<ModulePermission[]>([]);
  permissions$ = this.permissionsSubject.asObservable();

  private _permissions!: ModulePermission[];
  private baseUrl: string = environment.baseUrl

  PERMISSIONS_ADD = {
    CATALOGS: { moduleLabel: 'catalogs', action: 'add' },
    COMPANY: { moduleLabel: 'companies', action: 'add' },
    REFERRALS: { moduleLabel: 'referrals', action: 'add' },
    
    CUT: { moduleLabel: 'cut', action: 'add' },
    EXPENSES: { moduleLabel: 'expenses', action: 'add' },
    INVENTORIES: { moduleLabel: 'inventories', action: 'add' },
    JOB_CENTERS: { moduleLabel: 'job_centers', action: 'add' },
    CUSTOMERS: { moduleLabel: 'customers', action: 'add' },
    TICKETS: { moduleLabel: 'tickets', action: 'add' },
    TASKS: { moduleLabel: 'tasks', action: 'add' },
    QUOTES: { moduleLabel: 'quotes', action: 'add' },
    EVENTS: { moduleLabel: 'events', action: 'add' },
    MIP: { moduleLabel: 'mip', action: 'add' },
  }

  PERMISSIONS_READ = {
    MIP: { moduleLabel: 'mip', action: 'view' },
    LEADFORCE: { moduleLabel: 'chat', action: 'view' },
  }

  constructor(
    private http: HttpClient,
    private UserService: UserService
  ) { }

  getPermissionsByJobTitle(idJobTitle: any) {
    return this.http.get<any>(`${this.baseUrl}/catalogs/job-titles/${idJobTitle}/permissions/`)
  }

  deletePermissions(idJobTitle: string) {
    return this.http.delete<any>(`${this.baseUrl}/catalogs/job-titles/${idJobTitle}/permission_remove/`)
  }

  savePermissions(data: any, idJobTitle: string) {
    return this.http.put<any>(`${this.baseUrl}/catalogs/job-titles/${idJobTitle}/permission_add/`, data)
  }

  get Permissions() {
    return this._permissions;
  }

  set Permissions(permissions: ModulePermission[]) {
    this._permissions = permissions;
  }

  hasPermission(moduleLabel: string, action: keyof Permission): boolean {
    const module = this.Permissions.find((perm) => perm.label === moduleLabel);
    return module ? module.permissions[action] : false;
  }
}
