<div class="card border-left">

  <div class="col-12 md:col-12 d-flex justify-content-between flex-wrap mb-3">
    <span class=" h4 fw-bold text-primary p-2"><i class="fas fa-calendar me-2"></i>CALENDARIO</span>
<div class="me-3">
  <p-button
  (click)="resetFilters()"
  type="button"
  icon="fa-solid fa-filter-circle-xmark"
  styleClass="p-button-rounded p-button-danger p-button-sm ml-2"
  >
</p-button>
<!--
<p-button
(click)="openFilters()"
type="button"
icon="fa-solid fa-filter"
styleClass="p-button-rounded p-button-success p-button-sm ml-2"
>
</p-button>-->
  <p-button
  *appHasPermission="permissionsService.PERMISSIONS_ADD.EVENTS"
  (click)="addEvent()"
    type="button"
    icon="fa-solid fa-plus"
    styleClass="p-button-rounded ms-2"
  >
  </p-button>
</div>
</div>

  <p-accordion>
    <p-accordionTab  styleClass="custom-accordion-tab" header="Filtrar calendario" >
      <form [formGroup]="FormGroup" class="row pt-4 pb-4">
        <div class="col-xs-12 col-md-12 col-lg-4 mt-3">
            <app-select
            (onSelect)="filterByGroup($event)"
            [Catalog]="Catalog.GROUPS"
            field="name"
            [isDropdown]="true"
            type="p-autocomplete-administrative"
            [isMultiple]="false"
            label="Grupo ruta"
            formControlName="group"
          ></app-select>

        </div>
        <div class="col-xs-12 col-md-12 col-lg-4 mt-3">
          <app-select
          [Catalog]="Catalog.EVENT_TYPES"
          (onSelect)="filterByGroup($event)"
          field="name"
          [isDropdown]="true"
          type="p-autocomplete"
          [isMultiple]="false"
          label="Tipo evento"
        formControlName="event_type"
        ></app-select>
        </div>
        <div class="col-xs-12 col-md-12 col-lg-4 mt-3">
          <app-select
          [Catalog]="Catalog.EMPLOYEES"
          (onSelect)="filterByGroup($event)"
          field="name"
          [isDropdown]="true"
          type="p-autocomplete-administrative"
          [isMultiple]="false"
          label="Técnico"
          formControlName="employee"
        ></app-select>
        </div>

        <div class="col-xs-12 col-md-12 col-lg-4 mt-4">

        <app-select
        (onSelect)="filterByGroup($event)"
        [isMain]="false"
        [UrlCatalog]="URL_CATALOGS.CUSTOMERS_DROPDOWN"
        field ="name"
        [isDropdown] = "false"
        type="select-generic"
        [isMultiple] = "false"
        label="Filtrar por cliente"
        formControlName="customer">
      </app-select>
        </div>
        <div  class="col-xs-12 col-md-12 col-lg-4 mt-4">
          <app-select
          [Catalog]="Catalog.SERVICE_TYPE"
          (onSelect)="filterByGroup($event)"
          field="name"
          [isDropdown]="true"
          type="p-autocomplete"
          [isMultiple]="false"
          label="Tipo servicio"
          formControlName="service_type"
        ></app-select>
        </div>
        <div class="col-xs-12 col-md-12 col-lg-4 mt-4">
          <app-select
          [Catalog]="Catalog.QUOTES"
          (onSelect)="filterByGroup($event)"
          field="folio"
          [isDropdown]="true"
          type="p-autocomplete-administrative"
          [isMultiple]="false"
          label="Cotización"
       formControlName="quote"
               ></app-select>
        </div>

      </form>
    </p-accordionTab>
  </p-accordion>

<!--
  <div class="d-flex justify-content-between pt-3 mb-2">
  <div [formGroup]="FormGroup" class="col-4">
    <app-select
    (onSelect)="filterByGroup($event)"
    [Catalog]="Catalog.GROUPS"
    field="name"
    [isDropdown]="true"
    type="p-autocomplete-administrative"
    [isMultiple]="false"
    label="Grupo ruta"
    formControlName="group"
  ></app-select>
  </div>
  <div>
    <p-button
    (click)="resetFilters()"
    type="button"
    icon="fa-solid fa-filter-circle-xmark"
    styleClass="p-button-rounded p-button-danger p-button-sm ml-2"
    >
  </p-button>
  <p-button
  (click)="openFilters()"
  type="button"
  icon="fa-solid fa-filter"
  styleClass="p-button-rounded p-button-success p-button-sm ml-2"
  >
  </p-button>
    <p-button
    (click)="addEvent()"
      type="button"
      icon="fa-solid fa-plus"
      styleClass="p-button-rounded ms-2"
    >
    </p-button>
  </div>



</div>-->












    <p-tabView [activeIndex]="index" (onChange)="handleChange($event)">
        <p-tabPanel >
     <ng-template pTemplate="header">
                <i class="pi pi-calendar me-2"></i>
                <span>Calendario</span>
            </ng-template>

               <app-calendar *ngIf="index == 0" initialView="timeGridWeek"  ></app-calendar>

        </p-tabPanel>



    </p-tabView>

</div>

