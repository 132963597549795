import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { MIP_FORM } from 'src/app/system/core/enums/MipForms.model';
import { TYPE_TOAST } from 'src/app/system/core/enums/ToastsEnums';
import { CatalogsEnum } from 'src/app/system/core/enums/catalogs.model';
import { MipFormsService } from 'src/app/system/services/MipForms.service';
import { LoadingService } from 'src/app/system/services/loading.service';
import { MessagesService } from 'src/app/system/services/message.service';

@Component({
  selector: 'app-add-update-station',
  templateUrl: './add-update-station.component.html',
  styleUrls: ['./add-update-station.component.scss']
})
export class AddUpdateStationComponent {
  Catalog = CatalogsEnum;
  Form: FormGroup = this.FormBuilder.group({
    station: ['', [Validators.required]],
    station_condition: ['', [Validators.required]],
    stationTypeActivity: ['', [Validators.required]],
    station_activity: ['', [Validators.required]],
    id: [''],
    event:[''],
    plague: ['', [Validators.required]],
    EmployeeActivity: ['', [Validators.required]],
});
Event:any;
plague_counts:any = [];
constructor(
  private FormBuilder: FormBuilder,
  private LoadingService: LoadingService,
  public config: DynamicDialogConfig,
  public ref: DynamicDialogRef,
  private DialogService: DialogService,
  private MipFormsService:MipFormsService,
  private MessagesService: MessagesService,

) {

}

ngOnInit(): void {
this.Event = this.config.data.Event;

  if (this.config.data.Station) {


   this.patchForm();
  }
  this.listenFormPlague();
}


listenFormPlague(){

      // Suscribirse a cambios en el campo "name"
      this.Form.get('plague')?.valueChanges.subscribe((selectedValues) => {

        // Revisa los elementos seleccionados en el multiselect
        selectedValues.forEach((element: any) => {
          // Verifica si el elemento ya existe en `plague_counts`
          let existeRegistro = this.plague_counts.find((e: any) => e.plague === element.id);
         
          
          if (!existeRegistro) {
            console.log(existeRegistro);
            // Si no existe, lo añade a `plague_counts`
            this.plague_counts.push({
              plague: element.id,
              name: element.name,
              count_plague: 0
            });
          }
        });
      
        // Filtra `plague_counts` para eliminar elementos que ya no están seleccionados
        this.plague_counts = this.plague_counts.filter((item: any) =>
          selectedValues.some((selected: any) => selected.id === item.plague)
        );

 

      });
}


patchForm(){
  let item = this.config.data.Station;


this.plague_counts = item.plague_counts.map((element:any) => ({
  id:element.plague.id,
  plague: element.plague.id,
  name: element.plague.name,
  count_plague: element.count_plague
}));

console.log(this.plague_counts);


item.station.completeNameStation =  item?.station.station_type.name + ' - ' +  item.station.name +  '-' + item.station.key;
  this.Form.patchValue({
    station: item.station,
    station_condition: item.station_condition,
    stationTypeActivity: item.stationTypeActivity,
    station_activity: item.station_activity,
    id: item.id,
    event:this.Event.id,
    EmployeeActivity:item.EmployeeActivity,
    plague:this.plague_counts
  });
}

accept() {

  this.LoadingService.Show();
 if(!this.config.data.Station){

  this.save();
  return
 }
this.update();
}
cancel() {
  this.ref?.close();
}



  save(){

    let stationTypeActivity =  this.Form.value?.stationTypeActivity.map((item:any)=>item.id);
    let station_activity =  this.Form.value?.station_activity.map((item:any)=>item.id);
    let station_condition =  this.Form.value?.station_condition.map((item:any)=>item.id);
let data = {
  event:this.Event.id,
  station: this.Form.value.station.id,
    station_condition,
    stationTypeActivity,
    "EmployeeActivity" : this.Form.value.EmployeeActivity,
    station_activity,
    plague_counts:this.plague_counts,
}

  this.MipFormsService.saveDataForm(data, MIP_FORM.STATION_COUNT_DATA).subscribe((res)=>{
      this.LoadingService.Hide();
      this.MessagesService.ShowMessage(
          TYPE_TOAST.SUCCESS,
          'Correcto',
          'Guardado correctamente'
      );
      this.ref?.close({ success: true });
    });

  }

  update(){

console.log(this.plague_counts);


    let stationTypeActivity =  this.Form.value?.stationTypeActivity.map((item:any)=>item.id);
    let station_activity =  this.Form.value?.station_activity.map((item:any)=>item.id);
    let data = {
      event:this.Form.value.event,
      station: this.Form.value.station.id,
    station_condition: this.Form.value.station_condition.id,
    stationTypeActivity,
    "EmployeeActivity" : this.Form.value.EmployeeActivity,
    plague_counts:[    {
      "plague": "af53b159-6d43-486c-bef1-a1940a46f955",
      "count_plague": 10
  },
  {
      "plague": "6f57fbde-947a-480a-95b6-5e24bea12527",
      "count_plague": 5
  }],
    station_activity,
     id:this.Form.value.id,
    }

      this.MipFormsService.putDataForm(data, MIP_FORM.STATION_COUNT_DATA).subscribe((res)=>{
          this.LoadingService.Hide();
          this.MessagesService.ShowMessage(
              TYPE_TOAST.SUCCESS,
              'Correcto',
              'Actualizado correctamente'
          );
          this.ref?.close({ success: true });
        });
  }
}
