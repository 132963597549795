<p-tabView [activeIndex]="index" (onChange)="handleChange($event)">
  <p-tabPanel>
    <ng-template pTemplate="header">
      <span>Enviar solicitud de firma por correo</span>
    </ng-template>

    <div *ngIf="index == 0">
      <form (ngSubmit)="sendRequestEmail()" [formGroup]="FormEmail">
        <div class="grid formgrid  ">
          <div class="col-12 mb-2 mt-4  lg:col-12 lg:mb-0">
            <span class="w-100 p-fluid p-float-label p p-input-icon-left">
              <i class="fa-solid fa-envelope"></i>
              <input formControlName="customer_email" type="email" pInputText placeholder="Correo electrónico" />
              <label for="customer_email">Correo electrónico</label>
            </span>
          </div>
        </div>

        <div class="d-flex justify-content-center mt-4">
          <button pButton pRipple type="submit" [disabled]="FormEmail.invalid" label="Enviar"
            class="p-button-raised me-2 btn-sm"></button>

          <button (click)="cancel()" pButton pRipple type="button" label="Cancelar"
            class="p-button-raised p-button-secondary btn-sm"></button>
        </div>
      </form>
    </div>
  </p-tabPanel>
</p-tabView>
