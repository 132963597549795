<div class="grid formgrid mt-3 pb-4">
  <div class="col-12 mt-2 lg:col-6 lg:mb-0 mb-4">
    <span class="w-100 p-fluid p-input-icon-left">
      <i class="pi pi-search"></i>
      <input
        pInputText
        type="text"
        (input)="getEventValue($event)"
        placeholder="Buscar..."
        class="w-full"
      />
    </span>
  </div>

  <div
    class="col-12 mt-2 mb-4 d-flex justify-content-end text-center lg:col-6 lg:mb-0"
  >
    <p-button
    (click)="AddUpdate(null)"
      type="button"
      icon="fa-solid fa-plus"
      styleClass="p-button-rounded"
      [disabled]="loading"
    >
    </p-button>
  </div>
</div>

  <p-table
  class="custom-table"
  styleClass="p-datatable-sm"
  scrollDirection="horizontal"
  #dtCalendar
  [value]="dataItems"
  dataKey="id"
  sortMode="multiple"
  [rows]="10"
  [showCurrentPageReport]="true"
  [rowsPerPageOptions]="[10, 25, 50]"
  [loading]="loading"
  [paginator]="false"
  [globalFilterFields]="['name']"
  >

    <ng-template pTemplate="header">
      <tr>
        <th
          pSortableColumn="name"
          class="white-space-nowrap bg-primary"
          style="width: 25%"
        >
          Nombre <p-sortIcon field="name"></p-sortIcon>
        </th>
        <th

        class="white-space-nowrap bg-primary"
        style="width: 25%"
      >
      Zona

      </th>

        <th

          class="white-space-nowrap bg-primary"
          style="width: 25%"
        >
        Fecha

        </th>



      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-item>
      <tr class="text-sm">
        <td>

          <div class="dropdow d">
            <p  class="fw-bold h6 dropdown-toggle text-primary" type="button" data-bs-toggle="dropdown" aria-expanded="false">
              {{item.name}}
            </p>
            <ul class="dropdown-menu">
              <li><a (click)="AddUpdate(item)"  class="dropdown-item hov-pointer text-primary fw-bold" ><i class="fas fa-edit me-2"></i>Editar</a></li>
             <li><a (click)="deleteItem(item)" class="dropdown-item hov-pointer text-danger fw-bold" ><i class="fas fa-trash-alt me-2"></i>Eliminar</a></li>
            </ul>
          </div>

        </td>
        <td>{{ item.zone?.name }}</td>
        <td>
          <i class="fa-solid fa-plus text-primary"></i>
          {{ item.created_at | date : "short" : "GMT-5" }} <br />
          <i class="fa-solid fa-arrows-rotate text-primary"></i>
          {{ item.updated_at | date : "short" : "GMT-5" }}
        </td>

      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
        <td colspan="7">no hay datos.</td>
      </tr>
    </ng-template>
  </p-table>
  <p-paginator
  [first]="dataPaginate.first"
  [rows]="dataPaginate.rows"
  [rowsPerPageOptions]="[10, 20, 30, 50]"
  [totalRecords]="dataPaginate.totalRecords"
  (onPageChange)="onPageChange($event)"
  currentPageReportTemplate="{currentPage} de {totalPages}"
  [showJumpToPageDropdown]="true"
  [showPageLinks]="true"
  [showJumpToPageInput]="true"
></p-paginator>
