import { Component, inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { CatalogsEnum } from 'src/app/system/core/enums/catalogs.model';
import { TYPE_TOAST } from 'src/app/system/core/enums/ToastsEnums';
import { LoadingService } from 'src/app/system/services/loading.service';
import { MessagesService } from 'src/app/system/services/message.service';
import { MipFormsService } from 'src/app/system/services/MipForms.service';
import { PermissionsServices } from 'src/app/system/services/permissions.service';
import { UtilsService } from 'src/app/system/services/utils.service';

@Component({
  selector: 'app-select-area-monitoring-photos',
  templateUrl: './select-area-monitoring-photos.component.html',
  styleUrls: ['./select-area-monitoring-photos.component.scss']
})
export class SelectAreaMonitoringPhotosComponent {
  public permissionsService = inject(PermissionsServices);

  Area: any;
  loading: boolean = false;
  files: File[] = [];
  Catalog = CatalogsEnum;

  Form: FormGroup = this.FormBuilder.group({
    id: [''],
    comments: [''],
  });

  constructor(
    private FormBuilder: FormBuilder,
    private UtilsService: UtilsService,
    public config: DynamicDialogConfig,
    public MipFormsService: MipFormsService,
    private LoadingService: LoadingService,
    private MessagesService: MessagesService,
    public ref: DynamicDialogRef,
  ) { }

  ngOnInit(): void {
    this.Area = this.config.data.Area.area;

    if (this.config.data.Photo) {
      this.patchForm();
    }
  }

  patchForm() {
    let item = this.config.data.Photo;

    this.Form.patchValue({
      id: item.id,
      comments: item.comments,
    });

    this.files.push(item.photo);
  }

  onSelect(event: any) {
    if (this.files.length > 0) {
      this.files = [];
    }

    if (event.addedFiles && event.addedFiles.length > 0) {
      this.files.push(event.addedFiles[0]);
    }
  }

  onRemove(file: any) {
    this.files = this.files.filter(f => f !== file);
  }

  accept() {
    this.LoadingService.Show();

    if (!this.config.data.Photo) {
      this.save();
      return
    }

    this.update();
  }

  cancel() {
    this.ref?.close();
  }

  save() {
    let data = {
      photo: this.files[0],
      comments: this.Form.value.comments,
      area_activity: this.Area.id,
    }

    const formData = this.UtilsService.objectToFormData(data);

    this.MipFormsService.saveDataForm(formData, 'area-activity-photo').subscribe((res) => {
      this.LoadingService.Hide();

      this.MessagesService.ShowMessage(
        TYPE_TOAST.SUCCESS,
        'Correcto',
        'Guardado correctamente'
      );

      this.ref?.close({
        success: true,
        photo: res,
        isUpdate: false
      });
    });
  }

  update() {
    let data = {
      id: this.Form.value.id,
      photo: this.files[0],
      comments: this.Form.value.comments,
      area_activity: this.Area.id,
    }

    const formData = this.UtilsService.objectToFormData(data);

    this.MipFormsService.putDataFormData(formData, data.id, 'area-activity-photo').subscribe((res) => {
      this.LoadingService.Hide();

      this.MessagesService.ShowMessage(
        TYPE_TOAST.SUCCESS,
        'Correcto',
        'Guardado correctamente'
      );

      this.ref?.close({
        success: true,
        photo: res,
        isUpdate: true
      });
    });
  }
}
