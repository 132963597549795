<form (ngSubmit)="accept()" [formGroup]="Form">
  <div class="grid formgrid">

    <div class="col-12 mt-4">
      <h5 class="fw-bold text-center">Finalizar el servicio</h5>

      <div class="col-12 mt-4">
        <span class="w-100 p-fluid p-float-label p p-input-icon-left">
          <i class="fa-solid fa-calendar"></i>
          <p-calendar [showIcon]="true" appendTo="body" formControlName="initial_date" [showTime]="true"></p-calendar>
          <label for="ContactCustomer">Fecha Real inicial</label>
        </span>
      </div>

      <div class="col-12 mt-4">
        <span class="w-100 p-fluid p-float-label p p-input-icon-left">
          <i class="fa-solid fa-calendar"></i>
          <p-calendar [showIcon]="true" appendTo="body" formControlName="final_date" [showTime]="true"></p-calendar>
          <label for="final_date">Fecha Real final</label>
        </span>
      </div>
    </div>

    <div class="col-12 mt-4"></div>
  </div>

  <div *appHasPermission="permissionsService.PERMISSIONS_ADD.MIP" class="d-flex justify-content-center mt-4">
    <button pButton pRipple type="submit" [disabled]="Form.invalid" label="Finalizar"
      class="p-button-raised me-2 btn-sm"></button>

    <button (click)="cancel()" pButton pRipple type="button" label="Cancelar"
      class="p-button-raised p-button-secondary btn-sm"></button>
  </div>
</form>