<form (ngSubmit)="accept()" [formGroup]="Form">
  <div class="grid formgrid mt-4 ">
    <div class="col-12 mb-2 lg:col-6 lg:mb-0">
      <app-select [Catalog]="Catalog.PAYMENT_WAYS" field="name" [isDropdown]="true" type="p-autocomplete"
        [isMultiple]="false" label="Forma de pago" formControlName="payment_way"></app-select>
    </div>

    <div class="col-12 mb-2 lg:col-6 lg:mb-0">
      <app-select [Catalog]="Catalog.PAYMENT_METHODS" field="name" [isDropdown]="true" type="p-autocomplete"
        [isMultiple]="false" label="Método pago" formControlName="payment_method"></app-select>
    </div>

    <div class="col-12 mb-2 mt-4 lg:mb-0">
      <span class="w-100 p-fluid p-float-label p p-input-icon-left">
        <i class="fa-solid fa-dollar"></i>
        <input formControlName="amount" type="number" pInputText placeholder="Monto " />
        <label for="amount">Monto</label>
      </span>
    </div>

    <div class="col-12 mt-4 mb-2 lg:mb-0">
      <span class="p-fluid mt-5">
        <textarea formControlName="comments" placeholder="Comentarios" rows="3" cols="30" pInputTextarea></textarea>
      </span>
    </div>

    <div class="col-12 mb-2 mt-4 lg:mb-0">
      <p-fileUpload
        #fileUploader
        name="demo[]"
        url="./upload.php"
        (onUpload)="onUpload($event)"
        [customUpload]="true"
        [multiple]="true"
        (onSelect)="onUpload($event)"
        accept="image/*"
        [showUploadButton]="false"
        [showCancelButton]="false"
        [auto]="true"
        styleClass="border-1 surface-border surface-card p-0 border-round mb-4"
      >
        <ng-template pTemplate="content">
          <div style="height: 15rem !important;" class=" m-1 border-round">
            <div *ngIf="!image"
              class="flex flex-column w-full h-full justify-content-center align-items-center cursor-pointer"
              (click)="fileUploader.advancedFileInput.nativeElement.click()">
              <i class="pi pi-fw pi-file text-4xl text-primary"></i>
              <span class="block font-semibold text-900 text-lg mt-3">{{ textPhoto }}</span>
            </div>

            <div *ngIf="image" class="w-full h-full relative border-round p-0">
              <img [src]="image.objectURL ?? image" class="w-full h-full border-round" alt="blog cover">
              <button pButton pRipple type="button" icon="pi pi-times"
                class="p-button-rounded p-button-primary text-sm absolute justify-content-center align-items-center"
                style="top: -10px; right: -10px;" (click)="$event.stopPropagation(); removeImage()"></button>
            </div>
          </div>
        </ng-template>
      </p-fileUpload>
    </div>
  </div>

  <div  class="d-flex justify-content-center mt-3">
    <div class="field-checkbox">
      <p-checkbox [binary]="true" formControlName="clientPay" id="activate_inventory"></p-checkbox>
      <label for="activate_inventory">Cliente no pagó</label>
    </div>
  </div>

  <div class="d-flex justify-content-center mt-4">
    <button pButton pRipple type="submit" [disabled]="Form.invalid" label="Guardar"
      class="p-button-raised me-2 btn-sm"></button>

    <button (click)="cancel()" pButton pRipple type="button" label="Cancelar"
      class="p-button-raised p-button-secondary btn-sm"></button>
  </div>
</form>
