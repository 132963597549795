import { Component, ElementRef, QueryList, ViewChildren, inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { catchError, throwError } from 'rxjs';

import { TYPE_TOAST } from 'src/app/system/core/enums/ToastsEnums';
import { CatalogsEnum } from 'src/app/system/core/enums/catalogs.model';
import { MipFormsService } from 'src/app/system/services/MipForms.service';
import { LoadingService } from 'src/app/system/services/loading.service';
import { MessagesService } from 'src/app/system/services/message.service';
import { PermissionsServices } from 'src/app/system/services/permissions.service';
import { UtilsService } from 'src/app/system/services/utils.service';

@Component({
  selector: 'app-add-update-payment-mip',
  templateUrl: './add-update-payment-mip.component.html',
  styleUrls: ['./add-update-payment-mip.component.scss']
})
export class AddUpdatePaymentMipComponent {
  @ViewChildren('buttonEl') buttonEl!: QueryList<ElementRef>;
  public permissionsService = inject(PermissionsServices);
  image: any;

  objectURL: string = '';
  textPhoto: string = 'Selecciona o arrastra una imagen';
  Catalog = CatalogsEnum;
  Form: FormGroup = this.FormBuilder.group({
    amount: ['', [Validators.required]],
    photo: ['', [Validators.required]],
    payment_method: ['', [Validators.required]],
    payment_way: ['', [Validators.required]],
    comments: [''],
    clientPay: [false],
    id: [''],
    event: [''],
  });
  randomValue = Math.random();
  customer: any;

  constructor(
    private UtilsService: UtilsService,
    private FormBuilder: FormBuilder,
    private LoadingService: LoadingService,
    public config: DynamicDialogConfig,
    public ref: DynamicDialogRef,
    private DialogService: DialogService,
    private MipFormsService: MipFormsService,
    private MessagesService: MessagesService,
  ) { }

  ngOnInit(): void {
    this.customer = this.config.data.Event.customer.id;

    if (this.config.data.Payment) {
      this.patchForm();
    }
  }

  patchForm() {
    let item = this.config.data.Payment;
    this.image = item.photo + '?' + this.randomValue;

    this.Form.patchValue({
      amount: item.amount,
      payment_method: item.payment_method,
      payment_way: item.payment_way,
      photo: item.photo,
      comments: item.comments,
      id: item.id,
    });
  }

  accept() {
    this.LoadingService.Show();

    if (!this.config.data.Payment) {
      this.save();
      return;
    }

    this.update();
  }

  cancel() {
    this.ref?.close();
  }

  save() {
    let data = {
      amount: this.Form.value.amount,
      payment_method: this.Form.value.payment_method.id,
      payment_way: this.Form.value.payment_way.id,
      comments: this.Form.value.comments,
      event: this.config.data.Event.id,
      photo: this.Form.value.photo,
      clientPay: this.Form.value.clientPay
    };

    this.MipFormsService.saveDataForm(this.UtilsService.objectToFormData(data), 'payment-forms').subscribe((res) => {
      this.LoadingService.Hide();

      this.MessagesService.ShowMessage(
        TYPE_TOAST.SUCCESS,
        'Correcto',
        'Guardado correctamente'
      );

      this.ref?.close({ success: true });
    });
  }

  update() {
    let data = {
      amount: this.Form.value.amount,
      payment_method: this.Form.value.payment_method.id,
      payment_way: this.Form.value.payment_way.id,
      comments: this.Form.value.comments,
      event: this.config.data.Event.id,
      photo: this.Form.value.photo,
      id: this.Form.value.id,
    };

    if (this.UtilsService.isString(this.Form.value.photo)) {
      delete data.photo;
    }

    this.MipFormsService.patchDataForm(
      this.UtilsService.objectToFormData(data),
      'payment-forms',
      data.id
    ).pipe(
      catchError((error) => {
        console.error('Error during patch request:', error);
        return throwError(error);
      })
    ).subscribe((res) => {
      this.LoadingService.Hide();

      this.MessagesService.ShowMessage(
        TYPE_TOAST.SUCCESS,
        'Correcto',
        'Guardado correctamente'
      );

      this.ref?.close({ success: true });
    });
  }

  onUpload(event: any) {
    let file = event.files[0];

    if (!file.objectURL) {
      return;
    } else {
      this.image = file;
      this.objectURL = file.objectURL ?? '';
      this.Form.get('photo')?.setValue(file);
    }
  }

  removeImage() {
    this.image = null;
    this.Form.get('photo')?.setValue('');
  }

  clientNoPay(event: any) {
    console.log(this.Form.value.clientPay);
    const isChecked = event.checked;

    if (isChecked) {
      // El checkbox ha sido marcado
      console.log('El checkbox ha sido marcado');
    } else {
      // El checkbox ha sido desmarcado
      console.log('El checkbox ha sido desmarcado');
    }
  }
}
