import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-upload-document-image',
  templateUrl: './upload-document-image.component.html',
  styleUrls: ['./upload-document-image.component.scss']
})
export class UploadDocumentImageComponent implements OnInit {


  @Input() TypeDocument:string =".jpg, .jpeg, .png";
  @Input() isImage:boolean =false;
  @Input() Title:string ="";
  @Input() id:string ="";
  @Input() label:string ="Selecciona un archivo";

  @Output()uploadFile = new EventEmitter<any>();
  @Output()removeFile = new EventEmitter<boolean>();
  @Input() Document:any;
  isUpload:boolean = false;


constructor(){

}

ngOnInit(): void {

}
      myUploader(event: any) {

        this.isUpload = true;

        this.uploadFile.emit(event.target.files[0]);
     }



      removeImage(event: Event, ) {
          event.stopPropagation();
          this.Document = null;
          this.removeFile.emit(true);
        //  this.Form.patchValue({cover:null});
      }
}
