import { UserService } from '../../../services/user.service';
import { AuthService } from 'src/app/system/services/auth.service';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { LayoutService } from 'src/app/layout/service/app.layout.service';
import { Employee } from 'src/app/system/core/models/employee.interface';
import { DialogService } from 'primeng/dynamicdialog';
import { AddUpdateEmployeeComponent } from 'src/app/system/Panel/administrative/humanresources/employees/components/add-update-employee/add-update-employee.component';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss']
})
export class TopbarComponent implements OnInit{
    menu: MenuItem[] = [];

    @ViewChild('searchinput') searchInput!: ElementRef;

    @ViewChild('menubutton') menuButton!: ElementRef;

    searchActive: boolean = false;
    employee!:Employee;
    constructor(public layoutService: LayoutService, private DialogService:DialogService, public AuthService:AuthService, public UserService:UserService) {}

    onMenuButtonClick() {
        this.layoutService.onMenuToggle();
    }


        ngOnInit(): void {
            this.employee = this.UserService.Employee;


        }


    removeTab(event: MouseEvent, item: MenuItem, index: number) {
        this.layoutService.onTabClose(item, index);
        event.preventDefault();
    }

    get layoutTheme(): string {
        return this.layoutService.config.layoutTheme;
    }

    get colorScheme(): string {
        return this.layoutService.config.colorScheme;
    }


    get tabs(): MenuItem[] {
        return this.layoutService.tabs;
    }

    getPicture() : string {

        return this.UserService.profilePhoto;
      }
   //   https://pestwareapp.com/landing/images/pestware-cuadre.png
      profile(){
        this.employee.name= "as"
      }

      viewProfile() {
        let title = "Mi perfil";
         const dialog = this.DialogService.open(AddUpdateEmployeeComponent, {
          header: title,
          modal: true,
          width: '50%',
          //height:"100%",
          closable: true,
          draggable: false,
          maximizable: false,
          resizable: true,
          closeOnEscape: true,
          dismissableMask: true,
          data: {
            item:this.UserService.Employee,
            isMain:true,
            main_customer:""
          },
        });
        dialog.onClose.subscribe((res) => {

        });

      }
}
