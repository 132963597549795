

<form (ngSubmit)="accept()" [formGroup]="Form">
  <div class="grid formgrid  ">
    <div class="col-12 mb-2 mt-4  lg:col-12 lg:mb-0">
      <app-select  [Catalog]="Catalog.PRODUCTS" field ="name" [isDropdown] = "true" type="inventories" [isMultiple] = "false" label="Producto" formControlName="product"></app-select>
  </div>
    <div class="col-12 mb-2 mt-4  lg:col-12 lg:mb-0">
      <app-select  [Catalog]="Catalog.APPLICATION_METHODS" field ="name" [isDropdown] = "true" type="p-autocomplete" [isMultiple] = "false" label="Método de aplicación" formControlName="application_method"></app-select>
  </div>


  <div class="col-12 mb-2 mt-4  lg:col-12 lg:mb-0">
    <app-select  [Catalog]="Catalog.DOSE_TYPES_PRODUCTS" field ="dose_to_apply" [isDropdown] = "true" type="inventories" [isMultiple] = "false" label="Dósis" formControlName="dose"></app-select>
</div>


    <div class="col-12 mb-2 mt-4 lg:col-12 lg:mb-0">
      <span class="w-100 p-fluid p-float-label p p-input-icon-left">
        <i class="fa-solid fa-pencil"></i>
        <input formControlName="quantity"  type="number" pInputText placeholder="Cantidad "/>
        <label for="quantity">Cantidad </label>
      </span>
    </div>

    <div class="col-12 mb-2 mt-4  lg:col-12 lg:mb-0">
      <app-select [customer]="customer" [Catalog]="Catalog.NESTING_AREAS" field ="name" [isDropdown] = "true" type="pest-control" [isMultiple] = "true" label="Areas control" formControlName="nesting_area"></app-select>
  </div>



  </div>
  <div class="d-flex justify-content-center mt-4">
    <button pButton pRipple type="submit" [disabled]="Form.invalid" label="Guardar" class="p-button-raised me-2 btn-sm"></button>
    <button (click)="cancel()" pButton pRipple type="button" label="Cancelar" class="p-button-raised p-button-secondary btn-sm"></button>
</div>
</form>
