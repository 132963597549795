<div class="">
  <p class="mt-0 fw-bold h4">
    <span class="text-danger">{{Event?.folio}}</span>
    {{ ' - ' }}

    <span
      class="fw-bold h3 text-dan"
      [ngClass]="{'text-success': Event.status.key_string === 'event-completed', 'text-primary' :  Event.status.key_string === 'event-created' }"
    >
      {{Event.status.name}}
      <span *ngIf="Event.is_many">🔄</span>
    </span>
  </p>

  <p class="text-dark mt-2 fw-bold" style="font-size: 0.rem !important;">{{ labelRecurrent }}</p>

  <p-image *ngIf="Event.customer.logo" [src]="Event.customer.logo" alt="Image" width="100" [preview]="true">
    <br>
  </p-image>

  <div class="grid formgrid   ">
    <div class="col-12  mt-4">
      <p class="mt-1">
        <span class="fw-bold h6">Evento: </span> {{ Event?.title }}
      </p>

      <p class="mt-1">
        <span class="fw-bold h6">Cliente: </span> {{ Event?.customer.name }}
      </p>

      <p class="mt-1">
        <span class="fw-bold h6">Dirección: </span> {{ Event.customer.address }}
      </p>

      <p class="mt-1">
        <span class="fw-bold h6">Teléfono: </span> {{ Event.customer.phone }}
      </p>

      <p class="mt-1">
        <span class="fw-bold h6">Contacto: </span> {{ Event.customer.contact_name }}
      </p>

      <p *ngIf="Event.customer.contact_phone" class="mb-2">
        <span class="fw-bold h6">Teléfono de contacto:</span>
        {{ Event.customer.contact_phone }}
      </p>

      <p class="mt-1">
        <span class="fw-bold h6">Nombre Evento: </span> {{ Event.event_type.name }}
      </p>

      <p *ngIf="Event.plague.length > 0" class="mt-1">
        <span class="fw-bold h6">Plagas: </span>
        <span *ngFor="let element of Event.plague; let l = last">
          {{ element?.name }}
          <span *ngIf="!l">, </span>
        </span>
      </p>

      <p class="mt-1"><span class="fw-bold h6">
        Hora Inicio: </span> {{ Event.initial_hour + ' - ' + Event.initial_date }}
      </p>

      <p class="mt-1">
        <span class="fw-bold h6">Hora Fin: </span> {{Event.final_hour + ' - ' + Event.final_date }}
      </p>

      <p class="mt-1">
        <span class="fw-bold h6">Técnico: </span>
        <span *ngFor="let employee of Event.employee; let l = last ">
          {{ employee.name }}
          <span *ngIf="!l">,</span>
        </span>
      </p>

      <p class="mt-1">
        <span class="fw-bold h6">Total: </span>
        {{ Symbol_Country }}{{ Event.total | number:'1.2-2' }}
      </p>
    </div>
  </div>

  <div class="d-flex justify-content-center mt-3 align-items-center flex-wrap gap-2">
    <ng-container *appHasPermission="permissionsService.PERMISSIONS_ADD.EVENTS">
      <p-button
        *ngIf="getStatusForMenu(Event.status.key_string) === 'completed' && eventHasLocation()"
        (click)="openLocation()"
        type="button"
        pTooltip="Ubicación"
        icon="fa-solid fa-map-marker"
        tooltipPosition="top"
        styleClass="p-button p-button-rounded p-button-outlined color-blue">
      </p-button>
    </ng-container>

    <ng-container *appHasPermission="permissionsService.PERMISSIONS_ADD.EVENTS">
      <p-button
        *ngIf="getStatusForMenu(Event.status.key_string) === 'created'"
        (click)="updateTimeAndEmployees()"
        type="button"
        pTooltip="Editar fecha y técnico"
        icon="fas fa-calendar-alt"
        tooltipPosition="top"
        styleClass="p-button p-button-rounded p-button-outlined color-blue">
      </p-button>
    </ng-container>

    <ng-container *appHasPermission="permissionsService.PERMISSIONS_READ.MIP">
      <p-button
        *ngIf="getStatusForMenu(Event.status.key_string) !== 'cancelled'"
        (click)="CrudMipForms()"
        type="button"
        icon="fa-solid fa-file-pen"
        pTooltip="Ver formularios"
        tooltipPosition="top"
        styleClass="p-button p-button-rounded p-button-outlined color-blue">
      </p-button>
    </ng-container>

    <ng-container *appHasPermission="permissionsService.PERMISSIONS_ADD.EVENTS">
      <p-button
        *ngIf="getStatusForMenu(Event.status.key_string) === 'completed'"
        (click)="viewEvent()"
        type="button"
        pTooltip="Ver"
        icon="fas fa-eye"
        tooltipPosition="top"
        styleClass="p-button p-button-rounded p-button-outlined color-gray">
      </p-button>
    </ng-container>

    <ng-container *appHasPermission="permissionsService.PERMISSIONS_ADD.EVENTS">
      <p-button
        *ngIf="getStatusForMenu(Event.status.key_string) !== 'cancelled'"
        (click)="editEvent()"
        type="button"
        icon="fa-solid fa-pen"
        pTooltip="Editar Información"
        tooltipPosition="top"
        styleClass="p-button p-button-rounded p-button-outlined color-gray">
      </p-button>
    </ng-container>

    <ng-container *appHasPermission="permissionsService.PERMISSIONS_ADD.EVENTS">
      <p-button
        *ngIf="getStatusForMenu(Event.status.key_string) === 'completed'"
        (click)="sendCertificateEmail()"
        type="button"
        pTooltip="Enviar certificado"
        icon="fas fa-envelope"
        tooltipPosition="top"
        styleClass="p-button p-button-rounded p-button-outlined color-gray">
      </p-button>
    </ng-container>

    <ng-container *appHasPermission="permissionsService.PERMISSIONS_ADD.EVENTS">
      <p-button
        *ngIf="getStatusForMenu(Event.status.key_string) === 'completed'"
        (click)="sendCertificateWhatsApp()"
        type="button"
        pTooltip="Enviar certificado por WhatsApp"
        icon="fab fa-whatsapp"
        tooltipPosition="top"
        styleClass="p-button p-button-rounded p-button-outlined color-green">
      </p-button>
    </ng-container>

    <ng-container *appHasPermission="permissionsService.PERMISSIONS_ADD.EVENTS">
      <p-button
        *ngIf="getStatusForMenu(Event.status.key_string) === 'completed'"
        (click)="sendSignatureRequest()"
        type="button"
        pTooltip="Solicitar firma"
        icon="fas fa-signature"
        tooltipPosition="top"
        styleClass="p-button p-button-rounded p-button-outlined color-blue">
      </p-button>
    </ng-container>

    <ng-container *appHasPermission="permissionsService.PERMISSIONS_ADD.EVENTS">
      <p-button
        *ngIf="getStatusForMenu(Event.status.key_string) === 'completed'"
        (click)="sendEvaluate()"
        type="button"
        pTooltip="Evaluar"
        icon="fas fa-star"
        tooltipPosition="top"
        styleClass="p-button p-button-rounded p-button-outlined color-yellow">
      </p-button>
    </ng-container>

    <ng-container *appHasPermission="permissionsService.PERMISSIONS_ADD.EVENTS">
      <p-button
        *ngIf="getStatusForMenu(Event.status.key_string) === 'completed'"
        (click)="openWarranty()"
        type="button"
        pTooltip="Garantía"
        icon="fas fa-triangle-exclamation"
        tooltipPosition="top"
        styleClass="p-button p-button-rounded p-button-outlined color-yellow">
      </p-button>
    </ng-container>

    <ng-container *appHasPermission="permissionsService.PERMISSIONS_ADD.EVENTS">
      <p-button
        *ngIf="getStatusForMenu(Event.status.key_string) === 'completed'"
        (click)="openBooster()"
        type="button"
        pTooltip="Refuerzo"
        icon="fas fa-shield-alt"
        tooltipPosition="top"
        styleClass="p-button p-button-rounded p-button-outlined color-green">
      </p-button>
    </ng-container>

    <ng-container *appHasPermission="permissionsService.PERMISSIONS_ADD.EVENTS">
      <p-button
        *ngIf="getStatusForMenu(Event.status.key_string) === 'completed'"
        (click)="openFollow()"
        type="button"
        pTooltip="Seguimiento"
        icon="fas fa-arrow-right"
        tooltipPosition="top"
        styleClass="p-button p-button-rounded p-button-outlined color-gray">
      </p-button>
    </ng-container>

    <ng-container *appHasPermission="permissionsService.PERMISSIONS_ADD.EVENTS">
      <p-button
        *ngIf="getStatusForMenu(Event.status.key_string) === 'completed'"
        (click)="payEvent()"
        type="button"
        pTooltip="Pagar"
        icon="fas fa-money-bill"
        tooltipPosition="top"
        styleClass="p-button p-button-rounded p-button-outlined color-green">
      </p-button>
    </ng-container>

    <ng-container *appHasPermission="permissionsService.PERMISSIONS_ADD.EVENTS">
      <p-button
        *ngIf="getStatusForMenu(Event.status.key_string) !== 'completed' && getStatusForMenu(Event.status.key_string) !== 'cancelled'"
        (click)="sendIndications()"
        type="button"
        pTooltip="Enviar indicaciones por WhatsApp"
        icon="fab fa-whatsapp"
        tooltipPosition="top"
        styleClass="p-button p-button-rounded p-button-outlined color-green">
      </p-button>
    </ng-container>

    <ng-container *appHasPermission="permissionsService.PERMISSIONS_ADD.EVENTS">
      <p-button
        *ngIf="getStatusForMenu(Event.status.key_string) !== 'completed' && getStatusForMenu(Event.status.key_string) !== 'cancelled'"
        (click)="sendReminder()"
        type="button"
        pTooltip="Enviar recordatorio por WhatsApp"
        icon="fas fa-bell"
        tooltipPosition="top"
        styleClass="p-button p-button-rounded p-button-outlined color-green">
      </p-button>
    </ng-container>

    <ng-container *appHasPermission="permissionsService.PERMISSIONS_ADD.EVENTS">
      <p-button
        *ngIf="getStatusForMenu(Event.status.key_string) === 'created'"
        (click)="confirm()"
        type="button"
        pTooltip="Confirmar"
        icon="fas fa-check"
        tooltipPosition="top"
        styleClass="p-button p-button-rounded p-button-outlined color-green">
      </p-button>
    </ng-container>

    <ng-container *appHasPermission="permissionsService.PERMISSIONS_ADD.EVENTS">
      <p-button
        *ngIf="getStatusForMenu(Event.status.key_string) === 'created'"
        (click)="payEvent()"
        type="button"
        pTooltip="Cobrar"
        icon="fas fa-money-bill"
        tooltipPosition="top"
        styleClass="p-button p-button-rounded p-button-outlined color-green">
      </p-button>
    </ng-container>

    <ng-container>
      <p-button
        *ngIf="getStatusForMenu(Event.status.key_string) !== 'cancelled'"
        (click)="openDocuments()"
        type="button"
        icon="fa-solid fa-file-pdf"
        pTooltip="Documentación"
        tooltipPosition="top"
        styleClass="p-button p-button-rounded p-button-outlined color-red">
      </p-button>
    </ng-container>

    <ng-container *appHasPermission="permissionsService.PERMISSIONS_ADD.EVENTS">
      <p-button
        *ngIf="getStatusForMenu(Event.status.key_string) !== 'cancelled' && getStatusForMenu(Event.status.key_string) !== 'completed'"
        (click)="cancelEvent()"
        type="button"
        icon="fa-solid fa-ban"
        pTooltip="Cancelar evento"
        tooltipPosition="top"
        styleClass="p-button p-button-rounded p-button-outlined color-red">
      </p-button>
    </ng-container>

    <ng-container *appHasPermission="permissionsService.PERMISSIONS_ADD.EVENTS">
      <p-button
        (click)="deleteEvent()"
        type="button"
        icon="fa-solid fa-trash"
        pTooltip="Eliminar evento"
        tooltipPosition="top"
        styleClass="p-button p-button-rounded p-button-outlined color-red">
      </p-button>
    </ng-container>

    <ng-container *appHasPermission="permissionsService.PERMISSIONS_ADD.EVENTS">
      <p-button
        *ngIf="getStatusForMenu(Event.status.key_string) === 'cancelled'"
        (click)="reactivateEvent()"
        type="button"
        icon="fa-solid fa-check"
        pTooltip="Reactivar evento"
        tooltipPosition="top"
        styleClass="p-button p-button-rounded p-button-outlined color-green">
      </p-button>
    </ng-container>
  </div>
</div>
