import { Component } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

import { CalendarService } from 'src/app/system/services/calendar.service';
import { LoadingService } from 'src/app/system/services/loading.service';

@Component({
  selector: 'app-send-indications',
  templateUrl: './send-indications.component.html',
  styleUrls: ['./send-indications.component.scss']
})
export class SendIndicationsComponent {
  constructor(
    private FormBuilder: FormBuilder,
    private LoadingService: LoadingService,
    public config: DynamicDialogConfig,
    public ref: DynamicDialogRef,
    private CalendarService: CalendarService
  ) { }

  Form: FormGroup = this.FormBuilder.group({
    id: ''
  });

  ngOnInit(): void {
    this.Form.patchValue({
      id: this.config.data.Event.id
    })
  }

  sendIndications() {
    let data = this.Form.value

    this.LoadingService.Show();

    this.CalendarService.sendIndications(data).subscribe((res) => {
      if (res?.data) {
        window.open(res.data, '_blank');
      }

      this.ref?.close({ success: true });
      this.LoadingService.Hide();
    })
  }
}
