import { Component, Inject, Input, inject } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { TYPE_TOAST } from 'src/app/system/core/enums/ToastsEnums';
import { MipFormsService } from 'src/app/system/services/MipForms.service';
import { LoadingService } from 'src/app/system/services/loading.service';
import { MessagesService } from 'src/app/system/services/message.service';
import { AddUpdateStationComponent } from '../add-update-station/add-update-station.component';

import { MIP_FORM } from 'src/app/system/core/enums/MipForms.model';
import { PhotosStationComponent } from '../photos-station/photos-station.component';
import { PermissionsServices } from 'src/app/system/services/permissions.service';

@Component({
  selector: 'app-table-stations',
  templateUrl: './table-stations.component.html',
  styleUrls: ['./table-stations.component.scss']
})
export class TableStationsComponent {
  public permissionsService = inject(PermissionsServices);
  index : number = 0;
  activeTabIndex: { [key: string]: number } = {};

Stations:any[] = [];
constructor(@Inject('dynamicData') public Event: any, private MipFormsService:MipFormsService, private LoadingService:LoadingService, private DialogService:DialogService, private MessagesService:MessagesService){

}
handleChange(event: any) {


  this.index = event.index;
}
activeAccordionId: string | null = null;


handleAccordionClick(accordionId: string) {
  if (this.activeAccordionId === accordionId) {
    this.activeAccordionId = null;
  } else {
    this.activeAccordionId = accordionId;
  }
}
  ngOnInit(): void {


setTimeout(() => {
  this.getStations();
}, 0);
  }

  getStations(){
    this.LoadingService.Show();
    this.MipFormsService.getDataForm(this.Event.id,MIP_FORM.STATION_COUNT_DATA).subscribe((res) => {
      console.log(res);

      this.Stations = res;
      this.LoadingService.Hide();

    });
  }


  AddUpdate(Station: any,) {

     const dialog = this.DialogService.open(AddUpdateStationComponent, {
      header: "Estación",
      modal: true,
      width: '50rem',
      //height:"100%",
      closable: true,
      draggable: false,
      maximizable: false,
      resizable: true,
      closeOnEscape: true,
      dismissableMask: true,
      data: {
        Station,
        Event:this.Event
      },
    });
    dialog.onClose.subscribe((res) => {
      if (res?.success) {
        this.getStations();
      }
    });

  }
  deleteItem(item: any) {
    this.MessagesService.confirm(
      '¿Esta seguro de eliminar el monitoreo ' + item.station.name + ' ?',
      () => {
        this.MipFormsService.deleteStationCount(
          item.id
        ).subscribe((res) => {
          this.MessagesService.ShowMessage(TYPE_TOAST.SUCCESS,"Correcto","Se ha eliminado correctamente")
          this.getStations();
        });
      }
    );
  }

  viewImages( Station:any) {
    const dialog = this.DialogService.open(PhotosStationComponent, {
     header: "Fotos Estacion " + Station.station.station_type.name,
     modal: true,
     width: '50rem',
     //height:"100%",
     closable: true,
     draggable: false,
     maximizable: false,
     resizable: true,
     closeOnEscape: true,
     dismissableMask: true,
     data: {
      Station,
     },
   });
   dialog.onClose.subscribe((res) => {
     if (res?.success) {

     }
   });

  }



}
