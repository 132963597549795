import { JobCenter } from './../core/models/employee.interface';
import { UserService } from './user.service';



import {Injectable, OnDestroy, OnInit} from '@angular/core';
import {Observable} from "rxjs";
import {HttpClient, HttpParams} from "@angular/common/http";

import {FormGroup} from "@angular/forms";
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TasksService {

  private baseUrl: string = environment.baseUrl

  constructor(private http: HttpClient,

    private UserService:UserService) { }

    getJobCenters(filters:any){

      let params = new HttpParams();

      params = params.append('job_center', this.UserService.JobCenter);

      if(filters.search){
          params =  params.append('search',  filters.search)
      }

      return this.http.get<any>(`${this.baseUrl}/job-centers/`, {params})
  }


  getTasks(filters:any,paginate:boolean){

    let params = new HttpParams();

    //params = params.append('job_center', this.UserService.JobCenter);
    params = params.append('paginate',  paginate);

    if(filters.job_center){
      params = params.append('job_center', filters.job_center)
    }
    if(filters.initial_date){
      params = params.append('initial_date', filters.initial_date)
    }
    if(filters.final_date){
      params = params.append('final_date', filters.final_date)
    }

    if(filters.event){
      params = params.append('event', filters.event)
    }

    if(filters.search){

        params =  params.append('search',  filters.search)
    }
    if(filters.page){
      params = params.append('page', filters.page)
    }
    if(filters.page_size){
      params = params.append('page_size', filters.page_size)
    }


    if(filters.status){
      params = params.append('status', filters.status)
    }


    return this.http.get<any>(`${this.baseUrl}/tasks/tasks`, {params})
}

patchTask(data:any,taskId:string){
  return this.http.patch<any>(`${this.baseUrl}/tasks/tasks/${taskId}/`,data);
}



deleteTask(idTask:string){
  return this.http.delete<any>(`${this.baseUrl}/tasks/tasks/${idTask}/`)
}

}
