import {Injectable} from '@angular/core';
import * as moment from "moment"

@Injectable({
  providedIn: 'root'
})
export class DateService {

  constructor() {
    moment().locale('es')
  }
  getFormatDateWithHour(date: any) {
    return moment(new Date(date)).format('YYYY-MM-DDTHH:mm:ss').toString();
  }

  getFormatDate(date: string) {
    return moment(new Date(date)).format('DD-MM-YYYY').toString();
  }
  /*FormData Fechas*/
  getFormatDataDate(date: any) {
    return moment(new Date(date)).format('YYYY-MM-DD').toString();
  }

  convertirFecha(date: Date): string {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');

    return `${year}${month}${day}T${hours}${minutes}${seconds}Z`;
  }

  getFormatDataTime(date: any) {
    return moment(new Date(date)).format('HH:mm:ss').toString();
  }
  parseTimeToDate(timeString: string): Date {
  
    const now = new Date();
    const [hours, minutes, seconds] = timeString.split(":").map(Number);
    now.setHours(hours, minutes, seconds, 0);
    return now;
  }
  getFormatDateInput(date: any) {
    const [day, month, year] = date.split('-');
    return moment([year, (Number(month) - 1),  (Number(day) + 1), 0, 0, 0, 0]).format('YYYY-MM-DD');
  }
  formatDateToUTC(date: any){
    return moment().utc(date).format('DD-MM-YYYYTHH:mm');
  }
  formatDateUTC(date: any){
    return moment(new Date(date)).format('DD-MM-YYYYTHH:mm');
  }
  formatDateLocal(date:string){
    let local = date.split('T')
    return `${local[0]} ${local[1]}`;
  }

  // Format Input Angular Type Example: "2020-07-13T00:00:00-05:00".
  getFormatDateSetInputRangePicker(date: string){
    return moment(date).format()
  }

  getFormatDateComplete(date: any) {
    return moment(new Date(date)).format('lll').toString();
  }


  getEspecialDate(date:string):string{
 let formatDate =  date.split('-').reverse().join('/');
 const [day, month, year] =  formatDate.split('/');

 return month + '/' + day + '/' + year;
  }

  dateTodayCalendar():string{
    const date = new Date();

    const anio = date.getFullYear();
    const mes = ('0' + (date.getMonth() + 1)).slice(-2); // Se suma 1 porque los meses en JavaScript van de 0 a 11
    const dia = ('0' + date.getDate()).slice(-2);

    const fechaFormateada = `${anio}-${mes}-${dia}`;

    return fechaFormateada;
  }
}
