import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

import { TYPE_TOAST } from 'src/app/system/core/enums/ToastsEnums';
import { CalendarService } from 'src/app/system/services/calendar.service';
import { LoadingService } from 'src/app/system/services/loading.service';
import { MessagesService } from 'src/app/system/services/message.service';

@Component({
  selector: 'app-reactivate-event',
  templateUrl: './reactivate-event.component.html',
  styleUrls: ['./reactivate-event.component.scss']
})
export class ReactivateEventComponent implements OnInit {
  constructor(
    private MessagesService: MessagesService,
    private FormBuilder: FormBuilder,
    private LoadingService: LoadingService,
    public config: DynamicDialogConfig,
    public ref: DynamicDialogRef,
    private CalendarService: CalendarService
  ) { }

  Form: FormGroup = this.FormBuilder.group({
    id: ''
  });

  ngOnInit(): void {
    this.Form.patchValue({
      id: this.config.data.Event.id
    })
  }

  reactivateEvent() {
    let data = this.Form.value

  
    if(this.config.data.Event.is_many){
      this.MessagesService.labeBtnYes = "Reactivar todos";
      this.MessagesService.labelBtnNo = "No, solo el actual";
     
      this.MessagesService.confirm('¿Desea Reactivar todos los eventos recurrentes que pertenecen a este evento?' ,
        () => {
          this.LoadingService.Show();
    
          this.CalendarService.reactivateEventMssives(data).subscribe((res) => {
            this.LoadingService.Hide();
         
            this.MessagesService.ShowMessage(TYPE_TOAST.SUCCESS, "Reactivados correctamente", "");
            this.ref?.close({ success: true });
            });
    
    
        },
    
        () =>{
          this.CalendarService.reactivateEvent(data).subscribe((res) => {
            this.ref?.close({ success: true });
            this.LoadingService.Hide();
      
            this.MessagesService.ShowMessage(
              TYPE_TOAST.SUCCESS,
              'Correcto',
              'Reactivado correctamente'
            );
          });
        }
      );
    
      return
    }
    








    this.CalendarService.reactivateEvent(data).subscribe((res) => {
      this.ref?.close({ success: true });
      this.LoadingService.Hide();

      this.MessagesService.ShowMessage(
        TYPE_TOAST.SUCCESS,
        'Correcto',
        'Reactivado correctamente'
      );
    })
  }
}
