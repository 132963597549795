
import { UserService } from 'src/app/system/services/user.service';
import { Component, Input, OnInit, inject } from '@angular/core';
import { AreasControlService } from 'src/app/system/services/areasControlService';
import { CatalogService } from 'src/app/system/services/catalogs.service';
import { DialogService } from 'primeng/dynamicdialog';
import { MessagesService } from 'src/app/system/services/message.service';
import { CatalogsEnum } from 'src/app/system/core/enums/catalogs.model';
import { AddUpdateZoneComponent } from './components/add-update-zone/add-update-zone.component';
import { CustomerService } from 'src/app/system/services/customer.service';
import { TYPE_TOAST } from 'src/app/system/core/enums/ToastsEnums';
import { PermissionsServices } from 'src/app/system/services/permissions.service';

@Component({
  selector: 'app-table-zones',
  templateUrl: './table-zones.component.html',
  styleUrls: ['./table-zones.component.scss']
})
export class TableZonesComponent implements OnInit {
  @Input() idRegister: string = "";
  loading: boolean = false;
  dataPaginate: any;
  dataItems!: any[];
  public permissionsService = inject(PermissionsServices);
  constructor(private MessagesService: MessagesService, private CustomerService: CustomerService, private DialogService: DialogService, private AreasControlService: AreasControlService, private UserService: UserService, private CatalogService: CatalogService) {

    this.dataPaginate = {
      page_size: 10,
      first: 0,
      page: 0,
      rows: 10,
      totalRecords: 0,
      search: '',
      job_center: this.UserService.JobCenter
    };
  }
  ngOnInit(): void {

    // this.updateEventSharedService();
    this.loadDataZonesControl();

  }
  loadDataZonesControl(): void {
    if (this.AreasControlService.OriginScreen === "BUSINESS-ACTIVITES") {
      this.getZonesByBusinessPest();
    }
   else if (this.AreasControlService.OriginScreen === "CUSTOMERS") {
      this.getZonesByCustomer();
    }
    else if (this.AreasControlService.OriginScreen === "CHECKPOINT") {
      this.getZonesByCheckPoint();
    }
  }

  onPageChange(event: any) {
    this.dataPaginate.loading = true;
    this.dataPaginate.first = event.first;
    this.dataPaginate.page_size = event.rows;
    this.dataPaginate.pageCount = event.pageCount;
    this.dataPaginate.page = event.page + 1;
    this.loadDataZonesControl();
  }

  /**
   * Event Search Global
   * @param $event
   */
  getEventValue($event: any): void {
    this.dataPaginate.search = $event.target.value;
    this.loading = true;
    this.loadDataZonesControl();
  }


  //CUSTOMERS PATH
  //https://api.dev.pestwareapp.com/administrative/pest-control/zones/?customer=74d0fe3b-f5e4-4ea5-ac79-36a0149f3bc3&job_center=7b0aeb50-9a03-46a0-b284-996f8455a6f1&page_size=10 customers

  //BUSINESS ACTIVITES PATH
  //https://api.dev.pestwareapp.com/administrative/business-pest/zones/?business_activity=1dd08594-779a-4a3f-8e2f-4baa43a448e9&job_center=7b0aeb50-9a03-46a0-b284-996f8455a6f1&page_size=10

  getZonesByBusinessPest() {
    this.loading = true;
    this.AreasControlService.getZonesControlByBusiness(this.dataPaginate, this.idRegister).subscribe((response) => {
      this.dataItems = response.items;
      this.dataPaginate.page = response.page;
      this.dataPaginate.totalRecords = response.total;
      this.loading = false;
    });
  }

  getZonesByCustomer() {
    this.loading = true;
    this.CustomerService.getZonesControlByCustomer(this.dataPaginate, this.idRegister).subscribe((response) => {
      this.dataItems = response.items;
      this.dataPaginate.page = response.page;
      this.dataPaginate.totalRecords = response.total;
      this.loading = false;
    });
  }

  getZonesByCheckPoint() {
    this.loading = true;
    this.CustomerService.getZonesByCheckPoint(this.dataPaginate, this.idRegister).subscribe((response) => {
      this.dataItems = response.items;
      this.dataPaginate.page = response.page;
      this.dataPaginate.totalRecords = response.total;
      this.loading = false;
    });
  }


  AddUpdate(item: any) {
    const dialog = this.DialogService.open(AddUpdateZoneComponent, {
      header: "Zona",
      modal: true,
      width: "25rem",
      //height:"100%",
      closable: true,
      draggable: false,
      maximizable: false,
      resizable: true,
      closeOnEscape: true,
      dismissableMask: true,
      data: {
        item,
        idRegister: this.idRegister
      },
    });
    dialog.onClose.subscribe((res) => {
      if (res?.success) {
        this.loadDataZonesControl();
      }

    });
  }

  deleteItem(item: any) {


    this.MessagesService.confirm("¿Esta seguro de eliminar el registro " + item.name + ' ?', () => {

      if (this.AreasControlService.OriginScreen === "BUSINESS-ACTIVITES") { this.deleteItemBusinessPestcontrol(item.id) }
     else if (this.AreasControlService.OriginScreen === "CUSTOMERS") { this.deleteItemCustomersPestcontrol(item.id) }
     else if (this.AreasControlService.OriginScreen === "CHECKPOINT") { this.deleteZoneCheckPointPestcontrol(item.id) }



    });
  }

  deleteItemCustomersPestcontrol(id_zone: string) {
    this.AreasControlService.deleteItemCustomersPestcontrol(CatalogsEnum.ZONES, id_zone).subscribe(res => {
      this.MessagesService.ShowMessage(TYPE_TOAST.SUCCESS, "Correcto", "Eliminado correctamente");
      this.loadDataZonesControl();
    });

  }

  deleteZoneCheckPointPestcontrol(id_zone: string) {
    this.AreasControlService.deleteZoneCheckPoint(id_zone).subscribe(res => {
      this.MessagesService.ShowMessage(TYPE_TOAST.SUCCESS, "Correcto", "Eliminado correctamente");
      this.loadDataZonesControl();
    });

  }





  deleteItemBusinessPestcontrol(id_zone: string) {
    this.AreasControlService.deleteItemBusinessPestcontrol(CatalogsEnum.ZONES, id_zone).subscribe(res => {
      this.MessagesService.ShowMessage(TYPE_TOAST.SUCCESS, "Correcto", "Eliminado correctamente");
      this.loadDataZonesControl();
    });



  }

}
