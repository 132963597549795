import { Component, ElementRef, Input, Output, QueryList, ViewChildren, EventEmitter, ViewChild } from '@angular/core';

@Component({
  selector: 'app-upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.scss']
})
export class UploadFileComponent {
@ViewChildren('buttonEl') buttonEl!: QueryList<ElementRef>;


@Input() TypeDocument:string ="application/pdf";
@Input() isImage:boolean =false;
@Input() Title:string ="";
@Input() id:string ="";
@Input() Description:string ="Selecciona un archivo";

@Output()uploadFile = new EventEmitter<any>();
@Output()removeFile = new EventEmitter<boolean>();
@Input() Document:any;


    onUpload(event: any) {
    this.Document = event.files[0];

    this.uploadFile.emit(this.Document);


    }


    onImageMouseOver() {


        this.buttonEl.toArray().forEach(el => {

         ///   el.nativeElement.id === this.Document.name ? el.nativeElement.style.display = 'flex' : null;

        })
    }

    onImageMouseLeave() {

        this.buttonEl.toArray().forEach(el => {


         ///   el.nativeElement.id === this.Document.name ? el.nativeElement.style.display = 'none' : null;

        })
    }


    removeImage(event: Event, ) {
        event.stopPropagation();
        this.Document = null;
        this.removeFile.emit(true);
      //  this.Form.patchValue({cover:null});
    }
}
