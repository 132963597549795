import { Subject } from 'rxjs';

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  isloading$ = new Subject<boolean>();
  HideSidebar = false;

  constructor() { }

  Show(): void {
    this.isloading$.next(true);
  }

  Hide(): void {
    this.isloading$.next(false);
  }
}
