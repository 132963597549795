import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { AreasControlService } from 'src/app/system/services/areasControlService';
import { LoadingService } from 'src/app/system/services/loading.service';
import { MessagesService } from 'src/app/system/services/message.service';
import { AddUpdateZoneComponent } from '../table-zones/components/add-update-zone/add-update-zone.component';
import { CatalogsEnum } from 'src/app/system/core/enums/catalogs.model';
import { TYPE_TOAST } from 'src/app/system/core/enums/ToastsEnums';
import { AddUpdateAreaComponent } from '../table-areas/components/add-update-area/add-update-area.component';
import { AddUpdateStationsComponent } from '../table-stations/components/add-update-stations/add-update-stations.component';

@Component({
  selector: 'app-options-diagram-tree',
  templateUrl: './options-diagram-tree.component.html',
  styleUrls: ['./options-diagram-tree.component.scss']
})
export class OptionsDiagramTreeComponent {
TYPE:string = "";
ID_REGISTER:string = "";
NODE:any;
constructor(
  private LoadingService: LoadingService,
  public config: DynamicDialogConfig,
  public ref: DynamicDialogRef,
  private DialogService: DialogService,

  private MessagesService: MessagesService,
  private router: Router,
  private AreasControlService:AreasControlService,){

}

ngOnInit(): void {
  this.TYPE =this.config.data.Node.node.type_node;
  this.NODE = this.config.data.Node.node;
  this.ID_REGISTER = this.config.data.idRegister;

}

AddItem(){

if(this.TYPE =="Customer" || this.TYPE == "Business activity"){
  this.AddUpdateZone(null);
  return
}


if(this.TYPE =="Zone"){

  this.AddUpdateArea(null,this.NODE);
  return
}

if(this.TYPE =="Area"){
 //agregar estacion

  this.AddUpdateStation(null,this.NODE);
  return
}

}


UpdateItem(){

  if(this.TYPE =="Zone"){
      let zone = {
        "id": this.NODE.id,
        "is_active": true,
        "name": this.NODE.label,
        "customer": this.ID_REGISTER
    }
   this.AddUpdateZone(zone)
    return
  }

  if(this.TYPE =="Area"){

    let area = {
      "id": this.NODE.id,
      "customer": this.ID_REGISTER
    }
    this.AddUpdateArea(area,this.NODE)
    return
  }

  if(this.TYPE =="Station"){

    let station = {
      "id": this.NODE.id,
      "customer": this.ID_REGISTER
    }
    this.AddUpdateStation(station,this.NODE)
    return
  }
}

AddUpdateZone(item: any) {
  const dialog = this.DialogService.open(AddUpdateZoneComponent, {
    header: "Zona",
    modal: true,
    width: "25rem",
    //height:"100%",
    closable: true,
    draggable: false,
    maximizable: false,
    resizable: true,
    closeOnEscape: true,
    dismissableMask: true,
    data: {
      item,
      idRegister: this.ID_REGISTER
    },
  });
  dialog.onClose.subscribe((res) => {

this.ref.close();
  });
}




deleteItem() {


  this.MessagesService.confirm("¿Esta seguro de eliminar el registro  ?", () => {

    if (this.AreasControlService.OriginScreen === "BUSINESS-ACTIVITES") {

      if(this.TYPE =="Zone") this.deleteItemBussinessPestcontrol(this.NODE.id);
      else if(this.TYPE=="Area") this.deleteAreaBussines(this.NODE.id,);
      else if(this.TYPE=="Station") this.deleteStationsBusiness(this.NODE.id);

      }
   else if (this.AreasControlService.OriginScreen === "CUSTOMERS") {

  if(this.TYPE =="Zone") this.deleteItemCustomersPestcontrol(this.NODE.id,CatalogsEnum.ZONES);
  else if(this.TYPE=="Area") this.deleteItemCustomersPestcontrol(this.NODE.id,CatalogsEnum.NESTING_AREAS);
  else if(this.TYPE=="Station") this.deleteItemCustomersPestcontrol(this.NODE.id,CatalogsEnum.AREA_STATIONS);


  }



  });
}

deleteItemCustomersPestcontrol(id_zone: string,Catalog:string) {
  this.AreasControlService.deleteItemCustomersPestcontrol(Catalog, id_zone).subscribe(res => {
    this.MessagesService.ShowMessage(TYPE_TOAST.SUCCESS, "Correcto", "Eliminado correctamente");
    this.ref.close();
  });

}
deleteItemBussinessPestcontrol(id_zone: string) {
  this.AreasControlService.deleteItemBusinessPestcontrol(CatalogsEnum.ZONES, id_zone).subscribe(res => {
    this.MessagesService.ShowMessage(TYPE_TOAST.SUCCESS, "Correcto", "Eliminado correctamente");
    this.ref.close();
  });

}

deleteAreaBussines(idArea:string){

  this.AreasControlService.deleteItemBusinessPestcontrol(CatalogsEnum.NESTING_AREAS,idArea).subscribe(res => {
    this.ref.close();
  });

}
deleteStationsBusiness(idStation:string){
  this.AreasControlService.deleteItemBusinessPestcontrol(CatalogsEnum.AREA_STATIONS,idStation).subscribe(res => {
    this.MessagesService.ShowMessage(TYPE_TOAST.SUCCESS,"Correcto","Eliminado correctamente")
    this.ref.close();
});
}

AddUpdateArea(area:any,zone:any) {
let zonaSelected = {...zone,isDiagram:true}

  const dialog = this.DialogService.open(AddUpdateAreaComponent, {
      header: "Area - " +   zonaSelected?.label,
      modal: true,
      width:"28rem",
      //height:"100%",
      closable: true,
      draggable: false,
      maximizable: false,
      resizable: true,
      closeOnEscape: true,
      dismissableMask: true,
      data: {
        item:area,
        zone:zonaSelected,
        idRegister:this.ID_REGISTER

      },
  });
  dialog.onClose.subscribe((res) => {
  this.ref.close();

  });
}





AddUpdateStation(station:any,area:any) {
  let areaSelected = {...area,isDiagram:true}

  const dialog = this.DialogService.open(AddUpdateStationsComponent, {
      header:   areaSelected?.label ?? "",
      modal: true,
      width:"52rem",
      //height:"100%",
      closable: true,
      draggable: false,
      maximizable: false,
      resizable: true,
      closeOnEscape: true,
      dismissableMask: true,
      data: {

          item:station,
          area:areaSelected,
          idRegister:this.ID_REGISTER
      },
  });
  dialog.onClose.subscribe((res) => {

    this.ref.close();
  });
}






}
