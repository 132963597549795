import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { MIP_FORM } from 'src/app/system/core/enums/MipForms.model';
import { TYPE_TOAST } from 'src/app/system/core/enums/ToastsEnums';
import { CatalogsEnum } from 'src/app/system/core/enums/catalogs.model';
import { MipFormsService } from 'src/app/system/services/MipForms.service';
import { LoadingService } from 'src/app/system/services/loading.service';
import { MessagesService } from 'src/app/system/services/message.service';

@Component({
  selector: 'app-add-update-area',
  templateUrl: './add-update-area.component.html',
  styleUrls: ['./add-update-area.component.scss']
})
export class AddUpdateAreaComponent {
  Catalog = CatalogsEnum;
  Form: FormGroup = this.FormBuilder.group({
    nesting_area: ['', [Validators.required]],
    activity_area: ['', [Validators.required]],
    condition_area: ['', [Validators.required]],
    plague: ['', [Validators.required]],
    id: [''],
    event:[''],

});
Event:any;
constructor(
  private FormBuilder: FormBuilder,
  private LoadingService: LoadingService,
  public config: DynamicDialogConfig,
  public ref: DynamicDialogRef,
  private DialogService: DialogService,
  private MipFormsService:MipFormsService,
  private MessagesService: MessagesService,

) {

}

ngOnInit(): void {
this.Event = this.config.data.Event;

  if (this.config.data.Area) {


   this.patchForm();
  }
}

patchForm(){
 
  let item = this.config.data.Area;

  this.Form.patchValue({
    nesting_area: item.station,
    activity_area: item.activity_area,
    condition_area: item.condition_area,
    plague: item.plague,
    id: item.id,
    event:this.Event.id

  });
}

accept() {

  this.LoadingService.Show();
 if(!this.config.data.Area){

  this.save();
  return
 }
this.update();
}
cancel() {
  this.ref?.close();
}



  save(){



   
    let activity_area =  this.Form.value?.activity_area.map((item:any)=>item.id);
    let condition_area =  this.Form.value?.condition_area.map((item:any)=>item.id);
    let plague =  this.Form.value?.plague.map((item:any)=>item.id);
let data = {
  event:this.Event.id,
  nesting_area: this.Form.value.nesting_area.id,
  plague,
    activity_area,
    condition_area,
    

}


  this.MipFormsService.saveDataForm(data, MIP_FORM.AREAS_ACTIVITY).subscribe((res)=>{
      this.LoadingService.Hide();
      this.MessagesService.ShowMessage(
          TYPE_TOAST.SUCCESS,
          'Correcto',
          'Guardado correctamente'
      );
      this.ref?.close({ success: true });
    });

  }

  update(){
 

      
    let activity_area =  this.Form.value?.activity_area.map((item:any)=>item.id);
    let condition_area =  this.Form.value?.condition_area.map((item:any)=>item.id);
    let plague =  this.Form.value?.plague.map((item:any)=>item.id);
let data = {
  event:this.Event.id,
  nesting_area: this.Form.value.nesting_area.id,
  plague,
    activity_area,
    condition_area,
    id:this.Form.value.id,

}

      this.MipFormsService.putDataForm(data, MIP_FORM.AREAS_ACTIVITY).subscribe((res)=>{
          this.LoadingService.Hide();
          this.MessagesService.ShowMessage(
              TYPE_TOAST.SUCCESS,
              'Correcto',
              'Actualizado correctamente'
          );
          this.ref?.close({ success: true });
        });
  }
}
