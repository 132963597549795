import { Router } from '@angular/router';
import { LocalStorageService } from './local-storage-service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { catchError, map, Observable, of, tap } from 'rxjs';
import { UserService } from './user.service';
import { StorageEnum } from '../core/enums/StorageEnum';
import { PermissionsServices } from './permissions.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  //declaracion de la url de la API
  private baseUrl: string = environment.baseUrl;

  constructor(
    private http: HttpClient,
    private UserService:UserService,
    private permissions:PermissionsServices,
    private LocalStorageService:LocalStorageService,
    private Router:Router
  ) { }

  login(login: FormData):Observable<any>{
    return this.http.post<any>(`${this.baseUrl}/authentication/login/`, login);
  }

  logout(){
    this.LocalStorageService.clear();
    this.Router.navigateByUrl("/auth")
  }
 //Metodo validar token
 validarToken(): Observable<boolean> {

    const token = localStorage.getItem(StorageEnum.TOKEN);

    if(!token){
      return of(false);
    }

    this.UserService.User = this.LocalStorageService.get(StorageEnum.USER)!;
    this.UserService.Employee = this.LocalStorageService.get(StorageEnum.EMPLOYE)!;
    this.UserService.Company = this.LocalStorageService.get(StorageEnum.COMPANY)!;

    this.permissions.Permissions = this.LocalStorageService.get(StorageEnum.PERMISSIONS)!;
    return of(true);
  }


}
