import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { TYPE_TOAST } from 'src/app/system/core/enums/ToastsEnums';
import { CatalogsEnum } from 'src/app/system/core/enums/catalogs.model';
import { AreasControlService } from 'src/app/system/services/areasControlService';
import { CustomerService } from 'src/app/system/services/customer.service';
import { LoadingService } from 'src/app/system/services/loading.service';
import { MessagesService } from 'src/app/system/services/message.service';

@Component({
  selector: 'app-add-update-perimeter',
  templateUrl: './add-update-perimeter.component.html',
  styleUrls: ['./add-update-perimeter.component.scss']
})
export class AddUpdatePerimeterComponent {
  Form: FormGroup = this.FormBuilder.group({
    name: ['', [Validators.required]],
    zone: ['', [Validators.required]],
    customer:"",
    id:""
});
filteredPerimeters:any = [];
constructor(
    private FormBuilder: FormBuilder,
    private LoadingService: LoadingService,
    public config: DynamicDialogConfig,
    public ref: DynamicDialogRef,
    private DialogService: DialogService,
private Customers:CustomerService,
    private MessagesService: MessagesService,
    private router: Router,
    private AreasControlService:AreasControlService,
) {}

ngOnInit() {
    if (this.config.data.item) {
      this.Form.patchValue({
        name: this.config.data.item.name,
        zone:this.config.data.item.zone,
        id:this.config.data.item.id
    });
    }
}



cancel() {
    this.ref?.close();
}

accept() {
    this.LoadingService.Show();
   if(!this.config.data.item){
    this.save();
    return
   }
   this.update();
}

save(){

  let data = {
    name:this.Form.value.name,
    zone:this.Form.value.zone.id
  }

    this.AreasControlService.addPerimiterCustomerPestControl(data).subscribe(
        (res) => {
            this.LoadingService.Hide();
            this.MessagesService.ShowMessage(
                TYPE_TOAST.SUCCESS,
                'Agregado correctamente',
                ''
            );
            this.ref?.close({ success: true });
        }
    );
}



update(){
  const {id} = this.Form.value;
  this.Form.value.zone = this.Form.value.zone.id;
  this.AreasControlService.putPerimeters(id,this.Form.value).subscribe(
      (res) => {
          this.LoadingService.Hide();
          this.MessagesService.ShowMessage(
              TYPE_TOAST.SUCCESS,
              'Agregado correctamente',
              ''
          );
          this.ref?.close({ success: true });
      }
  );


}

filterZone(event: any) {
  let query = event.query;
  let data= {
    search:query
  }
  this.Customers.getZonesByCheckPoint(data,this.config.data.idRegister).subscribe((response)=>{
    this.filteredPerimeters = response
  });

}

}
