<p-table class="custom-table "
styleClass="p-datatable-sm"
scrollDirection="horizontal"
#dtCalendar
[value]="Tasks"
dataKey="id"
sortMode="multiple"
[rows]="10"
[showCurrentPageReport]="true"
[rowsPerPageOptions]="[10, 25, 50]"
[loading]="loading"
[paginator]="false"
[globalFilterFields]="['name']">
  <ng-template pTemplate="header">
      <tr>
          <th  class="white-space-nowrap bg-table-info text-center">Titulo</th>
          <th  class="white-space-nowrap bg-table-info text-center">Tipo de tarea</th>
          <th   class="white-space-nowrap bg-table-info text-center">Responsable</th>
          <th   class="white-space-nowrap bg-table-info text-center">Fecha Inicio</th>
          <th   class="white-space-nowrap bg-table-info text-center">Fecha fin</th>
          <th   class="white-space-nowrap bg-table-info text-center">Estatus</th>
          <th   class="white-space-nowrap bg-table-info text-center"></th>
      </tr>
  </ng-template>
  <ng-template pTemplate="body" let-item>
    <tr class="text-sm">
      <td class="text-center">
            {{ item.title }}
      </td>
      <td class="text-center">
        {{ item.task_type.name }}
  </td>
  <td class="text-center">
    {{ item.user.name }}
</td>
      <td class="text-center">
        {{ item?.initial_date }}
  </td>
  <td class="text-center">
    {{ item?.final_date }}
</td>
  <td class="text-center">



    <div class="dropdow d">
      <span


      *ngIf="item.status.key_string == 'task-inprogress'"
      class="status-yellow hov-pointer  dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false"
      >{{ item.status.name }}</span
    >
    <span

      *ngIf="item.status.key_string == 'task-done'"
      class="status-green hov-pointer  dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false"
      >{{ item.status.name }}</span
    >
    <span
  *ngIf="item.status.key_string == 'task-todo'"
      class="status-blue hov-pointer  dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false"
      >{{ item.status.name }}</span
    >
    <ul class="dropdown-menu">
      <li><a (click)="updateTask('ce17899d-beb1-402a-83b6-11cbc487af09',item)"  class="dropdown-item hov-pointer text-success fw-bold" ><i class="fas fa-check me-2"></i>Terminada</a></li>

     <li><a (click)="updateTask('ca926a12-a497-485c-9f63-151373a41c7a',item)" class="dropdown-item hov-pointer text-orange-700 fw-bold" ><i class="fas fa-tasks me-2"></i>En Progreso</a></li>


     <li><a (click)="updateTask('58f06601-267e-471e-b27e-98823f31750a',item)" class="dropdown-item hov-pointer text-primary fw-bold" ><i class="fas fa-pause me-2"></i>Por hacer</a></li>

    </ul>
    </div>
</td>

      <td class="text-center">

      <p-button
        (click)="deleteItem(item)"
      type="button"
      icon="fa-solid fa-trash"
      styleClass="p-button-rounded p-button-outlined p-button-danger p-button-sm ml-2 btn-sm"
      >
      </p-button>
        <p-button
        (click)="AddUpdateTask(item)"
          type="button"
          icon="fa-solid fa-edit"
          styleClass="p-button-rounded p-button-outlined p-button-info ms-2 btn-sm "
        >
        </p-button>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
      <tr>
          <td colspan="6">No tienes registros.</td>
      </tr>
  </ng-template>
</p-table>
