import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

import { CatalogsEnum, URL_ENDPOINTS } from 'src/app/system/core/enums/catalogs.model';
import { CalendarService } from 'src/app/system/services/calendar.service';
import { DateService } from 'src/app/system/services/date.service';
import { LoadingService } from 'src/app/system/services/loading.service';
import { MessagesService } from 'src/app/system/services/message.service';
import { UserService } from 'src/app/system/services/user.service';

@Component({
  selector: 'app-edit-time-employee',
  templateUrl: './edit-time-employee.component.html',
  styleUrls: ['./edit-time-employee.component.scss']
})
export class EditTimeEmployeeComponent implements OnInit {
  Form: FormGroup = this.FormBuilder.group({
    id: '',
    initial_date: ['', Validators.required],
    final_date: ['', Validators.required],
    initial_hour: [''],
    final_hour: [''],
    job_center: this.UserService.JobCenter,
    employee: ['', Validators.required],
  });
  URL_CATALOGS = URL_ENDPOINTS;
  Catalog = CatalogsEnum;

  constructor(
    private DialogService: DialogService,
    public ref: DynamicDialogRef,
    private FormBuilder: FormBuilder,
    private LoadingService: LoadingService,
    public config: DynamicDialogConfig,
    private MessagesService: MessagesService,
    private UserService: UserService,
    private CalendarService: CalendarService,
    private DateService: DateService,
  ) { }

  ngOnInit(): void {
    const initialDate = moment(this.config.data.Event.initial_date).toDate();
    const finalDate = moment(this.config.data.Event.final_date).toDate();
    const initialHour = moment(`1970-01-01T${this.config.data.Event.initial_hour}`).toDate();
    const finalHour = moment(`1970-01-01T${this.config.data.Event.final_hour}`).toDate();

    this.Form.patchValue({
      id: this.config.data.Event.id,
      initial_date: initialDate,
      final_date: finalDate,
      initial_hour: initialHour,
      final_hour: finalHour,
      employee: this.config.data.Event.employee[0]
    });
  }

  cancel() {
    this.ref?.close();
  }

  accept() {
    this.LoadingService.Show();

    const data = {
      id: this.Form.value.id,
      initial_hour: moment(this.Form.value.initial_hour).format('HH:mm:ss'),
      final_hour: moment(this.Form.value.final_hour).format('HH:mm:ss'),
      initial_date: moment(this.Form.value.initial_date).format('YYYY-MM-DD'),
      final_date: moment(this.Form.value.final_date).format('YYYY-MM-DD'),
      job_center: this.Form.value.job_center,
      employee: this.Form.value.employee.id
    };

    this.CalendarService.patchEvent(data).subscribe((res) => {
      this.LoadingService.Hide();

      this.MessagesService.ShowMessage(
        'success',
        'Correcto',
        'Guardado correctamente'
      );

      this.ref?.close();
    });
  }
}
