import { LoadingService } from './../../services/loading.service';
import { TYPE_TOAST } from '../enums/ToastsEnums';

import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import {  catchError } from 'rxjs/operators';

import { Router } from '@angular/router';
import { MessagesService } from 'src/app/system/services/message.service';


@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

    constructor(private MessageService: MessagesService, private LoadingService:LoadingService, private Router:Router) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        return <any> next.handle(request).pipe(

            catchError((error: HttpErrorResponse) => {
                this.LoadingService.Hide();
              console.log("************************ERROR************************");
              console.log(error);
              console.log("************************ERROR************************");

              let msj = error?.error?.message ? error?.error?.message + ' ('+error?.error?.data+')' : error?.error?.detail ? error?.error?.detail : "Ocurrio un error, favor de ponerse en contacto con soporte técnico.";


     //          error?.error?.message + ' ('+error?.error?.data+')' ?? "Ocurrio un error, favor de ponerse en contacto con soporte técnico." ?? error?.error?.detail;
            //  let msj = !strError ? "Ocurrio un error, favor de ponerse en contacto con soporte técnico." : strError

              if(msj === "Ocurrio un error, favor de ponerse en contacto con soporte técnico."){
                this.MessageService.ShowErrorDialog(msj);
              }
              else if(error.status == 500){

                this.MessageService.ShowErrorDialog(msj);
              }
             else if(error.status == 401){
                localStorage.clear();
                this.Router.navigateByUrl("/login");
                this.MessageService.ShowErrorDialog(msj);
              }

            else if (error.status != 500) {
              this.MessageService.ShowMessage(TYPE_TOAST.ERROR,"Error",msj)
            }

            else{
              this.MessageService.ShowErrorDialog(msj );
            }
                return throwError(()=>new Error(msj || "Sucedio un error con el servidor."));
            })

        );

    }
}
