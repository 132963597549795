import { Component, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { catchError, throwError } from 'rxjs';
import SignaturePad from 'signature_pad';

import { CatalogsEnum } from 'src/app/system/core/enums/catalogs.model';
import { TYPE_TOAST } from 'src/app/system/core/enums/ToastsEnums';
import { LoadingService } from 'src/app/system/services/loading.service';
import { MessagesService } from 'src/app/system/services/message.service';
import { MipFormsService } from 'src/app/system/services/MipForms.service';
import { UtilsService } from 'src/app/system/services/utils.service';

@Component({
  selector: 'app-add-update-signature',
  templateUrl: './add-update-signature.component.html',
  styleUrls: ['./add-update-signature.component.scss']
})
export class AddUpdateSignatureComponent {
  @ViewChild('firmadigital', { static: false }) firmadigital: any;
  @ViewChild('wrapper', { static: false }) wrapper: any;

  image: any;
  objectURL: string = '';
  textPhoto: string = 'Selecciona o arrastra una imagen';
  Catalog = CatalogsEnum;
  Form: FormGroup = this.FormBuilder.group({
    signature: ['',],
    name: ['', [Validators.required]],
    job_position: ['', [Validators.required]],
    id: [''],
    comments: [''],
    event: [''],
  });
  randomValue = Math.random();
  CanvasWidth = 500;
  CanvasHeigth = 100;
  signaturepad: any;

  constructor(
    private UtilsService: UtilsService,
    private FormBuilder: FormBuilder,
    private LoadingService: LoadingService,
    public config: DynamicDialogConfig,
    public ref: DynamicDialogRef,
    private MipFormsService: MipFormsService,
    private MessagesService: MessagesService,
  ) { }

  ngOnInit(): void {
    if (this.config.data.Signature) {
      this.patchForm();
    }
  }

  ngAfterViewInit(): void {
    this.signaturepad = new SignaturePad(this.firmadigital.nativeElement);
    this.CanvasWidth = this.wrapper.nativeElement.offsetWidth;
  }

  onResize() {
    this.CanvasWidth = this.wrapper.nativeElement.offsetWidth;
  }

  patchForm() {
    let item = this.config.data.Signature;

    this.image = item.signature + '?' + this.randomValue;

    this.Form.patchValue({
      name: item.name,
      job_position: item.job_position,
      signature: item.signature,
      comments: item.comments,
      id: item.id,
    });
  }

  accept() {
    this.LoadingService.Show();

    if (!this.config.data.Signature) {
      this.save();
      return;
    }

    this.update();
  }

  cancel() {
    this.ref?.close();
  }

  save() {
    if (this.signaturepad.isEmpty()) {
      this.MessagesService.ShowMessage(
        'info',
        'Firmar documento',
        `Debe firmar el documento para continuar`,
      );

      this.LoadingService.Hide();
      return;
    }

    const sign = this.signaturepad.toDataURL();
    var file = this.dataURLtoFile(sign, 'firma.jpg');
    this.Form.get('signature')?.setValue(file);

    let data = {
      signature: this.Form.value.signature,
      name: this.Form.value.name,
      job_position: this.Form.value.job_position,
      comments: this.Form.value.comments,
      event: this.config.data.event
    };

    this.MipFormsService.saveDataForm(this.UtilsService.objectToFormData(data), 'signature-forms').subscribe((res) => {
      this.LoadingService.Hide();

      this.MessagesService.ShowMessage(
        TYPE_TOAST.SUCCESS,
        'Correcto',
        'Guardado correctamente'
      );

      this.ref?.close({
        success: true,
        signature: res,
        isUpdate: false,
      });
    });
  }

  dataURLtoFile(dataurl: any, filename: string) {
    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  update() {
    if (!this.signaturepad.isEmpty()) {
      const sign = this.signaturepad.toDataURL();
      var file = this.dataURLtoFile(sign, 'firma.jpg');
      this.Form.get('signature')?.setValue(file);
    }

    let data = {
      signature: this.Form.value.signature,
      name: this.Form.value.name,
      job_position: this.Form.value.job_position,
      event: this.config.data.event,
      comments: this.Form.value.comments,
      id: this.Form.value.id,
    };

    if (this.signaturepad.isEmpty() && this.UtilsService.isString(this.Form.value.signature)) {
      delete data.signature;
    }

    this.MipFormsService.patchDataForm(
      this.UtilsService.objectToFormData(data),
      'signature-forms',
      data.id
    ).pipe(
      catchError((error) => {
        console.error('Error during patch request:', error);
        return throwError(error);
      })
    ).subscribe((res) => {
      this.LoadingService.Hide();

      this.MessagesService.ShowMessage(
        TYPE_TOAST.SUCCESS,
        'Correcto',
        'Guardado correctamente'
      );

      this.ref?.close({
        success: true,
        signature: res,
        isUpdate: true,
      });
    });
  }

  onUpload(event: any) {
    let file = event.files[0];

    if (!file.objectURL) {
      return;
    } else {
      this.image = file;
      this.objectURL = file.objectURL ?? '';
      this.Form.get('photo')?.setValue(file);
    }
  }

  removeImage() {
    this.image = null;
    this.Form.get('photo')?.setValue('');
  }

  clear() {
    this.signaturepad.clear();
    this.cleanDataForm();
  }

  cleanDataForm(): void {
    this.Form.patchValue({
      signature: this.config.data.Signature.signature ?? '',
    });
  }
}
