import { Component, ElementRef, QueryList, ViewChildren } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { catchError, throwError } from 'rxjs';
import { TYPE_TOAST } from 'src/app/system/core/enums/ToastsEnums';
import { CatalogsEnum } from 'src/app/system/core/enums/catalogs.model';
import { MipFormsService } from 'src/app/system/services/MipForms.service';
import { LoadingService } from 'src/app/system/services/loading.service';
import { MessagesService } from 'src/app/system/services/message.service';
import { UtilsService } from 'src/app/system/services/utils.service';

@Component({
  selector: 'app-add-update-cleaning',
  templateUrl: './add-update-cleaning.component.html',
  styleUrls: ['./add-update-cleaning.component.scss']
})
export class AddUpdateCleaningComponent {
  @ViewChildren('buttonEl') buttonEl!: QueryList<ElementRef>;

  image: any;

  objectURL: string = '';
  textPhoto: string = 'Selecciona o arrastra una imagen';
  Catalog = CatalogsEnum;
  Form: FormGroup = this.FormBuilder.group({
    cleaning: ['', [Validators.required]],
    nesting_area: ['', Validators.required],
    id: [''],
    condition: [''],
    indications:true
  });
  customer:any;
  constructor(
    private UtilsService:UtilsService,
    private FormBuilder: FormBuilder,
    private LoadingService: LoadingService,
    public config: DynamicDialogConfig,
    public ref: DynamicDialogRef,
    private DialogService: DialogService,
    private MipFormsService: MipFormsService,
    private MessagesService: MessagesService
  ) {}

  ngOnInit(): void {
    this.customer =  this.MipFormsService.Event.customer.id;

    if (this.config.data.Cleaning) {
      this.patchForm();
    }
  }

  patchForm() {
    let item = this.config.data.Cleaning;



    this.Form.patchValue({

      cleaning:item.cleaning,
      id: item.id,
      nesting_area:item.nesting_area,
    });
  }

  accept() {
    this.LoadingService.Show();
    if (!this.config.data.Cleaning) {
      this.save();
      return;
    }
    this.update();
  }
  cancel() {
    this.ref?.close();
  }

  save() {
    let nesting_area =  this.Form.value?.nesting_area.map((item:any)=>item.id);

    let data = {
      nesting_area,
      cleaning: this.Form.value.cleaning.id,
      condition: this.config.data.Condition.id,
      indications:this.Form.value.indications
    };


    this.MipFormsService.saveDataForm(
      data,
      'condition-forms-cleaning'
    ).subscribe((res) => {
      this.LoadingService.Hide();
      this.MessagesService.ShowMessage(
        TYPE_TOAST.SUCCESS,
        'Correcto',
        'Guardado correctamente'
      );
      this.ref?.close({ success: true });
    });
  }

  update() {
    let nesting_area =  this.Form.value?.nesting_area.map((item:any)=>item.id);
    let data = {
      nesting_area,
      cleaning: this.Form.value.cleaning.id,
      condition_form: this.config.data.Condition.id,
      id:this.Form.value.id,
    };






    this.MipFormsService.patchDataForm(
      data,
      'condition-forms-cleaning',
      data.id
    ).pipe(
      catchError((error) => {
        console.error('Error during patch request:', error);
        return throwError(error);
      })
    ).subscribe((res) => {
      this.LoadingService.Hide();
      this.MessagesService.ShowMessage(
        TYPE_TOAST.SUCCESS,
        'Correcto',
        'Guardado correctamente'
      );
      this.ref?.close({ success: true });
    });
  }



}
