import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { catchError, throwError } from 'rxjs';

import { CatalogsEnum } from 'src/app/system/core/enums/catalogs.model';
import { MIP_FORM } from 'src/app/system/core/enums/MipForms.model';
import { TYPE_TOAST } from 'src/app/system/core/enums/ToastsEnums';
import { LoadingService } from 'src/app/system/services/loading.service';
import { MessagesService } from 'src/app/system/services/message.service';
import { MipFormsService } from 'src/app/system/services/MipForms.service';

@Component({
  selector: 'app-add-update-action-plan',
  templateUrl: './add-update-action-plan.component.html',
  styleUrls: ['./add-update-action-plan.component.scss']
})
export class AddUpdateActionPlanComponent {
  Catalog = CatalogsEnum;
  Form: FormGroup = this.FormBuilder.group({
    root_cause: ['', [Validators.required]],
    qualification: ['', [Validators.required]],
    description: [''],
    id: [''],
    event: [''],
  });

  constructor(
    private FormBuilder: FormBuilder,
    private LoadingService: LoadingService,
    public config: DynamicDialogConfig,
    public ref: DynamicDialogRef,
    private MipFormsService: MipFormsService,
    private MessagesService: MessagesService,
  ) { }

  ngOnInit(): void {
    if (this.config.data.ActionPlan) {
      this.patchForm();
    }
  }

  patchForm() {
    let item = this.config.data.ActionPlan;

    this.Form.patchValue({
      description: item.description,
      id: item.id,
      event: this.config.data.event
    });
  }

  accept() {
    this.LoadingService.Show();

    if (!this.config.data.ActionPlan) {
      this.save();
      return;
    }

    this.update();
  }
  cancel() {
    this.ref?.close();
  }

  save() {
    let data = {
      root_cause: this.Form.value.root_cause.id,
      description: this.Form.value.description,
      qualification: this.Form.value.qualification.id,
      event: this.config.data.event
    };

    this.MipFormsService.saveDataForm(data, MIP_FORM.ACTION_PLANS).subscribe((res) => {
      this.LoadingService.Hide();

      this.MessagesService.ShowMessage(
        TYPE_TOAST.SUCCESS,
        'Correcto',
        'Guardado correctamente'
      );

      this.ref?.close({
        success: true,
        actionPlan: res,
        isUpdate: false
      });
    });
  }

  update() {
    let data = {
      root_cause: this.Form.value.root_cause.id,
      description: this.Form.value.description,
      qualification: this.Form.value.qualification.id,
      event: this.config.data.event,
      id: this.Form.value.id,
    };

    this.MipFormsService.patchDataForm(
      data,
      MIP_FORM.ACTION_PLANS,
      data.id
    ).pipe(
      catchError((error) => {
        console.error('Error during patch request:', error);
        return throwError(error);
      })
    ).subscribe((res) => {
      this.LoadingService.Hide();

      this.MessagesService.ShowMessage(
        TYPE_TOAST.SUCCESS,
        'Correcto',
        'Guardado correctamente'
      );

      this.ref?.close({
        success: true,
        actionPlan: res,
        isUpdate: true
      });
    });
  }
}
