import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { TYPE_TOAST } from 'src/app/system/core/enums/ToastsEnums';
import { CatalogsEnum } from 'src/app/system/core/enums/catalogs.model';
import { CustomerService } from 'src/app/system/services/customer.service';
import { EmployeeService } from 'src/app/system/services/employee.service';
import { LoadingService } from 'src/app/system/services/loading.service';
import { MessagesService } from 'src/app/system/services/message.service';
import { UserService } from 'src/app/system/services/user.service';
import { UtilsService } from 'src/app/system/services/utils.service';

@Component({
  selector: 'app-add-update-employee',
  templateUrl: './add-update-employee.component.html',
  styleUrls: ['./add-update-employee.component.scss']
})
export class AddUpdateEmployeeComponent {
  isEdit:boolean = false;
  Catalog = CatalogsEnum;
  Form: FormGroup = this.FormBuilder.group({
    name: ['', [Validators.required]],
    job_title: ['', [Validators.required]],
    username: ['', [Validators.required, Validators.email]],
    location: [''],
    avatar:[''],
    payroll_number:[''],
    signature:[''],
    zone:[''],
    is_active:true,
    password:['',[Validators.required]],
    management:[''],
    color: ['#3C5898', [Validators.required]],
    id: [''],
    job_center: ['', [Validators.required]],
});

constructor(
  private FormBuilder: FormBuilder,
  private LoadingService: LoadingService,
  public config: DynamicDialogConfig,
  public ref: DynamicDialogRef,
  private DialogService: DialogService,

  private customerService: CustomerService,
  private MessagesService: MessagesService,
  private UtilsService:UtilsService,
  private UserService:UserService,
  private EmployeeService:EmployeeService
) {

}

ngOnInit(): void {

  if (this.config.data.item) {

    this.isEdit = true;
    this.patchForm();
  }
}

patchForm(){
  let item = this.config.data.item;
  this.ImgDataUrl = item.avatar + '?' + Math.random();
  this.Form.patchValue({
    name: item.name,
    job_title: item.job_title,
    username: item.username,
    location: item.location,
    avatar:item.avatar ?? '',
    payroll_number:item.payroll_number,
    signature:item.signature ?? '',
    zone:item.zone ,
    is_active:true,
    management:item.management ,
    color: item.color,
    id: item.id,
    job_center: item.job_center,
    password:"1234567890"
  });
}

accept() {

  this.LoadingService.Show();
 if(!this.isEdit){

  this.save();
  return
 }
this.update();
}
cancel() {
  this.ref?.close();
}



  save(){

    if(this.UtilsService.isString(this.Form.value.avatar))delete this.Form.value.avatar;
    if(this.UtilsService.isString(this.Form.value.signature))delete this.Form.value.signature;


this.Form.value.job_title = this.Form.value.job_title.id;
this.Form.value.job_center =  this.Form.value.job_center.id;

    this.EmployeeService.saveEmployee(this.UtilsService.objectToFormData(this.Form.value)).subscribe((res)=>{
      this.saveUserPassword(res.id);
});
  }

  saveUserPassword(idEmployee:string){

let data = {
  username:this.Form.value.username,
  password:this.Form.value.password,
}

    this.EmployeeService.saveUserPassword(data,idEmployee).subscribe((res)=>{



            this.LoadingService.Hide();
            this.MessagesService.ShowMessage(
                TYPE_TOAST.SUCCESS,
                'Correcto',
                'Agregado correctamente'
            );
            this.ref?.close({ success: true });
          },
           error=>{

            this.EmployeeService.deleteEmployee(
              idEmployee
            ).subscribe((res) => {

            });
            //remove customer
          });
  }






  update(){
    delete this.Form.value.password;
    if(this.UtilsService.isString(this.Form.value.avatar))delete this.Form.value.avatar;
    if(this.UtilsService.isString(this.Form.value.signature))delete this.Form.value.signature;


      this.Form.value.job_title = this.Form.value.job_title.id;
      this.Form.value.job_center =  this.Form.value.job_center.id;

    this.EmployeeService.putEmployee(this.UtilsService.objectToFormData(this.Form.value),this.Form.value.id).subscribe((res)=>{
    this.UserService.profilePhoto = res.avatar + '?' + Math.random();

      //Actualizar username
      this.EmployeeService.patchUsername(this.Form.value.username,this.Form.value.id).subscribe((res)=>{


        this.LoadingService.Hide();
        this.MessagesService.ShowMessage(
            TYPE_TOAST.SUCCESS,
            'Correcto',
            'Actualizado correctamente'
        );
        this.ref?.close({ success: true });

      });



    });


  }



  uploadFile(event: any, ) {
    this.MessagesService.ShowMessage(TYPE_TOAST.SUCCESS, "Correcto", "Archivo cargado correctamente");

      this.Form.get('signature')?.setValue(event.target.files[0]);
  }


  ImgDataUrl:string = "";
  myUploader(event: any, ) {


const file = event.target.files[0];

if (file) {
  this.MessagesService.ShowMessage(TYPE_TOAST.SUCCESS, "Correcto", "Archivo cargado correctamente");
  this.Form.get('avatar')?.setValue(file);

  const reader = new FileReader();

  reader.onload = (e: any) => {
    // e.target.result contiene la URL de datos
this.ImgDataUrl =e.target.result;
    // Ahora puedes usar imageUrl en tu atributo src

  };

  reader.readAsDataURL(file);
}
}

  }

