import { JobCentersModule } from './system/Panel/administrative/humanresources/job-centers/job-centers.module';
import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { AppLayoutComponent } from './layout/app.layout.component';
import { LoginGuard } from './system/core/guards/validate-auth.guard';
import { ValidateTokenGuard } from './system/core/guards/validate-token.guard';
import {ProvidersModule} from "./system/Panel/administrative/inventory/providers/providers.module";

const routes: Routes = [
    { path: 'auth',
    canActivate:[LoginGuard],
    canLoad:[LoginGuard],
    loadChildren: () => import('./system/auth/auth.module').then(m => m.AuthModule)
},
    {
        path: '',
        component: AppLayoutComponent,
        canActivate:[ValidateTokenGuard],
        canLoad:[ValidateTokenGuard],
        children: [
            { path: '', loadChildren: () => import('./system/Panel/dashboard-start/dashboard-start.module').then(m => m.DashboardStartModule)},
            { path: 'events', loadChildren: () => import('./system/Panel/events/events.module').then(m => m.EventsModule)},
            { path: 'customers', loadChildren: () => import('./system/Panel/customers/customers.module').then(m => m.CustomersModule)},
            { path: 'custom', loadChildren: () => import('./system/Panel/config/config.module').then(m => m.ConfigModule)},
            { path: 'catalogs', loadChildren: () => import('./system/Panel/catalogs/catalogs.module').then(m => m.CatalogsModule)},
           
            { path: 'administrative', loadChildren: () => import('./system/Panel/administrative/humanresources/job-centers/job-centers.module').then(m => m.JobCentersModule)},
            { path: 'administrative', loadChildren: () => import('./system/Panel/administrative/humanresources/employees/employees.module').then(m => m.EmployeesModule)},
            { path: 'administrative', loadChildren: () => import('./system/Panel/administrative/expenses/expenses.module').then(m => m.ExpensesModule)},
            { path: 'administrative', loadChildren: () => import('./system/Panel/administrative/tasks/tasks.module').then(m => m.TasksModule)},
            { path: 'administrative', loadChildren: () => import('./system/Panel/administrative/tickets/tickets.module').then(m => m.TicketsModule)},
            { path: 'administrative', loadChildren: () => import('./system/Panel/administrative/referrals/referrals.module').then(m => m.ReferralsModule)},
            { path: 'administrative', loadChildren: () => import('./system/Panel/reports/reports.module').then(m => m.ReportsModule)},
           
            { path: 'inventory', loadChildren: () => import('./system/Panel/administrative/inventory/providers/providers.module').then(m => m.ProvidersModule)},
            { path: 'inventory', loadChildren: () => import('./system/Panel/administrative/inventory/products/products.module').then(m => m.ProductsModule)},
            { path: 'inventory', loadChildren: () => import('./system/Panel/administrative/inventory/purchase-orders/purchase-orders.module').then(m => m.PurchaseOrdersModule)},
            { path: 'inventory', loadChildren: () => import('./system/Panel/administrative/inventory/warehouses/warehouses.module').then(m => m.WarehousesModule)},
            { path: 'inventory', loadChildren: () => import('./system/Panel/administrative/inventory/transfers/transfers.module').then(m => m.TransfersModule)},
            { path: 'inventory', loadChildren: () => import('./system/Panel/administrative/inventory/entrys/entrys.module').then(m => m.EntrysModule)},
            { path: 'quotes', loadChildren: () => import('./system/Panel/quotes/quotes.module').then(m => m.QuotesModule)},
           // {path:'',redirectTo:'/customers'}
        ]
    },

  /**
   *   {
        path: '**',
        redirectTo: 'auth'
      },
   */
      {
        path: '',
        redirectTo: '/auth',
        pathMatch:'full'
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes,{useHash:false})],
    exports: [RouterModule]
})
export class RoutingModule { }
