import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { CatalogsEnum, URL_ENDPOINTS } from 'src/app/system/core/enums/catalogs.model';
import { TYPE_TOAST } from 'src/app/system/core/enums/ToastsEnums';
import { DateService } from 'src/app/system/services/date.service';
import { LoadingService } from 'src/app/system/services/loading.service';
import { MessagesService } from 'src/app/system/services/message.service';
import { MipFormsService } from 'src/app/system/services/MipForms.service';
import { UserService } from 'src/app/system/services/user.service';

@Component({
  selector: 'app-add-update-task',
  templateUrl: './add-update-task.component.html',
  styleUrls: ['./add-update-task.component.scss']
})
export class AddUpdateTaskComponent {
  Catalog = CatalogsEnum;
  Form: FormGroup = this.FormBuilder.group({
    title: ['', [Validators.required]],
    description: [''],
    task_type: ['', [Validators.required]],
    user: ['', [Validators.required]],
    nesting_area: ['', [Validators.required]],
    initial_date: ['',],
    final_date: ['',],
    range_date: ['', [Validators.required]],
    id: [''],
    event: this.config.data.event ?? '',
  });

  URL_CATALOGS = URL_ENDPOINTS;

  constructor(
    private FormBuilder: FormBuilder,
    private LoadingService: LoadingService,
    public config: DynamicDialogConfig,
    public ref: DynamicDialogRef,
    private MipFormsService: MipFormsService,
    private MessagesService: MessagesService,
    private DateService: DateService,
    private UserService: UserService
  ) { }

  ngOnInit(): void {
    if (this.config.data.Task) {
      this.patchForm();
    }
  }

  patchForm() {
    let item = this.config.data.Task;

    this.Form.patchValue({
      title: item.title,
      description: item.description,
      id: item.id,
    });
  }

  accept() {
    this.LoadingService.Show();

    if (!this.config.data.Task) {
      this.save();
      return
    }

    this.update();
  }

  cancel() {
    this.ref?.close();
  }

  save() {
    let nesting_area = this.Form.value?.nesting_area.map((item: any) => item.id);

    let data = {
      title: this.Form.value.title,
      description: this.Form.value.description,
      task_type: this.Form.value.task_type.id,
      user: this.Form.value.user.id,
      nesting_area,
      customer: this.config.data.customer ?? '',
      initial_date: this.Form.value.initial_date,
      final_date: this.Form.value.final_date,
      action_plan: null,
      job_center: this.UserService.JobCenter,
      event: this.config.data.event ?? '',
    }

    this.MipFormsService.saveTaskForm(data).subscribe((res) => {
      this.LoadingService.Hide();

      this.MessagesService.ShowMessage(
        TYPE_TOAST.SUCCESS,
        'Correcto',
        'Guardado correctamente'
      );

      this.ref?.close({
        success: true,
        task: res,
        isUpdate: false
      });
    });
  }

  update() {
    let nesting_area = this.Form.value?.nesting_area.map((item: any) => item.id);

    let data = {
      title: this.Form.value.title,
      description: this.Form.value.description,
      task_type: this.Form.value.task_type.id,
      user: this.Form.value.user.id,
      nesting_area,
      customer: this.config.data.customer ?? '',
      initial_date: this.Form.value.initial_date,
      final_date: this.Form.value.final_date,
      action_plan: null,
      event: this.config.data.event ?? '',
      job_center: this.UserService.JobCenter,
      id: this.Form.value.id,
    }

    this.MipFormsService.putTaskForm(data).subscribe((res) => {
      this.LoadingService.Hide();

      this.MessagesService.ShowMessage(
        TYPE_TOAST.SUCCESS,
        'Correcto',
        'Actualizado correctamente'
      );

      this.ref?.close({
        success: true,
        task: res,
        isUpdate: true
      });
    });
  }

  searchDate() {
    let initial_date!: string, final_date: string;
    let range_date: string = this.Form.value.range_date[1];

    if (range_date !== null) {
      initial_date = this.DateService.getFormatDataDate(this.Form.value.range_date[0]);
      final_date = this.DateService.getFormatDataDate(this.Form.value.range_date[1]);

      this.Form.patchValue({
        initial_date: initial_date,
        final_date: final_date
      });
    }
  }
}
