import { CalendarService } from 'src/app/system/services/calendar.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CatalogsEnum, URL_ENDPOINTS } from 'src/app/system/core/enums/catalogs.model';
import { DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-filters-calendar',
  templateUrl: './filters-calendar.component.html',
  styleUrls: ['./filters-calendar.component.scss']
})
export class FiltersCalendarComponent implements OnInit{
  Form: FormGroup = this.FormBuilder.group({
    event_type: [null],
    employee:[null],
    customer:[null],
    quote:[null],
    service_type:[null],
    ticket:[null]
  });
  Catalog = CatalogsEnum;

  URL_CATALOGS = URL_ENDPOINTS;

constructor(private FormBuilder:FormBuilder,private CalendarService:CalendarService,    public ref: DynamicDialogRef,){

}

  ngOnInit(): void {

  }

  filterCalendar(){

    this.CalendarService.FilterTable(this.Form.value);
    this.ref.close();
  }
}
