<form (ngSubmit)="accept()" [formGroup]="Form">

  <div class="grid formgrid  mt-3 ,b-4">
      <div class="col-12 mb-2 mt-4 lg:col-4 lg:mb-0">
          <span class="w-100 p-fluid p-float-label p p-input-icon-left">
              <i class="fa-solid fa-user-pen"></i>
              <input formControlName="name"  type="text" pInputText placeholder="Nombre"/>
              <label for="autocomplete">Nombre</label>
          </span>
      </div>

      <div class="col-12 mb-2 mt-4 lg:col-4 lg:mb-0">
        <app-select [Catalog]="Catalog.JOB_TITLES" field ="name" [isDropdown] = "true" type="p-autocomplete" [isMultiple] = "false" label="Puesto" formControlName="job_title"></app-select>
    </div>

    <div class="col-12 mb-2 mt-4 lg:col-4 lg:mb-0">

      <span class="w-100 p-fluid  p-float-label">
        <p-autoComplete
        formControlName="role"
        [suggestions]="filteredRoles"
        [showEmptyMessage]="true"
        appendTo="body"
        [dropdown]="true"
        field="name"
        [minLength]="1"
        (completeMethod)="search($event)"
        class="w-full"

      >
      </p-autoComplete>
          <label for="autocomplete">Rol</label>
      </span>
  </div>
 <!--
     <div class="col-12 mb-2 mt-4 lg:col-4 lg:mb-0 ">
      <span class="w-100 p-fluid p-float-label p p-input-icon-left">
          <i class="fa-solid fa-envelope"></i>
          <input formControlName="username"  type="text" pInputText placeholder="Correo"/>
          <label for="username">Correo</label>
      </span>
  </div>
    <div class="col-12 mb-2 mt-4 lg:col-4 lg:mb-0">
    <span class="w-100 p-fluid p-float-label p p-input-icon-left">
        <i class="fa-solid fa-lock"></i>
        <input formControlName="password"  type="password" pInputText placeholder="Contraseña"/>
        <label for="password">Contraseña</label>
    </span>
</div>
 -->



<div class="col-12 mb-2 mt-4 lg:col-4 lg:mb-0">
  <span class="w-100 p-fluid p-float-label p p-input-icon-left">
      <i class="fa-solid fa-folder-tree"></i>
      <input formControlName="management"  type="text" pInputText placeholder="Administrativo"/>
      <label for="management">Administrativo</label>
  </span>
</div>

<div class="col-12 mb-2 mt-4 lg:col-4 lg:mb-0">
  <span class="w-100 p-fluid p-float-label p p-input-icon-left">
      <i class="fa-solid fa-id-card"></i>
      <input formControlName="payroll_number"  type="text" pInputText placeholder="Número nómina"/>
      <label for="payroll_number">Número nómina</label>
  </span>
</div>

<div class="col-12 mb-2 mt-4 lg:col-4 lg:mb-0">
  <span class="w-100 p-fluid p-float-label p p-input-icon-left">
      <i class="fa-solid fa-street-view"></i>
      <input formControlName="location"  type="text" pInputText placeholder="Domicilio"/>
      <label for="location">Domicilio</label>
  </span>
</div>

<div class="col-12 mb-2 mt-4 lg:col-4 lg:mb-0">
  <span class="w-100 p-fluid p-float-label p p-input-icon-left">
      <i class="fa-solid fa-building-user"></i>
      <input formControlName="zone"  type="text" pInputText placeholder="Zona"/>
      <label for="zone">Zona</label>
  </span>
</div>



    <div class="col-12 mb-2 mt-4 lg:col-4 lg:mb-0">
      <app-select [Catalog]="Catalog.JOB_CENTERS" field ="name" [isDropdown] = "true" type="p-autocomplete-administrative" [isMultiple] = "false" label="Centro de trabajo" formControlName="job_center"></app-select>
  </div>
  <div class="col-12 mb-2 mt-4 lg:col-4 lg:mb-0">

    <span class="w-100 p-fluid p-float-label p p-input-icon-left">
      <i class="fa-solid fa-user"></i>
      <input formControlName="username"  type="text" pInputText placeholder="Usuario"/>
      <label for="autocomplete">Usuario</label>
  </span>

  </div>
<div *ngIf="!isEdit" class="col-12 mb-2 mt-4 lg:col-4 lg:mb-0 ">
  <span class="w-100 p-fluid p-float-label p p-input-icon-left">
    <i class="pi pi-key"></i>
  <p-password formControlName="password" [toggleMask]="true"  styleClass="p-password p-component p-inputwrapper p-input-icon-right" >
    <ng-template pTemplate="header">
    <h6>Ingresar Contraseña</h6>
    </ng-template>
  </p-password>
  <label>Contraseña</label>

</span>
</div>



  </div>
  <div class="col-12 mt-2 mb-2 lg:col-12 lg:mb-0 d-flex justify-content-center mt-3">
    <div>
      <p class="fw-bold" >Cambio de avatar</p>
      <div class=" text-center">
        <label  for="upload"
        class="hov-pointer   label-img" >
        <div class="contenedor-imagen">

         <i  *ngIf="!this.Form.value.avatar" class="fa-solid fa-user fa-4x mt-3"></i>
          <img
            *ngIf="this.Form.value.avatar"
            [src]="ImgDataUrl"
           class="bg-transparente"
          />
          <div class="leyenda" >Click para cambiar foto</div>
        </div>
        <input  (change)="myUploader($event)" type="file" accept="image/jpeg, image/jpg" name="file" id="upload" style="display:none;" />
      </label>
      </div>
    </div>
<!--
      <div>
      <label for="upload-signature" class="hov-pointer   label-img">
        <div class="borde-shadow mx-5 px-5 d-block   ">
          <br>
          <i *ngIf="!this.Form.value.signature" class="fa fa-file fa-3x  "></i>
          <i *ngIf="this.Form.value.signature" class="fa fa-file-pdf text-danger fa-3x  "></i>
          <p *ngIf="!this.Form.value.signature" class="mt-2">Click para subir documento</p>
          <p *ngIf="this.Form.value.signature" class="mt-2">{{this.Form.value.signature.name}}</p>
        </div>
        <input (change)="uploadFile($event)" type="file" accept="application/pdf" name="file" id="upload-signature"style="display:none;"/>
      </label>
    </div>
-->
  </div>

  <div class="col-12 mt-2 mb-2 lg:col-12 lg:mb-0 d-flex justify-content-center">
    <p-colorPicker formControlName="color" appendTo="body" ></p-colorPicker>
    <p class="ms-2 me-2 mt-2">Color de calendario</p>
  </div>



  <div class="d-flex justify-content-center mt-4">
      <button pButton pRipple type="submit" [disabled]="Form.invalid" label="Guardar" class="p-button-raised me-2 btn-sm"></button>
      <button (click)="cancel()" pButton pRipple type="button" label="Cancelar" class="p-button-raised p-button-secondary btn-sm"></button>
  </div>
</form>
