import { Component, EventEmitter, Input, Output, ViewChild, inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { FullCalendarComponent } from '@fullcalendar/angular';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';
import { DialogService } from 'primeng/dynamicdialog';
import { Subject, takeUntil } from 'rxjs';

import { CalendarService } from 'src/app/system/services/calendar.service';
import { PermissionsServices } from 'src/app/system/services/permissions.service';
import { DetailEventComponent } from '../../components/detail-event/detail-event.component';
import { CreateUpdateEventFormComponent } from '../create-update-event-form/create-update-event-form.component';
import { DateService } from './../../../../services/date.service';
import { LoadingService } from './../../../../services/loading.service';
import { UserService } from './../../../../services/user.service';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
})
export class CalendarComponent {
  loading: boolean = false;

  private onDestroy$ = new Subject<void>();
  public permissionsService = inject(PermissionsServices);

  @ViewChild('calendar') calendarComponent!: FullCalendarComponent;
  @Input() headerToolbar: any = {};
  @Input() initialView: string = '';
  @Input() ShowheaderToolbar: boolean = true;
  @Output() emitData = new EventEmitter<void>();

  dataForm: FormGroup = this.formBuilder.group({
    first: 0,
    rows: 10,
    totalRecords: 0,
    search: '',
    range_date: null,
    event_initial_date: null,
    event_final_date: null,
    group: '',
    customer: '',
    service_type: '',
    event_type: '',
    employee: '',
    quote: '',
    ticket: '',
    is_pay:null
  });

  modeFull: boolean = true;
  events: any[] = [];
  calendarOptions: any = {};

  // Values in Spanish Buttons
  buttonText = {
    today: 'Hoy',
    month: 'Mes',
    week: 'Semana',
    day: 'Día',
  };

  constructor(
    private LoadingService: LoadingService,
    private CalendarService: CalendarService,
    public dialogService: DialogService,
    private DateService: DateService,
    private UserService: UserService,
    private formBuilder: FormBuilder
  ) {
    setTimeout(() => {
      this.getCurrentDates();
    }, 750);
  }

  ngOnInit(): void {
    //  this.today = this.DateService.dateTodayCalendar();
    this.CalendarService.filterTable$.pipe(takeUntil(this.onDestroy$)).subscribe(data => {
      this.applyFilters(data);
    });

    this.configCalendar();
  }

  resetFilter() {
    this.dataForm.patchValue({
      first: 0,
      rows: 10,
      totalRecords: 0,
      search: '',
      range_date: null,
      event_initial_date: null,
      event_final_date: null,
      group: '',
      customer: '',
      service_type: '',
      event_type: '',
      employee: '',
      quote: '',
      ticket: '',
      is_pay:null
    });
  }

  applyFilters(filters: any) {
    console.log(filters.is_pay);
    console.log("*******");
    
    if (!filters) {
      this.resetFilter();
      this.getCurrentDates();
      return
    }

    if(filters.is_pay){
      this.dataForm.get("is_pay")?.setValue(filters.is_pay)
    } 

    if (filters.event_type) {
      this.dataForm.get("event_type")?.setValue(filters.event_type?.id)
    }

    if (filters.employee) {
      this.dataForm.get("employee")?.setValue(filters.employee?.id)
    }

    if (filters.customer) {
      this.dataForm.get("customer")?.setValue(filters.customer?.id)
    }

    if (filters.quote) {
      this.dataForm.get("quote")?.setValue(filters.quote?.id)
    }

    if (filters.service_type) {
      this.dataForm.get("service_type")?.setValue(filters.service_type?.id)
    }

    if (filters.ticket) {
      this.dataForm.get("ticket")?.setValue(filters.ticket?.id)
    }

    if (filters.group) {
      this.dataForm.get("group")?.setValue(filters.group?.id)
    }

    this.getCurrentDates();
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  getEvents() {
    this.LoadingService.Show();

    this.CalendarService.getEvents(this.dataForm.value).subscribe(
      (res: any) => {
        this.events = [];

        this.events = res.map((event: any) => ({
          id: `${event.id}`,
          title: `${event.customer?.name} =>` + ' ' + this.getStatusName(event),
          start: `${event.initial_date} ${event.initial_hour}`,
          end: `${event.final_date} ${event.final_hour}`,
          backgroundColor: this.getBackgroundColor(event), //event.status.key_string
          borderColor: this.getBackgroundColor(event),
          extendedProps: { is_many: event.is_many },
          className: 'recurring-event'
        }));

        this.configCalendar();

        this.LoadingService.Hide();
      }
    );
  }

  getCurrentDates() {
    let calendarApi = this.calendarComponent.getApi();
    let modeView = calendarApi.view;
    let startDate = this.DateService.getFormatDataDate(modeView.currentStart);
    let endDate = this.DateService.getFormatDataDate(modeView.currentEnd);
    let updateDate = new Date(startDate);

    updateDate.setDate(updateDate.getDate() + 2);
    let moreDay = this.DateService.getFormatDataDate(updateDate)

    if (modeView.type === 'dayGridMonth') {
      this.dataForm.patchValue({
        event_initial_date: moreDay,
        event_final_date: endDate,
      });
    } else {
      this.dataForm.patchValue({
        event_initial_date: moreDay,
        event_final_date: endDate,
      });
    }

    this.getEvents();
  }

  getBackgroundColor(event: any): string {
    switch (event.status.key_string) {
      case 'event-created':
        return event.employee[0]?.color || '#3C5898';
      case 'event-in-progress':
        return '#fbc02d';
      case 'event-completed':
        return '#16A05A';
      case 'event-cancelled':
        return '#ef4444';
      default:
        return '#3C5898';
    }
  }

  getStatusName(event: any): string {
    switch (event.status.key_string) {
      case 'event-created':
        return "(PROGRAMADO)";
      case 'event-in-progress':
        return "(EN PROGRESO)";
      case 'event-completed':
        return "(COMPLETADO)";
      case 'event-cancelled':
        return "(CANCELADO)";
      default:
        return 'NA';
    }
  }

  configCalendar() {
    this.calendarOptions = {
      events: this.events,
      plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
      height: 'auto',
      initialView: this.initialView,
      weekends: true,
      locale: 'es',
      timeZone: 'locale',
      dayMaxEventRows: 2, // Ajusta este valor
      contentHeight: 'auto',
      eventLimit: true, // Muestra el enlace "Ver más"
      eventLimitText: "Ver más",
      headerToolbar: this.ShowheaderToolbar ? {
        left: 'prev,next today changeRange',
        center: 'title',
        right: 'dayGridMonth,timeGridWeek,timeGridDay',
      } : {},
      buttonText: this.buttonText,
      slotMinTime: '07:00:00',
      slotMaxTime: '23:00:00',
      editable: true,
      customButtons: {
        next: {
          click: this.next.bind(this),
        },
        prev: {
          click: this.prev.bind(this)
        },
        today: {
          text: "Hoy",
          click: this.today.bind(this)
        },
        dayGridMonth: {
          text: "Mes",
          click: this.month.bind(this)
        },
        timeGridWeek: {
          text: "Semana",
          click: this.timeGridWeek.bind(this)
        },
        changeRange: {
          text: "12/24",
          click: this.customFunction.bind(this),
        },
        timeGridDay: {
          text: "Día",
          click: this.timeGridDay.bind(this),
        }
      },
      selectable: true,
      selectMirror: true,
      dayMaxEvents: true,
      eventClick: (e: MouseEvent) => this.onEventClick(e),
      select: (e: MouseEvent) => this.onDateSelect(e),
      eventDrop: this.handleEventDrag.bind(this),

      eventContent: function (arg: any) {
        if (arg.event.extendedProps.is_many) {

        if (arg.view.type == "dayGridMonth") {
       let   title = arg.event.title.length > 30 ? arg.event.title.substring(0, 30) + '...' : arg.event.title;
          return { html: `<span style="font-size:10px; font-weight:bold;">🔄 ${title}</span>` };
        }
          return {
            html: `<span>🔄 ${arg.event.title}</span>` // Agrega el ícono antes del título
          };
        }

        let title = arg.event.title;

        if (arg.view.type == "dayGridMonth") {
          title = arg.event.title.length > 30 ? arg.event.title.substring(0, 30) + '...' : arg.event.title;
          return { html: `<span style="font-size:10px; font-weight:bold;">${title}</span>` };
        }

        if (arg.view.type == "timeGridWeek") {
          title = arg.event.title;
        }

        return { html: `<span>${title}</span>` };
      }
    };
  }

  customFunction() {
    if (this.modeFull) {
      this.calendarOptions.slotMinTime = '00:00:00';
      this.calendarOptions.slotMaxTime = '24:00:00';
      this.modeFull = false
    } else {
      this.calendarOptions.slotMinTime = '07:00:00';
      this.calendarOptions.slotMaxTime = '21:00:00';
      this.modeFull = true
    }
  }

  handleEventDrag(selectInfoEventDrag: any) {
    if (this.permissionsService.hasPermission("events", "add")) {
      selectInfoEventDrag.revert();

      let initialDate = selectInfoEventDrag.event.startStr;
      let finalDate = selectInfoEventDrag.event.endStr;
      let Date = { initial_date: initialDate, final_date: finalDate }

      this.CalendarService.getEventById(selectInfoEventDrag.event.id).subscribe((res) => {
        this.LoadingService.Hide();

        const dialog = this.dialogService.open(CreateUpdateEventFormComponent, {
          header: 'Evento ' + res.folio,
          modal: true,
          width: '70%',
          //height:"100%",
          closable: true,
          draggable: false,
          // maximizable: true,
          resizable: true,
          closeOnEscape: true,
          dismissableMask: true,
          data: {
            Event: res.data,
            Date,
          },
        });

        dialog.onClose.subscribe((resp: any) => {
          this.getCurrentDates();
        });
      });
    }
  }

  onEventClick(e: any) {
    this.LoadingService.Show();

    this.CalendarService.getEventById(e.event.id).subscribe((res) => {
      this.LoadingService.Hide();

      const ref = this.dialogService.open(DetailEventComponent, {
        header: "",
        modal: true,
        closable: true,
        draggable: false,
        maximizable: false,
        resizable: true,
        width: '40rem',
        closeOnEscape: true,
        dismissableMask: true,
        data: {
          Event: res.data,
        },
      });

      ref.onClose.subscribe((resp: any) => {
   this.getCurrentDates();

   this.emitData.emit();
      });
    });
  }

  onDateSelect(e: any) {
    if (this.permissionsService.hasPermission("events", "add")) {
      let initialDate = e.startStr;
      let finalDate = e.endStr;
      let Date = { initial_date: initialDate, final_date: finalDate };

      const dialog = this.dialogService.open(CreateUpdateEventFormComponent, {
        header: 'Nuevo evento',
        modal: true,
        width: '70%',
        //height:"100%",
        closable: true,
        draggable: false,
        // maximizable: true,
        resizable: true,
        closeOnEscape: true,
        dismissableMask: true,
        data: {
          Event: null,
          Date,
        },
      });

      dialog.onClose.subscribe((res) => {
        if (res?.success) {
          this.getCurrentDates();
        }
      });
    }
  }

  next() {
    let calendarApi = this.calendarComponent.getApi();
    calendarApi.next();
    this.getCurrentDates();
  }

  prev() {
    let calendarApi = this.calendarComponent.getApi();
    calendarApi.prev();
    this.getCurrentDates();
  }

  today() {
    let calendarApi = this.calendarComponent.getApi();
    calendarApi.today();
    this.getCurrentDates();
  }

  month() {
    let calendarApi = this.calendarComponent.getApi();
    calendarApi.changeView('dayGridMonth');
    this.getCurrentDates();
  }

  timeGridWeek() {
    let calendarApi = this.calendarComponent.getApi();
    calendarApi.changeView('timeGridWeek');
    this.getCurrentDates();
  }

  timeGridDay() {
    let calendarApi = this.calendarComponent.getApi();
    calendarApi.changeView('timeGridDay');
    this.getCurrentDates();
  }
}
