

<form (ngSubmit)="accept()" [formGroup]="Form">
  <div class="grid formgrid  mt-4 ">
    <div *ngIf="Form.value.signature != ''" class="col-12 mb-2  lg:col-1 lg:mb-0">
    </div>
    <div *ngIf="Form.value.signature != ''" class="col-12 mb-2 text-center lg:col-10 lg:mb-0">
      <h6>Firma anterior</h6>
     <img [src]="Form.value.signature + '?' + randomValue" alt="">


    </div>
    <div *ngIf="Form.value.signature != ''" class="col-12 mb-2  lg:col-1 lg:mb-0">
    </div>
    <div class="col-12 mb-2  lg:col-1 lg:mb-0">
    </div>
    <div class="col-12 mb-2  lg:col-10 lg:mb-0">
      <h6>Nueva firma</h6>
      <div (window:resize)="onResize()" #wrapper style=" max-width: 500px;" class="">
        <canvas #firmadigital id="firma" class="signature-pad"  style="touch-action:none ;" [width]="CanvasWidth" [height]="CanvasHeigth"></canvas>


      </div>
      <p-button
      (click)="clear()"
      type="button"
      icon="fa-solid fa-trash"
      styleClass="p-button-rounded p-button-outlined p-button-danger p-button-sm ml-2 btn-sm"
    >
    </p-button>

    </div>

    <div class="col-12 mb-2  lg:col-1 lg:mb-0">
    </div>

    <div class="col-12 mb-2 mt-4 lg:col-12 lg:mb-0">
      <span class="w-100 p-fluid p-float-label p p-input-icon-left">
        <i class="fa-solid fa-pencil"></i>
        <input formControlName="name"  type="text" pInputText placeholder="Nombre "/>
        <label for="dose">Nombre </label>
      </span>
    </div>

    <div class="col-12 mb-2 mt-4 lg:col-12 lg:mb-0">
      <span class="w-100 p-fluid p-float-label p p-input-icon-left">
        <i class="fa-solid fa-pencil"></i>
        <input formControlName="job_position"  type="text" pInputText placeholder="Puesto "/>
        <label for="dose">Puesto </label>
      </span>
    </div>


  </div>
  <div class="d-flex justify-content-center mt-4">
    <button pButton pRipple type="submit" [disabled]="Form.invalid" label="Guardar" class="p-button-raised me-2 btn-sm"></button>
    <button (click)="cancel()" pButton pRipple type="button" label="Cancelar" class="p-button-raised p-button-secondary btn-sm"></button>
</div>
</form>
