import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { catchError, throwError } from 'rxjs';
import { MIP_FORM } from 'src/app/system/core/enums/MipForms.model';
import { TYPE_TOAST } from 'src/app/system/core/enums/ToastsEnums';
import { CatalogsEnum } from 'src/app/system/core/enums/catalogs.model';
import { MipFormsService } from 'src/app/system/services/MipForms.service';
import { LoadingService } from 'src/app/system/services/loading.service';
import { MessagesService } from 'src/app/system/services/message.service';
import { UtilsService } from 'src/app/system/services/utils.service';

@Component({
  selector: 'app-add-update-products',
  templateUrl: './add-update-products.component.html',
  styleUrls: ['./add-update-products.component.scss']
})
export class AddUpdateProductsComponent {


  Catalog = CatalogsEnum;
  Form: FormGroup = this.FormBuilder.group({
    quantity: ['', [Validators.required]],
    product: ['', [Validators.required]],
    dose: ['', [Validators.required]],
    application_method: ['', [Validators.required]],
    nesting_area: ['', [Validators.required]],
    id: [''],
    event: [''],
  });
  randomValue = Math.random();
  customer:any;
  constructor(
    private UtilsService:UtilsService,
    private FormBuilder: FormBuilder,
    private LoadingService: LoadingService,
    public config: DynamicDialogConfig,
    public ref: DynamicDialogRef,
    private DialogService: DialogService,
    private MipFormsService: MipFormsService,
    private MessagesService: MessagesService,

  ) {}

  ngOnInit(): void {
this.customer = this.config.data.Event.customer.id;
    if (this.config.data.Product) {
      this.patchForm();
    }
  }

  patchForm() {
    let item = this.config.data.Product;


    this.Form.patchValue({
    quantity: item.quantity,

    product: item.product,
    dose: item.dose,
    application_method: item.application_method,
    nesting_area: item.nesting_area,
    id: item.id,
    event: this.config.data.Event.id

    });
  }

  accept() {
    this.LoadingService.Show();
    if (!this.config.data.Product) {
      this.save();
      return;
    }
    this.update();
  }
  cancel() {
    this.ref?.close();
  }

  save() {
    let nesting_area =  this.Form.value?.nesting_area.map((item:any)=>item.id);

    let data = {
      ControlForm: this.config.data.Control.id,
      quantity: this.Form.value.quantity,
      product: this.Form.value.product.id,
      dose: this.Form.value.dose.id,
      application_method:this.Form.value.application_method.id,
      nesting_area,

    };

    this.MipFormsService.saveDataForm(  data, MIP_FORM.CONTROL_FORMS_PRODUCTS).subscribe((res)=>{
      this.LoadingService.Hide();
      this.MessagesService.ShowMessage(
          TYPE_TOAST.SUCCESS,
          'Correcto',
          'Guardado correctamente'
      );

      this.ref?.close({ success: true });
    });



  }

  update() {
    let nesting_area =  this.Form.value?.nesting_area.map((item:any)=>item.id);


    let data = {
      ControlForm: this.config.data.Control.id,
      quantity: this.Form.value.quantity,
      product: this.Form.value.product.id,
      dose: this.Form.value.dose.id,
      application_method:this.Form.value.application_method.id,
      nesting_area,
    id:this.Form.value.id,
    };





    this.MipFormsService.patchDataForm(
data,
      MIP_FORM.CONTROL_FORMS_PRODUCTS,
      data.id
    ).pipe(
      catchError((error) => {
        console.error('Error during patch request:', error);
        return throwError(error);
      })
    ).subscribe((res) => {
      this.LoadingService.Hide();
      this.MessagesService.ShowMessage(
        TYPE_TOAST.SUCCESS,
        'Correcto',
        'Guardado correctamente'
      );

      this.ref?.close({ success: true });
    });
  }



}
