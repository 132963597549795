<form (ngSubmit)="accept()" [formGroup]="Form">

  <div class="grid formgrid  mt-3 mb-4">
      <div    class="col-12 mb-2 mt-3   lg:col-6 lg:mb-0">
          <span class="w-100 p-fluid  p-input-icon-left">
              <i class="pi pi-pencil"></i>
              <input formControlName="name"  type="text" pInputText placeholder="Nombre"/>
          </span>
      </div>
      <div class="col-12 mb-2   mt-3  lg:col-6 lg:mb-0">
        <span class="w-100  p-fluid  p-float-label">
          <p-autoComplete
          formControlName="perimeter"
          [showEmptyMessage]="true"
          [suggestions]="filteredPerimeters"
          appendTo="body"
          [dropdown]="true"
          (completeMethod)="filterPerimeter($event)"
          field="name"
          [minLength]="1"
        >
        </p-autoComplete>
        <label>Perimetro</label>
      </span>
      </div>

      <div *ngIf="isEdit" class="col-12 mt-3 lg:col-6 lg:mb-0 mb-4">
        <span class="w-100 p-fluid  p-float-label p-input-icon-left">
            <i class="fa-solid fa-arrow-up-9-1"></i>
            <input formControlName="key"  type="text" pInputText placeholder="Clave"/>
            <label for="autocomplete">Clave</label>
        </span>
    </div>

      <div *ngIf="!isEdit" class="col-12 mt-3 lg:col-6 lg:mb-0 mb-4">
        <span class="w-100 p-fluid  p-float-label p-input-icon-left">
            <i class="fa-solid fa-arrow-up-9-1"></i>
            <input formControlName="start_range"  type="number" pInputText placeholder="Número Inicio"/>
            <label for="autocomplete">Número Inicio</label>
        </span>
    </div>

    <div  *ngIf="!isEdit" class="col-12 mt-3 lg:col-6 lg:mb-0 mb-4">
      <span class="w-100 p-fluid  p-float-label p-input-icon-left">
          <i class="fa-solid fa-arrow-up-9-1"></i>
          <input formControlName="end_range"  type="number" pInputText placeholder="Número Fin"/>
          <label for="autocomplete">Número final</label>
      </span>
  </div>








  </div>


  <div class="d-flex justify-content-center mt-4">
      <button pButton pRipple type="submit" [disabled]="Form.invalid" label="Aceptar" class="p-button-raised me-2 btn-sm"></button>
      <button (click)="cancel()" pButton pRipple type="button" label="Cancelar" class="p-button-raised p-button-secondary btn-sm"></button>
  </div>
</form>
