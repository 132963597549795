import { Component } from '@angular/core';

@Component({
  selector: 'app-form-inspections',
  templateUrl: './form-inspections.component.html',
  styleUrls: ['./form-inspections.component.scss']
})
export class FormInspectionsComponent {

}
