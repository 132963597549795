import { Component, Input, inject } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { MipFormsService } from 'src/app/system/services/MipForms.service';
import { LoadingService } from 'src/app/system/services/loading.service';
import { MessagesService } from 'src/app/system/services/message.service';
import { AddUpdateCleaningComponent } from '../add-update-cleaning/add-update-cleaning.component';
import { TYPE_TOAST } from 'src/app/system/core/enums/ToastsEnums';
import { PermissionsServices } from 'src/app/system/services/permissions.service';

@Component({
  selector: 'app-table-cleanings',
  templateUrl: './table-cleanings.component.html',
  styleUrls: ['./table-cleanings.component.scss']
})
export class TableCleaningsComponent {
  public permissionsService = inject(PermissionsServices);
  @Input() Condition:any;
  loading: boolean = false;
  index : number = 0;
  Cleanings:any[] = [];
  randomValue = Math.random();
  constructor( private MipFormsService:MipFormsService, private LoadingService:LoadingService, private DialogService:DialogService, private MessagesService:MessagesService){

  }
  handleChange(event: any) {
    this.index = event.index;
  }
    ngOnInit(): void {


  setTimeout(() => {
    this.getCleanings();
  }, 0);
    }

    getCleanings(){
      this.LoadingService.Show();
      this.MipFormsService.getCleaningsConditionForm(this.Condition.id).subscribe((res) => {

      this.Cleanings = res;
        console.log(res)
        this.LoadingService.Hide();

      });
    }




   deleteItem(item: any) {
    this.MessagesService.confirm(
      '¿Esta seguro de eliminar el registro ' + item.cleaning.name + ' ?',
      () => {
        this.MipFormsService.deleteCondition(
          item.id
        ).subscribe((res) => {
          this.MessagesService.ShowMessage(TYPE_TOAST.SUCCESS,"Correcto","Se ha eliminado correctamente")
          this.getCleanings();
        });
      }
    );
  }


  AddUpdateCleaning(Cleaning: any) {
    const dialog = this.DialogService.open(AddUpdateCleaningComponent, {
     header: "Limpieza",
     modal: true,
     width: '30rem',
     closable: true,
     draggable: false,
     maximizable: false,
     resizable: true,
     closeOnEscape: true,
     dismissableMask: true,
     data: {

      Condition:this.Condition,
       Cleaning
     },
   });
   dialog.onClose.subscribe((res) => {
     if (res?.success) {
      this.getCleanings();
     }
   });

 }
}
