

<form (ngSubmit)="accept()" [formGroup]="Form">
  <div class="grid formgrid  mt-4 ">

    <div class="col-12 mb-2  lg:col-12 lg:mb-0">
      <app-select
      [Catalog]="Catalog.PLAGUES"
      field ="name"
      [isDropdown] = "true"
      type="p-autocomplete"
      [isMultiple] = "true"
      formControlName="plague"
      label="Plaga">
    </app-select>
    </div>


    <div *ngFor="let item of plague_counts"  class="col-12 mb-2 mt-4  sm:col-12 lg:col-4 lg:mb-0">


      <span class="mx-2 p-fluid p-float-label p p-input-icon-left">
        <i class=""></i>
        <input [ngModelOptions]="{standalone: true}"  [(ngModel)]="item.count_plague"  type="number" pInputText [placeholder]="item.name"/>
        <label [for]="item.name">Cantidad {{item.name}}</label>
    </span>


    </div>


     <div class="col-12 mb-2 mt-4 lg:col-12 lg:mb-0">
      <app-select [customer]="Event.customer.id" [Catalog]="Catalog.AREA_STATIONS" field ="completeNameStation" [isDropdown] = "true" type="pest-control" [isMultiple] = "false" label="Estacion" formControlName="station"></app-select>
  </div>

  <div class="col-12 mb-2 mt-4 lg:col-12 lg:mb-0">
    <app-select [customer]="Event.customer.id" [Catalog]="Catalog.STATION_CONDITIONS" field ="name" [isDropdown] = "true" type="p-autocomplete" [isMultiple] = "true" label="Condiciones de estación" formControlName="station_condition"></app-select>
</div>
<div class="col-12 mb-2 mt-4  lg:col-12 lg:mb-0">
  <app-select  [Catalog]="Catalog.STATION_TYPE_ACTIVITIES" field ="name" [isDropdown] = "true" type="p-autocomplete" [isMultiple] = "true" label="Tipos actividad" formControlName="stationTypeActivity"></app-select>
</div>
<div class="col-12 mb-2 mt-4  lg:col-12 lg:mb-0">
  <app-select  [Catalog]="Catalog.STATION_ACTIVITIES" field ="name" [isDropdown] = "true" type="p-autocomplete" [isMultiple] = "true" label="Actividad estación" formControlName="station_activity"></app-select>
</div>

<div class="col-12  mb-2 mt-4  lg:col-12 lg:mb-0">
  <span class="p-fluid mt-5">
      <textarea formControlName="EmployeeActivity" placeholder="Activida de empleado" rows="3" cols="30"  pInputTextarea></textarea>
  </span>
</div>
  </div>


  <div class="d-flex justify-content-center mt-4">
    <button pButton pRipple type="submit" [disabled]="Form.invalid" label="Guardar" class="p-button-raised me-2 btn-sm"></button>
    <button (click)="cancel()" pButton pRipple type="button" label="Cancelar" class="p-button-raised p-button-secondary btn-sm"></button>
</div>
</form>
