
import { Component, Inject, ViewChild, inject } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { MIP_FORM } from 'src/app/system/core/enums/MipForms.model';
import { MipFormsService } from 'src/app/system/services/MipForms.service';
import { LoadingService } from 'src/app/system/services/loading.service';
import { MessagesService } from 'src/app/system/services/message.service';

import { TYPE_TOAST } from 'src/app/system/core/enums/ToastsEnums';
import { AddUpdatePaymentMipComponent } from '../payment/components/add-update-payment-mip/add-update-payment-mip.component';
import { TableActionPlanComponent } from './components/table-action-plan/table-action-plan.component';
import { AddUpdateTaskPlanComponent } from './components/add-update-task-plan/add-update-task-plan.component';
import { AddUpdatePlanComponent } from '../action-plan/components/add-update-plan/add-update-plan.component';
import { PermissionsServices } from 'src/app/system/services/permissions.service';
@Component({
  selector: 'app-action-plan',
  templateUrl: './action-plan.component.html',
  styleUrls: ['./action-plan.component.scss']
})
export class ActionPlanComponent {
  @ViewChild(TableActionPlanComponent, { static: false }) tableRef!: TableActionPlanComponent;

  index : number = 0;
  activeTabIndex: { [key: string]: number } = {};
  public permissionsService = inject(PermissionsServices);
  ActionPlanList:any[] = [];
constructor(@Inject('dynamicData') public Event: any, private MipFormsService:MipFormsService, private LoadingService:LoadingService, private DialogService:DialogService, private MessagesService:MessagesService){

}
handleChange(event: any) {
  this.index = event.index;
}
activeAccordionId: string | null = null;


handleAccordionClick(accordionId: string) {
  if (this.activeAccordionId === accordionId) {
    this.activeAccordionId = null;
  } else {
    this.activeAccordionId = accordionId;
  }
}
  ngOnInit(): void {



setTimeout(() => {
  this.getActionPlan();
}, 0);
  }

  getActionPlan(){
    this.LoadingService.Show();
    this.MipFormsService.getDataForm(this.Event.id,MIP_FORM.ACTION_PLANS).subscribe((res) => {

      this.ActionPlanList = res;
      this.LoadingService.Hide();

    });
  }


  AddUpdate(ActionPlan: any,) {
     const dialog = this.DialogService.open(AddUpdatePlanComponent, {
      header: "Plan de acción",
      modal: true,
      width: '50%',
      //height:"100%",
      closable: true,
      draggable: false,
      maximizable: false,
      resizable: true,
      closeOnEscape: true,
      dismissableMask: true,
      data: {
        ActionPlan,
        Event:this.Event
      },
    });
    dialog.onClose.subscribe((res) => {
      if (res?.success) {
        this.getActionPlan();
      }
    });

  }
  deleteItem(item: any) {
    this.MessagesService.confirm(
      '¿Esta seguro de eliminar  ' + item?.folio + ' ?',
      () => {
        this.MipFormsService.deleteDataForm(
          item.id,
          'action-plans'
        ).subscribe((res) => {
          this.MessagesService.ShowMessage(TYPE_TOAST.SUCCESS,"Correcto","Se ha eliminado correctamente")
          this.getActionPlan();
        });
      }
    );
  }




  AddUpdateTask(Task: any,ActionPlan:any) {
    const dialog = this.DialogService.open(AddUpdateTaskPlanComponent, {
     header: "Tarea",
     modal: true,
     width: '60%',
     closable: true,
     draggable: false,
     maximizable: false,
     resizable: true,
     closeOnEscape: true,
     dismissableMask: true,
     data: {
      ActionPlan,
      Event:this.Event,
      Task
     },
   });
   dialog.onClose.subscribe((res) => {
     if (res?.success) {
     this.tableRef.getTasks();
     }
   });

 }


}
