


<button *appHasPermission="permissionsService.PERMISSIONS_ADD.MIP" (click)="AddUpdateTask(null)" pButton class="mb-4 p-button-raised w-full sm:w-auto flex-order-0 sm:flex-order-1  p-button-info" icon="pi pi-plus" severity="info" label="Nueva tarea"></button>





  <div [formGroup]="dataForm" class="me-3 d-flex flex-wrap justify-content-between">

    <span class="w-25 p-fluid p-input-icon-left">
      <i class="pi pi-search"></i>
      <input
      formControlName="search"
        pInputText
        type="text"
        (input)="getEventValue($event)"
        placeholder="Buscar..."
        class="w-full"
      />
    </span>
  <div>


  </div>


  </div>





  <div class="custom-table-container mt-3 pe">
    <p-table
    class="custom-table pe "
    styleClass="p-datatable-sm"
    #dt1
    [value]="dataItems"
    dataKey="id"
    sortMode="multiple"
    [rows]="10"
    [showCurrentPageReport]="true"
    [rowsPerPageOptions]="[10, 25, 50]"
    [loading]="loading"
    [paginator]="false"
    [globalFilterFields]="['name']"
    >

      <ng-template pTemplate="header">

        <tr>
          <th
          pSortableColumn="folio"
            class=" bg-primary border-bottom"

          >
           # Folio <p-sortIcon field="folio"></p-sortIcon>
          </th>
          <th
          pSortableColumn="internal_id"
            class=" bg-primary"

          >
          # Cliente
          <p-sortIcon field="internal_id"></p-sortIcon>
          </th>

          <th
          pSortableColumn="name"
            class=" bg-primary"

          >
         Plan de acción
          <p-sortIcon field="name"></p-sortIcon>
          </th>

          <th
          pSortableColumn="phone"
            class=" bg-primary "

          >
          Tipo Tarea
          <p-sortIcon field="phone"></p-sortIcon>
          </th>
          <th
          pSortableColumn="email"
            class="b bg-primary"

          >
          Título
          <p-sortIcon field="email"></p-sortIcon>
          </th>
          <th
          pSortableColumn="business_activity.name"
            class=" bg-primary "

          >
         Usuario
          <p-sortIcon field="business_activity.name"></p-sortIcon>
          </th>

          <th   class=" bg-primary " pSortableColumn="contact_name">
          Fecha
            <p-sortIcon field="contact_name"></p-sortIcon>
          </th>
          <th   class=" bg-primary " pSortableColumn="contact_name">
            Descripción
              <p-sortIcon field="contact_name"></p-sortIcon>
            </th>


          <th  class=" bg-primary " pSortableColumn="is_active">
            Estatus<p-sortIcon field="is_active"></p-sortIcon>
          </th>

        </tr>

      </ng-template>
      <ng-template pTemplate="body" let-item>
        <tr class="text-sm">
          <td class=" ">

            <div class="dropdow d">
              <p  class="fw-bold  dropdown-toggle text-primary" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                {{item.folio}}
              </p>
              <ul class="dropdown-menu">
                <li  *appHasPermission="permissionsService.PERMISSIONS_ADD.MIP"><a (click)="AddUpdate(item,'Cliente')"  class="dropdown-item hov-pointer text-primary fw-bold" ><i class="fas fa-edit me-2"></i>Editar</a></li>
                <li  *appHasPermission="permissionsService.PERMISSIONS_ADD.MIP"><a (click)="ViewSucursales(item)"  class="dropdown-item hov-pointer text-orange-700 fw-bold" ><i class="fas fa-comments me-2"></i>Comentarios</a></li>
               <li  *appHasPermission="permissionsService.PERMISSIONS_ADD.MIP"><a (click)="deleteTask(item)" class="dropdown-item hov-pointer text-danger fw-bold" ><i class="fas fa-trash-alt me-2"></i>Eliminar</a></li>
              </ul>
            </div>

          </td>
          <td  >{{ item.customer.name }}</td>
          <td>{{ item.action_plan.folio }}</td>
          <td>{{ item.task_type.name }}</td>
          <td>{{ item.title }}</td>
          <td>
            {{ item.user?.name }}
          </td>
          <td>
            {{ item.initial_date }}  / {{ item.final_date }}

          </td>
          <td>
            {{ item.description }}

          </td>
          <td>

            <div class="dropdow de" >
              <span


              *ngIf="item.status.key_string == 'task-inprogress'"
              class="status-yellow hov-pointer de dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false"
              >{{ item.status.name }}</span
            >
            <span

              *ngIf="item.status.key_string == 'task-done'"
              class="status-green hov-pointer de  dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false"
              >{{ item.status.name }}</span
            >
            <span
          *ngIf="item.status.key_string == 'task-todo'"
              class="status-blue hov-pointer  de dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false"
              >{{ item.status.name }}</span
            >
              <ul  *appHasPermission="permissionsService.PERMISSIONS_ADD.MIP" class="dropdown-menu d">
                <li><a (click)="updateTask('ce17899d-beb1-402a-83b6-11cbc487af09',item)"  class="dropdown-item hov-pointer text-success fw-bold" ><i class="fas fa-check me-2"></i>Terminada</a></li>

                <li><a (click)="updateTask('ca926a12-a497-485c-9f63-151373a41c7a',item)" class="dropdown-item hov-pointer text-orange-700 fw-bold" ><i class="fas fa-tasks me-2"></i>En Progreso</a></li>


                <li><a (click)="updateTask('58f06601-267e-471e-b27e-98823f31750a',item)" class="dropdown-item hov-pointer text-primary fw-bold" ><i class="fas fa-pause me-2"></i>Por hacer</a></li>

              </ul>
            </div>




          </td>

        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="15">no hay datos.</td>
        </tr>
      </ng-template>
    </p-table>
    <p-paginator
    [first]="dataForm.value.first"
    [rows]="dataForm.value.rows"
    [rowsPerPageOptions]="[10, 20, 30, 50]"
    [totalRecords]="dataForm.value.totalRecords"
    (onPageChange)="onPageChange($event)"
    currentPageReportTemplate="{currentPage} de {totalPages}"
    [showJumpToPageDropdown]="true"
    [showPageLinks]="true"
    [showJumpToPageInput]="true"
  ></p-paginator> </div>






