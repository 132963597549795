<form (ngSubmit)="accept()" [formGroup]="Form">
  <div class="grid formgrid  mt-4 ">
    <div class="col-12 mb-4 lg:col-6">
      <span class="w-100 p-fluid p-float-label p p-input-icon-left">
        <i class="fa-solid fa-pencil"></i>
        <input formControlName="title" type="text" pInputText placeholder="Titulo" />
        <label for="title">Titulo</label>
      </span>
    </div>

    <div class="col-12 mb-4 lg:col-6">
      <app-select [Catalog]="Catalog.TASK_TYPES" field="name" [isDropdown]="true" type="p-autocomplete"
        [isMultiple]="false" label="Tipo de tarea" formControlName="task_type"></app-select>
    </div>

    <div class="col-12 mb-4 lg:col-6">
      <app-select [UrlCatalog]="URL_CATALOGS.NESTING_AREAS" field="name" [isDropdown]="false" type="select-generic"
        [isMultiple]="true" label="Area" formControlName="nesting_area">
      </app-select>
    </div>

    <div class="col-12 mb-4 lg:col-6">
      <app-select [Catalog]="Catalog.USERS" field="name" [isDropdown]="true" type="p-autocomplete-administrative"
        [isMultiple]="false" label="Usuario" formControlName="user"></app-select>
    </div>

    <div class="col-12 mb-4">
      <span class="w-100 p-fluid p-input-icon-left p-float-label">
        <p-calendar formControlName="range_date" selectionMode="range" [readonlyInput]="true" appendTo="body"
          (onSelect)="searchDate()"></p-calendar>
        <label>Rango de fecha</label>
      </span>
    </div>

    <div class="col-12 mb-4">
      <span class="p-fluid">
        <textarea formControlName="description" placeholder="Descripción" rows="3" cols="30" pInputTextarea></textarea>
      </span>
    </div>
  </div>

  <div class="d-flex justify-content-center mt-4">
    <button pButton pRipple type="submit" [disabled]="Form.invalid" label="Guardar"
      class="p-button-raised me-2 btn-sm"></button>

    <button (click)="cancel()" pButton pRipple type="button" label="Cancelar"
      class="p-button-raised p-button-secondary btn-sm"></button>
  </div>
</form>
