import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor() { }


isString(value:any):boolean{
    return typeof value === 'string';
}
isObject(value:any):boolean{
  return typeof value === 'object';
}

objectToFormData(obj: any): FormData {
    const formData = new FormData();
    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
            const value = obj[key];
            formData.append(key, value);
        }
    }
    return formData;
}

objectToFormDataArray(obj: any, formData = new FormData(), parentKey = ''): FormData {
  for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
          const value = obj[key];
          const formKey = parentKey ? `${parentKey}[${key}]` : key;

          if (value instanceof File) {
              formData.append(formKey, value);
          } else if (value instanceof Array) {
              value.forEach((element, index) => {
                  this.objectToFormDataArray({ [`${formKey}[${index}]`]: element }, formData);
              });
          } else if (value !== null && typeof value === 'object') {
              this.objectToFormDataArray(value, formData, formKey);
          } else if (value !== undefined) {
              formData.append(formKey, value);
          }
      }
  }
  return formData;
}

}
