export enum MIP_FORM{
INSPECTION_FORM='inspection-forms',
CONDITION_FORM='condition-forms',
STATION_COUNT='station-count',
STATION_COUNT_DATA='station-count-data',
AREAS_ACTIVITY='areas-activity',
CONTROL_FORMS_PRODUCTS='control-forms-products',
CONTROL_FORMS='control-forms',
ACTION_PLANS='action-plans'
}



