import { Component, OnInit } from '@angular/core';
import { ConfirmationService, PrimeNGConfig } from 'primeng/api';
import { MessagesService } from './system/services/message.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  constructor(private primengConfig: PrimeNGConfig, public MessagesService: MessagesService,private confirmationService: ConfirmationService) { }

  ngOnInit(): void {

    this.primengConfig.ripple = true;
    
  }

  closeDialog() {
    this.confirmationService.close();
  }
}
