<div class="layout-topbar">
  <!--
    <a class="app-logo" routerLink="/">
      <img alt="app logo"  width="30" [src]="getPicture()">
    </a>
  -->

  <a class="" routerLink="/">
    <h2 class="text-white fw-bold">
      PestForceApp
    </h2>
  </a>

  <button #menubutton class="topbar-menubutton p-link" type="button" (click)="onMenuButtonClick()">
    <span></span>
  </button>

  <ul class="topbar-menu">
    <li *ngFor="let item of tabs; let i = index">
      <a [routerLink]="item.routerLink" routerLinkActive="active-route"
        [routerLinkActiveOptions]="item.routerLinkActiveOptions||{paths: 'exact', queryParams: 'ignored', fragment: 'ignored', matrixParams: 'ignored'}"
        [fragment]="item.fragment" [queryParamsHandling]="item.queryParamsHandling"
        [preserveFragment]="item.preserveFragment!" [skipLocationChange]="item.skipLocationChange!"
        [replaceUrl]="item.replaceUrl!" [state]="item.state" [queryParams]="item.queryParams">
        <span>{{item.label}}</span>
      </a>

      <i class="pi pi-times" (click)="removeTab($event, item, i)"></i>
    </li>
  </ul>


  <div class="dropdown">
    <button (click)="getJobCenters()" class="text-white fw-bold dropdown-toggle p-link p-2" type="button"
      id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
      {{employee.job_center.name}}
    </button>

    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
      <!-- Mostrar indicador de carga -->
      <li *ngIf="isLoading">
        <a class="dropdown-item text-muted">Cargando...</a>
      </li>

      <!-- Mostrar los job centers cuando estén cargados -->
      <li *ngFor="let jobcenter of jobCenters">
        <a (click)="changeJobCenter(jobcenter)" class="dropdown-item hov-pointer">{{jobcenter.name}}</a>
      </li>
    </ul>
  </div>

  <div class="topbar-profile">
    <button class="topbar-profile-button p-link" type="button" pStyleClass="@next" enterClass="hidden"
      enterActiveClass="scalein" leaveToClass="hidden" leaveActiveClass="fadeout" [hideOnOutsideClick]="true">
      <img alt="avatar" [src]="getPicture()">

      <span class="profile-details">
        <span class="profile-name">{{employee.name}}</span>
        <!--  <span class="profile-job">{{employee.job_center.name}}</span> -->
      </span>

      <i class="pi pi-angle-down"></i>
    </button>

    <ul
      class="list-none p-3 m-0 border-round shadow-2 hidden absolute surface-overlay origin-top w-full sm:w-12rem mt-2 right-0 top-auto">
      <li>
        <a (click)="viewProfile()" pRipple
          class="flex p-2 border-round align-items-center hover:surface-hover transition-colors transition-duration-150 cursor-pointer">
          <i class="pi pi-user mr-3"></i>
          <span>Perfil</span>
        </a>

        <a (click)="AuthService.logout()" pRipple
          class="flex p-2 border-round align-items-center hover:surface-hover transition-colors transition-duration-150 cursor-pointer">
          <i class="pi pi-power-off mr-3"></i>
          <span>Cerrar sesión</span>
        </a>
      </li>
    </ul>
  </div>
</div>