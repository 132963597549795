<div class="container">

  <div class="grid formgrid pb-4">

    <div class="col-12 mt-2 lg:col-12 lg:mb-0 mb-4 card border-left">
      <p-table
      [scrollable]="true" scrollHeight="400px"
        class="custom-table"
        styleClass="p-datatable-sm"
        scrollDirection="horizontal"
        #dtCalendar
        [value]="Payments"
        dataKey="id"
        sortMode="multiple"
        [rows]="10"
        [showCurrentPageReport]="true"
        [rowsPerPageOptions]="[10, 25, 50]"
        [loading]="loading"
        [paginator]="false"
        [globalFilterFields]="['name']"
      >
        <ng-template pTemplate="header">
          <tr>
            <th
              pSortableColumn="name"
              class="white-space-nowrap bg-primary text-center"
              style="width: 25%"
            >
              Monto
            </th>
            <th
              class="white-space-nowrap bg-primary text-center"
              style="width: 25%"
            >
              Método de pago
            </th>
            <th
              class="white-space-nowrap bg-primary text-center"
              style="width: 25%"
            >
              Forma de pago
            </th>

            <th
            class="white-space-nowrap bg-primary text-center"
            style="width: 25%"
          >
            Status
          </th>

            <th
              class="white-space-nowrap bg-primary text-center d-none"
              style="width: 25%"
            >
              Foto
            </th>
            <th
              class="white-space-nowrap bg-primary text-center"
              style="width: 25%"
            >
              Acciones
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-item>
          <tr class="text-sm">
            <td class="text-center">
              {{ item.amount | currency }}
            </td>
            <td class="text-center">
              {{ item.payment_method?.name }}
            </td>
            <td class="text-center">
              {{ item?.payment_way.name }}
            </td>
            <td class="text-center d-none">
              {{ item?.not_paid ? 'Pagado':'No pagó'              }}
            </td>
            <td class="text-center">
              <img
                width="35px"
                *ngIf="item.photo"
                [src]="item.photo + '?' + randomValue"
                alt="photo"
              />
            </td>
            <td class="text-center">
              <p-button
              *appHasPermission="permissionsService.PERMISSIONS_ADD.MIP"
                (click)="deleteItem(item)"
                type="button"
                icon="fa-solid fa-trash"
                styleClass="p-button-rounded p-button-outlined p-button-danger p-button-sm ml-2 btn-sm"
              >
              </p-button>
              <p-button
              *appHasPermission="permissionsService.PERMISSIONS_ADD.MIP"
                (click)="AddUpdate(item)"
                type="button"
                icon="fa-solid fa-edit"
                styleClass="p-button-rounded p-button-outlined p-button-info ms-2 btn-sm "
              >
              </p-button>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="7">no hay datos.</td>
          </tr>
        </ng-template>
      </p-table>
    </div>

  </div>
</div>
