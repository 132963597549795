<div class="floating-cart-button" *appHasPermission="permissionsService.PERMISSIONS_READ.LEADFORCE">
  <button class="cart-button" (click)="openMenu()">
    <i class="pi pi-bolt" style="font-size: 1.5rem"></i>
    <span class="cart-count">{{ count }}</span>
  </button>

  <div class="float-dropdown-menu" [ngClass]="{ 'open': isMenuOpen }">
    <ul class="float-dropdown-list">
      <li *ngFor="let agent of agents" (click)="openAgent(agent)" class="float-dropdown-item">
        <i class="pi pi-circle-on text-red-500" *ngIf="agent.hasNotifications"></i>
        <i class="pi pi-circle-off text-gray-500" *ngIf="!agent.hasNotifications"></i>

        <span>{{ agent.name }}</span>
      </li>
    </ul>
  </div>
</div>