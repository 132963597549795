import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';
import { LocalStorageService } from './local-storage-service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class MipFormsService {
  public CustomerIdMip = "";
  public Event: any;
  private baseUrl: string = environment.baseUrl

  constructor(
    private http: HttpClient,
    private UserService: UserService,
    private localStorageService: LocalStorageService
  ) { }

  getDataForms(eventId: string) {
    return this.http.get<any>(`${this.baseUrl}/events/${eventId}/get_event`)
  }

  getProductsForm(controlForm: string) {
    let params = new HttpParams();
    params = params.append('ControlForm', controlForm);

    return this.http.get<any>(`${this.baseUrl}/mip/control-forms-products`, { params })
  }

  getDataForm(idEvent: string, formRoute: string) {
    let params = new HttpParams();
    params = params.append('event', idEvent);

    return this.http.get<any>(`${this.baseUrl}/mip/${formRoute}`, { params })
  }

  saveDataForm(data: any, formRoute: string) {
    return this.http.post<any>(`${this.baseUrl}/mip/${formRoute}/`, data);
  }

  saveTaskForm(data: any) {
    return this.http.post<any>(`${this.baseUrl}/tasks/tasks/`, data);
  }

  putTaskForm(data: any) {
    return this.http.put<any>(`${this.baseUrl}/tasks/tasks/${data.id}/`, data);
  }

  putDataForm(data: any, formRoute: string) {
    return this.http.put<any>(`${this.baseUrl}/mip/${formRoute}/${data.id}/`, data);
  }
  
  putDataFormData(data: any, id: string, formRoute: string) {
    return this.http.put<any>(`${this.baseUrl}/mip/${formRoute}/${id}/`, data);
  }

  patchDataForm(data: any, formRoute: string, idRegister: string) {
    return this.http.patch<any>(`${this.baseUrl}/mip/${formRoute}/${idRegister}/`, data);
  }

  finishServiceMip(data: any, idEvent: string) {
    return this.http.patch<any>(`${this.baseUrl}/events/${idEvent}/completed/`, data);
  }

  putImgForm(data: any, formRoute: string, idRegister: string) {
    return this.http.put<any>(`${this.baseUrl}/mip/${formRoute}/${idRegister}`, data);
  }

  deleteDataForm(idRegister: string, formRoute: string) {
    return this.http.delete<any>(`${this.baseUrl}/mip/${formRoute}/${idRegister}/`)
  }

  getDetailFormInfo(idRegister: string) {
    return this.http.get<any>(`${this.baseUrl}/mip/inspection-forms-data/${idRegister}`);
  }

  getEvidencesInspectionForm(inspection_form: string) {
    let params = new HttpParams();
    params = params.append('inspection_form', inspection_form);

    return this.http.get<any>(`${this.baseUrl}/mip/inspection-forms-evidence`, { params })
  }

  getCleaningsConditionForm(condition_form: string) {
    let params = new HttpParams();
    params = params.append('condition', condition_form);

    return this.http.get<any>(`${this.baseUrl}/mip/condition-forms-cleaning`, { params })
  }

  getTasksForm(action_plan: string) {
    let params = new HttpParams();
    params = params.append('action_plan', action_plan);

    return this.http.get<any>(`${this.baseUrl}/tasks/tasks/`, { params })
  }

  getStationCountActivity(station_count: string) {
    let params = new HttpParams();
    params = params.append('station_count', station_count);

    return this.http.get<any>(`${this.baseUrl}/mip/station-count-station-activity`, { params })
  }

  getPhotosMipCarousel(keyForm: string, valueId: string, routeForm: string) {
    let params = new HttpParams();
    params = params.append(keyForm, valueId);

    return this.http.get<any>(`${this.baseUrl}${routeForm}`, { params })
  }

  getPhotosStations(valueId: string, routeForm: string) {
    let params = new HttpParams();
    params = params.append('station__event', valueId);

    return this.http.get<any>(`${this.baseUrl}${routeForm}`, { params })
  }

  patchPhotoCarousel(data: any, photoId: string, routeForm: string) {
    return this.http.patch<any>(`${this.baseUrl}${routeForm}/${photoId}/`, data);
  }

  patchTask(data: any, taskId: string) {
    return this.http.patch<any>(`${this.baseUrl}/tasks/tasks/${taskId}/`, data);
  }

  getPhotosInspectionForm(inspection_form: string) {
    let params = new HttpParams();
    params = params.append('inspection_form', inspection_form);

    return this.http.get<any>(`${this.baseUrl}/mip/inspection-forms-photo`, { params })
  }

  getInspectionData(inspection_form: string) {
    let params = new HttpParams();
    params = params.append('inspection_form', inspection_form);

    return this.http.get<any>(`${this.baseUrl}/mip/inspection-forms-data`, { params })
  }

  deleteEvidence(idEvidence: string) {
    return this.http.delete<any>(`${this.baseUrl}/mip/inspection-forms-evidence/${idEvidence}/`)
  }

  deletePhotoInspection(idPhoto: string) {
    return this.http.delete<any>(`${this.baseUrl}/mip/inspection-forms-photo/${idPhoto}/`)
  }

  deleteInspection(idInspection: string) {
    return this.http.delete<any>(`${this.baseUrl}/mip/inspection-forms/${idInspection}/`)
  }

  deleteStationCount(idStation: string) {
    return this.http.delete<any>(`${this.baseUrl}/mip/station-count-data/${idStation}/`)
  }

  deleteAreaActivity(idArea: string) {
    return this.http.delete<any>(`${this.baseUrl}/mip/areas-activity/${idArea}/`)
  }

  deleteImageMipForm(idPhoto: string, route: string) {
    return this.http.delete<any>(`${this.baseUrl}${route}/${idPhoto}/`)
  }

  deleteStation(idStation: string) {
    return this.http.delete<any>(`${this.baseUrl}/mip/station-count/${idStation}/`)
  }

  deleteCondition(idCondition: string) {
    return this.http.delete<any>(`${this.baseUrl}/mip/condition-forms-cleaning/${idCondition}/`)
  }

  deleteTask(idTask: string) {
    return this.http.delete<any>(`${this.baseUrl}/tasks/tasks/${idTask}/`)
  }

  deleteActivityStation(idActivity: string) {
    return this.http.delete<any>(`${this.baseUrl}/mip/station-count-station-activity/${idActivity}/`)
  }

  deleteProduct(idProduct: string) {
    return this.http.delete<any>(`${this.baseUrl}/mip/control-forms-products/${idProduct}/`)
  }

  createEvent(data: any) {
    return this.http.post<any>(`${this.baseUrl}/events/`, data);
  }

  updateEvent(data: any) {
    return this.http.put<any>(`${this.baseUrl}/events/${data.id}`, data);
  }

  getViewQuoteById(id: string) {
    return this.http.get<any>(`${this.baseUrl}/quotes/${id}/pdf/`)
  }

  aproveQuote(id: string) {
    return this.http.patch<any>(`${this.baseUrl}/quotes/${id}/approved`, {});
  }

  cancelQuote(id: string) {
    return this.http.patch<any>(`${this.baseUrl}/quotes/${id}/cancelled`, {});
  }

  cancelEvent(data: any) {
    return this.http.patch<any>(`${this.baseUrl}/events/${data.id}`, data);
  }

  deleteQuote(id: string) {
    return this.http.delete<any>(`${this.baseUrl}/quotes/${id}/`)
  }

  deleteFolloUpQuote(idFollow: string) {
    return this.http.delete<any>(`${this.baseUrl}/quotes-tracing/${idFollow}/`)
  }

  deleteUserPortalCustomer(idItem: string) {
    return this.http.delete<any>(`${this.baseUrl}/customers-portal-accounts/${idItem}/`)
  }

  getCalendarByCustomer(idItem: string) {
    return this.http.get<any>(`${this.baseUrl}/customers/${idItem}/pdf_calendar`)
  }

  changePasswordUserPortal(data: any) {
    return this.http.post<any>(`${this.baseUrl}/customers-portal-accounts/${data.id}`, data);
  }

  sendCredentials(data: any) {
    return this.http.post<any>(`${this.baseUrl}/customers-portal-accounts/${data.id}/send_credentials`, data);
  }

  saveUserPortalCustomer(data: any) {
    return this.http.post<any>(`${this.baseUrl}/customers-portal-accounts/`, data);
  }

  changePasswordPortalCustomer(data: any) {
    return this.http.post<any>(`${this.baseUrl}/customers-portal-accounts/`, data);
  }

  saveDocumentCustomer(data: any) {
    return this.http.post<any>(`${this.baseUrl}/customer-documents/${data.id}`, data);
  }

  patchDocumentCustomer(data: any, idDocument: string) {
    return this.http.patch<any>(`${this.baseUrl}/customer-documents/${idDocument}/`, data);
  }

  putCustomer(data: any) {
    return this.http.put<any>(`${this.baseUrl}/customers/${data.id}`, data);
  }

  putUserPortalCustomer(data: any) {
    return this.http.put<any>(`${this.baseUrl}/customers-portal-accounts/${data.id}`, data);
  }

  deleteDocumentCustomer(idItem: string) {
    return this.http.delete<any>(`${this.baseUrl}/customer-documents/${idItem}/`)
  }
}
