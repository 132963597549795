

 <div *ngIf="Photos.length > 0 ">
  <h5 class="text-center mb-0">Imagenes cargadas</h5>
  <p-carousel [value]="Photos" [numVisible]="1" [numScroll]="1" [responsiveOptions]="responsiveOptions">
    <ng-template let-photo pTemplate="item">
        <div class="border-1 surface-border border-round m-2 text-center ">
            <div class="mb-3 mx-3 mt-2" >
              <app-upload  (uploadFile)="uploadFile($event)" [ImageString] ="photo.photo"></app-upload>
            </div>
            <div>



                    <div *ngIf="ShowNestingArea" class="col-12 mb-2  lg:col-12 lg:mb-0">
                      <app-select (onSelect)="GetArea($event,photo)" [area]="photo.nesting_area" [customer]="MipFormsService.CustomerIdMip" [Catalog]="Catalog.NESTING_AREAS" field ="name" [isDropdown] = "true" type="pest-control-ngModel" [isMultiple] = "false" label="Area" ></app-select>
                  </div>

                    <div  *ngIf="ShowComments" class="col-12  mt-1 mb-2 lg:col-12 lg:mb-0">
                      <span class="p-fluid mt-5">
                          <textarea  [(ngModel)]="photo.comments" placeholder="Comentarios" rows="3"  pInputTextarea></textarea>
                      </span>
                  </div>
                    <div class="d-flex justify-content-center mb-2">

                      <p-button
                        (click)="UpdateImage(photo)"
                        type="button"
                        icon="fa-solid fa-save"
                        styleClass="p-button-rounded p-button-outlined p-button-info ms-2 btn-sm "
                      >
                      </p-button>
                      <p-button
                      (click)="deleteImage(photo)"
                      type="button"
                      icon="fa-solid fa-trash"
                      styleClass="p-button-rounded p-button-outlined p-button-danger p-button-sm ml-2 btn-sm"
                      >
                      </p-button>
                  </div>



            </div>
        </div>
    </ng-template>
</p-carousel>

 </div>

 <div *ngIf="Photos.length == 0">
  <h5 class=" mb-0">No tienes fotos cargadas</h5>
</div>
