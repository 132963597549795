import { Component, Input } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { TYPE_TOAST } from 'src/app/system/core/enums/ToastsEnums';
import { CatalogsEnum } from 'src/app/system/core/enums/catalogs.model';
import { AreasControlService } from 'src/app/system/services/areasControlService';
import { CatalogService } from 'src/app/system/services/catalogs.service';
import { CustomerService } from 'src/app/system/services/customer.service';
import { MessagesService } from 'src/app/system/services/message.service';
import { UserService } from 'src/app/system/services/user.service';
import { AddUpdatePerimeterComponent } from './components/add-update-perimeter/add-update-perimeter.component';

@Component({
  selector: 'app-table-perimeters',
  templateUrl: './table-perimeters.component.html',
  styleUrls: ['./table-perimeters.component.scss'],
})
export class TablePerimetersComponent {
  @Input() idRegister: string = '';
  loading: boolean = false;
  dataPaginate: any;
  dataItems!: any[];
  constructor(
    private MessagesService: MessagesService,
    private CustomerService: CustomerService,
    private DialogService: DialogService,
    private AreasControlService: AreasControlService,
    private UserService: UserService,
    private CatalogService: CatalogService
  ) {
    this.dataPaginate = {
      page_size: 10,
      first: 0,
      page: 0,
      rows: 10,
      totalRecords: 0,
      search: '',
      job_center: this.UserService.JobCenter,
    };
  }
  ngOnInit(): void {
    // this.updateEventSharedService();
    this.loadDataPerimetersControl();
  }

  loadDataPerimetersControl(): void {
    this.loading = true;
    this.AreasControlService.getPerimeters(
      this.dataPaginate,
      this.idRegister
    ).subscribe((response) => {
      this.dataItems = response.items;
      this.dataPaginate.page = response.page;
      this.dataPaginate.totalRecords = response.total;
      this.loading = false;
    });
  }

  onPageChange(event: any) {
    this.dataPaginate.loading = true;
    this.dataPaginate.first = event.first;
    this.dataPaginate.page_size = event.rows;
    this.dataPaginate.pageCount = event.pageCount;
    this.dataPaginate.page = event.page + 1;
    this.loadDataPerimetersControl();
  }

  /**
   * Event Search Global
   * @param $event
   */
  getEventValue($event: any): void {
    this.dataPaginate.search = $event.target.value;
    this.loading = true;
    this.loadDataPerimetersControl();
  }


  AddUpdate(item: any) {
    const dialog = this.DialogService.open(AddUpdatePerimeterComponent, {
      header: 'Perimetro',
      modal: true,
      width: '25rem',
      //height:"100%",
      closable: true,
      draggable: false,
      maximizable: false,
      resizable: true,
      closeOnEscape: true,
      dismissableMask: true,
      data: {
        item,
        idRegister: this.idRegister,
      },
    });
    dialog.onClose.subscribe((res) => {
      if (res?.success) {
        this.loadDataPerimetersControl();
      }
    });
  }

  deleteItem(item: any) {
    this.MessagesService.confirm(
      '¿Esta seguro de eliminar el registro ' + item.name + ' ?',
      () => {
        this.AreasControlService.deletePerimeterPestcontrol(
          CatalogsEnum.ZONES,
          item.id
        ).subscribe((res) => {
          this.MessagesService.ShowMessage(
            TYPE_TOAST.SUCCESS,
            'Correcto',
            'Eliminado correctamente'
          );
          this.loadDataPerimetersControl();
        });
      }
    );
  }



}
