import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';

import { Component, OnInit, EventEmitter, forwardRef, inject } from '@angular/core';
import { EventService } from 'src/app/demo/service/event.service';
import { DialogService } from 'primeng/dynamicdialog';
import { DetailEventComponent } from '../../components/detail-event/detail-event.component';
import { CalendarService } from 'src/app/system/services/calendar.service';
import { FiltersCalendarComponent } from '../../components/filters-calendar/filters-calendar.component';
import { CatalogsEnum, URL_ENDPOINTS } from 'src/app/system/core/enums/catalogs.model';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CreateUpdateEventFormComponent } from '../../components/create-update-event-form/create-update-event-form.component';
import { PermissionsServices } from 'src/app/system/services/permissions.service';

@Component({
  selector: 'app-events-calendar',
  templateUrl: './events-calendar.component.html',
  styleUrls: ['./events-calendar.component.scss'],
})
export class EventsCalendarComponent implements OnInit {
  index: number = 0;
  Catalog = CatalogsEnum;
  groupSearch = '';
  eventCustomer: string = ""
  FormGroup: FormGroup = this.FormBuilder.group({
        group:[null],
        event_type: [null],
        employee:[null],
        customer:[null],
        quote:[null],
        service_type:[null],

  });
  public permissionsService = inject(PermissionsServices);
  constructor(
    private DialogService: DialogService,
      private FormBuilder:FormBuilder,
    private CalendarService: CalendarService
  ) {}
  ngOnInit(): void {
    if(localStorage.getItem("eventCustomer")){
      this.eventCustomer = localStorage.getItem("eventCustomer") || ""
      this.addEvent()
    }
  }
  handleChange(event: any) {
    this.index = event.index;
  }

  openFilters() {
    const dialog = this.DialogService.open(FiltersCalendarComponent, {
      header: 'Filtrar calendario',
      modal: true,
      width: '30rem',
      //height:"100%",
      closable: true,
      draggable: false,
      // maximizable: true,
      resizable: true,
      closeOnEscape: true,
      dismissableMask: true,
    });
    dialog.onClose.subscribe((res) => {});
  }

  URL_CATALOGS = URL_ENDPOINTS;

  resetFilters() {
    this.FormGroup.reset();
    this.CalendarService.FilterTable(null);
  }

  filterByGroup(e: any) {
    this.CalendarService.FilterTable(this.FormGroup.value);
  }

  addEvent(){
  
if(this.permissionsService.hasPermission("events","add")){
  const dialog = this.DialogService.open(CreateUpdateEventFormComponent, {
    header: 'Nuevo evento',
    modal: true,
    width: '50%',
    //height:"100%",
    closable: true,
    draggable: false,
    // maximizable: true,
    resizable: true,
    closeOnEscape: true,
    dismissableMask: true,
    data: {
      Event: null,
    },
  });
  dialog.onClose.subscribe((res) => {
    if (res?.success) {
      this.CalendarService.FilterTable({});
    }
  });
}
  
  }
}
