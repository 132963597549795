import { MipFormsService } from 'src/app/system/services/MipForms.service';

import { Component, Injector, ViewChild, ViewContainerRef } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { LoadingService } from 'src/app/system/services/loading.service';
import { InspectionComponent } from './inspection/inspection.component';
import { ConditionComponent } from './condition/condition.component';
import { StationComponent } from './station/station.component';
import { ControlComponent } from './control/control.component';
import { ActionPlanComponent } from './action-plan/action-plan.component';
import { TaskComponent } from './task/task.component';
import { PaymentComponent } from './payment/payment.component';
import { SignatureComponent } from './signature/signature.component';
import { FinishComponent } from './finish/finish.component';
import { AreaComponent } from './area/area.component';
interface FormItem {
  index: number;
  title: string;
  key: string;
  show: boolean;
  Component: any; // Ajusta este tipo según el tipo correcto de tu componente
  Event?: any;   // Ajusta este tipo según el tipo correcto de tu evento
}
export abstract class BasicProject {
  constructor(public event: any) {}
}
export class Project extends BasicProject {
}
@Component({
  selector: 'app-mip-forms',
  templateUrl: './mip-forms.component.html',
  styleUrls: ['./mip-forms.component.scss']
})
export class MipFormsComponent {
    // Crear un Injector básico
componentInjector!: Injector;
forms:FormItem[]=[
  {index:0,  title:'Inspección del lugar', key:"mip_inspection_form", show:false, Component:InspectionComponent, Event:{}},
  {index:0, title:'Condición del lugar', key:"mip_condition_form", show:false, Component:ConditionComponent, Event:{}},
  {index:0,  title:'Monitoreo de estaciones',key:"mip_station_count_form", show:false, Component:StationComponent, Event:{}},
  {index:0, title:'Monitoreo de áreas', key:"mip_AreasActivity", show:false,Component:AreaComponent, Event:{}},
  {index:0, title:'Control de plagas', key:"mip_control_form", show:false,Component:ControlComponent, Event:{}},


  {index:0, title:'Plan de acción',key:"mip_plan_action_form", show:false,Component:ActionPlanComponent, Event:{}},
 {index:0,  title:'Tareas',key:"mip_task_form", show:false,Component:TaskComponent, Event:{}},
  {index:0, title:'Pago del servicio',key:"mip_payment_form", show:false,Component:PaymentComponent, Event:{}},
  {index:0, title:'Firma del cliente', key:"mip_signature_form", show:false,Component:SignatureComponent, Event:{}},

  {index:0, title:"Finalizar", key:"finalizar", show:true,Component:FinishComponent,Event:{}},
]
@ViewChild('finishComponent', { read: ViewContainerRef }) finishComponent!: ViewContainerRef;

Event:any;
Customer:any;
  index : number = 0;
  constructor(
    private DialogService: DialogService,
    private LoadingService: LoadingService,
    public config: DynamicDialogConfig,
    public ref: DynamicDialogRef,
    private injector: Injector,
    public MipFormsService:MipFormsService

  ) {

  }
  initializeComponentInjector() {
    this.MipFormsService.CustomerIdMip = this.Event.customer.id;
    this.MipFormsService.Event = this.Event;
    this.Customer = this.Event.customer;


    // Crear un Injector con el valor actualizado de Event
    this.componentInjector = Injector.create({
      providers: [{ provide: 'dynamicData', useValue: { ...this.Event } }],
      parent: this.injector
    });
  }
  ngOnInit(): void {
        this.Event = this.config.data.Event;


        this.showForms();
        this.initializeComponentInjector();
  }

  handleChange(event: any) {
    this.index = event.index;
  }

  showForms(){
    console.log(this.Event.event_type);

    this.forms.forEach((form) => {
      const formName = form.key;


      // Verificar si el nombre del formulario existe en el objeto
      if (formName in this.Event.event_type) {
        // Actualizar el valor show basándote en el objeto
        form.show = this.Event.event_type[formName];
        form.Event = this.Event
      }

    });
// Filtrar el arreglo para obtener solo los elementos con show=true
this.forms = this.forms.filter((form) => form.show).map((form, index) => ({ ...form, index }));


  }

  close(){
    this.ref?.close();
  }

  handleFinishClose() {
    this.close();
  }
}
