<app-loading></app-loading>

<p-toast key="toast" position="top-right"></p-toast>

<p-confirmDialog #cd [style]="{width: '350px'}">
  <ng-template pTemplate="header">
    <div class="d-flex justify-content-between items-center w-full">
      <h3 class="m-0">Confirmación</h3>
      <button 
        type="button" 
        class="p-dialog-header-icon p-link" 
        (click)="closeDialog()"
      >
        <span class="pi pi-times"></span>
      </button>
    </div>
  </ng-template>

  <ng-template pTemplate="footer">
    <p-button size="small" styleClass="btn-sm" (click)="cd.accept()" 
      icon="pi pi-check" [label]="MessagesService.labeBtnYes">
    </p-button>
    <p-button size="small" (click)="cd.reject()" icon="pi pi-times" 
      [label]="MessagesService.labelBtnNo" styleClass="p-button-danger btn-sm">
    </p-button>
  </ng-template>
</p-confirmDialog>


<router-outlet></router-outlet>