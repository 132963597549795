import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { TYPE_TOAST } from 'src/app/system/core/enums/ToastsEnums';
import { CustomerService } from 'src/app/system/services/customer.service';
import { DateService } from 'src/app/system/services/date.service';
import { LoadingService } from 'src/app/system/services/loading.service';
import { MessagesService } from 'src/app/system/services/message.service';
import { QuotesService } from 'src/app/system/services/quotes.service';
import { UserService } from 'src/app/system/services/user.service';
import { Quote } from '../../../../core/models/calendar.interface';

@Component({
  selector: 'app-send-quote',
  templateUrl: './send-quote.component.html',
  styleUrls: ['./send-quote.component.scss'],
})
export class SendQuoteComponent {
  index: number = 0;

  Event: any = null;

  FormEmail: FormGroup = this.FormBuilder.group({
    quoteId: '',
    customer_email: ['', [Validators.required]],
    bussines_email: [''],
  });

  FormWhats: FormGroup = this.FormBuilder.group({
    phone: ['', [Validators.required]],
  });

  constructor(
    private MessagesService: MessagesService,
    private FormBuilder: FormBuilder,
    private LoadingService: LoadingService,
    public config: DynamicDialogConfig,
    public ref: DynamicDialogRef,
    private QuotesService: QuotesService
  ) {}

  ngOnInit() {
    if (this.config.data.Quote) {
      this.FormEmail.patchValue({
        customer_email: this.config.data.Quote.customer.email,
        //  bussines_email: this.config.data.Quote.customer.email
      });

      this.FormWhats.patchValue({
        phone: this.config.data.Quote.customer.phone,
      });

      return;
    }

    this.Event = this.config.data.Event;
  }

  cancel() {
    this.ref?.close();
  }

  handleChange(event: any) {
    this.index = event.index;
  }

  sedQuoteEmail() {
if(this.config.data.evaluation){
  let data = {
    customer_email: this.FormEmail.value.customer_email.trim().split(','),
    eventId:this.Event.id
  }


  

  this.LoadingService.Show();
  this.QuotesService.sendEvaluation(data).subscribe((res: any) => {
    this.LoadingService.Hide();
    this.MessagesService.ShowMessage(
      TYPE_TOAST.SUCCESS,
      'Correcto',
      'Enviado correctamente'
    );
    this.ref?.close({ success: true });
  });
  return
}

    if(this.config.data.Quote){
      let data = {
        customer_email: this.FormEmail.value.customer_email.trim().split(','),
        quoteId: this.config.data.Quote.id,
      };
  
      
      this.LoadingService.Show();
      this.QuotesService.sendQuote(data).subscribe((res: any) => {
        this.LoadingService.Hide();
        this.MessagesService.ShowMessage(
          TYPE_TOAST.SUCCESS,
          'Correcto',
          'Enviado correctamente'
        );
        this.ref?.close({ success: true });
      });
      return
    }


    if(this.config.data.Referral){
      let data = {
        customer_email: this.FormEmail.value.customer_email.trim().split(','),
        id: this.config.data.Referral.id,
      };
  
      
      this.LoadingService.Show();
      this.QuotesService.sendReferral(data).subscribe((res: any) => {
        this.LoadingService.Hide();
        this.MessagesService.ShowMessage(
          TYPE_TOAST.SUCCESS,
          'Correcto',
          'Enviado correctamente'
        );
        this.ref?.close({ success: true });
      });
      return
    }

else{
  let data = {
    customer_email: this.FormEmail.value.customer_email.trim().split(','),
    eventId:this.Event.id
  }



  this.LoadingService.Show();
  this.QuotesService.sendEmail(data).subscribe((res: any) => {
    this.LoadingService.Hide();
    this.MessagesService.ShowMessage(
      TYPE_TOAST.SUCCESS,
      'Correcto',
      'Enviado correctamente'
    );
    this.ref?.close({ success: true });
  });
  return
}

  }

  sedQuoteWhats() {
    let phone = this.FormWhats.value.phone.replace(/\D/g, '');

    let data = {
      phone,
      quoteId: this.config.data.Quote.id,
    };

    this.LoadingService.Show();
    this.QuotesService.sendQuoteWhats(data).subscribe((res: any) => {
      this.LoadingService.Hide();
      this.MessagesService.ShowMessage(
        TYPE_TOAST.SUCCESS,
        'Correcto',
        'Enviado correctamente'
      );
      window.open(res.data, '_blank');
      this.ref?.close({ success: true });
    });
  }
}
