import { map } from 'rxjs';
import { TYPE_TOAST } from 'src/app/system/core/enums/ToastsEnums';
import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { CatalogsEnum } from 'src/app/system/core/enums/catalogs.model';
import { MipFormsService } from 'src/app/system/services/MipForms.service';
import { LoadingService } from 'src/app/system/services/loading.service';
import { MessagesService } from 'src/app/system/services/message.service';

@Component({
  selector: 'app-add-update-plagues',
  templateUrl: './add-update-plagues.component.html',
  styleUrls: ['./add-update-plagues.component.scss']
})
export class AddUpdatePlaguesComponent {

  Catalog = CatalogsEnum;
  Form: FormGroup = this.FormBuilder.group({
    plague: ['', [Validators.required]],
    life_cycle_plague: [[], [Validators.required]],
    infestation_degree: ['', [Validators.required]],
    nesting_area:[[],],
    evidence_type:[[], [Validators.required]],
    id: [''],
    inspection_form:['']

});

constructor(
  private FormBuilder: FormBuilder,
  private LoadingService: LoadingService,
  public config: DynamicDialogConfig,
  public ref: DynamicDialogRef,
  public MipFormsService:MipFormsService,
  private MessagesService: MessagesService,

) {

}
idPlague:string ="";
ngOnInit(): void {

this.idPlague =this.config.data.Inspection.plague_category.id;

  if (this.config.data.Plague) {
      this.patchForm();
  }
}

patchForm(){
  let item = this.config.data.Plague;



  this.Form.patchValue({
    plague:item.plague,
    life_cycle_plague:item.life_cycle_plague,
    id:item.id,
    infestation_degree:item.infestation_degree,
    nesting_area:item.nesting_area,
    evidence_type:item.evidence_type,

  });

}

accept() {

  this.LoadingService.Show();
 if(!this.config.data.Plague){

  this.save();
  return
 }
this.update();
}
cancel() {
  this.ref?.close();
}



  save(){
let nesting_area =  this.Form.value?.nesting_area.map((item:any)=>item.id);
let life_cycle_plague =  this.Form.value?.life_cycle_plague.map((item:any)=>item.id);
let evidence_type =  this.Form.value?.evidence_type.map((item:any)=>item.id);
let data = {
  plague:this.Form.value.plague.id,
  inspection_form:this.config.data.Inspection.id,
  evidence_type,
  infestation_degree:this.Form.value.infestation_degree.id,
  life_cycle_plague,
  nesting_area,
}



  this.MipFormsService.saveDataForm(data, 'inspection-forms-data').subscribe((res)=>{
      this.LoadingService.Hide();
      this.MessagesService.ShowMessage(
          TYPE_TOAST.SUCCESS,
          'Correcto',
          'Guardado correctamente'
      );
      this.ref?.close({ success: true });
    });

  }

  update(){

    let nesting_area =  this.Form.value?.nesting_area.map((item:any)=>item.id);
    let life_cycle_plague =  this.Form.value?.life_cycle_plague.map((item:any)=>item.id);
    let evidence_type =  this.Form.value?.evidence_type.map((item:any)=>item.id);
    let data = {
      plague:this.Form.value.plague.id,
      inspection_form:this.config.data.Inspection.id,
      evidence_type,
      infestation_degree:this.Form.value.infestation_degree.id,
      life_cycle_plague,
      nesting_area,
      id:this.Form.value.id,
    }




      this.MipFormsService.putDataForm(data, 'inspection-forms-data').subscribe((res)=>{
          this.LoadingService.Hide();
          this.MessagesService.ShowMessage(
              TYPE_TOAST.SUCCESS,
              'Correcto',
              'Guardado correctamente'
          );
          this.ref?.close({ success: true });
        });



  }
}
