import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { TYPE_TOAST } from 'src/app/system/core/enums/ToastsEnums';
import { CatalogsEnum } from 'src/app/system/core/enums/catalogs.model';
import { CalendarService } from 'src/app/system/services/calendar.service';
import { LoadingService } from 'src/app/system/services/loading.service';
import { MessagesService } from 'src/app/system/services/message.service';

@Component({
  selector: 'app-cancel-event',
  templateUrl: './cancel-event.component.html',
  styleUrls: ['./cancel-event.component.scss']
})
export class CancelEventComponent implements OnInit {
  Catalog = CatalogsEnum;
  Form: FormGroup = this.FormBuilder.group({
    id: '',
    rejection_reason: ['', Validators.required],
    rejection_reason_comment: ['', Validators.required],
  });

  constructor(
    private MessagesService: MessagesService,
    private FormBuilder: FormBuilder,
    private LoadingService: LoadingService,
    public config: DynamicDialogConfig,
    public ref: DynamicDialogRef,
    private CalendarService: CalendarService
  ) { }

  ngOnInit(): void {
    this.Form.patchValue({
      id: this.config.data.Event.id,
    })
  }

  cancelEvent() {
    let data = {
      "rejection_reason": this.Form.value.rejection_reason.id,
      "rejection_reason_comment": this.Form.value.rejection_reason_comment,
      id: this.Form.value.id,
    }

  
    if(this.config.data.Event.is_many){
      this.MessagesService.labeBtnYes = "Cancelar todos";
      this.MessagesService.labelBtnNo = "No, solo el actual";
     
      this.MessagesService.confirm('¿Desea Cancelar todos los eventos recurrentes que pertenecen a este evento?' ,
        () => {
          this.LoadingService.Show();
    
          this.CalendarService.cancelEventMassive(data).subscribe((res) => {
            this.LoadingService.Hide();
            this.ref?.close();
            this.MessagesService.ShowMessage(TYPE_TOAST.SUCCESS, "Eliminados correctamente", "");
            
            })
    
    
         
        },
    
        () =>{
          this.CalendarService.cancelEvent(data).subscribe((res) => {
            this.ref?.close({ success: true });
            this.LoadingService.Hide();
      
            this.MessagesService.ShowMessage(
              TYPE_TOAST.SUCCESS,
              'Correcto',
              'Cancelado correctamente'
            );
          });
        }
      );
    
      return
    }
    




    this.CalendarService.cancelEvent(data).subscribe((res) => {
      this.ref?.close({ success: true });
      this.LoadingService.Hide();

      this.MessagesService.ShowMessage(
        TYPE_TOAST.SUCCESS,
        'Correcto',
        'Cancelado correctamente'
      );
    });
  }
}
