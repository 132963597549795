
import { Injectable } from '@angular/core';
import {CanActivate, CanLoad, Router} from '@angular/router';
import {Observable, tap} from 'rxjs';
import { AuthService } from 'src/app/system/services/auth.service';


@Injectable({
  providedIn: 'root'
})
export class ValidateTokenGuard implements CanActivate, CanLoad {

  constructor(
    private authService: AuthService,
    private router: Router
  ) {}

  canActivate(): Observable<boolean> | boolean {
    //return true

    return this.authService.validarToken()
      .pipe(
        tap( valid =>{
          if (!valid) {
            localStorage.clear()
            this.router.navigateByUrl('/auth');
          }
        })
      )

  }

  canLoad(): Observable<boolean> | boolean {
    //return true

    return this.authService.validarToken()
        .pipe(
          tap( valid =>{
            if (!valid) {
              localStorage.clear()
              this.router.navigateByUrl('/auth');
            }
          })
        )

    }

}
