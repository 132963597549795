<p-fileUpload #fileUploader name="demo[]" url="./upload.php" (onUpload)="onUpload($event)" [customUpload]="true" [multiple]="true" (onSelect)="onUpload($event)" accept="image/*" [showUploadButton]="false" [showCancelButton]="false" [auto]="true" styleClass="border-1 surface-border surface-card p-0 border-round mb-4">
  <ng-template pTemplate="content">
      <div class="h-20rem m-1 border-round">
          <div *ngIf="!image" class="flex flex-column w-full h-full justify-content-center align-items-center cursor-pointer" (click)="fileUploader.advancedFileInput.nativeElement.click()">
              <i class="pi pi-fw pi-file text-4xl text-primary"></i>
              <span class="block font-semibold text-900 text-lg mt-3">{{textPhoto}}</span>
          </div>
          <div *ngIf="image" class="w-full h-full relative border-round p-0">
              <img [src]="image.objectURL ?? image" class="w-full h-full border-round" alt="blog cover">
              <button pButton pRipple type="button" icon="pi pi-times" class="p-button-rounded p-button-primary text-sm absolute justify-content-center align-items-center" style="top: -10px; right: -10px;" (click)="$event.stopPropagation(); removeImage()"></button>
          </div>
      </div>
  </ng-template>
</p-fileUpload>
