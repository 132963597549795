import { Component, Inject, inject } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { MIP_FORM } from 'src/app/system/core/enums/MipForms.model';
import { MipFormsService } from 'src/app/system/services/MipForms.service';
import { LoadingService } from 'src/app/system/services/loading.service';
import { MessagesService } from 'src/app/system/services/message.service';
import { AddUpdateAreaComponent } from '../add-update-area/add-update-area.component';
import { TYPE_TOAST } from 'src/app/system/core/enums/ToastsEnums';
import { PhotosAreaComponent } from '../photos-area/photos-area.component';
import { PermissionsServices } from 'src/app/system/services/permissions.service';

@Component({
  selector: 'app-table-area',
  templateUrl: './table-area.component.html',
  styleUrls: ['./table-area.component.scss']
})
export class TableAreaComponent {
  public permissionsService = inject(PermissionsServices);
  index : number = 0;
  activeTabIndex: { [key: string]: number } = {};

Areas:any[] = [];
constructor(@Inject('dynamicData') public Event: any, private MipFormsService:MipFormsService, private LoadingService:LoadingService, private DialogService:DialogService, private MessagesService:MessagesService){

}
handleChange(event: any) {


  this.index = event.index;
}
activeAccordionId: string | null = null;


handleAccordionClick(accordionId: string) {
  if (this.activeAccordionId === accordionId) {
    this.activeAccordionId = null;
  } else {
    this.activeAccordionId = accordionId;
  }
}
  ngOnInit(): void {


setTimeout(() => {
  this.getAreas();
}, 0);
  }

  getAreas(){
    this.LoadingService.Show();
    this.MipFormsService.getDataForm(this.Event.id,MIP_FORM.AREAS_ACTIVITY).subscribe((res) => {
      this.Areas = res;
      this.LoadingService.Hide();

    });
  }


  AddUpdate(Area: any,) {

     const dialog = this.DialogService.open(AddUpdateAreaComponent, {
      header: "Área",
      modal: true,
      width: '50rem',
      //height:"100%",
      closable: true,
      draggable: false,
      maximizable: false,
      resizable: true,
      closeOnEscape: true,
      dismissableMask: true,
      data: {
        Area,
        Event:this.Event
      },
    });
    dialog.onClose.subscribe((res) => {
      if (res?.success) {
        this.getAreas();
      }
    });

  }
  deleteItem(item: any) {
    this.MessagesService.confirm(
      '¿Esta seguro de eliminar el monitoreo ' + item.station.name + ' ?',
      () => {
        this.MipFormsService.deleteAreaActivity(
          item.id
        ).subscribe((res) => {
          this.MessagesService.ShowMessage(TYPE_TOAST.SUCCESS,"Correcto","Se ha eliminado correctamente")
          this.getAreas();
        });
      }
    );
  }

  viewImages( Area:any) {
 
    const dialog = this.DialogService.open(PhotosAreaComponent, {
     header: "Fotos Área " ,
     modal: true,
     width: '50rem',
     //height:"100%",
     closable: true,
     draggable: false,
     maximizable: false,
     resizable: true,
     closeOnEscape: true,
     dismissableMask: true,
     data: {
      Area,
     },
   });
   dialog.onClose.subscribe((res) => {
     if (res?.success) {

     }
   });

  }


}
