import { Component, inject, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AgentsService } from 'src/app/system/services/agents.service';
import { PermissionsServices } from 'src/app/system/services/permissions.service';

@Component({
  selector: 'app-float-agents-button',
  templateUrl: './float-agents-button.component.html',
  styleUrls: ['./float-agents-button.component.scss']
})
export class FloatAgentsButtonComponent implements OnInit {
  @Input() count: number = 0;

  private _router = inject(Router);
  private _agentsServices = inject(AgentsService);
  public permissionsService = inject(PermissionsServices);

  public agents: any[] = [];
  public isMenuOpen: boolean = false;

  ngOnInit(): void {
    this.loadData();
  }

  loadData(): void {
    this._agentsServices.agents$.subscribe((agents: any[]) => {
      this.agents = agents.map((agent) => ({
        ...agent,
        hasNotifications: Math.random() < 0.5
      }));
    });
  }

  openMenu() {
    this.isMenuOpen = !this.isMenuOpen;
  }

  openAgent(agent: any) {
    this._router.navigate(['/lead-force/alias', agent.id]);
    this.isMenuOpen = false;
  }
}
