<div class="grid formgrid" [formGroup]="Form">
  <div class="col-12  mt-4">
    <app-select [Catalog]="Catalog.CANCELATION_REASONS" field="name" [isDropdown]="true" type="p-autocomplete"
      [isMultiple]="false" label="Motivo Cancelación" formControlName="rejection_reason"></app-select>
  </div>

  <div class="col-12  mt-4">
    <textarea formControlName="rejection_reason_comment" placeholder="Comentarios" rows="3" cols="48"
      pInputTextarea></textarea>
  </div>
</div>

<div class="text-center mt-3  mb-4 ">
  <button (click)="cancelEvent()" [disabled]="Form.invalid" pButton pRipple type="submit" label="Cancelar evento"
    class="p-button-raised btn-sm"></button>
</div>
