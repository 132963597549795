
<div class=" mt-4">
  <button *appHasPermission="permissionsService.PERMISSIONS_ADD.MIP" (click)="AddUpdate(null)" pButton class="mb-4 p-button-outlined btn-sm  " icon="pi pi-plus" severity="info" label="Agregar anexo"></button>

</div>
<div class="container">

  <div class="grid formgrid pb-4">

    <div class="col-12 mt-2 lg:col-12 lg:mb-0 mb-4 card border-left">
      <p-table
      [scrollable]="true" scrollHeight="400px"
        class="custom-table"
        styleClass="p-datatable-sm"
        scrollDirection="horizontal"
        #dtCalendar
        [value]="Anexos"
        dataKey="id"
        sortMode="multiple"
        [rows]="10"
        [showCurrentPageReport]="true"
        [rowsPerPageOptions]="[10, 25, 50]"
        [loading]="loading"
        [paginator]="false"
        [globalFilterFields]="['name']"
      >
        <ng-template pTemplate="header">
          <tr>
            <th
              pSortableColumn="name"
              class="white-space-nowrap bg-primary text-center"
              style="width: 25%"
            >
              Nombre del anexo
            </th>
            <th
              class="white-space-nowrap bg-primary text-center"
              style="width: 25%"
            >
              Comentario
            </th>
            <th
              class="white-space-nowrap bg-primary text-center"
              style="width: 25%"
            >
              Documento
            </th>



            <th
              class="white-space-nowrap bg-primary text-center"
              style="width: 25%"
            >
              Acciones
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-item>
          <tr class="text-sm">
            <td class="text-center fw-bold">
              {{ item.name }}
            </td>
            <td class="text-center">
              {{ item.comments ?? '' }}
            </td>

            <td class="text-center">
              <a [href]="item.document" target="_blank"><i class="fa fa-download text-primary fa-2x" aria-hidden="true"></i></a>
            </td>
            <td class="text-center">
              <p-button
                (click)="deleteItem(item)"
                type="button"
                icon="fa-solid fa-trash"
                styleClass="p-button-rounded p-button-outlined p-button-danger p-button-sm ml-2 btn-sm"
              >
              </p-button>
              <p-button
                (click)="AddUpdate(item)"
                type="button"
                icon="fa-solid fa-edit"
                styleClass="p-button-rounded p-button-outlined p-button-info ms-2 btn-sm "
              >
              </p-button>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="7">no hay datos.</td>
          </tr>
        </ng-template>
      </p-table>
    </div>

  </div>
</div>
