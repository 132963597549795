<!--

  <button (click)="AddUpdate(null)"  pButton class="mb-4 p-button-outlined  btn-sm  p-button-info" icon="pi pi-plus" severity="info" label="Nueva Evidencia"></button>
  <div *ngIf="Evidences.length > 0" class="grid formgrid mx-4 pb-4">

    <div class="col-12 mt-2  lg:mb-0 mb-4 card border-left">
      <p-table
      class="custom-table "
      styleClass="p-datatable-sm"
      scrollDirection="horizontal"
      #dtCalendar
      [value]="Evidences"
      dataKey="id"
      sortMode="multiple"
      [rows]="10"
      [showCurrentPageReport]="true"
      [rowsPerPageOptions]="[10, 25, 50]"
      [loading]="loading"
      [paginator]="false"
      [globalFilterFields]="['name']"
    >
      <ng-template pTemplate="header">
        <tr>
          <th
            pSortableColumn="name"
            class="white-space-nowrap bg-primary text-center"

          >
            Plaga
          </th>
          <th class="white-space-nowrap bg-primary text-center" >
          Área
          </th>
          <th class="white-space-nowrap bg-primary text-center" >
            Grado de infestación
           </th>
           <th class="white-space-nowrap bg-primary text-center" >
            Ciclo de vida
           </th>
           <th class="white-space-nowrap bg-primary text-center" >
            Evidencias
           </th>
          <th class="white-space-nowrap bg-primary text-center"s>
           Acciones
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-item>
        <tr class="text-sm">
          <td class="text-center">
                {{ item.plague.name }}
          </td>
          <td class="text-center">
            <span *ngFor="let area of item?.nesting_area">
              {{ area.name }},
            </span>

      </td>
          <td class="text-center">
            {{ item.infestation_degree.name }}
      </td>
      <td class="text-center">
        <span *ngFor="let cycle of item?.life_cycle_plague">
          {{ cycle.name }},
        </span>

  </td>
  <td class="text-center">
    <span *ngFor="let evidence of item?.evidence_type">
      {{ evidence.name }},
    </span>

</td>
          <td class="text-center">

          <p-button
            (click)="deleteItem(item)"
          type="button"
          icon="fa-solid fa-trash"
          styleClass="p-button-rounded p-button-outlined p-button-danger p-button-sm ml-2 btn-sm"
          >
          </p-button>
            <p-button
            (click)="AddUpdate(item)"
              type="button"
              icon="fa-solid fa-edit"
              styleClass="p-button-rounded p-button-outlined p-button-info ms-2 btn-sm "
            >
            </p-button>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="7">no hay datos.</td>
        </tr>
      </ng-template>
    </p-table>
    </div>

  </div>

  <div *ngIf="Evidences.length == 0">
    <h5 class=" mb-0">No tienes evidencias</h5>
  </div>



-->




<p-table class="custom-table "
styleClass="p-datatable-sm"
scrollDirection="horizontal"
#dtCalendar
[value]="Evidences"
dataKey="id"
sortMode="multiple"
[rows]="10"
[showCurrentPageReport]="true"
[rowsPerPageOptions]="[10, 25, 50]"
[loading]="loading"
[paginator]="false"
[globalFilterFields]="['name']">
  <ng-template pTemplate="header">
      <tr>
          <th  class="white-space-nowrap bg-table-info text-center">Plaga</th>
          <th   class="white-space-nowrap bg-table-info text-center">Area</th>
          <th   class="white-space-nowrap bg-table-info text-center">Grado infestación </th>
          <th   class="white-space-nowrap bg-table-info text-center">Ciclo de vida </th>
          <th   class="white-space-nowrap bg-table-info text-center">Evidencias </th>
          <th   class="white-space-nowrap bg-table-info text-center"></th>
      </tr>
  </ng-template>
  <ng-template pTemplate="body" let-item>
    <tr class="text-sm">
      <td class="text-center">
            {{ item.plague.name }}
      </td>
      <td class="text-center">
        <span *ngFor="let area of item?.nesting_area">
          {{ area.name }},
        </span>

  </td>
      <td class="text-center">
        {{ item.infestation_degree.name }}
  </td>
  <td class="text-center">
    <span *ngFor="let cycle of item?.life_cycle_plague">
      {{ cycle.name }},
    </span>

</td>
<td class="text-center">
<span *ngFor="let evidence of item?.evidence_type">
  {{ evidence.name }},
</span>

</td>
      <td class="text-center">

      <p-button
        (click)="deleteItem(item)"
      type="button"
      icon="fa-solid fa-trash"
      styleClass="p-button-rounded p-button-outlined p-button-danger p-button-sm ml-2 btn-sm"
      >
      </p-button>
        <p-button
        (click)="AddUpdate(item)"
          type="button"
          icon="fa-solid fa-edit"
          styleClass="p-button-rounded p-button-outlined p-button-info ms-2 btn-sm "
        >
        </p-button>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
      <tr>
          <td colspan="6">No tienes registros.</td>
      </tr>
  </ng-template>
</p-table>
