import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { TYPE_TOAST } from 'src/app/system/core/enums/ToastsEnums';

import { LoadingService } from 'src/app/system/services/loading.service';
import { MessagesService } from 'src/app/system/services/message.service';
import { SignatureService } from 'src/app/system/services/signature.service';

@Component({
  selector: 'app-send-signature-request',
  templateUrl: './send-signature-request.component.html',
  styleUrls: ['./send-signature-request.component.scss']
})
export class SendSignatureRequestComponent {
  index: number = 0;
  Event: any = null;

  FormEmail: FormGroup = this.FormBuilder.group({
    eventId: '',
    customer_email: ['', [Validators.required]],
  });

  constructor(
    private MessagesService: MessagesService,
    private FormBuilder: FormBuilder,
    private LoadingService: LoadingService,
    public config: DynamicDialogConfig,
    public ref: DynamicDialogRef,
    private SignatureService: SignatureService
  ) { }

  ngOnInit() {
    this.Event = this.config.data.Event;
  }

  cancel() {
    this.ref?.close();
  }

  handleChange(event: any) {
    this.index = event.index;
  }

  sendRequestEmail() {
    let data = {
      customer_email: this.FormEmail.value.customer_email.trim().split(','),
      eventId: this.Event.id
    }

    this.LoadingService.Show();

    this.SignatureService.sendRequest(data).subscribe((res: any) => {
      this.LoadingService.Hide();

      this.MessagesService.ShowMessage(
        TYPE_TOAST.SUCCESS,
        'Correcto',
        'Se ha enviado la solicitud de firma correctamente'
      );

      this.ref?.close({ success: true });
    });
  }
}
