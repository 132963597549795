<div class="card border-left">
  <div class="col-12 md:col-12 d-flex justify-content-between flex-wrap mb-3">
    <span class="h4 fw-bold text-primary">
      <i class="fas fa-calendar me-2"></i>
      CALENDARIO
    </span>

    <div class="me-3">
      <p-button (click)="resetFilters()" type="button" icon="fa-solid fa-filter-circle-xmark"
        styleClass="p-button-rounded p-button-danger p-button-sm ml-2">
      </p-button>

      <p-button *appHasPermission="permissionsService.PERMISSIONS_ADD.EVENTS" (click)="addEvent()" type="button"
        icon="fa-solid fa-plus" styleClass="p-button-rounded ms-2">
      </p-button>
    </div>
  </div>

  <p-accordion>
    <p-accordionTab styleClass="custom-accordion-tab" header="Filtrar calendario">
      <form [formGroup]="FormGroup" class="row pt-4 pb-4">
        <div class="col-xs-12 col-md-12 col-lg-4 mt-3">
          <app-select (onSelect)="filterByGroup($event)" [Catalog]="Catalog.GROUPS" field="name" [isDropdown]="true"
            type="p-autocomplete-administrative" [isMultiple]="false" label="Grupo ruta"
            formControlName="group"></app-select>
        </div>

        <div class="col-xs-12 col-md-12 col-lg-4 mt-3">
          <app-select [Catalog]="Catalog.EVENT_TYPES" (onSelect)="filterByGroup($event)" field="name"
            [isDropdown]="true" type="p-autocomplete" [isMultiple]="false" label="Tipo evento"
            formControlName="event_type"></app-select>
        </div>

        <div class="col-xs-12 col-md-12 col-lg-4 mt-3">
          <app-select [Catalog]="Catalog.EMPLOYEES" (onSelect)="filterByGroup($event)" field="name" [isDropdown]="true"
            type="p-autocomplete-administrative" [isMultiple]="false" label="Técnico"
            formControlName="employee"></app-select>
        </div>

        <div class="col-xs-12 col-md-12 col-lg-4 mt-4">
          <app-select (onSelect)="filterByGroup($event)" [isMain]="false" [UrlCatalog]="URL_CATALOGS.CUSTOMERS_DROPDOWN"
            field="name" [isDropdown]="false" type="select-generic" [isMultiple]="false" label="Filtrar por cliente"
            formControlName="customer">
          </app-select>
        </div>

        <div class="col-xs-12 col-md-12 col-lg-4 mt-4">
          <app-select [Catalog]="Catalog.SERVICE_TYPE" (onSelect)="filterByGroup($event)" field="name"
            [isDropdown]="true" type="p-autocomplete" [isMultiple]="false" label="Tipo servicio"
            formControlName="service_type"></app-select>
        </div>

        <div class="col-xs-12 col-md-12 col-lg-4 mt-4">
          <app-select [Catalog]="Catalog.QUOTES" (onSelect)="filterByGroup($event)" field="folio" [isDropdown]="true"
            type="p-autocomplete-administrative" [isMultiple]="false" label="Cotización"
            formControlName="quote"></app-select>
        </div>

        <div class="col-xs-12 col-md-12 col-lg-4 mt-4">
          <app-select [IS_LOCAL]="true" [Catalog]="Catalog.QUOTES" (onSelect)="filterByGroup($event)" field="name" [isDropdown]="true"
            type="p-autocomplete-administrative" [isMultiple]="false" label="Estatus de pago"
            formControlName="is_pay"></app-select>
        </div>
      </form>
    </p-accordionTab>
  </p-accordion>

  <p-tabView [activeIndex]="index" (onChange)="handleChange($event)">
    <p-tabPanel>
      <ng-template pTemplate="header">
        <i class="pi pi-calendar me-2"></i>
        <span>Calendario</span>
      </ng-template>

      <app-calendar *ngIf="index == 0" initialView="timeGridWeek" (emitData)="loadData()"></app-calendar>
    </p-tabPanel>
  </p-tabView>
</div>

<div class="card border-left">
  <div class="d-flex justify-content-end mb-3">
    <p-button pTooltip="Borrar filtros" (click)="resetTable()" type="button" icon="fa-solid fa-filter-circle-xmark"
      styleClass="p-button-rounded p-button-danger p-button-sm ml-2">
    </p-button>

    <p-button pTooltip="Exportar excel" (click)="exportExcel()" type="button" icon="fa fa-file-excel-o"
      styleClass="p-button-rounded p-button-success p-button-sm ml-2">
    </p-button>
  </div>

  <div [formGroup]="dataForm" class="custom-table-container">
    <p-table
      class="custom-table"
      styleClass="p-datatable-sm"
      #dt1
      [value]="events"
      dataKey="id"
      sortMode="multiple"
      [rows]="10"
      [showCurrentPageReport]="true"
      [rowsPerPageOptions]="[10, 25, 50]"
      [loading]="false"
      [paginator]="false"
      [globalFilterFields]="['name']"
      [tableStyle]="{ 'min-width': '125rem' }"
    >
      <ng-template pTemplate="header">
        <tr>
          <th style="width: 120px !important;" pSortableColumn="folio" class="bg-gray-100 border-bottom">
            # Folio
            <p-sortIcon field="folio"></p-sortIcon>
          </th>

          <th style="width: 120px !important;" pSortableColumn="created_at" class="bg-gray-100">
            Fecha de creación
            <p-sortIcon field="created_at"></p-sortIcon>
          </th>

          <th style="width: 120px !important;" pSortableColumn="initial_date" class="bg-gray-100 ">
            Fecha de servicio
            <p-sortIcon field="initial_date"></p-sortIcon>
          </th>

          <th  pSortableColumn="employee.name" class="bg-gray-100 ">
            Técnico
            <p-sortIcon field="employee.name"></p-sortIcon>
          </th>

          <th pSortableColumn="customer.name" class="bg-gray-100 ">
            Cliente
            <p-sortIcon field="customer.name"></p-sortIcon>
          </th>

          <th style="width: 120px !important;" pSortableColumn="customer.phone" class="bg-gray-100 ">
            Teléfono
            <p-sortIcon field="customer.phone"></p-sortIcon>
          </th>

          <th pSortableColumn="customer.address" class="bg-gray-100 ">
            Dirección
            <p-sortIcon field="customer.address"></p-sortIcon>
          </th>

          <th style="width: 190px !important;" class="bg-gray-100 ">
            Plaga
          </th>

          <th  style="width: 120px !important;" pSortableColumn="total" class="bg-gray-100 ">
            Monto
            <p-sortIcon field="total"></p-sortIcon>
          </th>

          <th  style="width: 160px !important;" class="bg-gray-100 " pSortableColumn="status.name">
            Estatus
            <p-sortIcon field="status.name"></p-sortIcon>
          </th>
        </tr>

        <tr>
          <th>
            <input pInputText type="text" (input)="onFilterInput($event,'search')" placeholder="Folio" class="w-full">
          </th>

          <th>
            <span class="p-fluid p-input-icon-left p-float-label">
              <p-calendar formControlName="creation_date" [readonlyInput]="true" appendTo="body"
                (onSelect)="searchDate('CREACION')"></p-calendar>
            </span>
          </th>

          <th>
            <span class="p-fluid p-input-icon-left p-float-label">
              <p-calendar formControlName="range_date" selectionMode="range" [readonlyInput]="true" appendTo="body"
                (onSelect)="searchDate('SERVICIO')"></p-calendar>
            </span>
          </th>

          <th>
            <app-select [Catalog]="Catalog.EMPLOYEES" (onSelect)="onFilterInput($event, 'employee')" field="name"
              [isDropdown]="true" type="p-autocomplete-administrative" [isMultiple]="false" label=""
              formControlName="employee"></app-select>
          </th>

          <th>
            <app-select (onSelect)="onFilterInput($event,'customer')" [isMain]="false"
              [UrlCatalog]="URL_CATALOGS.CUSTOMERS_DROPDOWN" field="name" [isDropdown]="false" type="select-generic"
              [isMultiple]="false" label="Filtrar por cliente" formControlName="customer">
            </app-select>
          </th>

          <th>
            <input pInputText type="text" (input)="onFilterInput($event,'customer__phone')" placeholder="Teléfono" class="w-full">
          </th>

          <th>
            <input pInputText type="text" (input)="onFilterInput($event,'customer__address')" placeholder="Domicilio" class="w-full">
          </th>

          <th>
            <app-select [Catalog]="Catalog.PLAGUES" (onSelect)="onFilterInput($event, 'plague')" field="name"
              [isDropdown]="true" type="p-autocomplete" [isMultiple]="false" label=""
              formControlName="plague"></app-select>
          </th>

          <th></th>

          <th>
            <p-autoComplete [dropdown]="true" [suggestions]="filteredStatus" appendTo="body"
              (completeMethod)="filterStatus($event)" (onSelect)="onFilterInput($event, 'status')" />
          </th>
        </tr>
      </ng-template>

      <ng-template pTemplate="body" let-item>
        <tr class="text-sm">
          <td class=" ">
            <div class="dropdow d">
              <p
                class="fw-bold dropdown-toggle text-primary"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                {{ item.folio }}
              </p>

              <ul class="dropdown-menu" *ngIf="getStatusForMenu(item.status.key_string) === 'created'">
                <li *appHasPermission="permissionsService.PERMISSIONS_ADD.EVENTS">
                  <a (click)="editEvent(item)" class="dropdown-item hov-pointer ">
                    <span class="color-gray"><i class="fas fa-edit me-2"></i></span>Editar
                  </a>
                </li>

                <li *appHasPermission="permissionsService.PERMISSIONS_READ.MIP">
                  <a (click)="CrudMipForms(item)" class="dropdown-item hov-pointer">
                    <span class="color-blue"><i class="fa-solid fa-file-pen me-2"></i></span>Ver formularios
                  </a>
                </li>

                <li *appHasPermission="permissionsService.PERMISSIONS_ADD.EVENTS">
                  <a (click)="sendIndications(item)" class="dropdown-item hov-pointer ">
                    <span class="color-green"><i class="fab fa-whatsapp me-2"></i></span>Enviar indicaciones por
                    WhatsApp
                  </a>
                </li>

                <li *appHasPermission="permissionsService.PERMISSIONS_ADD.EVENTS">
                  <a (click)="sendReminder(item)" class="dropdown-item hov-pointer ">
                    <span class="color-green"><i class="fas fa-bell me-2"></i></span>Enviar recordatorio por WhatsApp
                  </a>
                </li>

                <li *appHasPermission="permissionsService.PERMISSIONS_ADD.EVENTS">
                  <a (click)="confirmar(item)" class="dropdown-item hov-pointer ">
                    <span class="color-green"><i class="fas fa-check me-2"></i></span>Confirmar
                  </a>
                </li>

                <li *appHasPermission="permissionsService.PERMISSIONS_ADD.EVENTS">
                  <a (click)="plantilla(item)" class="dropdown-item hov-pointer ">
                    <span class="color-red"><i class="fas fa-file-pdf me-2"></i></span>Plantilla
                  </a>
                </li>

                <li *appHasPermission="permissionsService.PERMISSIONS_ADD.EVENTS">
                  <a (click)="cancelEvent(item)" class="dropdown-item hov-pointer ">
                    <span class="color-red"><i class="fas fa-ban me-2"></i></span>Cancelar
                  </a>
                </li>
              </ul>

              <ul class="dropdown-menu" *ngIf="getStatusForMenu(item.status.key_string) === 'completed'">
                <li *appHasPermission="permissionsService.PERMISSIONS_ADD.EVENTS">
                  <a (click)="editEvent(item)" class="dropdown-item hov-pointer ">
                    <span class="color-gray"><i class="fas fa-edit me-2"></i></span>Editar
                  </a>
                </li>

                <li *appHasPermission="permissionsService.PERMISSIONS_READ.MIP">
                  <a (click)="CrudMipForms(item)" class="dropdown-item hov-pointer">
                    <span class="color-blue"><i class="fa-solid fa-file-pen me-2"></i></span>Ver formularios
                  </a>
                </li>

                <li *appHasPermission="permissionsService.PERMISSIONS_ADD.EVENTS">
                  <a (click)="viewEvent(item)" class="dropdown-item hov-pointer ">
                    <span class="color-gray"><i class="fas fa-eye me-2"></i></span>Ver
                  </a>
                </li>

                <li *appHasPermission="permissionsService.PERMISSIONS_ADD.EVENTS">
                  <a (click)="seeCertificate(item)" class="dropdown-item hov-pointer ">
                    <span class="color-red"><i class="fas fa-file-pdf me-2"></i></span>Certificado de servicio
                  </a>
                </li>

                <li *appHasPermission="permissionsService.PERMISSIONS_ADD.EVENTS">
                  <a (click)="sendCertificateEmail(item)" class="dropdown-item hov-pointer ">
                    <span class="color-gray"><i class="fas fa-envelope me-2"></i></span>Enviar certificado
                  </a>
                </li>

                <li *appHasPermission="permissionsService.PERMISSIONS_ADD.EVENTS">
                  <a (click)="sendCertificateWhatsApp(item)" class="dropdown-item hov-pointer ">
                    <span class="color-green"><i class="fab fa-whatsapp me-2"></i></span>Enviar certificado por WhatsApp
                  </a>
                </li>

                <li *appHasPermission="permissionsService.PERMISSIONS_ADD.EVENTS">
                  <a (click)="sendSignatureRequest(item)" class="dropdown-item hov-pointer ">
                    <span class="color-blue"><i class="fas fa-signature me-2"></i></span>Solicitar firma
                  </a>
                </li>

                <li *appHasPermission="permissionsService.PERMISSIONS_ADD.EVENTS">
                  <a (click)="sendEvaluate(item)" class="dropdown-item hov-pointer ">
                    <span class="color-green"><i class="fas fa-star me-2"></i></span>Evaluar
                  </a>
                </li>

                <li *appHasPermission="permissionsService.PERMISSIONS_ADD.EVENTS">
                  <a (click)="openWarranty(item)" class="dropdown-item hov-pointer ">
                    <span class="color-yellow"><i class="fas fa-triangle-exclamation me-2"></i></span>Garantía
                  </a>
                </li>

                <li *appHasPermission="permissionsService.PERMISSIONS_ADD.EVENTS">
                  <a (click)="openBootster(item)" class="dropdown-item hov-pointer ">
                    <span class="color-green"><i class="fas fa-shield-alt me-2"></i></span>Refuerzo
                  </a>
                </li>

                <li *appHasPermission="permissionsService.PERMISSIONS_ADD.EVENTS">
                  <a (click)="openFollow(item)" class="dropdown-item hov-pointer ">
                    <span class="color-gray"><i class="fas fa-arrow-right me-2"></i></span>Seguimiento
                  </a>
                </li>

                <li *appHasPermission="permissionsService.PERMISSIONS_ADD.EVENTS">
                  <a (click)="seeServiceOrder(item)" class="dropdown-item hov-pointer ">
                    <span class="color-red"><i class="fas fa-file-pdf me-2"></i></span>Orden de servicio
                  </a>
                </li>

                <li *appHasPermission="permissionsService.PERMISSIONS_ADD.EVENTS">
                  <a (click)="editEvent(item)" class="dropdown-item hov-pointer ">
                    <span class="color-red"><i class="fas fa-file-pdf me-2"></i></span>Plantilla
                  </a>
                </li>

                <li *appHasPermission="permissionsService.PERMISSIONS_ADD.EVENTS">
                  <a (click)="payEvent(item)" class="dropdown-item hov-pointer ">
                    <span class="color-blue"><i class="fas fa-money-bill me-2"></i></span>Pagar
                  </a>
                </li>
              </ul>

              <ul class="dropdown-menu" *ngIf="getStatusForMenu(item.status.key_string) === 'cancelled'">
                <li *appHasPermission="permissionsService.PERMISSIONS_ADD.EVENTS">
                  <a (click)="reactivateEvent(item)" *ngIf="item.status.name == 'Cancelado'"
                    class="dropdown-item hov-pointer ">
                    <span class="color-green"><i class="fas fa-check me-2"></i></span>Recuperar evento
                  </a>
                </li>
              </ul>
            </div>
          </td>

          <td>
            <span>
              {{ item.created_at | date: 'dd/MM/yyyy' }}
            </span>

            <br>

            <span>
              {{ item.created_at | date: 'HH:mm:ss a' }}
            </span>
          </td>

          <td>
            <span>
              {{ item.initial_date | date: 'dd/MM/yyyy' }}
            </span>

            <br>

            <span>
              {{ formatHour(item.initial_hour) }}
            </span>
          </td>

          <td class="table-text">
            <ng-container *ngIf="item.employee && item.employee.length > 0; else noEmployee">
              {{ item.employee[0].name }}
            </ng-container>

            <ng-template #noEmployee>
              -
            </ng-template>
          </td>

          <td class="table-text">
            {{ item.customer.name }}, {{ item.customer.contact_name }}

          </td>

          <td>
            {{ item.customer.phone }}
          </td>

          <td style="max-width: 15rem;">
            <p class="table-text">
              {{ item.customer.address }}
            </p>
          </td>

          <td>
            <span *ngFor="let plague of item.plague" class="table-text">
              {{ plague.name }} <br>
            </span>
          </td>

          <td>
            <span class="fw-bold text-success" *ngIf="item.is_pay">
              Pagado
            </span>

            <br>

            <span
              class="fw-bold"
              [ngClass]="{'text-danger': item.debt > 0  && item.key_string == 'event-completed'}"
            >
              Sub: {{ Symbol_Country }} {{ item.total | number:'1.0-0' }}
            </span>

            <br>

            <span
              class="fw-bold"
              [ngClass]="{'text-danger': item.debt > 0 && item.key_string == 'event-completed' }"
            >
              Tot: {{ Symbol_Country }} {{ item.total | number:'1.0-0' }}
            </span>

            <br>

            <span
              *ngIf="item.debt > 0 && item.key_string == 'event-completed'"
              class="fw-bold text-danger"
            >
              Adeudo: {{ Symbol_Country }} {{ item.debt | number:'1.0-0' }}
            </span>

          </td>

          <td class="text-center">
            <p-tag [severity]="getEventStatusTag(item.status.key_string)" [value]="item?.status.name"></p-tag>

            <p class="mt-2">
              <i *ngIf="item.certificate_email" style="color: green; padding-right: 3px;"
              class="pi pi-envelope me-1"></i>
              <i *ngIf="item.certificate_whats" style="color: green" class="pi pi-whatsapp me-1"></i>
              <i *ngIf="item.reminder" style="color: green" class="pi pi-bell me-1"></i>
            </p>

            <ng-container *ngIf="item.evaluation">
              <p class="hov-pointer" [pTooltip]="item?.evaluation.comments" *ngIf="item?.evaluation.value == 1">
                <i class="fa fa-star text-warning"  aria-hidden="true"></i>
                <i class="fa fa-star-o" aria-hidden="true"></i>
                <i class="fa fa-star-o" aria-hidden="true"></i>
                <i class="fa fa-star-o" aria-hidden="true"></i>
                <i class="fa fa-star-o" aria-hidden="true"></i>
              </p>

              <p class="hov-pointer" [pTooltip]="item?.evaluation.comments" *ngIf="item?.evaluation.value == 2">
                <i class="fa fa-star text-warning"  aria-hidden="true"></i>
                <i class="fa fa-star text-warning" aria-hidden="true"></i>
                <i class="fa fa-star-o" aria-hidden="true"></i>
                <i class="fa fa-star-o" aria-hidden="true"></i>
                <i class="fa fa-star-o" aria-hidden="true"></i>
              </p>

              <p class="hov-pointer" [pTooltip]="item?.evaluation.comments" *ngIf="item?.evaluation.value == 3">
                <i class="fa fa-star text-warning" aria-hidden="true"></i>
                <i class="fa fa-star text-warning" aria-hidden="true"></i>
                <i class="fa fa-star text-warning" aria-hidden="true"></i>
                <i class="fa fa-star-o" aria-hidden="true"></i>
                <i class="fa fa-star-o" aria-hidden="true"></i>
              </p>

              <p class="hov-pointer" [pTooltip]="item?.evaluation.comments" *ngIf="item?.evaluation.value == 4">
                <i class="fa fa-star text-warning"  aria-hidden="true"></i>
                <i class="fa fa-star text-warning" aria-hidden="true"></i>
                <i class="fa fa-star text-warning" aria-hidden="true"></i>
                <i class="fa fa-star text-warning" aria-hidden="true"></i>
                <i class="fa fa-star-o" aria-hidden="true"></i>
              </p>

              <p class="hov-pointer" [pTooltip]="item?.evaluation.comments" *ngIf="item?.evaluation.value == 5">
                <i class="fa fa-star text-warning"  aria-hidden="true"></i>
                <i class="fa fa-star text-warning" aria-hidden="true"></i>
                <i class="fa fa-star text-warning" aria-hidden="true"></i>
                <i class="fa fa-star text-warning" aria-hidden="true"></i>
                <i class="fa fa-star text-warning" aria-hidden="true"></i>
              </p>
            </ng-container>
          </td>
        </tr>
      </ng-template>

      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="15">No hay datos</td>
        </tr>
      </ng-template>
    </p-table>

    <p-paginator
      [first]="dataForm.value.first"
      [rows]="dataForm.value.rows"
      [rowsPerPageOptions]="[10, 20, 30]"
      [totalRecords]="dataForm.value.totalRecords"
      (onPageChange)="onPageChange($event)"
      currentPageReportTemplate="{currentPage} de {totalPages}"
      [showJumpToPageDropdown]="true"
      [showPageLinks]="true"
      [showJumpToPageInput]="true"
    ></p-paginator>
  </div>
</div>
