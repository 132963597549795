

<form (ngSubmit)="accept()" [formGroup]="Form">
  <div class="grid formgrid  ">
    <div class="col-12 mb-2 mt-4  lg:col-12 lg:mb-0">
      <app-select  [Catalog]="Catalog.ROOT_CAUSES" field ="name" [isDropdown] = "true" type="p-autocomplete" [isMultiple] = "false" label="Raíz del caso" formControlName="root_cause"></app-select>
  </div>
    <div class="col-12 mb-2 mt-4  lg:col-12 lg:mb-0">
      <app-select  [Catalog]="Catalog.QUALIFICATIONS" field ="name" [isDropdown] = "true" type="p-autocomplete" [isMultiple] = "false" label="Calificación" formControlName="qualification"></app-select>
  </div>



    <div class="col-12 mb-2 mt-4 lg:col-12 lg:mb-0">
      <span class="w-100 p-fluid p-float-label p p-input-icon-left">
        <i class="fa-solid fa-pencil"></i>
        <input formControlName="description"  type="text" pInputText placeholder="descripción "/>
        <label for="description">Descripción </label>
      </span>
    </div>




  </div>
  <div class="d-flex justify-content-center mt-4">
    <button pButton pRipple type="submit" [disabled]="Form.invalid" label="Guardar" class="p-button-raised me-2 btn-sm"></button>
    <button (click)="cancel()" pButton pRipple type="button" label="Cancelar" class="p-button-raised p-button-secondary btn-sm"></button>
</div>
</form>
