<div class="d-flex flex-wrap ">

    <p class=""><span class="fw-bold h5">Cliente: </span><span class="h5"> {{Event.customer.name}} </span></p>
<p class="ms-4" *ngIf="Event.customer.address"><span class="fw-bold h5">Domicilio:</span> <span class="h5">{{Event.customer.address}}</span></p>
<p class="ms-4"><span class="fw-bold h5">Servicio:</span> <span class="h5">{{Event.title}}</span></p>

    <p class="ms-4"><span class="fw-bold h5">Técnico: </span><span class="h5"> {{Event?.employee[0].name ?? ''}}   </span></p>

</div>


<p-tabView [activeIndex]="index" (onChange)="handleChange($event)">

  <!---->
 <p-tabPanel *ngFor="let form of forms" >
   <ng-template class="fixed-top" pTemplate="header">
     <span>{{form.title}}</span>
   </ng-template>


   <div class="pe" *ngIf="index == form.index ">
    <ng-container *ngComponentOutlet="form.Component; injector: componentInjector; "></ng-container>

  </div>
 </p-tabPanel>

</p-tabView>
