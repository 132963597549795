import { Component } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { TYPE_TOAST } from 'src/app/system/core/enums/ToastsEnums';

import { LoadingService } from 'src/app/system/services/loading.service';
import { MessagesService } from 'src/app/system/services/message.service';
import { MipFormsService } from 'src/app/system/services/MipForms.service';
import { UserService } from 'src/app/system/services/user.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-view-event',
  standalone: false,
  templateUrl: `./ViewEvent.component.html`,
  styleUrls: ['./ViewEvent.component.css'],
})
export class ViewEventComponent {
  public Event: any;
  public s3Url = environment.s3Url;
  public activeIndexesInspections: number[] = [];
  public activeIndexesConditions: number[] = [];
  public activeIndexesPestControls: number[] = [];

  Object = Object;

  inspections: any[] = [];
  conditions: any[] = [];
  pestControls: any[] = [];
  stationsMonitoring: any[] = [];
  actionPlans: any[] = [];
  tasks: any[] = [];
  payments: any[] = [];
  signatures: any[] = [];
  areasActivity: any[] = [];
  documents: any[] = [];

  constructor(
    private LoadingService: LoadingService,
    public config: DynamicDialogConfig,
    public ref: DynamicDialogRef,
    private MessagesService: MessagesService,
    private UserService: UserService,
    private mipFormsService: MipFormsService,
  ) { }

  ngOnInit(): void {
    this.Event = this.config.data.Event;
    this.loadData();
  }

  get Symbol_Country() {
    return this.UserService.Symbol_Country ?? ""
  }

  loadData() {
    this.LoadingService.Show();

    this.mipFormsService.getDataForms(this.Event.id).subscribe({
      next: (res) => {
        this.inspections = res.data.inspection || [];
        this.conditions = res.data.conditions || [];
        this.pestControls = res.data.controlForm || [];
        this.stationsMonitoring = res.data.station || [];
        this.actionPlans = res.data.actionPlan || [];
        this.tasks = res.data.task || [];
        this.payments = res.data.paymentForm || [];
        this.signatures = res.data.signature || [];
        this.areasActivity = res.data.areaActivity || [];
        this.documents = res.data.documents || [];

        this.openAllAccordions();

        this.LoadingService.Hide();
      },
      error: (err) => {
        this.LoadingService.Hide();
        this.MessagesService.ShowMessage(TYPE_TOAST.ERROR, 'Error', 'Ocurrió un error al cargar los datos.');
      },
    });
  }

  getAreaNames(areas: any[]): string {
    return areas.map(area => area.name).join(', ');
  }

  openAllAccordions() {
    this.activeIndexesInspections = this.getAllIndices(this.inspections);
    this.activeIndexesConditions = this.getAllIndices(this.conditions);
    this.activeIndexesPestControls = this.getAllIndices(this.pestControls);
  }

  getAllIndices(array: any[]): number[] {
    return array?.length ? array.map((_, index) => index) : [];
  }

  isPdfDocument(documentPath: string): boolean {
    return documentPath?.toLowerCase().endsWith('.pdf');
  }

  openPdfInNewWindow(pdfUrl: string): void {
    if (pdfUrl.toLowerCase().startsWith('http')) {
      window.open(pdfUrl, '_blank');
    } else {
      window.open(this.s3Url + pdfUrl, '_blank');
    }
  }

  getDocumentUrl(documentPath: string): string {
    if (documentPath.toLowerCase().startsWith('http')) {
      return documentPath;
    } else {
      return this.s3Url + documentPath;
    }
  }

  getStatusSeverity(statusKey: string): string {
    const SEVERITY_DICT = {
      'Por hacer': 'danger',
      'En progreso': 'warning',
      'Terminada': 'success',
    } as any;

    return SEVERITY_DICT[statusKey] || 'info';
  }
}
