
<div
class=" d-flex justify-content-center pt-3"
>
<p-button
*ngIf="TYPE != 'Station' "
  type="button"
  icon="fa-solid fa-plus"
  styleClass="p-button-rounded p-button-secondary p-button-sm me-2"
 (click)="AddItem()"
>
</p-button>

<p-button
*ngIf="TYPE != 'Customer' && TYPE != 'Business activity' "
  type="button"
  icon="fa-solid fa-edit"
  styleClass="p-button-rounded"
  (click)="UpdateItem()"
>
</p-button>
<p-button
*ngIf="TYPE != 'Customer' && TYPE != 'Business activity'"
type="button"
icon="fa-solid fa-trash"
styleClass="p-button-rounded p-button-danger ms-2"
(click)="deleteItem()"
>
</p-button>
</div>
