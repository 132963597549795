import { Component, Inject, inject } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { MIP_FORM } from 'src/app/system/core/enums/MipForms.model';
import { MipFormsService } from 'src/app/system/services/MipForms.service';
import { LoadingService } from 'src/app/system/services/loading.service';
import { MessagesService } from 'src/app/system/services/message.service';
import { AddUpdateSignatureComponent } from './components/add-update-signature/add-update-signature.component';
import { TYPE_TOAST } from 'src/app/system/core/enums/ToastsEnums';
import { PermissionsServices } from 'src/app/system/services/permissions.service';

@Component({
  selector: 'app-signature',
  templateUrl: './signature.component.html',
  styleUrls: ['./signature.component.scss']
})
export class SignatureComponent {
  Signatures:any[] = [];

  responsiveOptions: any[] | undefined;
  randomValue = Math.random();
  constructor(@Inject('dynamicData') public Event: any, private MipFormsService:MipFormsService, private LoadingService:LoadingService, private DialogService:DialogService, private MessagesService:MessagesService){

  }
  public permissionsService = inject(PermissionsServices);

    ngOnInit(): void {
      this.responsiveOptions = [
        {
            breakpoint: '1199px',
            numVisible: 3,
            numScroll: 3
        },
        {
            breakpoint: '991px',
            numVisible: 3,
            numScroll: 3
        },
        {
            breakpoint: '767px',
            numVisible: 3,
            numScroll: 3
        }
    ];

  setTimeout(() => {
    this.getSignature();
  }, 0);
    }

    getSignature(){
      this.LoadingService.Show();
      this.MipFormsService.getDataForm(this.Event.id,'signature-forms').subscribe((res) => {
        console.log(res);

        this.Signatures = res;
        this.LoadingService.Hide();

      });
    }


    AddUpdate(Signature: any,) {
      const dialog = this.DialogService.open(AddUpdateSignatureComponent, {
       header: "Firma cliente",
       modal: true,
       width: '35rem',
       closable: true,
       draggable: false,
       maximizable: false,
       resizable: true,
       closeOnEscape: true,
       dismissableMask: true,
       data: {
        Event:this.Event,
        Signature
       },
     });
     dialog.onClose.subscribe((res) => {
       if (res?.success) {
         this.getSignature();
       }
     });

   }
    deleteItem(item: any) {
      this.MessagesService.confirm(
        '¿Esta seguro de eliminar el registro ' + item.name + ' ?',
        () => {
          this.MipFormsService.deleteDataForm(
            item.id,
            'signature-forms'
          ).subscribe((res) => {
            this.MessagesService.ShowMessage(TYPE_TOAST.SUCCESS,"Correcto","Se ha eliminado correctamente")
            this.getSignature();
          });
        }
      );
    }

    generateRandomString() {
      const randomChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      let result = '';
      for (let i = 0; i < 10; i++) {
        result += randomChars.charAt(Math.floor(Math.random() * randomChars.length));
      }
      return result;
    }
}
