import { Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { PermissionsServices } from 'src/app/system/services/permissions.service';

@Component({
  selector: 'app-multiple-files',
  templateUrl: './multiple-files.component.html',
  styleUrls: ['./multiple-files.component.scss']
})
export class MultipleFilesComponent {
  files: File[] = [];
@Input() label:string = "Carga una imagen"
@Input() accept:string = 'image/*'
@Output()uploadFiles = new EventEmitter<File[]>();
public permissionsService = inject(PermissionsServices);

  onSelect(event:any) {
    this.files.push(...event.addedFiles);
    this.uploadFiles.emit(this.files);
  }

  onRemove(event:any) {
    this.files.splice(this.files.indexOf(event), 1);
    this.uploadFiles.emit(this.files);
  }

}
