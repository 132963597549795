import { Component, inject } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

import { MIP_FORM } from 'src/app/system/core/enums/MipForms.model';
import { TYPE_TOAST } from 'src/app/system/core/enums/ToastsEnums';
import { CalendarService } from 'src/app/system/services/calendar.service';
import { CatalogService } from 'src/app/system/services/catalogs.service';
import { LoadingService } from 'src/app/system/services/loading.service';
import { MessagesService } from 'src/app/system/services/message.service';
import { MipFormsService } from 'src/app/system/services/MipForms.service';
import { PermissionsServices } from 'src/app/system/services/permissions.service';
import { TasksService } from 'src/app/system/services/tasks.service';
import { UserService } from 'src/app/system/services/user.service';
import { environment } from 'src/environments/environment';
import { AddUpdateActionPlanComponent } from './action-plans/add-update-action-plan/add-update-action-plan.component';
import { AddUpdateAreaMonitoringComponent } from './areas/add-update-area-monitoring/add-update-area-monitoring.component';
import { SelectAreaMonitoringPhotosComponent } from './areas/select-area-monitoring-photos/select-area-monitoring-photos.component';
import { AddUpdateDocumentComponent } from './documents/add-update-document/add-update-document.component';
import { FinishServiceComponent } from './finish/finish-service/finish-service.component';
import { AddUpdatePaymentComponent } from './payments/add-update-payment/add-update-payment.component';
import { AddUpdateSignatureComponent } from './signature/add-update-signature/add-update-signature.component';
import { AddUpdateStationMonitoringComponent } from './stations/add-update-station-monitoring/add-update-station-monitoring.component';
import { SelectStationMonitoringPhotosComponent } from './stations/select-station-monitoring-photos/select-station-monitoring-photos.component';
import { AddUpdateTaskComponent } from './tasks/add-update-task/add-update-task.component';

@Component({
  selector: 'app-mip-forms',
  templateUrl: './mip-forms.component.html',
  styleUrls: ['./mip-forms.component.scss']
})
export class MipFormsComponent {
  public permissionsService = inject(PermissionsServices);

  public Event: any;
  public s3Url = environment.s3Url;
  public activeIndexesInspections: number[] = [];
  public activeIndexesConditions: number[] = [];
  public activeIndexesPestControls: number[] = [];

  Object = Object;

  inspections: any[] = [];
  conditions: any[] = [];
  pestControls: any[] = [];
  stationsMonitoring: any[] = [];
  actionPlans: any[] = [];
  tasks: any[] = [];
  payments: any[] = [];
  signatures: any[] = [];
  areasActivity: any[] = [];
  documents: any[] = [];

  constructor(
    private DialogService: DialogService,
    private FormBuilder: FormBuilder,
    private LoadingService: LoadingService,
    public config: DynamicDialogConfig,
    public ref: DynamicDialogRef,
    private MessagesService: MessagesService,
    private UserService: UserService,
    private CalendarService: CalendarService,
    private CatalogService: CatalogService,
    private mipFormsService: MipFormsService,
    private TasksService: TasksService
  ) { }

  ngOnInit(): void {
    this.Event = this.config.data.Event;
    console.log('EL EVENTO: ', this.Event);
    this.loadData();
  }

  get Symbol_Country() {
    return this.UserService.Symbol_Country ?? ""
  }

  loadData() {
    this.LoadingService.Show();

    this.mipFormsService.getDataForms(this.Event.id).subscribe({
      next: (res) => {
        this.inspections = res.data.inspection || [];
        this.conditions = res.data.conditions || [];
        this.pestControls = res.data.controlForm || [];
        this.stationsMonitoring = res.data.station || [];
        this.actionPlans = res.data.actionPlan || [];
        this.tasks = res.data.task || [];
        this.payments = res.data.paymentForm || [];
        this.signatures = res.data.signature || [];
        this.areasActivity = res.data.areaActivity || [];
        this.documents = res.data.documents || [];

        this.openAllAccordions();

        this.LoadingService.Hide();
      },
      error: (err) => {
        this.LoadingService.Hide();
        this.MessagesService.ShowMessage(TYPE_TOAST.ERROR, 'Error', 'Ocurrió un error al cargar los datos.');
      },
    });
  }

  getAreaNames(areas: any[]): string {
    return areas.map(area => area.name).join(', ');
  }

  openAllAccordions() {
    this.activeIndexesInspections = this.getAllIndices(this.inspections);
    this.activeIndexesConditions = this.getAllIndices(this.conditions);
    this.activeIndexesPestControls = this.getAllIndices(this.pestControls);
  }

  getAllIndices(array: any[]): number[] {
    return array?.length ? array.map((_, index) => index) : [];
  }

  isPdfDocument(documentPath: string): boolean {
    return documentPath?.toLowerCase().endsWith('.pdf');
  }

  openPdfInNewWindow(pdfUrl: string): void {
    if (pdfUrl.toLowerCase().startsWith('http')) {
      window.open(pdfUrl, '_blank');
    } else {
      window.open(this.s3Url + pdfUrl, '_blank');
    }
  }

  getDocumentUrl(documentPath: string): string {
    if (documentPath.toLowerCase().startsWith('http')) {
      return documentPath;
    } else {
      return this.s3Url + documentPath;
    }
  }

  getStatusSeverity(statusKey: string): string {
    const SEVERITY_DICT = {
      'Por hacer': 'danger',
      'En progreso': 'warning',
      'Terminada': 'success',
    } as any;

    return SEVERITY_DICT[statusKey] || 'info';
  }

  AddUpdateStationMonitoring(station: any) {
    const dialog = this.DialogService.open(AddUpdateStationMonitoringComponent, {
      header: 'Monitoreo de estación',
      modal: true,
      width: '40%',
      closable: true,
      draggable: false,
      maximizable: false,
      resizable: true,
      closeOnEscape: true,
      dismissableMask: true,
      data: {
        Station: station,
        Event: this.Event
      },
    });

    dialog.onClose.subscribe((res) => {
      if (res?.success) {
        const stationResponse = {
          sation: {
            id: res.station.id,
            station: [res.station.station.name, res.station.station.key],
            condition: res.station.station_condition.map((item: any) => item.name),
            activity: res.station.station_activity.map((item: any) => item.name),
            typeActivity: res.station.stationTypeActivity.map((item: any) => item.name),
            activityinStation: res.station.activityinStation,
            EmployeeActivity: res.station.EmployeeActivity,
            comments: res.station.comments,
            plague: res.station.plague_counts.map((item: any) => [item.plague.name, item.count_plague]),
          },
          photos: []
        }

        if (res.isUpdate) {
          this.stationsMonitoring = this.stationsMonitoring.map((item) => {
            if (item.sation.id == stationResponse.sation.id) {
              return stationResponse
            }

            return item;
          });
        } else {
          this.stationsMonitoring.push(stationResponse);
        }
      }
    });
  }

  DeleteStationMonitoring(station: any) {
    this.MessagesService.confirm(
      '¿Esta seguro de eliminar este monitoreo de estación?',
      () => {
        this.mipFormsService.deleteDataForm(
          station.sation.id,
          MIP_FORM.STATION_COUNT_DATA
        ).subscribe((res) => {
          this.MessagesService.ShowMessage(TYPE_TOAST.SUCCESS, "Correcto", "Se ha eliminado correctamente")

          this.stationsMonitoring = this.stationsMonitoring.filter((item) => item.sation.id != station.sation.id);
        });
      }
    );
  }

  AddUpdateStationMonitoringPhotos(station: any, photo: any) {
    const dialog = this.DialogService.open(SelectStationMonitoringPhotosComponent, {
      header: 'Fotos de monitoreo de estación',
      modal: true,
      width: '30%',
      closable: true,
      draggable: false,
      maximizable: false,
      resizable: true,
      closeOnEscape: true,
      dismissableMask: true,
      data: {
        Station: station,
        Photo: photo,
      },
    });

    dialog.onClose.subscribe((res) => {
      if (res?.success) {
        const photoResponse = {
          id: res.photo.id,
          nesting_area__name: res.photo.nesting_area.name,
          photo: res.photo.photo,
          comments: res.photo.comments,
          station: res.photo.station,
        }

        if (res.isUpdate) {
          this.stationsMonitoring = this.stationsMonitoring.map((item) => {
            if (item.sation.id == photoResponse.station) {
              item.photos = item.photos.map((photoItem: any) => {
                if (photoItem.id == photoResponse.id) {
                  return photoResponse;
                }

                return photoItem;
              });
            }

            return item;
          });
        } else {
          this.stationsMonitoring = this.stationsMonitoring.map((item) => {
            if (item.sation.id == photoResponse.station) {
              item.photos.push(photoResponse);
            }

            return item;
          });
        }
      }
    });
  }

  DeleteStationMonitoringPhoto(photo: any) {
    this.MessagesService.confirm(
      '¿Esta seguro de eliminar esta fotografía?',
      () => {
        this.mipFormsService.deleteDataForm(
          photo.id,
          'station-count-photo'
        ).subscribe((res) => {
          this.MessagesService.ShowMessage(TYPE_TOAST.SUCCESS, "Correcto", "Se ha eliminado correctamente")

          this.stationsMonitoring = this.stationsMonitoring.map((item) => {
            item.photos = item.photos.filter((photoItem: any) => photoItem.id != photo.id);
            return item;
          });
        });
      }
    );
  }

  AddUpdateActionPlan(actionPlan: any) {
    const dialog = this.DialogService.open(AddUpdateActionPlanComponent, {
      header: 'Plan de acción',
      modal: true,
      width: '30%',
      closable: true,
      draggable: false,
      maximizable: false,
      resizable: true,
      closeOnEscape: true,
      dismissableMask: true,
      data: {
        ActionPlan: actionPlan,
        event: this.Event.id
      },
    });

    dialog.onClose.subscribe((res) => {
      if (res?.success) {
        const actionPlanResponse = {
          id: res.actionPlan.id,
          folio: res.actionPlan.folio,
          root_cause__name: res.actionPlan.root_cause.name,
          qualification__name: res.actionPlan.qualification.name,
          description: res.actionPlan.description,
          task_count: 0
        }

        if (res.isUpdate) {
          this.actionPlans = this.actionPlans.map((item) => {
            if (item.id == actionPlanResponse.id) {
              return actionPlanResponse
            }

            return item;
          });
        } else {
          this.actionPlans.push(actionPlanResponse);
        }
      }
    });
  }

  DeleteActionPlan(actionPlan: any) {
    this.MessagesService.confirm(
      '¿Esta seguro de eliminar este plan de acción?',
      () => {
        this.mipFormsService.deleteDataForm(
          actionPlan.id,
          MIP_FORM.ACTION_PLANS
        ).subscribe((res) => {
          this.MessagesService.ShowMessage(TYPE_TOAST.SUCCESS, "Correcto", "Se ha eliminado correctamente")

          this.actionPlans = this.actionPlans.filter((item) => item.id != actionPlan.id);
        });
      }
    );
  }

  AddUpdateTask(task: any) {
    const dialog = this.DialogService.open(AddUpdateTaskComponent, {
      header: 'Tarea',
      modal: true,
      width: '40%',
      closable: true,
      draggable: false,
      maximizable: false,
      resizable: true,
      closeOnEscape: true,
      dismissableMask: true,
      data: {
        Task: task,
        event: this.Event.id,
        customer: this.Event.customer.id,
      },
    });

    dialog.onClose.subscribe((res) => {
      if (res?.success) {
        const taskResponse = {
          id: res.task.id,
          title: res.task.title,
          folio: res.task.folio,
          description: res.task.description,
          status__name: res.task.status.name,
        }

        if (res.isUpdate) {
          this.tasks = this.tasks.map((item) => {
            if (item.id == taskResponse.id) {
              return taskResponse;
            }

            return item;
          });
        } else {
          this.tasks.push(taskResponse);
        }
      }
    });
  }

  DeleteTask(task: any) {
    this.MessagesService.confirm(
      '¿Esta seguro de eliminar la tarea ' + task.title + ' ?',
      () => {
        this.TasksService.deleteTask(
          task.id
        ).subscribe((res) => {
          this.MessagesService.ShowMessage(TYPE_TOAST.SUCCESS, "Correcto", "Se ha eliminado correctamente")

          this.tasks = this.tasks.filter((item) => item.id != task.id);
        });
      }
    );
  }

  AddUpdatePayment(payment: any) {
    const dialog = this.DialogService.open(AddUpdatePaymentComponent, {
      header: 'Pago',
      modal: true,
      width: '40%',
      closable: true,
      draggable: false,
      maximizable: false,
      resizable: true,
      closeOnEscape: true,
      dismissableMask: true,
      data: {
        Payment: payment,
        event: this.Event.id,
      },
    });

    dialog.onClose.subscribe((res) => {
      if (res?.success) {
        const paymentResponse = {
          id: res.payment.id,
          payment_method__name: res.payment.payment_method.name,
          payment_way__name: res.payment.payment_way.name,
          payment_way__credit_days: res.payment.payment_way.credit_days,
          amount: res.payment.amount,
        }

        if (res.isUpdate) {
          this.payments = this.payments.map((item) => {
            if (item.id == paymentResponse.id) {
              return paymentResponse;
            }

            return item;
          });
        } else {
          this.payments.push(paymentResponse);
        }
      }
    });
  }

  DeletePayment(payment: any) {
    this.MessagesService.confirm(
      '¿Esta seguro de eliminar este pago?',
      () => {
        this.mipFormsService.deleteDataForm(
          payment.id,
          'payment-forms'
        ).subscribe((res) => {
          this.MessagesService.ShowMessage(TYPE_TOAST.SUCCESS, "Correcto", "Se ha eliminado correctamente")

          this.payments = this.payments.filter((item) => item.id != payment.id);
        });
      }
    );
  }

  AddUpdateSignature(signature: any) {
    const dialog = this.DialogService.open(AddUpdateSignatureComponent, {
      header: 'Firma',
      modal: true,
      width: '40%',
      closable: true,
      draggable: false,
      maximizable: false,
      resizable: true,
      closeOnEscape: true,
      dismissableMask: true,
      data: {
        Signature: signature,
        event: this.Event.id,
      },
    });

    dialog.onClose.subscribe((res) => {
      if (res?.success) {
        const signatureResponse = {
          id: res.signature.id,
          signature: res.signature.signature,
          job_position: res.signature.job_position,
          name: res.signature.name,
          comments: res.signature.comments,
        }

        if (res.isUpdate) {
          this.signatures = this.signatures.map((item) => {
            if (item.id == signatureResponse.id) {
              return signatureResponse;
            }

            return item;
          });
        } else {
          this.signatures.push(signatureResponse);
        }
      }
    });
  }

  DeleteSignature(signature: any) {
    this.MessagesService.confirm(
      '¿Esta seguro de eliminar esta firma?',
      () => {
        this.mipFormsService.deleteDataForm(
          signature.id,
          'signature-forms'
        ).subscribe((res) => {
          this.MessagesService.ShowMessage(TYPE_TOAST.SUCCESS, "Correcto", "Se ha eliminado correctamente")

          this.signatures = this.signatures.filter((item) => item.id != signature.id);
        });
      }
    );
  }

  AddUpdateAreaMonitoring(area: any) {
    const dialog = this.DialogService.open(AddUpdateAreaMonitoringComponent, {
      header: 'Monitoreo de áreas',
      modal: true,
      width: '40%',
      closable: true,
      draggable: false,
      maximizable: false,
      resizable: true,
      closeOnEscape: true,
      dismissableMask: true,
      data: {
        Area: area,
        Event: this.Event
      },
    });

    dialog.onClose.subscribe((res) => {
      if (res?.success) {
        const areaResponse = {
          area: {
            id: res.area.id,
            nestingArea: res.area.nesting_area.name,
            activity_area: res.area.activity_area.map((item: any) => item.name),
            condition_area: res.area.condition_area.map((item: any) => item.name),
            plague: res.area.plague.map((item: any) => item.name),
            comments: res.area.comments,
          },
          photos: []
        }

        if (res.isUpdate) {
          this.areasActivity = this.areasActivity.map((item) => {
            if (item.area.id == areaResponse.area.id) {
              return areaResponse;
            }

            return item;
          });
        } else {
          this.areasActivity.push(areaResponse);
        }
      }
    });
  }

  DeleteAreaMonitoring(area: any) {
    this.MessagesService.confirm(
      '¿Esta seguro de eliminar este monitoreo de áreas?',
      () => {
        this.mipFormsService.deleteDataForm(
          area.area.id,
          MIP_FORM.AREAS_ACTIVITY
        ).subscribe((res) => {
          this.MessagesService.ShowMessage(TYPE_TOAST.SUCCESS, "Correcto", "Se ha eliminado correctamente")

          this.areasActivity = this.areasActivity.filter((item) => item.area.id != area.area.id);
        });
      }
    );
  }

  AddUpdateAreaMonitoringPhotos(area: any, photo: any) {
    const dialog = this.DialogService.open(SelectAreaMonitoringPhotosComponent, {
      header: 'Fotos de monitoreo de áreas',
      modal: true,
      width: '30%',
      closable: true,
      draggable: false,
      maximizable: false,
      resizable: true,
      closeOnEscape: true,
      dismissableMask: true,
      data: {
        Area: area,
        Photo: photo,
      },
    });

    dialog.onClose.subscribe((res) => {
      if (res?.success) {
        if (res.isUpdate) {
          this.areasActivity = this.areasActivity.map((item) => {
            if (item.area.id == res.photo.area_activity) {
              item.photos = item.photos.map((photoItem: any) => {
                if (photoItem.id == res.photo.id) {
                  return res.photo;
                }

                return photoItem;
              });
            }

            return item;
          });
        } else {
          this.areasActivity = this.areasActivity.map((item) => {
            if (item.area.id == res.photo.area_activity) {
              item.photos.push(res.photo);
            }

            return item;
          });
        }
      }
    });
  }

  DeleteAreaMonitoringPhoto(photo: any) {
    this.MessagesService.confirm(
      '¿Esta seguro de eliminar esta fotografía?',
      () => {
        this.mipFormsService.deleteDataForm(
          photo.id,
          'area-activity-photo'
        ).subscribe((res) => {
          this.MessagesService.ShowMessage(TYPE_TOAST.SUCCESS, "Correcto", "Se ha eliminado correctamente")

          this.areasActivity = this.areasActivity.map((item) => {
            item.photos = item.photos.filter((photoItem: any) => photoItem.id != photo.id);
            return item;
          });
        });
      }
    );
  }

  AddUpdateDocument(document: any) {
    const dialog = this.DialogService.open(AddUpdateDocumentComponent, {
      header: 'Documento',
      modal: true,
      width: '30%',
      closable: true,
      draggable: false,
      maximizable: false,
      resizable: true,
      closeOnEscape: true,
      dismissableMask: true,
      data: {
        Document: document,
        event: this.Event.id
      },
    });

    dialog.onClose.subscribe((res) => {
      if (res?.success) {
        if (res.isUpdate) {
          this.documents = this.documents.map((item) => {
            if (item.id == res.document.id) {
              return res.document;
            }

            return item;
          });
        } else {
          this.documents.push(res.document);
        }
      }
    });
  }

  DeleteDocument(document: any) {
    this.MessagesService.confirm(
      '¿Esta seguro de eliminar este documento?',
      () => {
        this.mipFormsService.deleteDataForm(
          document.id,
          'Attached-Document'
        ).subscribe((res) => {
          this.MessagesService.ShowMessage(TYPE_TOAST.SUCCESS, "Correcto", "Se ha eliminado correctamente")

          this.documents = this.documents.filter((item) => item.id != document.id);
        });
      }
    );
  }

  FinishEvent() {
    const dialog = this.DialogService.open(FinishServiceComponent, {
      header: 'Finalizar servicio',
      modal: true,
      width: '25%',
      closable: true,
      draggable: false,
      maximizable: false,
      resizable: true,
      closeOnEscape: true,
      dismissableMask: true,
      data: {
        Event: this.Event,
      },
    });

    dialog.onClose.subscribe((res) => {
      if (res?.success) {
        this.ref?.close({
          success: true,
        });
      }
    });
  }
}
