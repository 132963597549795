import { DateService } from './../../../../../services/date.service';
import { UserService } from './../../../../../services/user.service';
import { MipFormsService } from 'src/app/system/services/MipForms.service';
import { Event } from './../../../../../core/models/action-plan.interface';
import { Component, EventEmitter, Inject, Output, inject } from '@angular/core';
import { LoadingService } from 'src/app/system/services/loading.service';
import { DialogService } from 'primeng/dynamicdialog';
import { MessagesService } from 'src/app/system/services/message.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TYPE_TOAST } from 'src/app/system/core/enums/ToastsEnums';
import { MipFormsComponent } from '../mip-forms.component';
import { PermissionsServices } from 'src/app/system/services/permissions.service';

@Component({
  selector: 'app-finish',
  templateUrl: './finish.component.html',
  styleUrls: ['./finish.component.scss']
})
export class FinishComponent {
  public permissionsService = inject(PermissionsServices);

  Signatures:any[] = [];
  Form: FormGroup = this.FormBuilder.group({
    id: '',
    initial_date: ['', Validators.required],
    final_date: ['', Validators.required],
    initial_hour: [''],
    final_hour: [''],
    job_center: this.UserService.JobCenter,
  });
  @Output() closeEvent = new EventEmitter<void>();

  constructor(@Inject('dynamicData') public Event: any,  private DateService:DateService, private FormBuilder:FormBuilder, private UserService:UserService, private MipFormsService:MipFormsService, private LoadingService:LoadingService, private DialogService:DialogService, private MessagesService:MessagesService){

  }


    ngOnInit(): void {

if(this.Event.real_initial_date){

  const newDate = new Date(); // Ejemplo: fecha actual


const real_initial_date = this.Event.real_initial_date;
const real_initial_time = this.Event.real_initial_hour;

const real_final_time = this.Event.real_final_hour;
const real_final_date = this.Event.real_final_date;
let combinedDateTimeInitial = new Date(real_initial_date + 'T' + real_initial_time);
let combinedDateTimeFinal = new Date(real_final_date + 'T' + real_final_time);

this.Form.patchValue({
  initial_date: combinedDateTimeInitial,
  final_date: combinedDateTimeFinal,

});
}

    }


    accept(){

      /**
       *
       */

let data = {
  "real_initial_date":this.DateService.getFormatDataDate(this.Form.value.initial_date),
  "real_final_date":this.DateService.getFormatDataDate(this.Form.value.final_date),
  "real_initial_hour":this.DateService.getFormatDataTime(this.Form.value.initial_date),
  "real_final_hour":this.DateService.getFormatDataTime(this.Form.value.final_date),
}

        this.MipFormsService.finishServiceMip(
          data,
          this.Event.id
        ).subscribe((res) => {
          this.LoadingService.Hide();
          this.MessagesService.ShowMessage(
            TYPE_TOAST.SUCCESS,
            'Correcto',
            'Finalizado correctamente'
          );

        });



    }


}
