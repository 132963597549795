import { Component, inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DialogService } from 'primeng/dynamicdialog';

import { TYPE_TOAST } from 'src/app/system/core/enums/ToastsEnums';
import { CatalogsEnum, URL_ENDPOINTS } from 'src/app/system/core/enums/catalogs.model';
import { CalendarService } from 'src/app/system/services/calendar.service';
import { DateService } from 'src/app/system/services/date.service';
import { LoadingService } from 'src/app/system/services/loading.service';
import { MessagesService } from 'src/app/system/services/message.service';
import { PermissionsServices } from 'src/app/system/services/permissions.service';
import { UserService } from 'src/app/system/services/user.service';
import { environment } from 'src/environments/environment';
import { SendQuoteComponent } from '../../../quotes/components/send-quote/send-quote.component';
import { MipFormsComponent } from '../../components/MIP-FORMS/mip-forms.component';
import { AddUpdatePaymentMipComponent } from '../../components/MIP-FORMS/payments/add-update-payment-mip/add-update-payment-mip.component';
import { CancelEventComponent } from '../../components/cancel-event/cancel-event.component';
import { CreateUpdateEventFormComponent } from '../../components/create-update-event-form/create-update-event-form.component';
import { DocumentsComponent } from '../../components/documents/documents.component';
import { FiltersCalendarComponent } from '../../components/filters-calendar/filters-calendar.component';
import { ReactivateEventComponent } from '../../components/reactivate-event/reactivate-event.component';
import { SendIndicationsComponent } from '../../components/send-indications/send-indications.component';
import { SendReminderComponent } from '../../components/send-reminder/send-reminder.component';
import { SendSignatureRequestComponent } from '../../components/send-signature-request/send-signature-request.component';
import { ViewEventComponent } from '../../components/view-event/ViewEvent.component';

@Component({
  selector: 'app-events-calendar',
  templateUrl: './events-calendar.component.html',
  styleUrls: ['./events-calendar.component.scss'],
})
export class EventsCalendarComponent implements OnInit {
  public index: number = 0;
  public Catalog = CatalogsEnum;
  public groupSearch = '';
  public eventCustomer: string = '';
  public dataForm!: FormGroup;
  public events: any[] = [];
  public statusOptions: any[] = [];
  public filteredStatus: any[] | undefined;
  public URL_CATALOGS = URL_ENDPOINTS;
  public StatusConfirm: any;

  public FormGroup: FormGroup = this.FormBuilder.group({
    group: [null],
    event_type: [null],
    employee: [null],
    customer: [null],
    quote: [null],
    is_pay: [null],
    service_type: [null],
    plague: [null],
  });

  public permissionsService = inject(PermissionsServices);
  page_size: number = 10;

  constructor(
    private UserService: UserService,
    private MessagesService: MessagesService,
    private DialogService: DialogService,
    private FormBuilder: FormBuilder,
    private CalendarService: CalendarService,
    private loading: LoadingService,
    private DateService: DateService
  ) { }

  ngOnInit(): void {
    this.page_size = Number(localStorage.getItem("page_size")) == 0 ? 10 : Number(localStorage.getItem("page_size"));

    if (localStorage.getItem('eventCustomer')) {
      this.eventCustomer = localStorage.getItem('eventCustomer') || '';
      this.addEvent();
    }

    this.loadDataForm();
    this.loadStatusOptions();
    this.loadData();
  }

  handleChange(event: any) {
    this.index = event.index;
  }

  openFilters() {
    const dialog = this.DialogService.open(FiltersCalendarComponent, {
      header: 'Filtrar calendario',
      modal: true,
      width: '30rem',
      //height:"100%",
      closable: true,
      draggable: false,
      // maximizable: true,
      resizable: true,
      closeOnEscape: true,
      dismissableMask: true,
    });

    dialog.onClose.subscribe((res) => { });
  }


  resetFilters() {
    this.FormGroup.reset();
    this.CalendarService.FilterTable(null);
  }

  filterByGroup(e: any) {
    this.CalendarService.FilterTable(this.FormGroup.value);
  }

  filterStatus(event: any) {
    let filtered: any[] = [];
    let query = event.query;

    for (let i = 0; i < (this.statusOptions as any[]).length; i++) {
      let country = (this.statusOptions as any[])[i];

      if (country.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(country);
      }
    }

    this.filteredStatus = filtered;
  }

  addEvent() {
    if (this.permissionsService.hasPermission('events', 'add')) {
      const dialog = this.DialogService.open(CreateUpdateEventFormComponent, {
        header: 'Nuevo evento',
        modal: true,
        width: '50%',
        //height:"100%",
        closable: true,
        draggable: false,
        // maximizable: true,
        resizable: true,
        closeOnEscape: true,
        dismissableMask: true,
        data: {
          Event: null,
        },
      });

      dialog.onClose.subscribe((res) => {
        if (res?.success) {
          this.CalendarService.FilterTable({});
        }
      });
    }
  }

  onFilterInput(event: any, filter: string) {
    if (filter == 'search') this.dataForm.value.search = event.target.value;
    if (filter == 'employee') this.dataForm.value.employee = event.id;
    if (filter === 'plague') this.dataForm.value.plague = event.id;
    if (filter == 'customer') this.dataForm.value.customer = event?.id;
    if (filter == 'status') this.dataForm.value.status = event;
    if (filter == 'customer__phone') this.dataForm.value.customer__phone = event.target.value;
    if (filter == 'customer__address') this.dataForm.value.customer__address = event.target.value;
    this.loadData();
  }

  loadData(): void {
    this.CalendarService.getEvents(this.dataForm.value).subscribe(
      (response) => {
        this.loading.Hide();

        this.events = response.items;

        this.dataForm.value.page_size = response.page_size;
        this.dataForm.value.page = response.page;
        this.dataForm.value.totalRecords = response.total;
      }
    );
  }

  loadDataForm(): void {
    this.dataForm = this.FormBuilder.group({
      page_size: this.page_size,
      first: 0,
      page: 1,
      rows: this.page_size,
      search: '',
      status: '',
      customer__phone: '',
      customer__address: '',

      employee: '',
      customer: '',
      totalRecords: 0,
      creation_date: '',
      created_at: '',
      range_date: '',
      event_initial_date: '',
      event_final_date: '',
      plague: [null],
    });
  }

  loadStatusOptions() {
    this.CalendarService.getStatusOptions().subscribe((res: any[]) => {
      this.StatusConfirm = res.find((e) => e.key_string == "event-confirm");
      this.statusOptions = res.map((status) => status.name);
    });
  }

  onPageChange(event: any) {

    this.page_size = event.rows;
    localStorage.setItem("page_size", event.rows)

    this.dataForm.value.first = event.first;

    this.dataForm.value.page_size = event.rows;
    this.dataForm.value.pageCount = event.pageCount;
    this.dataForm.value.page = event.page + 1;

    this.loadData();
  }

  resetTable() {
    this.dataForm.patchValue({
      page_size: this.page_size,
      first: 0,
      page: 1,
      rows: this.page_size,
      search: '',
      status: '',
      employee: '',
      customer: '',
      range_date: '',
      customer__phone: '',
      customer__address: '',
      creation_date: '',
      created_at: '',
      event_initial_date: '',
      event_final_date: '',
      totalRecords: 0,
    });

    this.loadData();
  }

  searchStatus(event: any) {
    let filtered: any[] = [];
    let query = event.query;

    console.log('Status Options: ', this.statusOptions);

    // for (let i = 0; i < (this.statusOptions as any[]).length; i++) {
    //   let country = (this.statusOptions as any[])[i];
    //   if (country.name.toLowerCase().indexOf(query.toLowerCase()) == 0) {
    //     filtered.push(country);
    //   }
    // }

    // this.statusOptions = filtered;
  }

  deleteEvent(event: any) {
    this.MessagesService.confirm(
      '¿Esta seguro de eliminar el event? ' + event.folio,
      () => {
        this.loading.Show();
        this.CalendarService.deleteEvent(event.id).subscribe((res) => {
          this.loading.Hide();
          window.location.reload();
          this.MessagesService.ShowMessage(
            TYPE_TOAST.SUCCESS,
            'Eliminado correctamente',
            ''
          );
        });
      }
    );
  }

  CrudMipForms(event: any) {
    this.CalendarService.getEventById(event.id).subscribe((res) => {
      this.loading.Hide();

      const dialog = this.DialogService.open(MipFormsComponent, {
        header: 'Folio ' + event.folio,
        modal: true,
        width: '75%',
        closable: true,
        draggable: false,
        maximizable: true,
        resizable: true,
        closeOnEscape: true,
        dismissableMask: true,
        data: {
          Event: res.data,
        },
      });

      dialog.onClose.subscribe((res) => {
        this.loadData();
      });
    });
  }

  openDocuments(event: any) {
    this.CalendarService.getEventById(event.id).subscribe((res) => {
      this.loading.Hide();

      const dialog = this.DialogService.open(DocumentsComponent, {
        header: 'Folio ' + event.folio,
        modal: true,
        width: '32rem',
        closable: true,
        draggable: false,
        maximizable: true,
        resizable: true,
        closeOnEscape: true,
        dismissableMask: true,
        data: {
          Event: res.data,
        },
      });

      dialog.onClose.subscribe((res) => {
        //  this.ref.close({success:true})
        this.loadData();
      });
    });
  }

  formatHour(hour: string): string {
    return new Date('1970-01-01T' + hour).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true });
  }

  exportExcel() {
    this.loading.Show();

    this.CalendarService.exportExcelCustomer(this.dataForm.value).subscribe((response: any) => {
      const blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');

      a.href = url;
      a.download = 'Eventos.xlsx'; // Nombre del archivo a descargar
      a.click();

      window.URL.revokeObjectURL(url); // Liberar memoria
      this.loading.Hide();
    }, (error: any) => {
      console.error('Error al descargar el archivo:', error);
      this.loading.Hide();
    });
  }

  plantilla(event: any) {
    this.CalendarService.getDocumentPlantilla(event.id).subscribe(
      (res) => {
        if (res.is_s3) {
          window.open(res.data, '_blank');
          return;
        }

        this.loading.Show();

        this.CalendarService.getDocument(res.data).subscribe((res) => {
          let url = environment.s3Url + res.data;
          window.open(url, '_blank');

          this.loading.Hide();
        });
      }
    );
  }

  confirmar(event: any) {
    this.loading.Show();

    this.CalendarService.ConfirmEvent(event.id,).subscribe((res) => {
      this.loadData();
      this.loading.Hide();
    });
  }

  searchDate(typeDate: string) {
    if (typeDate == 'CREACION') {
      let creation_date!: string;
      creation_date = this.DateService.getFormatDataDate(
        this.dataForm.value.creation_date
      );

      this.dataForm.patchValue({
        created_at: creation_date,
      });

      this.loadData();
      return;
    }

    let initial_date!: string, final_date: string;
    let range_date: string = this.dataForm.value.range_date[1];

    if (range_date !== null) {
      initial_date = this.DateService.getFormatDataDate(
        this.dataForm.value.range_date[0]
      );

      final_date = this.DateService.getFormatDataDate(
        this.dataForm.value.range_date[1]
      );

      this.dataForm.patchValue({
        event_initial_date: initial_date,
        event_final_date: final_date,
      });

      this.loadData();
    }
  }

  getEventStatusTag(status: string): string {
    const SEVERITY_DICT: any = {
      'event-confirm': 'success',
      'event-completed': 'success',
      'event-created': 'primary',
      'event-cancelled': 'danger',
      'event-in-progress': 'secondary',
    };

    if (status && status in SEVERITY_DICT) {
      return SEVERITY_DICT[status];
    }

    return 'secondary';
  }

  getStatusForMenu(status: string): string {
    const EVENT_STATUS_DICT: any = {
      'event-completed': 'completed',
      'event-created': 'created',
      'event-cancelled': 'cancelled',
      'event-in-progress': 'created',
    };

    if (status && status in EVENT_STATUS_DICT) {
      return EVENT_STATUS_DICT[status];
    }

    return 'created';
  }

  openWarranty(event: any) {
    let Warranty: any = null;

    if (!event.warranty) {
      this.MessagesService.ShowMessage(TYPE_TOAST.ERROR, "No cuenta con servicio de garantía", "");
      return
    }

    this.loading.Show();

    this.CalendarService.getEventsType().subscribe((res) => {
      Warranty = res.find((e: any) => e.name.toUpperCase() == "GARANTIA" || e.name.toUpperCase() == "GARANTÍA")

      this.CalendarService.getEventById(event.id).subscribe((res) => {
        this.loading.Hide();

        if (!Warranty) {
          this.MessagesService.ShowMessage(TYPE_TOAST.ERROR, "Tienes que dar de alta el servicio de garantía en tipo de eventos para continuar", "");
          return
        }

        let Date = null;

        const dialog = this.DialogService.open(CreateUpdateEventFormComponent, {
          header: 'Evento ' + event.folio,
          modal: true,
          width: '50%',
          closable: true,
          draggable: false,
          resizable: true,
          closeOnEscape: true,
          dismissableMask: true,
          data: {
            Event: res.data,
            Date,
            Warranty
          },
        });

        dialog.onClose.subscribe((res) => {
          window.location.reload();
        });
      });
    });
  }

  openBootster(event: any) {
    let Bootster: any = null;

    if (!event.bootster) {
      this.MessagesService.ShowMessage(TYPE_TOAST.ERROR, "No cuenta con servicio de refuerzo", "");
      return
    }

    this.loading.Show();

    this.CalendarService.getEventsType().subscribe((res) => {
      Bootster = res.find((e: any) => e.name.toUpperCase() == "REFUERZO")

      this.CalendarService.getEventById(event.id).subscribe((res) => {
        this.loading.Hide();

        if (!Bootster) {
          this.MessagesService.ShowMessage(TYPE_TOAST.ERROR, "Tienes que dar de alta el servicio de refuerzo en tipo de eventos para continuar", "");
          return
        }

        let Date = null;

        const dialog = this.DialogService.open(CreateUpdateEventFormComponent, {
          header: 'Evento ' + event.folio,
          modal: true,
          width: '50%',
          closable: true,
          draggable: false,
          resizable: true,
          closeOnEscape: true,
          dismissableMask: true,
          data: {
            Event: res.data,
            Date,
            Bootster
          },
        });

        dialog.onClose.subscribe((res) => {
          window.location.reload();
        });
      });
    });
  }

  openFollow(event: any) {
    let Follow: any = null;

    if (!event.follow) {
      this.MessagesService.ShowMessage(TYPE_TOAST.ERROR, "No cuenta con servicio de Seguimiento", "");
      return
    }

    this.loading.Show();

    this.CalendarService.getEventsType().subscribe((res) => {
      Follow = res.find((e: any) => e.name.toUpperCase() == "SEGUIMIENTO");

      this.CalendarService.getEventById(event.id).subscribe((res) => {
        this.loading.Hide();

        if (!Follow) {
          this.MessagesService.ShowMessage(TYPE_TOAST.ERROR, "Tienes que dar de alta el servicio de seguimiento en tipo de eventos para continuar", "");
          return
        }

        let Date = null;

        const dialog = this.DialogService.open(CreateUpdateEventFormComponent, {
          header: 'Evento ' + event.folio,
          modal: true,
          width: '50%',
          closable: true,
          draggable: false,
          resizable: true,
          closeOnEscape: true,
          dismissableMask: true,
          data: {
            Event: res.data,
            Date,
            Follow
          },
        });

        dialog.onClose.subscribe((res) => {
          window.location.reload();
        });
      });
    });
  }

  sendCertificateEmail(item: any) {
    const dialog = this.DialogService.open(SendQuoteComponent, {
      header: "Enviar Certificado ",
      modal: true,
      width: "38rem",
      closable: true,
      draggable: false,
      maximizable: false,
      resizable: true,
      closeOnEscape: true,
      dismissableMask: true,
      data: {
        Quote: null,
        Event: item,
      },
    });

    dialog.onClose.subscribe((res) => {
      if (res?.success) {
        this.loadData();
      }
    });
  }

  sendEvaluate(item: any) {
    const dialog = this.DialogService.open(SendQuoteComponent, {
      header: "Enviar evaluación ",
      modal: true,
      width: "38rem",
      closable: true,
      draggable: false,
      maximizable: false,
      resizable: true,
      closeOnEscape: true,
      dismissableMask: true,
      data: {
        Quote: null,
        Event: item,
        evaluation: true
      },
    });

    dialog.onClose.subscribe((res) => {
      if (res?.success) {
        this.loadData();
      }
    });
  }

  editEvent(event: any) {
    this.loading.Show();

    this.CalendarService.getEventById(event.id).subscribe((res) => {
      this.loading.Hide();
      let Date = null;

      const dialog = this.DialogService.open(CreateUpdateEventFormComponent, {
        header: 'Evento ' + event.folio,
        modal: true,
        width: '50%',
        //height:"100%",
        closable: true,
        draggable: false,
        // maximizable: true,
        resizable: true,
        closeOnEscape: true,
        dismissableMask: true,
        data: {
          Event: res.data,
          Date,
        },
      });

      dialog.onClose.subscribe((res) => {
        // this.ref.close({success:true})
        window.location.reload();
        // this.loadData();
      });
    });
  }

  get Symbol_Country() {
    return this.UserService.Symbol_Country ?? '';
  }

  sendIndications(event: any) {
    this.loading.Show();

    this.CalendarService.getEventById(event.id).subscribe((res) => {
      this.loading.Hide();

      this.DialogService.open(SendIndicationsComponent, {
        header: 'Enviar indicaciones del evento ' + event.folio,
        modal: true,
        width: '30rem',
        closable: true,
        draggable: false,
        resizable: true,
        closeOnEscape: true,
        dismissableMask: true,
        data: {
          Event: res.data,
        },
      });
    });
  }

  sendCertificateWhatsApp(event: any) {
    this.loading.Show();

    this.CalendarService.sendCertificateWhatsApp(event.id).subscribe((res) => {
      if (res?.data) {
        window.open(res.data, '_blank');
      }

      this.loading.Hide();
    });
  }

  viewEvent(event: any) {
    let idEvent = event.id;

    this.loading.Show();

    this.CalendarService.viewEvent(idEvent).subscribe((res) => {
      const dialog = this.DialogService.open(ViewEventComponent, {
        header: 'Evento ' + event.folio,
        modal: true,
        width: '60%',
        //height:"100%",
        closable: true,
        draggable: false,
        // maximizable: true,
        resizable: true,
        closeOnEscape: true,
        dismissableMask: true,
        data: {
          Event: event,
          DetailEvent: res
        },
      });

      dialog.onClose.subscribe((res) => {
        // this.ref.close({success:true})
        this.loadData();
      });

      this.loading.Hide();
    });
  }

  sendReminder(event: any) {
    this.loading.Show();

    this.CalendarService.getEventById(event.id).subscribe((res) => {
      this.loading.Hide();

      this.DialogService.open(SendReminderComponent, {
        header: 'Enviar recordatorio del evento ' + event.folio,
        modal: true,
        width: '30rem',
        closable: true,
        draggable: false,
        resizable: true,
        closeOnEscape: true,
        dismissableMask: true,
        data: {
          Event: res.data,
        },
      });
    });
  }

  cancelEvent(event: any) {
    const dialog = this.DialogService.open(CancelEventComponent, {
      header: 'Cancelar evento ' + event.folio,
      modal: true,
      width: '30rem',
      closable: true,
      draggable: false,
      resizable: true,
      closeOnEscape: true,
      dismissableMask: true,
      data: {
        Event: event,
      },
    });

    dialog.onClose.subscribe((res) => {
      this.loadData();
    });
  }

  seeCertificate(event: any) {
    this.loading.Show();

    this.CalendarService.getServiceCertificatePdf(event.id).subscribe({
      next: (response) => {
        const url = environment.s3Url + response.data;
        window.open(url, '_blank');

        this.loading.Hide();
      },
      error: (err) => {
        console.error('Error fetching service certificate: ', err);

        this.loading.Hide();
        this.MessagesService.ShowMessage(
          TYPE_TOAST.ERROR,
          'Error',
          'Error al obtener el certificado del servicio'
        );
      },
      complete: () => {
        this.loading.Hide();
      },
    });
  }

  sendSignatureRequest(item: any) {
    const dialog = this.DialogService.open(SendSignatureRequestComponent, {
      header: "Enviar solicitud de firma ",
      modal: true,
      width: "38rem",
      closable: true,
      draggable: false,
      maximizable: false,
      resizable: true,
      closeOnEscape: true,
      dismissableMask: true,
      data: {
        Event: item,
      },
    });

    dialog.onClose.subscribe((res) => {
      if (res?.success) {
        this.loadData();
      }
    });
  }

  seeServiceOrder(event: any) {
    this.loading.Show();

    this.CalendarService.getServiceOrderPdf(event.id).subscribe({
      next: (response) => {
        const url = environment.s3Url + response.data;
        window.open(url, '_blank');

        this.loading.Hide();
      },
      error: (err) => {
        console.error('Error fetching service order: ', err);

        this.loading.Hide();
        this.MessagesService.ShowMessage(
          TYPE_TOAST.ERROR,
          'Error',
          'Error al obtener la orden de servicio'
        );
      },
      complete: () => {
        this.loading.Hide();
      },
    });
  }

  payEvent(event: any) {
    this.loading.Show();

    this.CalendarService.getEventById(event.id).subscribe((res) => {
      this.loading.Hide();

      const dialog = this.DialogService.open(AddUpdatePaymentMipComponent, {
        header: 'Pago del servicio',
        modal: true,
        width: '42rem',
        closable: true,
        draggable: false,
        maximizable: false,
        resizable: true,
        closeOnEscape: true,
        dismissableMask: true,
        data: {
          Event: res.data,
          Payment: null,
        },
      });

      dialog.onClose.subscribe((res) => {
        this.loadData();
      });
    });
  }

  reactivateEvent(event: any) {
    this.loading.Show();

    this.CalendarService.getEventById(event.id).subscribe((res) => {
      this.loading.Hide();

      const dialog = this.DialogService.open(ReactivateEventComponent, {
        header: 'Reactivar evento ' + event.folio,
        modal: true,
        width: '30rem',
        closable: true,
        draggable: false,
        resizable: true,
        closeOnEscape: true,
        dismissableMask: true,
        data: {
          Event: res.data,
        },
      });

      dialog.onClose.subscribe((res) => {
        window.location.reload();
      });
    });
  }
}
