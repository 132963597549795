<div class="grid formgrid">
  <div class="col-12  mt-4">
    <h3>
      ¿Estas seguro que deseas enviar las indicaciones?
    </h3>
  </div>
</div>

<div class="text-center mt-3  mb-4 ">
  <button (click)="sendIndications()" pButton pRipple type="submit" label="Confirmar"
    class="p-button-raised btn-sm"></button>
</div>

<div class="text-center mt-3  mb-4 ">
</div>
