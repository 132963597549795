import { InputMaskModule } from 'primeng/inputmask';

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ButtonModule} from "primeng/button";
import {CardModule} from "primeng/card";
import {MenubarModule} from "primeng/menubar";
import {FieldsetModule} from "primeng/fieldset";
import {ListboxModule} from "primeng/listbox";
import {AutoCompleteModule} from "primeng/autocomplete";
import {RadioButtonModule} from "primeng/radiobutton";
import {InputSwitchModule} from "primeng/inputswitch";
import {ChipModule} from "primeng/chip";
import {TagModule} from "primeng/tag";
import {InputTextModule} from "primeng/inputtext";
import {BadgeModule} from "primeng/badge";
import {AvatarModule} from "primeng/avatar";
import {MenuModule} from "primeng/menu";
import {PanelMenuModule} from "primeng/panelmenu";
import {CalendarModule} from "primeng/calendar";
import {PasswordModule} from "primeng/password";
import {DividerModule} from "primeng/divider";
import {DropdownModule} from "primeng/dropdown";
import {DialogModule} from "primeng/dialog";
import {CheckboxModule} from "primeng/checkbox";
import {KeyFilterModule} from 'primeng/keyfilter';
import {ScrollPanelModule} from "primeng/scrollpanel";
import {FileUploadModule} from "primeng/fileupload";
import {DynamicDialogModule} from "primeng/dynamicdialog";
import {MessagesModule} from "primeng/messages";
import {MessageModule} from "primeng/message";
import {TableModule} from "primeng/table";
import {MultiSelectModule} from "primeng/multiselect";
import {TabMenuModule} from "primeng/tabmenu";
import {TabViewModule} from "primeng/tabview";
import {ContextMenuModule} from "primeng/contextmenu";
import {ConfirmDialogModule} from "primeng/confirmdialog";
import {ToastModule} from "primeng/toast";
import {StepsModule} from "primeng/steps";
import {ColorPickerModule} from "primeng/colorpicker";
import {ImageModule} from "primeng/image";
import {PaginatorModule} from "primeng/paginator";
import {InputTextareaModule} from "primeng/inputtextarea";
import {OverlayPanelModule} from "primeng/overlaypanel";
import {EditorModule} from "primeng/editor";
import {ConfirmPopupModule} from "primeng/confirmpopup";
import {GalleriaModule} from "primeng/galleria";
import {PanelModule} from 'primeng/panel';
import {KnobModule} from 'primeng/knob';
import {SidebarModule} from 'primeng/sidebar';
import {AccordionModule} from "primeng/accordion";
import {TreeModule} from 'primeng/tree';
import {TimelineModule} from "primeng/timeline";
import {CarouselModule} from "primeng/carousel";
import { RippleModule } from 'primeng/ripple';
import { TooltipModule } from 'primeng/tooltip';
import { InputNumberModule } from 'primeng/inputnumber';
import { ChartModule } from 'primeng/chart';
import { FormsModule } from '@angular/forms';
import { AvatarGroupModule } from 'primeng/avatargroup';
import { ProgressBarModule } from 'primeng/progressbar';
import { FullCalendarModule } from '@fullcalendar/angular';
import { EventService } from '../../demo/service/event.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { StyleClassModule } from 'primeng/styleclass';
//import { PickListModule } from 'primeng/picklist';
import { OrganizationChartModule } from 'primeng/organizationchart';

@NgModule({
  declarations: [],
  exports: [
    ButtonModule,
    CardModule,
    MenubarModule,
    FieldsetModule,
    ListboxModule,
    AutoCompleteModule,
    RadioButtonModule,
    InputSwitchModule,
    ChipModule,
    TagModule,

    InputTextModule,
    BadgeModule,
    AvatarModule,
    MenuModule,
    PanelMenuModule,
    CalendarModule,
    PasswordModule,
    DividerModule,
    DropdownModule,
    DialogModule,
    CheckboxModule,
    KeyFilterModule,
    ScrollPanelModule,
    ///PickListModule,
    FileUploadModule,
    DynamicDialogModule,
    MessageModule,
    MessagesModule,
    TableModule,
    MultiSelectModule,
    TabMenuModule,
    TabViewModule,
    ContextMenuModule,
    ConfirmDialogModule,
    ToastModule,
    StepsModule,
    ColorPickerModule,
    ImageModule,
    PaginatorModule,
    InputTextareaModule,
    OverlayPanelModule,
    EditorModule,
    ConfirmPopupModule,
    GalleriaModule,
    PanelModule,
    KnobModule,
    SidebarModule,
    AccordionModule,
    TreeModule,
    TimelineModule,
    CarouselModule,
    FullCalendarModule,
CalendarModule,
    RippleModule,

    TooltipModule,

    InputNumberModule,
    ChartModule,
    FormsModule,

    AvatarGroupModule,

    ProgressBarModule,

    InputMaskModule,

    OrganizationChartModule,
    StyleClassModule,
    BadgeModule,
    RadioButtonModule,
    InputSwitchModule,
    RippleModule,
    TooltipModule,


  ],
  imports: [
    CommonModule,
    
  ],
  providers: [EventService,MessageService,ConfirmationService]
})
export class PrimengModule { }
