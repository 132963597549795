import { Component, inject } from '@angular/core';
import { CatalogsEnum } from 'src/app/system/core/enums/catalogs.model';
import { MipPhotosForm } from '../../../generic-components/carousel-images-form/carousel-images-form.component';
import { UtilsService } from 'src/app/system/services/utils.service';
import { DialogService, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { MipFormsService } from 'src/app/system/services/MipForms.service';
import { LoadingService } from 'src/app/system/services/loading.service';
import { MessagesService } from 'src/app/system/services/message.service';
import { TYPE_TOAST } from 'src/app/system/core/enums/ToastsEnums';
import { PermissionsServices } from 'src/app/system/services/permissions.service';

@Component({
  selector: 'app-photos-condiciones',
  templateUrl: './photos-condiciones.component.html',
  styleUrls: ['./photos-condiciones.component.scss']
})
export class PhotosCondicionesComponent {
  public permissionsService = inject(PermissionsServices);
  Condition:any;
  loading: boolean = false;
  index : number = 0;
  Photos:any[] = [];
  files: File[] = [];
  Catalog = CatalogsEnum;
  randomValue = Math.random();
    responsiveOptions: any[] | undefined;
    FormValue:MipPhotosForm = {
      KeyForm:'condition',
      ValueId:'',
      RouteForm:'/mip/condition-forms-photo'
    };
  constructor( private UtilsService:UtilsService, public config: DynamicDialogConfig,public MipFormsService:MipFormsService, private LoadingService:LoadingService, private DialogService:DialogService, private MessagesService:MessagesService){

  }

  handleChange(event: any) {
    this.index = event.index;
  }
    ngOnInit(): void {


this.Condition = this.config.data.Condition
this.FormValue.ValueId =  this.Condition.id;

    }



    onSelect(event:any) {
this.files = event;

    }

   async  save(){
      await this.saveImages(this.Condition.id);


        }

        async saveImages(idPadre:string){
          for (const file of this.files) {
            const data = {
              photo: file,
              condition: idPadre,
            };

            const formData = this.UtilsService.objectToFormData(data);
            await this.MipFormsService.saveDataForm(formData, 'condition-forms-photo').toPromise();
          }

          // Mostrar alerta después de que todas las fotos se hayan guardado
          this.LoadingService.Hide();
          this.MessagesService.ShowMessage(TYPE_TOAST.SUCCESS,'Correcto','Guardado correctamente');

      this.index = 0;

        }
}
