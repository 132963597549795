import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class AgentsService {
  private _http = inject(HttpClient);
  private _userService = inject(UserService);

  private baseUrl: string = environment.baseUrl;
  private agentSubject = new BehaviorSubject<any[]>([]);
  public agents$ = this.agentSubject.asObservable();

  constructor() {
    this.loadAgents();
  }

  public loadAgents() {
  
    const jobCenterId = this._userService.JobCenter;

    this._http.get<any[]>(`${this.baseUrl}/agent/?job_center=${jobCenterId}`).subscribe((agents) => {
      this.agentSubject.next(agents);
    });
  }

  public getAgent(id: string) {
    return this._http.get<any>(`${this.baseUrl}/agent/${id}/`);
  }

  public saveAgent(data: any) {
    data.job_center = this._userService.JobCenter;

    if (data.id) {
      return this._http.put<any>(`${this.baseUrl}/agent/${data.id}/`, data).pipe(
        tap((agent) => {
          const currentAgents = this.agentSubject.value.map((a) =>
            a.id === data.id ? agent : a
          );

          this.agentSubject.next(currentAgents);
        })
      ).subscribe();
    } else {
      return this._http.post<any>(`${this.baseUrl}/agent/`, data).pipe(
        tap((newAgent) => {
          const currentAgents = this.agentSubject.value;
          this.agentSubject.next([...currentAgents, newAgent]);
        })
      ).subscribe();
    }
  }

  public deleteAgent(id: number) {
    return this._http.delete<any>(`${this.baseUrl}/agent/${id}/`).pipe(
      tap(() => {
        const currentAgents = this.agentSubject.value.filter((a) => a.id !== id);
        this.agentSubject.next(currentAgents);
      })
    );
  }
}
