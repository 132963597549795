




<button *appHasPermission="permissionsService.PERMISSIONS_ADD.MIP" (click)="AddUpdate(null)" pButton class="mb-4 p-button-raised w-full sm:w-auto flex-order-0 sm:flex-order-1  p-button-info" icon="pi pi-plus" severity="info" label="Nuevo registro"></button>

<h3 *ngIf="Control.length ==0">No tienes registros</h3>
<h5 *ngIf="Control.length >0">Control plagas</h5>




<p-table
class="custom-table "
styleClass="p-datatable-sm"
scrollDirection="horizontal"
#dtCalendar
[value]="Control"
dataKey="id"
sortMode="multiple"
[rows]="10"
[showCurrentPageReport]="true"
[rowsPerPageOptions]="[10, 25, 50]"
[paginator]="false"
[globalFilterFields]="['name']">
  <ng-template pTemplate="header">
      <tr>
          <th      class="white-space-nowrap bg-primary " style="width: 5rem"></th>
          <th     class="white-space-nowrap bg-primary " >Control plaga  </th>
          <th     class="white-space-nowrap bg-primary ">Acciones</th>

      </tr>
  </ng-template>
  <ng-template pTemplate="body" let-control let-expanded="expanded">
      <tr class="border-bottom">
          <td>
              <button type="button" pButton pRipple [pRowToggler]="control" class="p-button-text p-button-rounded p-button-plain fw-bold" [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
          </td>
          <td><span class="fw-bold h5">{{ control.name
          }}</span></td>
          <td >
            <p-button
            *appHasPermission="permissionsService.PERMISSIONS_ADD.MIP"
            (click)="deleteItem(control)"
          type="button"
          icon="fa-solid fa-trash"
          styleClass="p-button-rounded p-button-outlined p-button-danger p-button-sm ml-2 btn-sm"
          >
          </p-button>
            <p-button
            *appHasPermission="permissionsService.PERMISSIONS_ADD.MIP"
            (click)="AddUpdate(control)"
              type="button"
              icon="fa-solid fa-edit"
              styleClass="p-button-rounded p-button-outlined p-button-info ms-2 btn-sm "
            >
            </p-button>
          </td>
      </tr>
  </ng-template>

  <ng-template pTemplate="rowexpansion" let-control>
    <button *appHasPermission="permissionsService.PERMISSIONS_ADD.MIP" (click)="AddUpdateProducts(null,control)" pButton class="mb-4 p-button-outlined btn-sm mt-3 ms-5" icon="pi pi-plus" severity="info" label="Agregar Producto"></button>
    <button (click)="viewImages(control)" pButton class="mb-4 p-button-outlined btn-sm mt-3 ms-2" icon="pi pi-image" severity="info" label="Ver imagenes"></button>

      <tr>
          <td colspan="7">
              <div class="p-3 mx-3">

                <app-table-control  #tableRef [Control] ="control" [Event]="Event" ></app-table-control>
        <!--

          <app-table-cleanings #tableRef [Condition] ="condition"></app-table-cleanings>-->
              </div>
          </td>
      </tr>
  </ng-template>
</p-table>

