<form (ngSubmit)="accept()" [formGroup]="Form">
  <div class="grid formgrid  mt-4 ">
    <div class="col-12 mb-2 mt-4 lg:col-12 lg:mb-0">
      <span class="w-100 p-fluid p-float-label p p-input-icon-left">
        <i class="fa-solid fa-file"></i>
        <input formControlName="name" type="text" pInputText placeholder="Nombre del documento" />
        <label for="amount">Nombre del documento</label>
      </span>
    </div>

    <div class="col-12 md:col-12 mt-4">
      <span class="p-fluid mt-5">
        <textarea formControlName="comments" placeholder="Comentarios" rows="3" cols="30" pInputTextarea></textarea>
      </span>
    </div>
  </div>

  <div class="d-flex justify-content-center mt-4 text-center">
    <app-upload-file
      [Document]="Form.value.document"
      Title="Documento"
      TypeDocument="application/pdf"
      [isImage]="false"
      (removeFile)="removeFile()"
      (uploadFile)="uploadFile($event)"
    ></app-upload-file>
  </div>

  <div class="d-flex justify-content-center mt-4">
    <button pButton pRipple type="submit" [disabled]="Form.invalid" label="Guardar"
      class="p-button-raised me-2 btn-sm"></button>

    <button (click)="cancel()" pButton pRipple type="button" label="Cancelar"
      class="p-button-raised p-button-secondary btn-sm"></button>
  </div>
</form>
