import { JobCenter } from './../core/models/employee.interface';
import { UserService } from './user.service';



import {Injectable, OnDestroy, OnInit} from '@angular/core';
import {Observable} from "rxjs";
import {HttpClient, HttpParams} from "@angular/common/http";

import {FormGroup} from "@angular/forms";
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EmployeeService {

  private baseUrl: string = environment.baseUrl

  constructor(private http: HttpClient,

    private UserService:UserService) { }

    getGroupEmployee(filters:any){

      let params = new HttpParams();

     params = params.append('job_center', this.UserService.JobCenter);

      if(filters.search){
          params =  params.append('search',  filters.search)
      }
      if(filters.page){
        params =  params.append('page',  filters.page)
    }

    if(filters.page_size){
      params =  params.append('page_size',  filters.page_size)
  }

      return this.http.get<any>(`${this.baseUrl}/groups/`, {params})
  }





  deleteGroup(idItem:string){
    return this.http.delete<any>(`${this.baseUrl}/groups/${idItem}/`)
}

saveGroup(data:any){

  return this.http.post<any>(`${this.baseUrl}/groups/`,data)
}

saveEmployee(data:any){
  return this.http.post<any>(`${this.baseUrl}/employees/`,data)
}

saveUserPassword(data:any,idEmploye:string){
  return this.http.post<any>(`${this.baseUrl}/employees/${idEmploye}/create_account/`,data)
}
changePassword(data:any,idEmploye:string){
  return this.http.post<any>(`${this.baseUrl}/employees/${idEmploye}/change_password/`,data)
}

getEmployees(filters:any){

  let params = new HttpParams();

 params = params.append('job_center', this.UserService.JobCenter);

  if(filters.search){
      params =  params.append('search',  filters.search)
  }
  if(filters.job_title){
    params =  params.append('job_title',  filters.job_title)
}

  if(filters.page){
    params =  params.append('page',  filters.page)
}

if(filters.page_size){
  params =  params.append('page_size',  filters.page_size)
}

  return this.http.get<any>(`${this.baseUrl}/employees/`, {params})
}


deleteEmployee(idItem:string){
  return this.http.delete<any>(`${this.baseUrl}/employees/${idItem}/`)
}

patchJobCenter(data:any,id:string){
  return this.http.patch<any>(`${this.baseUrl}/job-centers/${id}`,data)
}

putGroup(data:any,id:string){
  return this.http.patch<any>(`${this.baseUrl}/groups/${id}/`,data)
}

putEmployee(data:any,id:string){
  return this.http.patch<any>(`${this.baseUrl}/employees/${id}/`,data)
}


patchUsername(username:string,id:string,role:string){
  let data = {
    username,
    device_token: "[]",
    role
  }
  console.log(data);

  return this.http.patch<any>(`${this.baseUrl}/employees/${id}/update_account/`,data)
}

patchStatusEmployee(data:any,idEmployee:string){
  return this.http.patch<any>(`${this.baseUrl}/employees/${idEmployee}/`,data)
}

}
