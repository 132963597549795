import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { CatalogsEnum } from 'src/app/system/core/enums/catalogs.model';
import { TYPE_TOAST } from 'src/app/system/core/enums/ToastsEnums';
import { LoadingService } from 'src/app/system/services/loading.service';
import { MessagesService } from 'src/app/system/services/message.service';
import { MipFormsService } from 'src/app/system/services/MipForms.service';
import { UtilsService } from 'src/app/system/services/utils.service';

@Component({
  selector: 'app-add-update-document',
  templateUrl: './add-update-document.component.html',
  styleUrls: ['./add-update-document.component.scss']
})
export class AddUpdateDocumentComponent {
  Catalog = CatalogsEnum;
  Form: FormGroup = this.FormBuilder.group({
    comments: ['',],
    name: ['', [Validators.required]],
    id: [''],
    event: [''],
    document: ['', [Validators.required]]
  });

  constructor(
    private UtilsService: UtilsService,
    private FormBuilder: FormBuilder,
    private LoadingService: LoadingService,
    public config: DynamicDialogConfig,
    public ref: DynamicDialogRef,
    public MipFormsService: MipFormsService,
    private MessagesService: MessagesService,
  ) { }

  ngOnInit(): void {
    if (this.config.data.Document) {
      this.patchForm();
    }
  }

  patchForm() {
    let item = this.config.data.Document;

    this.Form.patchValue({
      name: item.name,
      comments: item.comments,
      document: item.document,
      id: item.id,
      event: this.config.data.event
    });
  }

  accept() {
    this.LoadingService.Show();

    if (!this.config.data.Document) {
      this.save();
      return;
    }

    this.update();
  }

  cancel() {
    this.ref?.close();
  }

  save() {
    let data = {
      document: this.Form.value.document,
      name: this.Form.value.name,
      comments: this.Form.value.comments,
      event: this.config.data.event
    };

    this.MipFormsService.saveDataForm(this.UtilsService.objectToFormData(data), 'Attached-Document').subscribe((res) => {
      this.LoadingService.Hide();

      this.MessagesService.ShowMessage(
        TYPE_TOAST.SUCCESS,
        'Correcto',
        'Guardado correctamente'
      );

      this.ref?.close({
        success: true,
        document: res,
        isUpdate: false
      });
    });
  }

  update() {
    let data = {
      document: this.Form.value.document,
      name: this.Form.value.name,
      comments: this.Form.value.comments,
      event: this.config.data.event,
      id: this.Form.value.id,
    };

    if (this.UtilsService.isString(data.document)) {
      delete data.document;
    }

    this.MipFormsService.patchDataForm(
      this.UtilsService.objectToFormData(data),
      'Attached-Document',
      data.id
    ).subscribe((res) => {
      this.LoadingService.Hide();

      this.MessagesService.ShowMessage(
        TYPE_TOAST.SUCCESS,
        'Correcto',
        'Guardado correctamente'
      );

      this.ref?.close({
        success: true,
        document: res,
        isUpdate: true
      });
    });
  }

  uploadFile(file: any) {
    this.Form.get('document')?.setValue(file);
  }

  removeFile() {
    this.Form.patchValue({ document: null });
  }
}
