import { Component, Inject, Input, inject } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { MipFormsService } from 'src/app/system/services/MipForms.service';
import { LoadingService } from 'src/app/system/services/loading.service';
import { MessagesService } from 'src/app/system/services/message.service';
import { AddUpdatePaymentMipComponent } from '../add-update-payment-mip/add-update-payment-mip.component';
import { TYPE_TOAST } from 'src/app/system/core/enums/ToastsEnums';
import { PermissionsServices } from 'src/app/system/services/permissions.service';

@Component({
  selector: 'app-table-payment',
  templateUrl: './table-payment.component.html',
  styleUrls: ['./table-payment.component.scss']
})
export class TablePaymentComponent {
  @Input()  Event:any;
  loading: boolean = false;
  Payments:any[] = [];
  randomValue = Math.random();
  constructor( private MipFormsService:MipFormsService, private LoadingService:LoadingService, private DialogService:DialogService, private MessagesService:MessagesService){

  }
  public permissionsService = inject(PermissionsServices);

    ngOnInit(): void {


  setTimeout(() => {
    this.getPayments();
  }, 0);
    }

    getPayments(){
      this.LoadingService.Show();
      this.MipFormsService.getDataForm(this.Event.id,'payment-forms').subscribe((res) => {
        this.Payments = res;
        console.log(res);

        this.LoadingService.Hide();

      });
    }


    AddUpdate(Payment: any,) {
      const dialog = this.DialogService.open(AddUpdatePaymentMipComponent, {
       header: "Pago del servicio",
       modal: true,
       width: '42rem',
       closable: true,
       draggable: false,
       maximizable: false,
       resizable: true,
       closeOnEscape: true,
       dismissableMask: true,
       data: {
        Event:this.Event,
        Payment
       },
     });
     dialog.onClose.subscribe((res) => {
       if (res?.success) {
         this.getPayments();
       }
     });

   }
    deleteItem(item: any) {
      this.MessagesService.confirm(
        '¿Esta seguro de eliminar el pago ' + item.amount + ' ?',
        () => {
          this.MipFormsService.deleteDataForm(
            item.id,
            'payment-forms'
          ).subscribe((res) => {
            this.MessagesService.ShowMessage(TYPE_TOAST.SUCCESS,"Correcto","Se ha eliminado correctamente")
            this.getPayments();
          });
        }
      );
    }

}
