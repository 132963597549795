import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { TYPE_TOAST } from 'src/app/system/core/enums/ToastsEnums';
import { CatalogsEnum } from 'src/app/system/core/enums/catalogs.model';
import { CalendarService } from 'src/app/system/services/calendar.service';
import { LoadingService } from 'src/app/system/services/loading.service';
import { MessagesService } from 'src/app/system/services/message.service';

@Component({
  selector: 'app-reactivate-event',
  templateUrl: './reactivate-event.component.html',
  styleUrls: ['./reactivate-event.component.scss']
})
export class ReactivateEventComponent implements OnInit {

  constructor(
    private MessagesService:MessagesService,
    private FormBuilder: FormBuilder,
    private LoadingService: LoadingService,
    public config: DynamicDialogConfig,
    public ref: DynamicDialogRef,
    private CalendarService:CalendarService,){

  }

  Form: FormGroup = this.FormBuilder.group({
    id: '',
    cancellation_reason: '',
    cancellation_reason_comment:'',
    status:'fa002623-8b18-446d-8129-96797f5a2b6e' //status for event programed
  });

  ngOnInit(): void {
    this.Form.patchValue({
      id:this.config.data.Event.id
    })
  }

  reactivateEvent(){
    let data = this.Form.value




this.LoadingService.Show();
this.CalendarService.reactivateEvent(data).subscribe((res)=>{
  this.ref?.close({ success: true });
  this.LoadingService.Hide();
  this.MessagesService.ShowMessage(
    TYPE_TOAST.SUCCESS,
    'Correcto',
    'Reactivado correctamente'
  );
})
  }

}
