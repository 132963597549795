
<button  *appHasPermission="permissionsService.PERMISSIONS_ADD.MIP" (click)="AddUpdate(null)" pButton class="mb-4 p-button-raised w-full sm:w-auto flex-order-0 sm:flex-order-1  p-button-info" icon="pi pi-plus" severity="info" label="Nuevo monitoreo"></button>

<h3 *ngIf="Areas.length ==0">No tienes registros</h3>
<h5 *ngIf="Areas.length >0">Monitoreo de Áreas</h5>
<p-table class="custom-table "
styleClass="p-datatable-sm"
scrollDirection="horizontal"
#dtCalendar
[value]="Areas"
dataKey="id"
sortMode="multiple"
[rows]="10"
[showCurrentPageReport]="true"
[rowsPerPageOptions]="[10, 25, 50]"
[paginator]="false"
[globalFilterFields]="['name']">
  <ng-template pTemplate="header">
      <tr>
          <th  class="white-space-nowrap bg-table-info text-center">Área</th>

          <th   class="white-space-nowrap bg-table-info text-center">Actividad en área </th>
          <th   class="white-space-nowrap bg-table-info text-center">Condiciones </th>
          <th   class="white-space-nowrap bg-table-info text-center">Plagas </th>
          <th   class="white-space-nowrap bg-table-info text-center">Imagenes</th>
          <th   class="white-space-nowrap bg-table-info text-center"></th>
      </tr>
  </ng-template>
  <ng-template pTemplate="body" let-item>
    <tr class="text-sm">
      <td class="text-center">
            {{ item.nesting_area?.name ?? 'Pendiente ' }}
      </td>
   
      <td class="text-center">
        <span *ngFor="let activity_area of item?.activity_area">
          {{ activity_area.name }},
        </span>

  </td>

<td class="text-center">
  <span *ngFor="let condition_area of item?.condition_area">
    {{ condition_area.name }},
  </span>

</td>
  
<td class="text-center">
  <span *ngFor="let plague of item?.plague">
    {{ plague.name }},
  </span>

</td>

  <td class="text-center">

    <p-button
    (click)="viewImages(item)"
  type="button"
  icon="fa-solid fa-image"
  styleClass="p-button-rounded p-button-outlined p-button-primary p-button-sm ml-2 btn-sm"
  >
  </p-button>
</td>


      <td class="text-center">

      <p-button
      *appHasPermission="permissionsService.PERMISSIONS_ADD.MIP"
        (click)="deleteItem(item)"
      type="button"
      icon="fa-solid fa-trash"
      styleClass="p-button-rounded p-button-outlined p-button-danger p-button-sm ml-2 btn-sm"
      >
      </p-button>
        <p-button
        *appHasPermission="permissionsService.PERMISSIONS_ADD.MIP"
        (click)="AddUpdate(item)"
          type="button"
          icon="fa-solid fa-edit"
          styleClass="p-button-rounded p-button-outlined p-button-info ms-2 btn-sm "
        >
        </p-button>
      </td>

    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
      <tr>
          <td colspan="6">No tienes registros.</td>
      </tr>
  </ng-template>
</p-table>
