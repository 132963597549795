
<button *appHasPermission="permissionsService.PERMISSIONS_ADD.MIP" (click)="AddUpdate(null)" pButton class="mb-4 p-button-raised w-full sm:w-auto flex-order-0 sm:flex-order-1  p-button-info" icon="pi pi-plus" severity="info" label="Nueva Inspección"></button>

<h3 *ngIf="Inspecciones.length ==0">No tienes inspecciones</h3>
<h5 *ngIf="Inspecciones.length >0">Inspecciones</h5>

<!--
  <div *ngIf="Inspecciones.length >0" class="accordion " id="accordionInspection">

    <div *ngFor="let item of Inspecciones" class="accordion-item mt-2 shadow-sm border ">
    <div class="accordion-header " id="headingTw" >
      <button (click)="handleAccordionClick(item.id)" class="accordion-button collapsed fw-bold h5" type="button" data-bs-toggle="collapse" [attr.data-bs-target]="'#collapse_' + item.id" aria-expanded="false" >
       <span class="fw-bold">Inspección: {{item.plague_category.name}}</span>
      </button>
    </div>
    <div [id]="'collapse_' + item.id" class="accordion-collapse collapse"  data-bs-parent="#accordionInspection">
      <div class="accordion-body">

        <div *ngIf="activeAccordionId === item.id">
          <div class="grid formgrid  mt-3 ">
            <div class="col-12 mb-2  lg:col-8 lg:mb-0">
              <app-table-plagues [Inspection] ="item"></app-table-plagues>
            </div>
          <div class="col-12 mb-2  lg:col-4 lg:mb-0">
            <app-carousel-images-form [ShowNestingArea]="true" [ShowComments]="true" [Inspection]="item"></app-carousel-images-form>
          </div>
        </div>
    </div>




          <p-tabView [activeIndex]="index" (onChange)="handleChange($event)">

         <p-tabPanel>
           <ng-template pTemplate="header">
             <span>Evidencias</span>
           </ng-template>


           <div *ngIf="index == 0 && activeAccordionId === item.id">
            <app-table-plagues [Inspection] ="item"></app-table-plagues>
           </div>

         </p-tabPanel>



           <p-tabPanel>
           <ng-template pTemplate="header">
             <span>Plagas</span>
           </ng-template>

           <div *ngIf="index == 1 && activeAccordionId === item.id">
            <app-table-plagues [Inspection] ="item"></app-table-plagues>
           </div>
         </p-tabPanel>


          <p-tabPanel>
           <ng-template pTemplate="header">
             <span>Fotos</span>
           </ng-template>


           <div *ngIf="index == 2 && activeAccordionId === item.id">
            <app-photos-inspection [Inspection] ="item"></app-photos-inspection>
           </div>
         </p-tabPanel>

       </p-tabView>





    <div class="d-flex justify-content-center">
      <p-button
      (click)="deleteItem(item)"
      pTooltip="Eliminar Inspección"
      type="button"
      icon="fa-solid fa-trash"
      styleClass="p-button-rounded p-button-danger p-button-sm ml-2"
      >
    </p-button>
    <p-button
    (click)="AddUpdate(item)"
    pTooltip="Editar Inspección"
    type="button"
    icon="fa-solid fa-edit"
    styleClass="p-button-rounded p-button-info p-button-sm ml-2"
    >
    </p-button>

    </div>
      </div>
    </div>
  </div>
  </div>
-->





<p-table
class="custom-table "
styleClass="p-datatable-sm"
scrollDirection="horizontal"
#dtCalendar
[value]="Inspecciones"
dataKey="id"
sortMode="multiple"
[rows]="10"
[showCurrentPageReport]="true"
[rowsPerPageOptions]="[10, 25, 50]"
[paginator]="false"
[globalFilterFields]="['name']">
  <ng-template pTemplate="header">
      <tr>
          <th      class="white-space-nowrap bg-primary " style="width: 5rem"></th>
          <th     class="white-space-nowrap bg-primary " >Nombre Inspeccion </th>
          <th     class="white-space-nowrap bg-primary ">Acciones</th>

      </tr>
  </ng-template>
  <ng-template pTemplate="body" let-inspection let-expanded="expanded">
      <tr class="border-bottom">
          <td>
              <button type="button" pButton pRipple [pRowToggler]="inspection" class="p-button-text p-button-rounded p-button-plain fw-bold" [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
          </td>
          <td><span class="fw-bold h5">{{ inspection.plague_category.name }}</span></td>
          <td >
            <p-button
            *appHasPermission="permissionsService.PERMISSIONS_ADD.MIP"
            (click)="deleteItem(inspection)"
          type="button"
          icon="fa-solid fa-trash"
          styleClass="p-button-rounded p-button-outlined p-button-danger p-button-sm ml-2 btn-sm"
          >
          </p-button>
            <p-button
            *appHasPermission="permissionsService.PERMISSIONS_ADD.MIP"
            (click)="AddUpdate(inspection)"
              type="button"
              icon="fa-solid fa-edit"
              styleClass="p-button-rounded p-button-outlined p-button-info ms-2 btn-sm "
            >
            </p-button>
          </td>
      </tr>
  </ng-template>

  <ng-template pTemplate="rowexpansion" let-inspection>
    <button *appHasPermission="permissionsService.PERMISSIONS_ADD.MIP" (click)="AddUpdateEvidence(null,inspection)" pButton class="mb-4 p-button-outlined btn-sm mt-3 ms-5" icon="pi pi-plus" severity="info" label="Agregar evidencia"></button>
    <button (click)="viewImages(inspection)" pButton class="mb-4 p-button-outlined btn-sm mt-3 ms-2" icon="pi pi-image" severity="info" label="Ver imagenes"></button>

      <tr>
          <td colspan="7">
              <div class="p-3 mx-3">
                <app-table-plagues #tableRef [Inspection] ="inspection"></app-table-plagues>
              </div>
          </td>
      </tr>
  </ng-template>
</p-table>
