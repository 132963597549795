import { Component, EventEmitter, Input, OnInit, Optional, Output, ViewChild, forwardRef, signal } from '@angular/core';
import { AbstractControl, ControlContainer, FormControl, FormControlDirective, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Observable } from 'rxjs';

import { CatalogsEnum, URL_ENDPOINTS } from 'src/app/system/core/enums/catalogs.model';
import { BaseControlValueAccessor } from 'src/app/system/core/models/control-accesor.model';
import { CatalogService } from 'src/app/system/services/catalogs.service';
import { UserService } from 'src/app/system/services/user.service';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectComponent),
      multi: true,
    },
  ],
})
export class SelectComponent extends BaseControlValueAccessor<any> implements OnInit {
  @ViewChild(FormControlDirective, { static: true }) formControlDirective:
    | FormControlDirective
    | undefined;
  @Input() idPlague: string = "";
  @Input() arrayIds:any = [];
  @Input() idGroup: any ;

  data: any = signal([]);
  @Input() isMultiple = false;
  @Input() UrlCatalog: string = URL_ENDPOINTS.CUSTOMERS;
  @Input() IS_LOCAL = false
  @Input() formControlName: string = '';
  @Input() isDropdown: boolean = true;
  @Input() customer: string = '';
  @Input() company: boolean = false;
  @Input() warehouse: string = '';
  @Input() isExpense: boolean = false;
  @Input() isMain: boolean = false;
  @Input() idProduct: any = null;
  
  @Input() field: string = "name";
  @Output() enableOptions = new EventEmitter<boolean>();
  @Input() type: string = 'p-autocomplete';
  @Input() label: string = '';
  @Input() placeholder: string = 'Selecciona';
  @Input() Catalog: CatalogsEnum = CatalogsEnum.PLAGUES;
  @Input() Route: string = "";
  @Input() idCustomer: string = "";

  @Input() params: object = {};
  @Input() area: object = {};
  @Input() page: number = 1;
  @Input() pageSize: number = 15;
  @Input() isDisabled: boolean = false;

  @Output() onSelect = new EventEmitter<any>();
  control: AbstractControl | any;
  form: FormGroup | any;


  observable: Observable<any> | undefined;

  constructor(
    @Optional() private controlContainer: ControlContainer,
    private CatalogService: CatalogService,
    private UserService: UserService,
  ) {
    super();
  }

  get c(): AbstractControl {
    return this.control;
  }

  get f(): FormGroup {
    return this.form;
  }

  ngOnInit(): void {
    if (this.formControlName && this.controlContainer) {
      this.control = this.controlContainer.control!.get(this.formControlName) as FormControl;
      this.form = this.controlContainer.control as FormGroup;
    }
  }

  enableChildrens() {
    this.enableOptions.emit(false);
  }

  filterMultiSelect(event: any) {
    console.log(this.control);

    let query = event.query;
    let filters = {
      search: query,
      page: this.page,
      pageSize: this.pageSize,
    }

    const isACatalog = this.Catalog !== 'quotes';

    this.CatalogService.getCatalog(this.Catalog, filters, isACatalog).subscribe((res) => {
      if (this.idPlague != "") {
        let array = res.items.filter((e: any) => e?.category?.id == this.idPlague);

        if (array.length == 0) {
          array = res.items.filter((e: any) => e?.plague_category?.id == this.idPlague);
        }


        this.data.set(array);
        return
      }

      if(this.arrayIds.length > 0){
// Crear un conjunto con los IDs de categorías en arregloB
const categoryIdsB = new Set(this.arrayIds.map((item:any) => item.id));

// Filtrar los objetos de arregloA cuyas categorías están en arregloB
const resultado = res.items.filter((item:any) => categoryIdsB.has(item.category.id));
this.data.set(resultado);
return
      }

/**
 * 
 * if(this.Catalog == CatalogsEnum.EVENT_TYPES){
alert("Aas")
}

 */

      console.log(res.items);
        
      this.data.set(res.items);
    });
  }

  filterMultiSelectAdministrative(event: any) {

if(this.IS_LOCAL){
  this.data.set([
    {
    name:'Pagado',
    value:true
  },
  {
    name:'No Pagado',
    value:false
  },
]);
  return
}



    let query = event.query;


    let filters = {
      search: query,
      isMain: this.isMain,
      customer: this.customer,
      company: this.UserService.Company.id,
      page: this.page,
      pageSize: this.pageSize,
      group:this.idGroup ? this.idGroup.id : null
     // group:"a",
     // "group":"a"
  //    group:this.idGroup
      //     company:this.company ? this.UserService.Company.id : null
    }



    if (this.isExpense) {
      this.CatalogService.getCatalogAdministrativeExpense(this.Catalog, filters).subscribe((res) => {
        this.data.set(res.items);
      });

      return
    }

    this.CatalogService.getCatalogAdministrative(this.Catalog, filters).subscribe((res) => {
  /**
   *     if (this.idGroup != "") {
        res.items.filter((employee: any) => employee?.employee_group == this.idGroup)
      }

   */
      if (this.idCustomer != "") {
        let array = res.items.filter((e: any) => e?.customer == this.idCustomer);

        this.data.set(array);
        return
      }



      this.data.set(res.items);
    });
  }

  filterMultiSelectPestControl(event: any) {
    let query = event.query;
    let filters = {
      search: query,
      customer: this.customer ?? null,
      nesting_area__customer__id: this.customer ?? null,
      page: this.page,
      pageSize: this.pageSize,
    }

    this.CatalogService.getCatalogPestControl(this.Catalog, filters).subscribe((res) => {
      this.data.set(res.items);

      if (filters.nesting_area__customer__id) {
        this.data().forEach((item: any) => {
          item.completeNameStation = item?.station_type?.name + ' - ' + item?.name + '-' + item?.key
        });
      }
    });
  }

  filterMultiSelectInventories(event: any) {
    let query = event.query;
    let filters = {
      search: query,
      customer: this.customer ?? null,
      warehouse: this.warehouse ?? null,
      page: this.page,
      pageSize: this.pageSize,
      product_one:this.idProduct ? this.idProduct : null
    }

    console.log(filters);
    

    this.CatalogService.getCatalogInventories(this.Catalog, filters).subscribe((res) => {
      if (filters?.warehouse) {
        this.data.set(
          res.items.map((objeto: any) => {
            // Crear un nuevo objeto con los datos del objeto original y el nuevo dato agregado
            return { ...objeto, name: objeto.product.name };
          })
        );

        return
      }

      this.data.set(res.items);
    });
  }

  select(object: any): void {
    this.onSelect.emit(object);
  }

  filterMultiSelectGeneric(event: any) {
    let query = event.query;
    let filters = {
      search: query,
      customer: this.customer ?? null,
      nesting_area__customer__id: this.customer ?? null,
      page: this.page,
      pageSize: this.pageSize,
    }

    this.CatalogService.getCatalogGeneric(this.Route, filters).subscribe((res) => {
      this.data.set(res.items);
    });
  }

  /**COMPONENTE GENERICO Y UNICO */

  filterSelectCatalog(event: any) {
    let query = event.query;
    let filters = {
      search: query,
      isMain: this.isMain,
      customer: this.customer,
      page: this.page,
      pageSize: this.pageSize,
      //  company:this.company ? this.UserService.Company.id : null
    }

    this.CatalogService.getCatalogSelect(this.UrlCatalog, filters).subscribe((res) => {
      this.data.set(res.items);
    });
  }
}
