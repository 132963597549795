import { Component, OnInit, inject } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { LayoutService } from './service/app.layout.service';
import { DialogService } from 'primeng/dynamicdialog';
import { EventsCalendarComponent } from '../system/Panel/events/pages/events-calendar/events-calendar.component';
import { PermissionsServices } from '../system/services/permissions.service';

@Component({
    selector: 'app-menu',
    templateUrl: './app.menu.component.html'
})
export class AppMenuComponent implements OnInit {
  private permissionService = inject(PermissionsServices);
    model: any[] = [];
    constructor(public layoutService: LayoutService,private DialogService:DialogService) { }
    ejecutarMetodoCalendario() {
        const dialog = this.DialogService.open(EventsCalendarComponent, {
          header: 'Calendario de servicios',
          modal: true,
          width: '95%',
          height:"100%",
          closable: true,
          draggable: false,
          maximizable: true,
          resizable: true,
          closeOnEscape: true,
          dismissableMask: true,
        });

    }
    ngOnInit() {
     // console.log(this.permissionService.Permissions);
      
    const itemsMenu = [
            {
                label: 'Atención a Cliente', icon: 'pi pi-home',
                items: [
                    { label: 'Inicio', 
                      permissionKey:'start',
                      icon: 'pi pi-desktop', 
                      routerLink: ['/'] 
                    },
                    {
                        label: 'Cotizaciones',
                        permissionKey:'quotes',
                        icon: 'fa fa-file-text-o',
                        routerLink: ['/quotes']
                    },

                    {
                        label: 'Calendario',
                        permissionKey:'events',
                        icon: 'fa fa-calendar',
                       // command: (event:any) => this.ejecutarMetodoCalendario(),
                        routerLink: ['/events']
                    },

                    {
                        label: 'Clientes',
                        permissionKey:'customers',
                        icon: 'fa fa-users',
                       routerLink: ['/customers']
                    },
                    {
                      label: 'Tareas',
                      permissionKey:'tasks',
                      icon: 'fa fa-tasks',
                      routerLink: ['/administrative/tasks']
                    },
                    {
                      label: 'Tickets',
                      permissionKey:'tickets',
                      icon: 'fa fa-ticket',
                      routerLink: ['/administrative/tickets']
                    },
                    {
                      label: 'Reportes',
                      permissionKey:'customers',
                      icon: 'fa fa-bar-chart',
                      routerLink: ['/administrative/reports']
                    },
        

                ]
            },


{
  label: 'Administrativo', permissionKey:'job_centers', icon: 'pi pi-briefcase',
items:[
  {
      label: 'Recursos humanos',     permissionKey:'job_centers', icon: 'fa-solid fa-users-rays',
      items: [
          {
              label: 'Empleados', permissionKey:'job_centers', icon: 'fa-solid fa-users',
              routerLink: ['/administrative/employee']
          },

          {
            label: 'Centros de trabajo',  permissionKey:'job_centers', icon: 'fa-solid fa-building-user',
            routerLink: ['/administrative/job_center']
          }
      ]
  },
  {
    label: 'Inventario', permissionKey:'inventories', icon: 'fa-solid fa-warehouse',
    items: [
      {
        label: 'Proveedores', permissionKey:'inventories', icon: 'fa-solid fa-building-user',
        routerLink: ['/inventory/providers']
      },
      {
        label: 'Productos', permissionKey:'inventories', icon: 'fa-solid fa-flask',
        routerLink: ['/inventory/products']
      },
      {
        label: 'Ordenes de Compra', permissionKey:'inventories', icon: 'fa-solid fa-file-invoice',
        routerLink: ['/inventory/purchase-orders']
      },
      {
        label: 'Almacenes', permissionKey:'inventories', icon: 'fa-solid fa-dolly',
        routerLink: ['/inventory/warehouses']
      },
      {
        label: 'Traspasos', permissionKey:'inventories', icon: 'fa-solid fa-exchange',
        routerLink: ['/inventory/transfers']
      },
      {
        label: 'Entradas', permissionKey:'inventories', icon: 'fa-solid fa-external-link-square',
        routerLink: ['/inventory/entrys']
      },
      ]
  },
  {
    label: 'Gastos',
    permissionKey:'expenses',
    icon: 'fa fa-money',
    routerLink: ['/administrative/expenses']
  },
  {
    label: 'Remisiones',
    permissionKey:'referrals',
    icon: 'fa fa-credit-card',
    routerLink: ['/administrative/referrals']
  },

]

},
{
  label: 'Ajustes', icon: 'pi pi-cog',
items:[
  {
      label: 'Catalogos',  permissionKey:'catalogs', icon: 'fa fa-list',
      items: [
          {
              label: 'Cotizaciones', permissionKey:'catalogs', icon: 'pi pi-wallet',
              routerLink: ['/catalogs/quotes']
          },
          {
            label: 'Servicios', permissionKey:'catalogs', icon: 'pi pi-server',routerLink: ['/catalogs/services']},
          {
            label: 'Monitoreo', permissionKey:'catalogs', icon: 'pi pi-desktop',     routerLink: ['/catalogs/monitoring']
          },
          {
            label: 'Productos',permissionKey:'catalogs', icon: 'pi pi-align-right',
            routerLink: ['/catalogs/products']
        },


          {
            label: 'Contabilidad',
            permissionKey:'catalogs',
            icon: 'pi pi-money-bill',
            routerLink: ['/catalogs/acounting']
          },

          {
            label: 'Calendario', permissionKey:'catalogs', icon: 'pi pi-calendar',
            routerLink: ['/catalogs/calendar']
        },

          {
            label: 'Recursos Humanos', permissionKey:'catalogs', icon: 'pi pi-users',
            routerLink: ['/catalogs/human-resources']
        },
          {
            label: 'Tickets',permissionKey:'catalogs', icon: 'pi pi-ticket',
            routerLink: ['/catalogs/tickets']
        },

          { label: 'Plan de acción', permissionKey:'catalogs', icon: ' pi pi-bolt',   routerLink: ['/catalogs/action-plan']},

          {
            label: 'Tareas', permissionKey:'catalogs', icon: 'pi pi pi-book',
            routerLink: ['/catalogs/tasks']
        },



      ]
  },
  {
    label: 'Personalizar',  permissionKey:'companies', icon: 'fa fa-paint-brush',
    items: [
        {
            label: 'Instancia', permissionKey:'companies',  icon: 'pi pi-home',
            routerLink: ['/custom/instance']
        },

    ]
},
]

},
        ];
        this.model = this.filterMenuByPermissions(itemsMenu, this.permissionService.Permissions);
    
       // this.model = this.filterMenuByPermissions(itemsMenu, this.permissionService.Permissions);
    }

    filterMenuByPermissions(menu: any[], permissions: any[]): any[] {
      const permissionMap = new Map(permissions.map(p => [p.label, p.permissions.view]));

      const filterItems:any = (items: any[]) => {
          return items
              .filter(item => !item.permissionKey || permissionMap.get(item.permissionKey))
              .map(item => ({
                  ...item,
                  items: item.items ? filterItems(item.items) : undefined
              }))
              .filter(item => item.items ? item.items.length > 0 || !item.items : true);
      };

      return filterItems(menu);
  }

}
