import { AreasControlService } from 'src/app/system/services/areasControlService';
import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { TYPE_TOAST } from 'src/app/system/core/enums/ToastsEnums';

import { CatalogService } from 'src/app/system/services/catalogs.service';
import { LoadingService } from 'src/app/system/services/loading.service';
import { MessagesService } from 'src/app/system/services/message.service';
import { CatalogsEnum } from 'src/app/system/core/enums/catalogs.model';

@Component({
  selector: 'app-add-update-zone',
  templateUrl: './add-update-zone.component.html',
  styleUrls: ['./add-update-zone.component.scss']
})
export class AddUpdateZoneComponent {

  Form: FormGroup = this.FormBuilder.group({
    name: ['', [Validators.required]],
    business_activity:"",
    customer:"",
    id:""
});

constructor(
    private FormBuilder: FormBuilder,
    private LoadingService: LoadingService,
    public config: DynamicDialogConfig,
    public ref: DynamicDialogRef,
    private DialogService: DialogService,

    private MessagesService: MessagesService,
    private router: Router,
    private AreasControlService:AreasControlService,
) {}

ngOnInit() {
    if (this.config.data.item) {

if (this.AreasControlService.OriginScreen === "CUSTOMERS") { this.patchFormCustomers() }

  if (this.AreasControlService.OriginScreen === "BUSINESS-ACTIVITES") { this.patchFormBusinessActivitie() }

  if (this.AreasControlService.OriginScreen === "CHECKPOINT") { this.patchFormCustomers() }

    }
}

patchFormBusinessActivitie(){
  this.Form.patchValue({
    name: this.config.data.item.name,
    business_activity:this.config.data.idRegister,
    id:this.config.data.item.id //id de la zona
});
}

patchFormCustomers(){
  this.Form.patchValue({
    name: this.config.data.item.name,
    customer:this.config.data.idRegister,
    id:this.config.data.item.id //id de la zona
});
}

cancel() {
    this.ref?.close();
}

accept() {
    this.LoadingService.Show();
   if(!this.config.data.item){
    this.save();
    return
   }
   this.update();
}

save(){
  if (this.AreasControlService.OriginScreen === "CUSTOMERS") {
    this.saveZoneCustomers()
  }
 else if (this.AreasControlService.OriginScreen === "BUSINESS-ACTIVITES") {
    this.saveZoneBussines()
  }
 else if (this.AreasControlService.OriginScreen === "CHECKPOINT") {
    this.saveZoneCheckPoint()
  }
}

saveZoneCheckPoint(){

  let data = {
    name:this.Form.value.name,
    customer:this.config.data.idRegister
  }

    this.AreasControlService.saveZoneCheckPoint(data).subscribe(
        (res) => {
            this.LoadingService.Hide();
            this.MessagesService.ShowMessage(
                TYPE_TOAST.SUCCESS,
                'Agregado correctamente',
                ''
            );
            this.ref?.close({ success: true });
        }
    );
}


update(){
  if (this.AreasControlService.OriginScreen === "CUSTOMERS") {
    this.updateZoneCustomers()
  }
  else if (this.AreasControlService.OriginScreen === "BUSINESS-ACTIVITES") {
    this.updateZoneBussines()
  }
  else if (this.AreasControlService.OriginScreen === "CHECKPOINT") {
    this.putDataCheckPointPestcontrol()
  }


}
putDataCheckPointPestcontrol(){
  const {id,zone,customer} = this.Form.value;

  this.AreasControlService.putDataCheckPointPestcontrol(id,this.Form.value).subscribe(
      (res) => {
          this.LoadingService.Hide();
          this.MessagesService.ShowMessage(
              TYPE_TOAST.SUCCESS,
              'Actualizado correctamente',
              ''
          );
          this.ref?.close({ success: true });
      }
  );
}


updateZoneCustomers(){
  const {id,zone,customer} = this.Form.value;

  this.AreasControlService.putDataCustomersPestcontrol(CatalogsEnum.ZONES,id,this.Form.value).subscribe(
      (res) => {
          this.LoadingService.Hide();
          this.MessagesService.ShowMessage(
              TYPE_TOAST.SUCCESS,
              'Actualizado correctamente',
              ''
          );
          this.ref?.close({ success: true });
      }
  );
}

updateZoneBussines(){
  const {id,zone,business_activity} = this.Form.value;

  this.AreasControlService.putDataBusinessPestcontrol(CatalogsEnum.ZONES,id,this.Form.value).subscribe(
      (res) => {
          this.LoadingService.Hide();
          this.MessagesService.ShowMessage(
              TYPE_TOAST.SUCCESS,
              'Agregado correctamente',
              ''
          );
          this.ref?.close({ success: true });
      }
  );
}


saveZoneBussines(){
  const {id,zone,business_activity} = this.Form.value;

  let data = {
    name:this.Form.value.name,
    business_activity:this.config.data.idRegister
  }

    this.AreasControlService.addDataBusinessPestcontrol(CatalogsEnum.ZONES,data).subscribe(
        (res) => {
            this.LoadingService.Hide();
            this.MessagesService.ShowMessage(
                TYPE_TOAST.SUCCESS,
                'Agregado correctamente',
                ''
            );
            this.ref?.close({ success: true });
        }
    );
}

saveZoneCustomers(){
  const {id,zone,customer} = this.Form.value;

  let data = {
    name:this.Form.value.name,
    customer:this.config.data.idRegister
  }

    this.AreasControlService.addDataCustomers(CatalogsEnum.ZONES,data).subscribe(
        (res) => {
            this.LoadingService.Hide();
            this.MessagesService.ShowMessage(
                TYPE_TOAST.SUCCESS,
                'Agregado correctamente',
                ''
            );
            this.ref?.close({ success: true });
        }
    );
}
}
