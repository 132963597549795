import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { TYPE_TOAST } from 'src/app/system/core/enums/ToastsEnums';
import { CatalogsEnum } from 'src/app/system/core/enums/catalogs.model';
import { CatalogService } from 'src/app/system/services/catalogs.service';
import { CustomerService } from 'src/app/system/services/customer.service';

import { LoadingService } from 'src/app/system/services/loading.service';
import { MessagesService } from 'src/app/system/services/message.service';
import { UserService } from 'src/app/system/services/user.service';
import { UtilsService } from 'src/app/system/services/utils.service';

@Component({
  selector: 'app-add-update-customer',
  templateUrl: './add-update-customer.component.html',
  styleUrls: ['./add-update-customer.component.scss']
})
export class AddUpdateCustomerComponent {
      ImgDataUrl:string = "";
  Form: FormGroup = this.FormBuilder.group({
    name: ['', [Validators.required]],
    phone: ['', []],
    business_activity:['', [Validators.required]],
    contact_email:['',[ ]],
    email:['',[]],
    address_latitude: [0],//'21.1605558',
    address_longitude:[ 0],//"-101.6837776"
    contact_name:[''],
    contact_phone:[''],
    internal_id:[''],
    is_main:true,
    main_customer:[''],
    address:['',Validators.required],
    logo:[''],
    
    reason_social:[''], 
    taxpayer_registration:[''],

    is_active:[false],
    job_center: this.UserService.JobCenter,
    id:''
});
Catalog = CatalogsEnum;

constructor(
    private FormBuilder: FormBuilder,
    private LoadingService: LoadingService,
    public config: DynamicDialogConfig,
    public ref: DynamicDialogRef,
    private DialogService: DialogService,
private UtilsService:UtilsService,
    private customerService: CustomerService,
    private MessagesService: MessagesService,
    private router: Router,
    private UserService:UserService
) {}

ngOnInit() {

    this.Form.get("is_main")?.setValue(this.config.data.isMain);
    this.Form.get("main_customer")?.setValue(this.config.data.main_customer);

    if (this.config.data.item) {

        this.ImgDataUrl = this.config.data.item.logo + '?' + new Date().getTime();
        this.Form.patchValue({
            name: this.config.data.item.name,
            business_activity: this.config.data.item.business_activity,
            job_center: this.UserService.JobCenter,
            id:this.config.data.item.id,
            phone:this.config.data.item.phone,
            email:this.config.data.item.email,
            contact_email:this.config.data.item.contact_email,
            address_latitude:0,
            address_longitude:0,
            contact_name:this.config.data.item.contact_name,
            contact_phone:this.config.data.item.contact_phone,
            internal_id:this.config.data.item.internal_id,
            is_main:this.config.data.item.is_main,
            main_customer:this.config.data.item?.main_customer ?? this.config.data.item.id,
            address:this.config.data.item.address,
            reason_social:this.config.data.item.reason_social, 
            taxpayer_registration:this.config.data.item.taxpayer_registration, 
            logo:this.config.data.item.logo,
            is_active:this.config.data.item.is_active
        });


        this.Form.get("business_activity")?.valueChanges.subscribe(valor => {
            this.MessagesService.confirm(
                'Si cambia el giro de negocio se borraran todas las areas y estaciones que tenga creadas, ¿Desea continuar?',
                () => {
                 
                },
                () =>{
                    this.Form.patchValue({   business_activity: this.config.data.item.business_activity,})
                }
              );
            console.log('Nuevo valor:', valor);
          });


    }
}

cancel() {
    this.ref?.close();
}

accept() {

    this.LoadingService.Show();
   if(!this.config.data.item){
    this.save();
    return
   }
   this.update();
}

save(){

    if(this.UtilsService.isString(this.Form.value.logo))delete this.Form.value.logo;


    this.Form.value.business_activity =  this.Form.value.business_activity.id;


  this.customerService.saveCustomer(this.UtilsService.objectToFormData(this.Form.value)).subscribe(
    (res) => {

if(this.Form.value.logo){

    this.customerService.patchLogoCustomer(this.UtilsService.objectToFormData(this.Form.value),res.data.id).subscribe((res)=>{
        this.LoadingService.Hide();
        this.MessagesService.ShowMessage(
            TYPE_TOAST.SUCCESS,
            'Correcto',
            'Agregado correctamente'
        );
        this.ref?.close({ success: true });
    });

    return
}


        this.LoadingService.Hide();
        this.MessagesService.ShowMessage(
            TYPE_TOAST.SUCCESS,
            'Correcto',
            'Agregado correctamente'
        );
        this.ref?.close({ success: true });
    }
);
}



update(){
    if(this.UtilsService.isString(this.Form.value.logo))delete this.Form.value.logo;


  this.Form.value.business_activity =  this.Form.value.business_activity.id;




  this.customerService.putCustomer( this.UtilsService.objectToFormData(this.Form.value),this.Form.value.id).subscribe(
    (res) => {

        if(this.Form.value.logo){
            console.log(this.UtilsService.objectToFormData(this.Form.value));

            this.customerService.patchLogoCustomer(this.UtilsService.objectToFormData(this.Form.value),this.Form.value.id).subscribe((res)=>{
                this.LoadingService.Hide();
                this.MessagesService.ShowMessage(
                    TYPE_TOAST.SUCCESS,
                    'Correcto',
                    'Agregado correctamente'
                );
                this.ref?.close({ success: true });
            });

            return
        }



        this.LoadingService.Hide();
        this.MessagesService.ShowMessage(
            TYPE_TOAST.SUCCESS,
            'Correcto',
            'Actualizado correctamente'
        );
        this.ref?.close({ success: true });
    }
);

}



uploadFile(event: any, ) {
    this.MessagesService.ShowMessage(TYPE_TOAST.SUCCESS, "Correcto", "Archivo cargado correctamente");

      this.Form.get('logo')?.setValue(event.target.files[0]);
  }


  myUploader(event: any, ) {


const file = event.target.files[0];

if (file) {
  this.MessagesService.ShowMessage(TYPE_TOAST.SUCCESS, "Correcto", "Archivo cargado correctamente");
  this.Form.get('logo')?.setValue(file);

  const reader = new FileReader();

  reader.onload = (e: any) => {
    // e.target.result contiene la URL de datos
    const imageUrl = e.target.result;
this.ImgDataUrl =e.target.result;
    // Ahora puedes usar imageUrl en tu atributo src

  };

  reader.readAsDataURL(file);
}
}


}
