import { Component, EventEmitter, Input, OnInit, Optional, Output, ViewChild, forwardRef } from '@angular/core';
import { AbstractControl, ControlContainer, FormControl, FormControlDirective, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Observable, filter } from 'rxjs';
import { CatalogsEnum, URL_ENDPOINTS } from 'src/app/system/core/enums/catalogs.model';
import { BaseControlValueAccessor } from 'src/app/system/core/models/control-accesor.model';
import { CatalogService } from 'src/app/system/services/catalogs.service';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectComponent),
      multi: true,
    },
  ],
})
export class SelectComponent extends BaseControlValueAccessor<any> implements OnInit {
  @ViewChild(FormControlDirective, { static: true }) formControlDirective:
    | FormControlDirective
    | undefined;
    @Input() idPlague: string = "";
    @Input() idGroup: string = "";

  data: any = [];
  @Input() isMultiple = false;
  @Input() UrlCatalog: string = URL_ENDPOINTS.CUSTOMERS;

  @Input() formControlName: string = '';
  @Input() isDropdown: boolean = true;
  @Input() customer: string = '';
  @Input() warehouse: string = '';
  @Input() isExpense: boolean = false;
  @Input() isMain: boolean = false;

  @Input() field: string = "name";
  @Output() enableOptions = new EventEmitter<boolean>();
  @Input() type: string = 'p-autocomplete';
  @Input() label: string = '';
  @Input() placeholder: string = 'Selecciona';
  @Input() Catalog: CatalogsEnum = CatalogsEnum.PLAGUES;
  @Input() Route: string = "";
  @Input() idCustomer: string = "";

  @Input() params: object = {};
  @Input() area: object = {};
  @Output() onSelect = new EventEmitter<any>();
  control: AbstractControl | any;
  form: FormGroup | any;

  observable: Observable<any> | undefined;

  constructor(
    @Optional() private controlContainer: ControlContainer,
    private CatalogService:CatalogService
  ) {
    super();
  }

  get c(): AbstractControl {
    return this.control;
  }

  get f(): FormGroup {
    return this.form;
  }

  ngOnInit(): void {
    if (this.formControlName && this.controlContainer) {
      this.control = this.controlContainer.control!.get(this.formControlName) as FormControl ;
      this.form = this.controlContainer.control as FormGroup;
    }




  }

  enableChildrens() {
    this.enableOptions.emit(false);
  }

  filterMultiSelect(event:any){

    let query = event.query;
    let filters = {
        search:query
        }

    this.CatalogService.getCatalog(this.Catalog,filters).subscribe((res) => {





     if(this.idPlague != ""){

        let array = res.filter((e:any)=>e?.category?.id == this.idPlague);


      if(array.length == 0){


        array = res.filter((e:any)=>e?.plague_category?.id == this.idPlague);
      }

        this.data = array;
        return
     }


     this.data = res;



  });
  }


  filterMultiSelectAdministrative(event:any){



    let query = event.query;
    let filters = {
        search:query,
        isMain:this.isMain,
        customer:this.customer
        }


if(this.isExpense){
  this.CatalogService.getCatalogAdministrativeExpense(this.Catalog,filters).subscribe((res) => {
    this.data = res;
 });

  return
}


    this.CatalogService.getCatalogAdministrative(this.Catalog,filters).subscribe((res) => {




     if(this.idGroup != ""){
      res.filter((employee:any)=> employee?.employee_group == this.idGroup)
     }



     if(this.idCustomer != ""){

      let array = res.filter((e:any)=>e?.customer == this.idCustomer);
console.log(array);

      this.data = array;
      return
   }






     this.data = res;

  });






  }
  filterMultiSelectPestControl(event:any){

    let query = event.query;
    let filters = {
        search:query,
        customer:this.customer ?? null,
        nesting_area__customer__id:this.customer ?? null
        }

    this.CatalogService.getCatalogPestControl(this.Catalog,filters).subscribe((res) => {
     this.data = res;

     if(filters.nesting_area__customer__id){
      this.data.forEach((item:any)  =>{


        item.completeNameStation =  item?.station_type?.name + ' - ' +  item?.name +  '-' + item?.key
    });
     }


  });
  }

  filterMultiSelectInventories(event:any){


    let query = event.query;
    let filters = {
        search:query,
        customer:this.customer ?? null,
        warehouse:this.warehouse ?? null
        }

    this.CatalogService.getCatalogInventories(this.Catalog,filters).subscribe((res) => {


      if(filters?.warehouse){
        this.data = res.map((objeto:any) => {
          // Crear un nuevo objeto con los datos del objeto original y el nuevo dato agregado
          return { ...objeto, name: objeto.product.name };
      });

return

      }



     this.data = res;
  });
  }



  select(object:any) : void {



    this.onSelect.emit(object);
  }


  filterMultiSelectGeneric(event:any){

    let query = event.query;
    let filters = {
        search:query,
        customer:this.customer ?? null,
        nesting_area__customer__id:this.customer ?? null
        }

    this.CatalogService.getCatalogGeneric(this.Route,filters).subscribe((res) => {
     this.data = res;




  });
  }



  /**COMPONENTE GENERICO Y UNICO */

  filterSelectCatalog(event:any){

    let query = event.query;
    let filters = {
        search:query,
        isMain:this.isMain,
        customer:this.customer
        }


    this.CatalogService.getCatalogSelect(this.UrlCatalog,filters).subscribe((res) => {

     this.data = res;

  });






  }

}
