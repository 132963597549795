<p-tabView [activeIndex]="index" (onChange)="handleChange($event)">
  <!--ZONAS-->
  <p-tabPanel
    *ngIf="Screen_Origin === 'BUSINESS-ACTIVITES' || Screen_Origin === 'CUSTOMERS' || Screen_Origin === 'CHECKPOINT'">
    <ng-template pTemplate="header">
      <span>Zonas</span>
    </ng-template>

    <div *ngIf="index == 0">
      <app-table-zones [idRegister]="idRegister"></app-table-zones>
    </div>
  </p-tabPanel>

  <!--AREAS-->
  <p-tabPanel *ngIf="Screen_Origin === 'BUSINESS-ACTIVITES' || Screen_Origin === 'CUSTOMERS'">
    <ng-template pTemplate="header">
      <span>Areas</span>
    </ng-template>

    <div *ngIf="index == 1">
      <app-table-areas [idRegister]="idRegister"></app-table-areas>
    </div>
  </p-tabPanel>

  <!--ESTACIONES-->
  <p-tabPanel *ngIf="Screen_Origin === 'BUSINESS-ACTIVITES' || Screen_Origin === 'CUSTOMERS'">
    <ng-template pTemplate="header">
      <span>Estaciones</span>
    </ng-template>

    <div *ngIf="index == 2">
      <app-table-stations [idRegister]="idRegister"></app-table-stations>
    </div>
  </p-tabPanel>

  <!--
    <p-tabPanel *ngIf="Screen_Origin === 'CUSTOMERS'">
    <ng-template  pTemplate="header">
      <span>Inspecciones</span>
    </ng-template>

    <div *ngIf="index == 3">

    </div>
  </p-tabPanel>
-->

  <p-tabPanel *ngIf="Screen_Origin === 'CHECKPOINT'">
    <ng-template pTemplate="header">
      <span>Perímetros</span>
    </ng-template>

    <div *ngIf="index == 1">
      <app-table-perimeters [idRegister]="idRegister"></app-table-perimeters>
    </div>
  </p-tabPanel>

  <p-tabPanel *ngIf="Screen_Origin === 'CHECKPOINT'">
    <ng-template pTemplate="header">
      <span> Punto Control</span>
    </ng-template>

    <div *ngIf="index == 2">
      <app-table-check-point [idRegister]="idRegister"></app-table-check-point>
    </div>
  </p-tabPanel>

  <!--Diagramas-->
  <p-tabPanel *ngIf="Screen_Origin === 'BUSINESS-ACTIVITES' || Screen_Origin === 'CUSTOMERS'">
    <ng-template pTemplate="header">
      <span>Diagrama</span>
    </ng-template>

    <div *ngIf="index == 3">
      <app-tree-diagram [idRegister]="idRegister"></app-tree-diagram>
    </div>
  </p-tabPanel>
</p-tabView>