import { LoadingService } from 'src/app/system/services/loading.service';
import { MipFormsService } from './../../../../../services/MipForms.service';
import { Component, Inject, Input, OnInit, ViewChild, inject } from '@angular/core';
import { MIP_FORM } from 'src/app/system/core/enums/MipForms.model';
import { DialogService } from 'primeng/dynamicdialog';
import { AddUpdateInspectionComponent } from './components/add-update-inspection/add-update-inspection.component';
import { MessagesService } from 'src/app/system/services/message.service';
import { TYPE_TOAST } from 'src/app/system/core/enums/ToastsEnums';
import { AddUpdatePlaguesComponent } from './components/add-update-plagues/add-update-plagues.component';
import { TablePlaguesComponent } from './components/table-plagues/table-plagues.component';
import { PhotosInspectionComponent } from './components/photos-inspection/photos-inspection.component';
import { PermissionsServices } from 'src/app/system/services/permissions.service';

@Component({
  selector: 'app-inspection',
  templateUrl: './inspection.component.html',
  styleUrls: ['./inspection.component.scss']
})
export class InspectionComponent implements OnInit {
  @ViewChild(TablePlaguesComponent, { static: false }) tableRef!: TablePlaguesComponent;
  public permissionsService = inject(PermissionsServices);
  index : number = 0;
  activeTabIndex: { [key: string]: number } = {};

Inspecciones:any[] = [];
constructor(@Inject('dynamicData') public Event: any, private MipFormsService:MipFormsService, private LoadingService:LoadingService, private DialogService:DialogService, private MessagesService:MessagesService){

}
handleChange(event: any) {


  this.index = event.index;
}
activeAccordionId: string | null = null;


handleAccordionClick(accordionId: string) {
  if (this.activeAccordionId === accordionId) {
    this.activeAccordionId = null;
  } else {
    this.activeAccordionId = accordionId;
  }
}
  ngOnInit(): void {
    console.log(this.Event);

setTimeout(() => {
  this.getInspections();
}, 0);
  }

  getInspections(){
    this.LoadingService.Show();
    this.MipFormsService.getDataForm(this.Event.id,MIP_FORM.INSPECTION_FORM).subscribe((res) => {
      this.Inspecciones = res;
      this.LoadingService.Hide();

    });
  }


  AddUpdate(Inspection: any,) {

     const dialog = this.DialogService.open(AddUpdateInspectionComponent, {
      header: "Inspección",
      modal: true,
      width: '50rem',
      //height:"100%",
      closable: true,
      draggable: false,
      maximizable: false,
      resizable: true,
      closeOnEscape: true,
      dismissableMask: true,
      data: {
        Inspection,
        Event:this.Event
      },
    });
    dialog.onClose.subscribe((res) => {
      if (res?.success) {
        this.getInspections();
      }
    });

  }
  deleteItem(item: any) {
    this.MessagesService.confirm(
      '¿Esta seguro de eliminar la inspección ' + item.plague_category.name + ' ?',
      () => {
        this.MipFormsService.deleteInspection(
          item.id
        ).subscribe((res) => {
          this.MessagesService.ShowMessage(TYPE_TOAST.SUCCESS,"Correcto","Se ha eliminado correctamente")
          this.getInspections();
        });
      }
    );
  }

  AddUpdateEvidence(Plague: any, Inspection:any) {
    const dialog = this.DialogService.open(AddUpdatePlaguesComponent, {
     header: "Plaga",
     modal: true,
     width: '40rem',
     //height:"100%",
     closable: true,
     draggable: false,
     maximizable: false,
     resizable: true,
     closeOnEscape: true,
     dismissableMask: true,
     data: {
       Inspection,
       Plague
     },
   });
   dialog.onClose.subscribe((res) => {
     if (res?.success) {
      this.tableRef.getInspectionData();
     }
   });

 }

 viewImages( Inspection:any) {
  const dialog = this.DialogService.open(PhotosInspectionComponent, {
   header: "Fotos Inspección " + Inspection.plague_category.name,
   modal: true,
   width: '50rem',
   //height:"100%",
   closable: true,
   draggable: false,
   maximizable: false,
   resizable: true,
   closeOnEscape: true,
   dismissableMask: true,
   data: {
     Inspection,
   },
 });
 dialog.onClose.subscribe((res) => {
   if (res?.success) {

   }
 });

}
}
