
import { Component } from '@angular/core';
import { LayoutService } from 'src/app/layout/service/app.layout.service';
import { UserService } from 'src/app/system/services/user.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
logoPestware = 'https://pestware-storage-dev.s3.amazonaws.com/companies/document_logos/02b3d6da-7de4-4b6d-abb4-5540091dc650.jpg'
    constructor(public layoutService: LayoutService, private UserService:UserService) {}

    get colorScheme(): string {
        return this.layoutService.config.colorScheme;
    }





    getDocumentLogo() {
     // if (this.UserService.Company?.document_logo) return this.UserService.Company?.document_logo; //+ '?' + this.company.updated_at;
      return this.UserService.LogoWeb + '?' + Math.random();;
    }

}
