

<form (ngSubmit)="accept()" [formGroup]="Form">
  <div class="grid formgrid  mt-4 ">


    <div class="col-12 mb-2  lg:col-12 lg:mb-0">
      <app-select  [Catalog]="Catalog.CLEANINGS" field ="name" [isDropdown] = "true" type="p-autocomplete" [isMultiple] = "false" label="Tipo condición" formControlName="cleaning"></app-select>
  </div>

  <div class="col-12 mb-2  lg:col-12 lg:mb-0 mt-3">
    <app-select [customer]="customer"  [Catalog]="Catalog.NESTING_AREAS" field ="name" [isDropdown] = "true" type="pest-control" [isMultiple] = "true" label="Areas" formControlName="nesting_area"></app-select>
</div>


  </div>
  <div class="d-flex justify-content-center mt-4">
    <button pButton pRipple type="submit" [disabled]="Form.invalid" label="Guardar" class="p-button-raised me-2 btn-sm"></button>
    <button (click)="cancel()" pButton pRipple type="button" label="Cancelar" class="p-button-raised p-button-secondary btn-sm"></button>
</div>
</form>
