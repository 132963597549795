import { Component, Input } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { TYPE_TOAST } from 'src/app/system/core/enums/ToastsEnums';
import { MipFormsService } from 'src/app/system/services/MipForms.service';
import { LoadingService } from 'src/app/system/services/loading.service';
import { MessagesService } from 'src/app/system/services/message.service';
import { AddUpdateTaskPlanComponent } from '../add-update-task-plan/add-update-task-plan.component';

@Component({
  selector: 'app-table-action-plan',
  templateUrl: './table-action-plan.component.html',
  styleUrls: ['./table-action-plan.component.scss']
})
export class TableActionPlanComponent {
  @Input() ActionPlan:any;
  @Input() Event:any;
  loading: boolean = false;
  index : number = 0;
  Tasks:any[] = [];

  constructor( private MipFormsService:MipFormsService, private LoadingService:LoadingService, private DialogService:DialogService, private MessagesService:MessagesService){

  }
  handleChange(event: any) {
    this.index = event.index;
  }
    ngOnInit(): void {


  setTimeout(() => {
    this.getTasks();
  }, 0);
    }

    getTasks(){
      this.LoadingService.Show();
      this.MipFormsService.getTasksForm(this.ActionPlan.id).subscribe((res) => {
      this.Tasks = res;
        this.LoadingService.Hide();

      });
    }




   deleteItem(item: any) {
    this.MessagesService.confirm(
      '¿Esta seguro de eliminar el registro ' + item.title + ' ?',
      () => {
        this.MipFormsService.deleteTask(
          item.id
        ).subscribe((res) => {
          this.MessagesService.ShowMessage(TYPE_TOAST.SUCCESS,"Correcto","Se ha eliminado correctamente")
          this.getTasks();
        });
      }
    );
  }

  updateTask(status: string,task:any) {

    const data = {
      status
    }
    this.LoadingService.Show();
    this.MipFormsService.patchTask(data, task.id).subscribe((res) => {
          this.getTasks();
      this.LoadingService.Hide();

    })

  }

  AddUpdateTask(Task: any) {


    const dialog = this.DialogService.open(AddUpdateTaskPlanComponent, {
     header: "Tarea",
     modal: true,
     width: '70%',
     closable: true,
     draggable: false,
     maximizable: false,
     resizable: true,
     closeOnEscape: true,
     dismissableMask: true,
     data: {
      Event:this.Event,
      ActionPlan:this.ActionPlan,
       Task
     },
   });
   dialog.onClose.subscribe((res) => {

    if (res?.success) {

      this.getTasks();
    }
  });


 }
}
