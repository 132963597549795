import { Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { CalendarService } from 'src/app/system/services/calendar.service';
import { DateService } from 'src/app/system/services/date.service';
import { LoadingService } from 'src/app/system/services/loading.service';
import { MessagesService } from 'src/app/system/services/message.service';
import { QuotesService } from 'src/app/system/services/quotes.service';
import { UserService } from 'src/app/system/services/user.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss']
})
export class DocumentsComponent {
  Event:any;
  Documents:any = [];
  routeDocument:string = environment.s3Url;
  constructor(
    private LoadingService: LoadingService,
    public config: DynamicDialogConfig,
    public ref: DynamicDialogRef,
    private CalendarService:CalendarService) { }

  ngOnInit() {
  this.Event = this.config.data.Event;

setTimeout(() => {
  this.getDocuments();
}, 0);

  }

getDocuments(){
  this.LoadingService.Show();
  this.CalendarService.getDocumentsById(this.Event.id).subscribe((res) => {

this.Documents = res.data;
console.log(this.Documents);
    this.LoadingService.Hide();

  });
}

dowloadDocument(item:any){
  this.LoadingService.Show();
  this.CalendarService.getDocument(item.url).subscribe((res) => {
let url = environment.s3Url + res.data;
console.log(url);
    window.open(url, '_blank');

        this.LoadingService.Hide();

      });



}
}
