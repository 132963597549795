<div class=" ">
  <label for="upload-image" class="hov-pointer   label-img">

    <div class="borde-shadow p-2 d-block   ">
      <br>
      <i *ngIf="!isUpload" class="fa fa-file fa-3x  "></i>
      <i *ngIf="isUpload && !isImage" class="fa fa-file-pdf text-danger fa-3x  "></i>
      <i *ngIf="isUpload && isImage" class="fa fa-image text-primary fa-3x  "></i>
      <p  class="mt-2">{{label}}</p>

    <!--   <p *ngIf="this.Form.value.document">{{this.Form.value.document.name}}</p>-->
    </div>
    <input id="upload-image" (change)="myUploader($event)" type="file" [accept]="TypeDocument" name="file"  style="display:none;"/>

  </label>
</div>


