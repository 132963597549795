import { Component, Input } from '@angular/core';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { MipFormsService } from 'src/app/system/services/MipForms.service';
import { LoadingService } from 'src/app/system/services/loading.service';
import { MessagesService } from 'src/app/system/services/message.service';
import { AddUpdateSignatureComponent } from '../../signature/components/add-update-signature/add-update-signature.component';
import { TYPE_TOAST } from 'src/app/system/core/enums/ToastsEnums';
import { CatalogsEnum } from 'src/app/system/core/enums/catalogs.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UtilsService } from 'src/app/system/services/utils.service';

@Component({
  selector: 'app-add-update-anexo',
  templateUrl: './add-update-anexo.component.html',
  styleUrls: ['./add-update-anexo.component.scss']
})
export class AddUpdateAnexoComponent {

  Catalog = CatalogsEnum;
  Form: FormGroup = this.FormBuilder.group({
    comments: ['', ],
    name: ['', [Validators.required]],
    id: [''],
    event: [''],
    document:['',[Validators.required]]
  });

  constructor(
    private UtilsService:UtilsService,
    private FormBuilder: FormBuilder,
    private LoadingService: LoadingService,
    public config: DynamicDialogConfig,
    public ref: DynamicDialogRef,
    private DialogService: DialogService,
    public MipFormsService: MipFormsService,
    private MessagesService: MessagesService,

  ) {}

  ngOnInit(): void {

    if (this.config.data.Anexo) {
      this.patchForm();
    }
  }


  patchForm() {
    let item = this.config.data.Anexo;

// this.image = item.signature + '?' + this.randomValue;

    this.Form.patchValue({
      name: item.name,
      comments: item.comments,
      document: item.document,
      id: item.id,
    event:item.event
    });
  }

  accept() {
    this.LoadingService.Show();
    if (!this.config.data.Anexo) {
      this.save();
      return;
    }
    this.update();
  }
  cancel() {
    this.ref?.close();
  }

  save() {


    let data = {
      document:this.Form.value.document,
      name: this.Form.value.name,
      comments: this.Form.value.comments,
      event:this.config.data.Event.id
    };


    this.MipFormsService.saveDataForm(this.UtilsService.objectToFormData(data), 'Attached-Document').subscribe((res)=>{
      this.LoadingService.Hide();
      this.MessagesService.ShowMessage(
          TYPE_TOAST.SUCCESS,
          'Correcto',
          'Guardado correctamente'
      );
      this.ref?.close({ success: true });
    });



  }

  update() {

    let data = {
      document:this.Form.value.document,
      name: this.Form.value.name,
      comments: this.Form.value.comments,
      event:this.config.data.Event.id,
      id:this.Form.value.id,
    };

    if(this.UtilsService.isString(data.document)){

      delete data.document;
    }


    this.MipFormsService.patchDataForm(
      this.UtilsService.objectToFormData(data),
      'Attached-Document',
      data.id
    ).subscribe((res) => {
      this.LoadingService.Hide();
      this.MessagesService.ShowMessage(
        TYPE_TOAST.SUCCESS,
        'Correcto',
        'Guardado correctamente'
      );
      this.ref?.close({ success: true });
    });
  }



  uploadFile(file:any){

    this.Form.get('document')?.setValue(file);
    }

    removeFile(){
        this.Form.patchValue({document:null});
    }



}
