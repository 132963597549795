import { LoadingService } from './loading.service';

import { Subject } from 'rxjs';

import { Injectable } from '@angular/core';
import {ConfirmationService, ConfirmEventType, MessageService} from "primeng/api";
import { DialogService } from 'primeng/dynamicdialog';
import { TYPE_TOAST } from '../core/enums/ToastsEnums';
import { DialogErrorComponent } from '../shared/components/dialog-error/dialog-error.component';
@Injectable({
  providedIn: 'root'
})
export class MessagesService {

labeBtnYes = "Aceptar";
labelBtnNo = "Cancelar"


  constructor( private confirmationService: ConfirmationService,    public dialogService: DialogService,       public messageService: MessageService) {

   }

  confirm(message: string, yesFn: () => void, cancelFn?:()=>void) {
    this.confirmationService.confirm({
        message,
        header: 'Confirmación',
        acceptLabel:"asde",
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          yesFn();
        },
        reject: (type:any) => {
          if(cancelFn){
            cancelFn();
          }

        }
    });
}

   ShowMessage(TYPE:string = TYPE_TOAST.SUCCESS, title: string, message: string,) {
    this.messageService.add({ key:"toast", severity: TYPE, summary: title, detail: message, life: 3500, });
  }


 /**
   * Component Error
   */
 ShowErrorDialog(error: any) {
    let sizeModal = '30%';
   // if (this.ResizeService.isXS || this.ResizeService.isSM) sizeModal = '90%'
    this.dialogService.open(DialogErrorComponent, {
      data:error ,
      header: "Error",
      width: sizeModal,
      dismissableMask: false
    });
  }



}
