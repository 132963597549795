

<p-tabView [activeIndex]="index" (onChange)="handleChange($event)">

    <p-tabPanel>
      <ng-template pTemplate="header">
        <span>Imagenes</span>
      </ng-template>
  
  
      <div *ngIf="index == 0" >
        <app-carousel-images-form [ShowNestingArea]="true" [FormValue]="FormValue"></app-carousel-images-form>
  
      </div>
  
    </p-tabPanel>
    <p-tabPanel  *appHasPermission="permissionsService.PERMISSIONS_ADD.MIP">
      <ng-template pTemplate="header">
        <span>Subir imagenes</span>
      </ng-template>
  
  
      <div *ngIf="index == 1">
  
        <app-multiple-files (uploadFiles)="onSelect($event)"></app-multiple-files>
  
  

  
        <div class="d-flex justify-content-center mb-2">
  
          <p-button
          (click)="save()"
            type="button"
            icon="fa-solid fa-save"
            styleClass="p-button-rounded p-button-outlined p-button-info ms-2 btn-sm "
          >
          </p-button>
  
      </div>
  
      </div>
  
    </p-tabPanel>
  </p-tabView>
  
  
  