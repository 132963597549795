<form [formGroup]="Form">
  <div class="grid formgrid">
    <div class="col-12 md:col-6 mt-4 ">
      <div>
        <span class="w-100 p-fluid p-float-label p p-input-icon-left">
          <i class="fa-solid fa-calendar"></i>
          <p-calendar [showIcon]="true" appendTo="body" formControlName="initial_date" [hourFormat]="12"
            [showTime]="false"></p-calendar>
          <label for="initial_date">Fecha inicial</label>
        </span>
      </div>
    </div>

    <div class="col-12 md:col-6 mt-4">
      <div>
        <span class="w-100 p-fluid p-float-label p p-input-icon-left">
          <i class="fa-solid fa-calendar"></i>
          <p-calendar [showIcon]="true" appendTo="body" formControlName="initial_hour" [timeOnly]="true"
            [hourFormat]="12" [showTime]="true"></p-calendar>
          <label for="initial_hour">Horario de inicio</label>
        </span>
      </div>
    </div>

    <div class="col-12 md:col-6 mt-4">
      <div>
        <span class="w-100 p-fluid p-float-label p p-input-icon-left">
          <i class="fa-solid fa-calendar"></i>
          <p-calendar [showIcon]="true" appendTo="body" formControlName="final_date" [hourFormat]="12"
            [showTime]="false"></p-calendar>
          <label for="final_date">Fecha final</label>
        </span>
      </div>
    </div>

    <div class="col-12 md:col-6 mt-4">
      <div>
        <span class="w-100 p-fluid p-float-label p p-input-icon-left">
          <i class="fa-solid fa-calendar"></i>
          <p-calendar [showIcon]="true" appendTo="body" formControlName="final_hour" [timeOnly]="true" [hourFormat]="12"
            [showTime]="true"></p-calendar>
          <label for="final_hour">Horario de finalización</label>
        </span>
      </div>
    </div>

    <div class="col-12 mt-4">
      <app-select [Catalog]="Catalog.EMPLOYEES" field="name" [isDropdown]="true" type="p-autocomplete-administrative"
        label="Empleado" formControlName="employee"></app-select>
    </div>
  </div>

  <div class="d-flex justify-content-center mt-4">
    <button pButton pRipple type="submit" (click)="accept()" [disabled]="Form.invalid" label="Guardar"
      class="p-button-raised me-2 btn-sm"></button>
    <button (click)="cancel()" pButton pRipple type="button" label="Cancelar"
      class="p-button-raised p-button-secondary btn-sm"></button>
  </div>
</form>