<form (ngSubmit)="accept()" [formGroup]="Form">
  <div class="grid formgrid  mt-4 ">
    <div class="col-12 mb-2  lg:col-12 lg:mb-0">
      <app-select
      [idPlague]="idPlague"
      [Catalog]="Catalog.PLAGUES"
      field ="name"
      [isDropdown] = "true"
      type="p-autocomplete"
      [isMultiple] = "false"
      label="Plaga"
      formControlName="plague"></app-select>
  </div>
  <div class="col-12 mb-2  mt-4  lg:col-12 lg:mb-0">
    <app-select [customer]="MipFormsService.CustomerIdMip" [Catalog]="Catalog.NESTING_AREAS" field ="name" [isDropdown] = "true" type="pest-control" [isMultiple] = "true" label="Areas" formControlName="nesting_area"></app-select>
  </div>
  <div class="col-12 mb-2  mt-4  lg:col-12 lg:mb-0">
    <app-select  [Catalog]="Catalog.INFESTATION_DEGREES" field ="name" [isDropdown] = "true" type="p-autocomplete" [isMultiple] = "false" label="Grado de infestación" formControlName="infestation_degree"></app-select>
</div>
<div class="col-12 mb-2  mt-4  lg:col-12 lg:mb-0">
  <app-select [idPlague]="idPlague" [Catalog]="Catalog.LIFE_CYCLE_PLAGUE_CATEGORIES" field ="name" [isDropdown] = "true" type="p-autocomplete" [isMultiple] = "true" label="Ciclo de vida" formControlName="life_cycle_plague"></app-select>
</div>
<div class="col-12 mb-2  mt-4  lg:col-12 lg:mb-0">
  <app-select  [idPlague]="idPlague" [Catalog]="Catalog.EVIDENCE_TYPES" field ="name" [isDropdown] = "true" type="p-autocomplete" [isMultiple] = "true" label="Evidencias" formControlName="evidence_type"></app-select>
</div>
  </div>
  <div class="d-flex justify-content-center mt-4">
    <button pButton pRipple type="submit" [disabled]="Form.invalid" label="Guardar" class="p-button-raised me-2 btn-sm"></button>
    <button (click)="cancel()" pButton pRipple type="button" label="Cancelar" class="p-button-raised p-button-secondary btn-sm"></button>
</div>
</form>

