import { UserService } from 'src/app/system/services/user.service';
import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { TYPE_TOAST } from 'src/app/system/core/enums/ToastsEnums';
import { CatalogsEnum, URL_ENDPOINTS } from 'src/app/system/core/enums/catalogs.model';
import { MipFormsService } from 'src/app/system/services/MipForms.service';
import { DateService } from 'src/app/system/services/date.service';
import { LoadingService } from 'src/app/system/services/loading.service';
import { MessagesService } from 'src/app/system/services/message.service';

@Component({
  selector: 'app-add-upadate-task',
  templateUrl: './add-upadate-task.component.html',
  styleUrls: ['./add-upadate-task.component.scss']
})
export class AddUpadateTaskComponent {
  Catalog = CatalogsEnum;
  Form: FormGroup = this.FormBuilder.group({
    title: ['', [Validators.required]],
    description: [''],
    task_type: ['', [Validators.required]],
    user: ['', [Validators.required]],
    nesting_area: ['', [Validators.required]],
    customer: ['', [Validators.required]],
    initial_date: ['', ],
    final_date: ['',],
    action_plan: ['', [Validators.required]],
    range_date: ['', [Validators.required]],
    id: [''],
    event:this.config?.data?.Event ?? "",
});

URL_CATALOGS = URL_ENDPOINTS;

Event:any;
constructor(
  private FormBuilder: FormBuilder,
  private LoadingService: LoadingService,
  public config: DynamicDialogConfig,
  public ref: DynamicDialogRef,
  private DialogService: DialogService,
  private MipFormsService:MipFormsService,
  private MessagesService: MessagesService,
private DateService:DateService,
private UserService:UserService
) {

}

ngOnInit(): void {

//this.Event = this.config.data.Event;

  if (this.config.data.Task) {


   this.patchForm();
  }
}

patchForm(){
  let item = this.config.data.Task;

const initialDate = new Date(Date.UTC(
  parseInt(item.initial_date.substring(0, 4)), // Año
  parseInt(item.initial_date.substring(5, 7)) - 1, // Mes (restamos 1 porque los meses en JavaScript son base 0)
  parseInt(item.initial_date.substring(8, 10)) +1, // Día
  0, 0, 0)); // Hora, minuto, segundo en UTC
const finalDate = new Date(Date.UTC(
  parseInt(item.final_date.substring(0, 4)), // Año
  parseInt(item.final_date.substring(5, 7)) - 1, // Mes (restamos 1 porque los meses en JavaScript son base 0)
  parseInt(item.final_date.substring(8, 10)) +1, // Día
  0, 0, 0));

  this.Form.patchValue({
    title: item.title,
    task_type: item.task_type,
    user:  item.user,
    nesting_area: item.nesting_area,
    customer: item.customer,
    initial_date:item.initial_date,
    final_date: item.final_date,
    description:  item.description,
     range_date:[initialDate, finalDate],
    id: item.id,
    action_plan:item.action_plan

  });
}

accept() {

  this.LoadingService.Show();
 if(!this.config.data.Task){

  this.save();
  return
 }
this.update();
}
cancel() {
  this.ref?.close();
}



  save(){


    let nesting_area =  this.Form.value?.nesting_area.map((item:any)=>item.id);

let data = {
  title:this.Form.value.title,
  description: this.Form.value.description,
  task_type:this.Form.value.task_type.id,
  user:this.Form.value.user.id,
  nesting_area,
  customer:this.Form.value.customer.id,
  initial_date: this.Form.value.initial_date ,
  final_date:this.Form.value.final_date ,
  action_plan:this.Form.value.action_plan.id,
  job_center:this.UserService.JobCenter,
  event:this.config?.data?.event ?? "",
  id_temp:'50d52b3a-f19e-4b24-93fd-cdb2964bb227'
}


  this.MipFormsService.saveTaskForm(data).subscribe((res)=>{
      this.LoadingService.Hide();
      this.MessagesService.ShowMessage(
          TYPE_TOAST.SUCCESS,
          'Correcto',
          'Guardado correctamente'
      );

      this.ref?.close({ success: true });
    });

  }

  update(){
    let nesting_area =  this.Form.value?.nesting_area.map((item:any)=>item.id);

    let data = {
      title:this.Form.value.title,
      description: this.Form.value.description,
      task_type:this.Form.value.task_type.id,
      user:this.Form.value.user.id,
      nesting_area,
      customer:this.Form.value.customer.id,
      initial_date: this.Form.value.initial_date,
      final_date:this.Form.value.final_date,
      action_plan:this.Form.value.action_plan.id,
      event:this.config?.data?.event ?? "",
      job_center:this.UserService.JobCenter,
      id_temp:'50d52b3a-f19e-4b24-93fd-cdb2964bb227',

     id:this.Form.value.id,
    }

      this.MipFormsService.putTaskForm(data).subscribe((res)=>{
          this.LoadingService.Hide();
          this.MessagesService.ShowMessage(
              TYPE_TOAST.SUCCESS,
              'Correcto',
              'Actualizado correctamente'
          );
          this.ref?.close({ success: true });
        });
  }

  searchDate() {
    let initial_date!: string, final_date: string;
    let range_date: string = this.Form.value.range_date[1];


    if (range_date !== null) {
      initial_date = this.DateService.getFormatDataDate(this.Form.value.range_date[0]);
      final_date = this.DateService.getFormatDataDate(this.Form.value.range_date[1]);


      this.Form.patchValue({
        initial_date: initial_date,
        final_date: final_date
      });



    }
  }
}
