import { Component, Input } from '@angular/core';
import { AddUpdatePhotoComponent } from '../add-update-photo/add-update-photo.component';
import { MipFormsService } from 'src/app/system/services/MipForms.service';
import { LoadingService } from 'src/app/system/services/loading.service';
import { DialogService, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { MessagesService } from 'src/app/system/services/message.service';
import { ProductService } from 'src/app/demo/service/product.service';
import { TYPE_TOAST } from 'src/app/system/core/enums/ToastsEnums';
import { CatalogsEnum } from 'src/app/system/core/enums/catalogs.model';
import { UtilsService } from 'src/app/system/services/utils.service';
import { MipPhotosForm } from '../../../generic-components/carousel-images-form/carousel-images-form.component';

@Component({
  selector: 'app-photos-inspection',
  templateUrl: './photos-inspection.component.html',
  styleUrls: ['./photos-inspection.component.scss']
})
export class PhotosInspectionComponent {
Inspection:any;
  loading: boolean = false;
  index : number = 0;
  Photos:any[] = [];
  files: File[] = [];
  Catalog = CatalogsEnum;
  randomValue = Math.random();
    responsiveOptions: any[] | undefined;
    FormValue:MipPhotosForm = {
      KeyForm:'inspection_form',
      ValueId:'',
      RouteForm:'/mip/inspection-forms-photo'
    };
  constructor( private UtilsService:UtilsService, public config: DynamicDialogConfig,public MipFormsService:MipFormsService, private LoadingService:LoadingService, private DialogService:DialogService, private MessagesService:MessagesService){

  }

  handleChange(event: any) {
    this.index = event.index;
  }
    ngOnInit(): void {


this.Inspection = this.config.data.Inspection
this.FormValue.ValueId =  this.Inspection.id;

    }



    onSelect(event:any) {
this.files = event;

    }

   async  save(){
      await this.saveImages(this.Inspection.id);


        }

        async saveImages(idPadre:string){
          for (const file of this.files) {
            const data = {
              photo: file,
              inspection_form: idPadre,
            };

            const formData = this.UtilsService.objectToFormData(data);
            await this.MipFormsService.saveDataForm(formData, 'inspection-forms-photo').toPromise();
          }

          // Mostrar alerta después de que todas las fotos se hayan guardado
          this.LoadingService.Hide();
          this.MessagesService.ShowMessage(TYPE_TOAST.SUCCESS,'Correcto','Guardado correctamente');

      this.index = 0;

        }
}
