import { Component, Inject, inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DialogService } from 'primeng/dynamicdialog';
import { AddUpadateTaskComponent } from 'src/app/system/Panel/administrative/tasks/componente/add-upadate-task/add-upadate-task.component';

import { TYPE_TOAST } from 'src/app/system/core/enums/ToastsEnums';
import { CatalogsEnum } from 'src/app/system/core/enums/catalogs.model';
import { LoadingService } from 'src/app/system/services/loading.service';
import { MessagesService } from 'src/app/system/services/message.service';
import { PermissionsServices } from 'src/app/system/services/permissions.service';
import { TasksService } from 'src/app/system/services/tasks.service';
import { UserService } from 'src/app/system/services/user.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-task',
  templateUrl: './task.component.html',
  styleUrls: ['./task.component.scss']
})
export class TaskComponent {
  public permissionsService = inject(PermissionsServices);

  draggedTask: any | undefined | null;


LisTaskInProgress:any = [];
ListTaskDone:any = [];
ListTaskTodo:any = [];


  loading: boolean = false;
  dataForm!: FormGroup;
  dataItems!: any[];
  Catalog = CatalogsEnum;
  s3Url: string = environment.s3Url;
  showCalendar:boolean = false;
  sidebarVisible2: boolean = false;
  headerToolbar:any  ={}
  constructor(@Inject('dynamicData') public Event: any,private TasksService:TasksService, private LoadingService:LoadingService, private DialogService:DialogService, private MessagesService:MessagesService,     private formBuilder: FormBuilder,    private UserService: UserService,){

  }

  ngOnInit() {
    this.loadDataForm();
    this.loadData();


  }



  loadData(): void {
    this.LoadingService.Show();
     this.TasksService.getTasks(this.dataForm.value,true).subscribe((response)=>{

this.dataItems = response.items;
this.dataForm.value.page_size = response.page_size;
this.dataForm.value.page = response.page;
this.dataForm.value.totalRecords = response.total;
   this.LoadingService.Hide();

       this.loading = false;
     });
   }
   loadDataForm(): void {
    this.dataForm = this.formBuilder.group({
      page_size: 10,
      first: 0,
      page: 0,
      rows: 10,
      search: '',
      totalRecords: 0,
      status:'',
      event:this.Event.id,
      //job_center: this.UserService.JobCenter,
    });
  }

  /**
   * Event Search Global
   * @param $event
   */
  getEventValue($event: any): void {
    this.dataForm.value.search = $event.target.value;
    this.loading = true;
    this.loadData();
  }

  resetFilter() {
    this.dataForm.patchValue({
      page_size: 10,
      first: 0,
      page: 0,
      rows: 10,
      search: '',
      totalRecords: 0,
      range_date: null,
      initial_date: null,
      final_date: null,
      zone: '',
      zone_search: '',
      business_activity_filter:'',
      business_activity:'',
     // job_center: this.UserService.JobCenter,
    });
    this.loadData();
  }

/**
 *
  dragStart(task: any) {
      this.draggedTask = task;
  }
  updateTask(status: string,task:any) {

    const data = {
      status
    }
    this.LoadingService.Show();
    this.TasksService.patchTask(data, task.id).subscribe((res) => {
      this.LoadingService.Hide();
          this.loadData();
          this.draggedTask = null;

    })

  }
  drop(newStatus:string) {

    if (this.draggedTask) {
      if(newStatus == "todo"){

        //id de status todo
        this.updateTask('50e5b0fb-637d-41c3-a6d4-57cbcc919587',this.draggedTask);

          }

          else if(newStatus == "inprogress"){

            //id de status inprogress
            this.updateTask('4989bc42-28ee-4dfe-a210-b0c0be87c11d',this.draggedTask);

              }
              else if(newStatus == "done"){

                //id de status done
                this.updateTask('57c95762-0823-42e8-996b-53ffe4af5e99',this.draggedTask);

                  }
    }




  }


  dragEnd() {
      this.draggedTask = null;
  }



 */

AddUpdate(){

}

  onPageChange(event: any) {
    this.dataForm.value.loading = true;
    this.dataForm.value.first = event.first;
    this.dataForm.value.page_size = event.rows;
    this.dataForm.value.pageCount = event.pageCount;
    this.dataForm.value.page = event.page + 1;
    this.loadData();
  }
  updateTask(status: string,task:any) {

    const data = {
      status
    }
    this.LoadingService.Show();
    this.TasksService.patchTask(data, task.id).subscribe((res) => {
          this.loadData();
      this.LoadingService.Hide();

    })

  }



  deleteTask(item: any) {
    this.MessagesService.confirm(
      '¿Esta seguro de eliminar la tarea ' + item.title + ' ?',
      () => {
        this.TasksService.deleteTask(
          item.id
        ).subscribe((res) => {
          this.MessagesService.ShowMessage(TYPE_TOAST.SUCCESS,"Correcto","Se ha eliminado correctamente")
          this.loadData();
        });
      }
    );
  }



  AddUpdateTask(Task: any) {
    let title = "Tarea " + (Task?.name ?? '');
     const dialog = this.DialogService.open(AddUpadateTaskComponent, {
      header: title,
      modal: true,
      width: '50%',
      //height:"100%",
      closable: true,
      draggable: false,
      maximizable: false,
      resizable: true,
      closeOnEscape: true,
      dismissableMask: true,
      data: {
        Task,
        event:this.Event.id
      },
    });
    dialog.onClose.subscribe((res) => {
      if (res?.success) {
        this.loadData();
      }
    });


  }
}
