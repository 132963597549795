import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { TYPE_TOAST } from 'src/app/system/core/enums/ToastsEnums';
import { CatalogsEnum } from 'src/app/system/core/enums/catalogs.model';
import { CalendarService } from 'src/app/system/services/calendar.service';
import { LoadingService } from 'src/app/system/services/loading.service';
import { MessagesService } from 'src/app/system/services/message.service';

@Component({
  selector: 'app-cancel-event',
  templateUrl: './cancel-event.component.html',
  styleUrls: ['./cancel-event.component.scss']
})
export class CancelEventComponent implements OnInit {
  Catalog = CatalogsEnum;
  Form: FormGroup = this.FormBuilder.group({
    id: '',
    cancellation_reason:['',Validators.required],
    cancellation_reason_comment:['',Validators.required],
    status:'c7707aea-a2e4-4bec-a02e-e68b7695d649' //status for event cancel
  });

  constructor(
    private MessagesService:MessagesService,
    private FormBuilder: FormBuilder,
    private LoadingService: LoadingService,
    public config: DynamicDialogConfig,
    public ref: DynamicDialogRef,
    private CalendarService:CalendarService,){

  }

  ngOnInit(): void {
    this.Form.patchValue({
      id:this.config.data.Event.id
    })
  }

  cancelEvent(){
let data = {
  "cancellation_reason": this.Form.value.cancellation_reason.id,
  "cancellation_reason_comment": this.Form.value.cancellation_reason_comment,
  "status": "d198f9d9-854a-4d38-8c9d-bb0413c46d77",
  id:this.Form.value.id,
}



this.LoadingService.Show();
this.CalendarService.cancelEvent(data).subscribe((res)=>{
  this.ref?.close({ success: true });
  this.LoadingService.Hide();
  this.MessagesService.ShowMessage(
    TYPE_TOAST.SUCCESS,
    'Correcto',
    'Cancelado correctamente'
  );
})

  }
}
