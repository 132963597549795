import { InputMaskModule } from 'primeng/inputmask';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { FullCalendarModule } from '@fullcalendar/angular';
import { AccordionModule } from "primeng/accordion";
import { ConfirmationService, MessageService } from 'primeng/api';
import { AutoCompleteModule } from "primeng/autocomplete";
import { AvatarModule } from "primeng/avatar";
import { AvatarGroupModule } from 'primeng/avatargroup';
import { BadgeModule } from "primeng/badge";
import { ButtonModule } from "primeng/button";
import { CalendarModule } from "primeng/calendar";
import { CardModule } from "primeng/card";
import { CarouselModule } from "primeng/carousel";
import { ChartModule } from 'primeng/chart';
import { CheckboxModule } from "primeng/checkbox";
import { ChipModule } from "primeng/chip";
import { ColorPickerModule } from "primeng/colorpicker";
import { ConfirmDialogModule } from "primeng/confirmdialog";
import { ConfirmPopupModule } from "primeng/confirmpopup";
import { ContextMenuModule } from "primeng/contextmenu";
import { DialogModule } from "primeng/dialog";
import { DividerModule } from "primeng/divider";
import { DropdownModule } from "primeng/dropdown";
import { DynamicDialogModule } from "primeng/dynamicdialog";
import { EditorModule } from "primeng/editor";
import { FieldsetModule } from "primeng/fieldset";
import { FileUploadModule } from "primeng/fileupload";
import { GalleriaModule } from "primeng/galleria";
import { ImageModule } from "primeng/image";
import { InputNumberModule } from 'primeng/inputnumber';
import { InputSwitchModule } from "primeng/inputswitch";
import { InputTextModule } from "primeng/inputtext";
import { InputTextareaModule } from "primeng/inputtextarea";
import { KeyFilterModule } from 'primeng/keyfilter';
import { KnobModule } from 'primeng/knob';
import { ListboxModule } from "primeng/listbox";
import { MenuModule } from "primeng/menu";
import { MenubarModule } from "primeng/menubar";
import { MessageModule } from "primeng/message";
import { MessagesModule } from "primeng/messages";
import { MultiSelectModule } from "primeng/multiselect";
import { OverlayPanelModule } from "primeng/overlaypanel";
import { PaginatorModule } from "primeng/paginator";
import { PanelModule } from 'primeng/panel';
import { PanelMenuModule } from "primeng/panelmenu";
import { PasswordModule } from "primeng/password";
import { ProgressBarModule } from 'primeng/progressbar';
import { RadioButtonModule } from "primeng/radiobutton";
import { RippleModule } from 'primeng/ripple';
import { ScrollPanelModule } from "primeng/scrollpanel";
import { SidebarModule } from 'primeng/sidebar';
import { StepsModule } from "primeng/steps";
import { StyleClassModule } from 'primeng/styleclass';
import { TableModule } from "primeng/table";
import { TabMenuModule } from "primeng/tabmenu";
import { TabViewModule } from "primeng/tabview";
import { TagModule } from "primeng/tag";
import { TimelineModule } from "primeng/timeline";
import { ToastModule } from "primeng/toast";
import { TooltipModule } from 'primeng/tooltip';
import { TreeModule } from 'primeng/tree';
import { EventService } from '../../demo/service/event.service';
//import { PickListModule } from 'primeng/picklist';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { OrganizationChartModule } from 'primeng/organizationchart';
import { SpeedDialModule } from 'primeng/speeddial';

@NgModule({
  declarations: [],
  exports: [
    BreadcrumbModule,
    ButtonModule,
    CardModule,
    MenubarModule,
    FieldsetModule,
    ListboxModule,
    AutoCompleteModule,
    RadioButtonModule,
    InputSwitchModule,
    ChipModule,
    TagModule,

    InputTextModule,
    BadgeModule,
    AvatarModule,
    MenuModule,
    PanelMenuModule,
    CalendarModule,
    PasswordModule,
    DividerModule,
    DropdownModule,
    DialogModule,
    CheckboxModule,
    KeyFilterModule,
    ScrollPanelModule,
    ///PickListModule,
    FileUploadModule,
    DynamicDialogModule,
    MessageModule,
    MessagesModule,
    TableModule,
    MultiSelectModule,
    TabMenuModule,
    TabViewModule,
    ContextMenuModule,
    ConfirmDialogModule,
    ToastModule,
    StepsModule,
    ColorPickerModule,
    ImageModule,
    PaginatorModule,
    InputTextareaModule,
    OverlayPanelModule,
    EditorModule,
    ConfirmPopupModule,
    GalleriaModule,
    PanelModule,
    KnobModule,
    SidebarModule,
    AccordionModule,
    TreeModule,
    TimelineModule,
    CarouselModule,
    FullCalendarModule,
    CalendarModule,
    RippleModule,

    TooltipModule,

    InputNumberModule,
    ChartModule,
    FormsModule,

    AvatarGroupModule,

    ProgressBarModule,

    InputMaskModule,

    OrganizationChartModule,
    StyleClassModule,
    BadgeModule,
    RadioButtonModule,
    InputSwitchModule,
    RippleModule,
    TooltipModule,
    SpeedDialModule


  ],
  imports: [
    CommonModule,

  ],
  providers: [EventService, MessageService, ConfirmationService]
})
export class PrimengModule { }
