<form  [formGroup]="Form">

  <div class="grid formgrid   ">

    <div class="col-12  mt-4">
      <app-select
      [Catalog]="Catalog.EVENT_TYPES"
      field="name"
      [isDropdown]="true"
      type="p-autocomplete"
      [isMultiple]="false"
      label="Tipo evento"
      formControlName="event_type"
    ></app-select>
  </div>

  <div class="col-12  mt-4">
    <app-select
    [Catalog]="Catalog.EMPLOYEES"
    field="name"
    [isDropdown]="true"
    type="p-autocomplete-administrative"
    [isMultiple]="false"
    label="Técnico"
    formControlName="employee"
  ></app-select>
</div>
<div class="col-12  mt-4">

  <app-select

  [isMain]="false"
  [UrlCatalog]="URL_CATALOGS.CUSTOMERS_DROPDOWN"
  field ="name"
  [isDropdown] = "false"
  type="select-generic"
  [isMultiple] = "false"
  label="Cliente"
  formControlName="customer">
</app-select>


</div>

<div class="col-12  mt-4">
  <app-select
  [Catalog]="Catalog.QUOTES"
  field="folio"
  [isDropdown]="false"
  type="p-autocomplete-administrative"
  [isMultiple]="false"
  label="Cotización"
  formControlName="quote"
></app-select>
</div>
<div class="col-12  mt-4">
  <app-select
  [Catalog]="Catalog.SERVICE_TYPE"
  field="name"
  [isDropdown]="true"
  type="p-autocomplete"
  [isMultiple]="false"
  label="Tipo servicio"
  formControlName="service_type"
></app-select>
</div>
<div class="col-12  mt-4">
  <app-select
  [Catalog]="Catalog.TICKETS"
  field="folio"
  [isDropdown]="true"
  type="p-autocomplete-administrative"
  [isMultiple]="false"
  label="Ticket"
  formControlName="ticket"
></app-select>
</div>
  </div>
</form>

<div class="text-center mt-4">
  <button (click)="filterCalendar()"   icon="pi pi-filter" pButton pRipple type="submit"  label="Filtrar tabla" class="p-button-raised btn-sm p-button-success"></button>

</div>
