import { Component, Input } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { MipFormsService } from 'src/app/system/services/MipForms.service';
import { LoadingService } from 'src/app/system/services/loading.service';
import { MessagesService } from 'src/app/system/services/message.service';
import { AddUpdateControlFormComponent } from '../add-update-control-form/add-update-control-form.component';
import { TYPE_TOAST } from 'src/app/system/core/enums/ToastsEnums';
import { MIP_FORM } from 'src/app/system/core/enums/MipForms.model';
import { AddUpdateProductsComponent } from '../add-update-products/add-update-products.component';

@Component({
  selector: 'app-table-control',
  templateUrl: './table-control.component.html',
  styleUrls: ['./table-control.component.scss']
})
export class TableControlComponent {
@Input()  Event:any;
@Input() Control:any;
  loading: boolean = false;
  index : number = 0;
  Products:any[] = [];
  randomValue = Math.random();
  constructor( private MipFormsService:MipFormsService, private LoadingService:LoadingService, private DialogService:DialogService, private MessagesService:MessagesService){

  }
  handleChange(event: any) {
    this.index = event.index;
  }
    ngOnInit(): void {

  setTimeout(() => {
    this.getProducts();
  }, 0);
    }


    getProducts(){

      this.LoadingService.Show();
      this.MipFormsService.getProductsForm(this.Control.id).subscribe((res) => {

        this.Products = res;
        this.LoadingService.Hide();

      });

    }



   deleteItem(item: any) {

    this.MessagesService.confirm(
      '¿Esta seguro de eliminar el registro ' +  item.product.name  + ' ?',
      () => {
        this.MipFormsService.deleteProduct(
          item.id
        ).subscribe((res) => {
          this.MessagesService.ShowMessage(TYPE_TOAST.SUCCESS,"Correcto","Se ha eliminado correctamente")
          this.getProducts();
        });
      }
    );
  }



  AddUpdateProducts(Product: any) {

    const dialog = this.DialogService.open(AddUpdateProductsComponent, {
     header: "Producto",
     modal: true,
     width: '30rem',
     closable: true,
     draggable: false,
     maximizable: false,
     resizable: true,
     closeOnEscape: true,
     dismissableMask: true,
     data: {
      Control:this.Control,
      Event:this.Event,
      Product
     },
   });

   dialog.onClose.subscribe((res) => {

     if (res?.success) {

      this.getProducts();
     }
   });

 }
}
