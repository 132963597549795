import { Component } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-dialog-error',
  templateUrl: './dialog-error.component.html',
  styleUrls: ['./dialog-error.component.scss']
})
export class DialogErrorComponent {
    data!: Response;

    constructor(public ref: DynamicDialogRef,
                public config: DynamicDialogConfig) { }

    ngOnInit(): void {
      this.data = this.config.data;
      console.log(this.data);

    }

    close() : void {
      this.ref.close();
    }
}
