<p-tabView [activeIndex]="index" (onChange)="handleChange($event)">
  <!--CORREO-->
 <p-tabPanel >
   <ng-template pTemplate="header">
     <span>Enviar por Correo</span>
   </ng-template>


   <div *ngIf="index == 0">
    <form (ngSubmit)="sedQuoteEmail()" [formGroup]="FormEmail">

      <div class="grid formgrid  ">
        <div class="col-12 mb-2 mt-4  lg:col-12 lg:mb-0">
          <span class="w-100 p-fluid p-float-label p p-input-icon-left">
              <i class="fa-solid fa-envelope"></i>
              <input formControlName="customer_email"  type="email" pInputText placeholder="Correo electrónico"/>
              <label for="customer_email">Correo electrónico</label>
          </span>
      </div>

  



      </div>




      <div class="d-flex justify-content-center mt-4">
          <button pButton pRipple type="submit" [disabled]="FormEmail.invalid" label="Enviar" class="p-button-raised me-2 btn-sm"></button>
          <button (click)="cancel()" pButton pRipple type="button" label="Cancelar" class="p-button-raised p-button-secondary btn-sm"></button>
      </div>
    </form>
   </div>




 </p-tabPanel>

  <!--WHATSAPP-->
 <p-tabPanel *ngIf="!Event" >
   <ng-template pTemplate="header">
     <span>Enviar por whatsapp</span>
   </ng-template>

  <div *ngIf="index == 1">
    <form (ngSubmit)="sedQuoteWhats()" [formGroup]=" FormWhats">

      <div class="grid formgrid  ">
        <div class="col-12 mb-2 mt-4  lg:col-12 lg:mb-0">
          <span class="w-100 p-fluid p-float-label p p-input-icon-left">
            <p-inputMask mask="(999) 999-9999" formControlName="phone" placeholder="(999) 999-9999"></p-inputMask>
          </span>
      </div>





      </div>




      <div class="d-flex justify-content-center mt-4">
        <button *ngIf="index == 1" pButton pRipple type="submit" [disabled]="FormWhats.invalid" label="Enviar" class="p-button-raised me-2 btn-sm"></button>
          <button  *ngIf="index == 0" pButton pRipple type="submit" [disabled]="FormEmail.invalid" label="Enviar" class="p-button-raised me-2 btn-sm"></button>
          <button (click)="cancel()" pButton pRipple type="button" label="Cancelar" class="p-button-raised p-button-secondary btn-sm"></button>
      </div>
    </form>

   </div>
 </p-tabPanel>


</p-tabView>
