<form (ngSubmit)="accept()" [formGroup]="Form">
  <div class="grid formgrid mt-4">
    <div class="col-12 mb-2 lg:col-12 lg:mb-0 mt-3">
      <app-select [customer]="Event.customer.id" [Catalog]="Catalog.NESTING_AREAS" field="name" [isDropdown]="true"
        type="pest-control" [isMultiple]="false" label="Área" formControlName="nesting_area"></app-select>
    </div>

    <div class="col-12 mb-2 mt-4 lg:col-12 lg:mb-0">
      <app-select [customer]="Event.customer.id" [Catalog]="Catalog.AREA_ACTIVITY" field="name" [isDropdown]="true"
        type="p-autocomplete" [isMultiple]="true" label="Actividad en área"
        formControlName="activity_area"></app-select>
    </div>

    <div class="col-12 mb-2 mt-4 lg:col-12 lg:mb-0">
      <app-select [Catalog]="Catalog.AREA_CONDITIONS" field="name" [isDropdown]="true" type="p-autocomplete"
        [isMultiple]="true" label="Condiciones área " formControlName="condition_area"></app-select>
    </div>

    <div class="col-12 mb-2 mt-4 lg:col-12 lg:mb-0">
      <app-select [Catalog]="Catalog.PLAGUES" field="name" [isDropdown]="true" type="p-autocomplete" [isMultiple]="true"
        label="Plaga" formControlName="plague"></app-select>
    </div>

    <div class="col-12 mb-2 mt-4 lg:col-12 lg:mb-0">
      <span class="p-fluid mt-5">
        <textarea formControlName="comments" placeholder="Comentarios" rows="3" cols="30"
          pInputTextarea></textarea>
      </span>
    </div>
  </div>

  <div class="d-flex justify-content-center mt-4">
    <button pButton pRipple type="submit" [disabled]="Form.invalid" label="Guardar"
      class="p-button-raised me-2 btn-sm"></button>

    <button (click)="cancel()" pButton pRipple type="button" label="Cancelar"
      class="p-button-raised p-button-secondary btn-sm"></button>
  </div>
</form>
