import { Component, Input, inject } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { MipFormsService } from 'src/app/system/services/MipForms.service';
import { LoadingService } from 'src/app/system/services/loading.service';
import { MessagesService } from 'src/app/system/services/message.service';
import { AddUpdateSignatureComponent } from '../../signature/components/add-update-signature/add-update-signature.component';
import { TYPE_TOAST } from 'src/app/system/core/enums/ToastsEnums';
import { AddUpdateAnexoComponent } from '../add-update-anexo/add-update-anexo.component';
import { PermissionsServices } from 'src/app/system/services/permissions.service';

@Component({
  selector: 'app-table-anexo',
  templateUrl: './table-anexo.component.html',
  styleUrls: ['./table-anexo.component.scss']
})
export class TableAnexoComponent {
  @Input()  Event:any;
  loading: boolean = false;
  Anexos:any[] = [];
  public permissionsService = inject(PermissionsServices);
  randomValue = Math.random();
  constructor( private MipFormsService:MipFormsService, private LoadingService:LoadingService, private DialogService:DialogService, private MessagesService:MessagesService){

  }
  openSignature(item: any): void {
    window.open(item.signature);
  }

    ngOnInit(): void {


  setTimeout(() => {
    this.getAnexos();
  }, 0);
    }

    getAnexos(){
      this.LoadingService.Show();
      this.MipFormsService.getDataForm(this.Event.id,'Attached-Document').subscribe((res) => {
        console.log(res);

        this.Anexos = res;
        this.LoadingService.Hide();

      });
    }


    AddUpdate(Anexo: any,) {
      const dialog = this.DialogService.open(AddUpdateAnexoComponent, {
       header: "Anexo",
       modal: true,
       width: '37rem',
       closable: true,
       draggable: false,
       maximizable: false,
       resizable: true,
       closeOnEscape: true,
       dismissableMask: true,
       data: {
        Event:this.Event,
        Anexo
       },
     });
     dialog.onClose.subscribe((res) => {
       if (res?.success) {
         this.getAnexos();
       }
     });

   }
    deleteItem(item: any) {
      this.MessagesService.confirm(
        '¿Esta seguro de eliminar el documento de ' + item.name + ' ?',
        () => {
          this.MipFormsService.deleteDataForm(
            item.id,
            'Attached-Document'
          ).subscribe((res) => {
            this.MessagesService.ShowMessage(TYPE_TOAST.SUCCESS,"Correcto","Se ha eliminado correctamente")
            this.getAnexos();
          });
        }
      );
    }
}
