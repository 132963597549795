<div class="grid formgrid">
  <div class="col-12  mt-4">
    <h3>¿Estas seguro que deseas reactivar el evento?</h3>
  </div>
</div>

<div class="text-center mt-3  mb-4 ">
  <button (click)="reactivateEvent()" pButton pRipple type="submit" label="Confirmar"
    class="p-button-raised btn-sm"></button>
</div>

<div class="text-center mt-3  mb-4 ">
</div>
